import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MiniWidget from '../Dashboard/mini-widget';


function Breadcrumb(props) {
  const [personName, setPersonName] = React.useState(["This Month"]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };
  const names = [
    'This Month',
    'Previous Month',
  ];
  const handleChange = (event) => {
    const { target: { value } } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
    props?.emitFilterItem(value)
  };

  const borderBottomStyle = props.breadcrumbItem === 'Stats' ? '1px solid #CAD2DF' : 'none';
  return (
    <div className="page-title-box pb-2 d-flex align-items-center justify-content-between w-100" style={{ borderBottom: borderBottomStyle }}>
      <div className='d-flex align-items-center'>
        <h4 className="me-2 mb-0" style={{ color: '#565656', marginRight: '2px' }}>{props.breadcrumbItem}</h4>
        <div className='dashboard_upper_cards upper_card_board'>
          <MiniWidget reports={props.reports} />
        </div>
      </div>
      <div className="page-title-right">
        <ol className="breadcrumb m-0">
          {/* <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem> */}
          {props.breadcrumbItem === 'Stats' && (
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={personName}
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: 111, height: 31, fontSize: '12px', backgroundColor: '#fff' }}
            >
              {/* <MenuItem value="This Month">This Month</MenuItem> */}
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        </ol>
      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
