import React, { useState } from 'react';
import Select from 'react-select';
import ConfirmationModal from '../Common/ConfirmationModal';
import { ACTIONS, PROPERTY_STATUS } from '../../common/constant';
import { createTheme } from '@mui/material';

const options = Object.keys(PROPERTY_STATUS).map((key) => ({ value: PROPERTY_STATUS[key], label: PROPERTY_STATUS[key] }));
const colors = createTheme()?.palette;

const customStyles = {
  control: (provided, state) => {
    let backgroundColor = 'transparent';

    if (state.selectProps.value) {
      const selectedOption = state.selectProps.options.find(
        (option) => option.value === state.selectProps.value.value,
      );

      backgroundColor = selectedOption.value === PROPERTY_STATUS.ACTIVE
        ? colors.primary.main
        : selectedOption.value === PROPERTY_STATUS.LIVE
          ? colors.success.main
          : selectedOption.value === PROPERTY_STATUS.SUSPENDED
            ? colors.warning.main :
            selectedOption.value === PROPERTY_STATUS.CLOSED
              ? colors.error.main : 'transparent';
    }

    return {
      ...provided,
      backgroundColor,
    };
  },
  menu: () => ({ borderRadius: '20px', overflow: 'hidden', backgroundColor: '#fff' }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.value === PROPERTY_STATUS.SUSPENDED ? colors.warning.contrastText :
      state.data.value === PROPERTY_STATUS.ACTIVE ? colors.primary.contrastText :
        state.data.value === PROPERTY_STATUS.LIVE ? colors.success.contrastText :
          state.data.value === PROPERTY_STATUS.CLOSED ? colors.error.contrastText :
            '#000000', // Default color,
  }),
};

export default function SelectBox({ selectedOption, handleChange, item, hasEditPermission }) {
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });


  return (
    <>
      <Select
        isDisabled={hasEditPermission !== undefined && !hasEditPermission}
        value={selectedOption ?? options.find((op) => op.value === item.status)}
        onChange={(newValue) => { setEditConfirm({ status: true, id: item.id, data: newValue }) }}
        options={options}
        className="customSelectfilter"
        styles={customStyles}
        menuPortalTarget={document.body}
      />

      <ConfirmationModal
        action={ACTIONS.EDIT}
        show={isEditConfirm?.status}
        onCloseClick={() =>
          setEditConfirm({ status: false, id: null, data: null })
        }
        onAcceptClick={async () => {
          const isUpdated = handleChange(item, isEditConfirm?.data)
          if (isUpdated) {
            setEditConfirm({ status: false, id: null });
          }
        }}
      />
    </>
  );
}
