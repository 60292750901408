import React from 'react';
import Select from 'react-select';
import { SCHEDULE_PROPERTY_VISIT_STATUS } from '../../common/constant';
import { createTheme } from '@mui/material';

const options = [
  { value: SCHEDULE_PROPERTY_VISIT_STATUS.NOT_VISITED, label: SCHEDULE_PROPERTY_VISIT_STATUS.NOT_VISITED },
  { value: SCHEDULE_PROPERTY_VISIT_STATUS.VISITED, label: SCHEDULE_PROPERTY_VISIT_STATUS.VISITED },
];
const colors = createTheme()?.palette;

// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.inputValue === 'Incomplete'
//     ? 'lightcoral !important'  // Light red background for Incomplete
//     : state.inputValue === 'Complete'
//     ? 'lightyellow'
//     : 'transparent',
//   }),
//   singleValue: (provided, state) => ({
//     ...provided,
//     color: state.data.value === 'Incomplete' ? 'red' : 'yellow',
//   }),
// };

const customStyles = {
  control: (provided, state) => {
    let backgroundColor = 'transparent';

    if (state.selectProps.value) {
      const selectedOption = state.selectProps.options.find(
        (option) => option.value === state.selectProps.value.value,
      );

      backgroundColor = selectedOption.value === SCHEDULE_PROPERTY_VISIT_STATUS.NOT_VISITED
        ? colors.error.main
        : selectedOption.value === SCHEDULE_PROPERTY_VISIT_STATUS.VISITED
          ? colors.success.main
          : 'transparent';
    }

    return {
      ...provided,
      backgroundColor,
    };
  },
  menu:(base)=> ({borderRadius:'20px', overflow: 'hidden', backgroundColor: '#fff' }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.value === SCHEDULE_PROPERTY_VISIT_STATUS.NOT_VISITED ? colors.error.contrastText : colors.success.contrastText,
  }),
};

class SelectBox extends React.Component {
  render() {
    const { selectedOption, handleChange, item } = this.props;

    return (
      <Select
        value={selectedOption ?? options.find((op) => op.value === item.status)}
        onChange={(newValue) => { handleChange(item, newValue) }}
        options={options}
        className="customSelectfilter"
        styles={customStyles}
        menuPortalTarget={document.body}
      />
    );
  }
}

export default SelectBox;
