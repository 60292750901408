import React, { useState } from 'react';
import {
  Button, InputGroup, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import GlobalInnerHeader from './globalInnerHeader';

function Reminders() {
  const [data_attr, setdata_attr] = useState(1000);
  const [activeTab1, setactiveTab1] = useState('5');
  const [selectedMulti, setselectedMulti] = useState(null);

  const optionGroup = [
    {
      label: 'Day',
      options: [
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
        { label: '25', value: '25' },
        { label: '26', value: '26' },
        { label: '27', value: '27' },
        { label: '28', value: '28' },
        { label: '29', value: '29' },
        { label: '30', value: '30' },
        { label: '31', value: '31' },
      ],
    },
  ];

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  }
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Reminders" />
      <div className="settingBody settings_holder mt-4">
        <Nav pills>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '5',
              })}
              onClick={() => {
                toggle1('5');
              }}
            >
              <span>Rent Payment</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '6',
              })}
              onClick={() => {
                toggle1('6');
              }}
            >
              <span>Rent Missed</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab1} className="p-3 text-muted">
          <TabPane tabId="5">
            <span>Set the rent payment reminder on</span>
            <div className="d-inline-block mx-2">
              <Select
                className="d-inline-flex"
                value={selectedMulti}
                isMulti
                onChange={() => {
                  handleMulti();
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <span>th day</span>

            <div className="mt-4">
              <textarea
                id="paymentReminderAlert"
                name="paymentReminderAlert"
                rows="4"
                className="form-control"
                placeholder="Payment Reminder Alert"
                value=""
              />
            </div>

            <div className="text-end mt-4">
              <Button className="yellow_gradient_btn">Save</Button>
            </div>
          </TabPane>
          <TabPane tabId="6">
            <span>Set the rent missed reminder on</span>
            <div className="d-inline-block mx-2">
              <Select
                className="d-inline-flex"
                value={selectedMulti}
                isMulti
                onChange={() => {
                  handleMulti();
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            </div>
            <span>th day</span>

            <div className="mt-4">
              <textarea
                id="missedReminderAlert"
                name="missedReminderAlert"
                rows="4"
                className="form-control"
                placeholder="Missed Reminder Alert"
                value=""
              />
            </div>

            <div className="text-end mt-4">
              <Button className="yellow_gradient_btn">Save</Button>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

export default Reminders;
