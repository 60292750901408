import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { fetchAllActivity } from "../../../api/activity";
import { toast } from "react-toastify";
import { ACTIONS, ALL_MODULES, DEFAULT_DATETIME_FORMAT } from "../../../common/constant";
import { getInitials } from "../../../constants/kanban_helper";
import { removeSpecial } from "../../../helpers/string_helper";
import dayjs from "dayjs";

function ActivityTimeline(props) {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [timeLineData, setTimeLineData] = useState([]);
  const [meta, setMeta] = useState({});
  const getAllActivityData = async (limit, page) => {
    const paramData = {
      limit,
      page,
      module: props.module,
      module_id: +props.module_id,
    };
    try {
      const responseActivity = await fetchAllActivity(paramData);
      setTimeLineData((old) => [
        ...old,
        ...(responseActivity?.data?.data?.data || ""),
      ]);
      setMeta(responseActivity?.data?.data?.meta);
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    props.module_id && getAllActivityData(limit, page);
  }, [props.module_id]);

  const scrollHandler = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 2) {
      if (meta?.current_page < meta?.last_page) {
        getAllActivityData(limit, page + 1);
        setPage((o) => o + 1);
      }
    }
  };

  const getDate = (date) => {
    const dateArray = dayjs(date).format(DEFAULT_DATETIME_FORMAT).split(' ')
    return `${dateArray[0]} at ${dateArray[1]} ${dateArray[2]}`
  }

  function getList(item) {
    switch (item.event) {
      case ACTIONS.CREATE:
        return (
          <>
            <div
              className="named_picture me-2"
              style={{ fontWeight: "bolder" }}
            >
              {getInitials(item?.user?.name)}
            </div>

            <div className="activity_status">
              <span>
                {item?.user?.name} has created a{" "}
                {removeSpecial(item?.payload?.actual_updated_property)}
              </span>
              {item?.payload?.name ? (
                <>
                  <span>&nbsp;for</span>
                  <span className="activity_user">
                    &nbsp;{item?.payload?.name}
                  </span>
                </>
              ) : item?.payload?.created_property_name ? (
                <>
                  <span>&nbsp;with name</span>
                  <span className="activity_user">
                      &nbsp;{item?.payload?.created_property_name}
                  </span>
                </>
              ) : (
                " "
              )}
              &nbsp;on &nbsp;<span>{getDate(item?.created_at)}</span>
            </div>
          </>
        );
      case ACTIONS.EDIT:
        return (
          <>
            {item?.payload?.actual_updated_property ? (
              <>
                <div
                  className="named_picture me-2"
                  style={{ fontWeight: "bolder" }}
                >
                  {getInitials(item?.user?.name)}
                </div>

                <div style={{ width: "90%" }} className="activity_status">
                  <span>
                    {item?.user?.name} has updated{" "}
                    {item?.module === ALL_MODULES.PG_VACANT_REQUESTS
                      ? removeSpecial(item.payload.field_name) : removeSpecial(item?.payload?.actual_updated_property)}{" "}
                  </span>
                  {item?.payload?.old_value ? (
                    <span>
                      {" "}
                      from{" "}
                      <span className="activity_status_name">
                        <b>{item?.payload?.old_value}</b>
                      </span>{" "}
                      {item?.payload?.new_value ? (
                        <>
                          to{" "}
                          <span className="activity_status_name">
                            <b>{item?.payload?.new_value}</b>{" "}
                          </span>
                        </>
                      ) : (
                        " "
                      )}
                    </span>
                  ) : item?.payload?.new_value ? (
                    <span className="inside_activity">
                      to <b>{item?.payload?.new_value} </b>
                    </span>
                  ) : (
                    " "
                  )}
                  {item?.payload?.name ? (
                    <>
                      <span>for </span>
                      <span
                        style={{
                          fontWeight: "bolder",
                        }}
                        className="inside_activity"
                      >
                        {item?.payload?.name}{" "}
                      </span>
                    </>
                  ) : (
                    " "
                  )}
                  {item?.payload?.created_property_name ? (
                    <>
                      <span>with name </span>
                      <span
                        style={{
                          fontWeight: "bolder",
                        }}
                        className="inside_activity"
                      >
                        {item?.payload?.created_property_name}{" "}
                      </span>
                    </>
                  ) : (
                    " "
                  )}
                  <span>
                    on {getDate(item?.created_at)}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        );
      default:
    }
  }
  return (
    <div className="latest_activities">
      <div
        className="inner_pg_content"
        style={{
          maxHeight: `${
            props?.activityCompHeight ? props?.activityCompHeight : 69
          }vh`,
        }}
        onScroll={scrollHandler}
      >
        <Card className="shadow-none border">
          <div style={{
            margin:'8px 0px -9px 8px'
          }}>
            <ul className="ps-0">
              {timeLineData.map((item, index) => (
                <li
                  key={index}
                  className="list-inline d-flex align-items-start"
                >
                  {getList(item)}
                </li>
              ))}
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ActivityTimeline;
