import React, { useState } from 'react';
import {
  Row, Col, Card, NavLink, Modal,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { PropTypes } from 'prop-types';

function AddFilesCompanyAssociates({ isOpen, toggleDrawer }) {
  const [isAddCompanyAssociates, setAddCompanyAssociates] = useState(false);

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      // formattedSize: formatBytes(file.size),
    }));
    setselectedFiles(files);
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={() => toggleDrawer(!isAddCompanyAssociates)}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload Files</h5>
        <button
          type="button"
          onClick={() => {
            toggleDrawer(!isAddCompanyAssociates);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted uil uil-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={`${i}-file`}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <NavLink
                        href="#"
                        className="text-muted font-weight-bold"
                      >
                        {f.name}
                      </NavLink>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

AddFilesCompanyAssociates.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
}

export default AddFilesCompanyAssociates;
