import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";

const getAllProperties = (params) => axiosInstance.get("/properties", getParam(params));
const getAllLivePropertiesBasedOnOrder = () => axiosInstance.get("/properties/fetch-live");
const updateLivePropertyCustomOrder = (payload) => axiosInstance.put("/properties/update-custom-order", payload);
const createProperty = (payload) => axiosInstance.post("/properties", payload);
const updatePropertyStatus = (id, payload) =>  axiosInstance.put(`/properties/${id}/change-status`, payload );
const getPropertyDetails = (params) => axiosInstance.get(`/properties/${params}`);
const createNearTo = (payload) => axiosInstance.post('/near-to-tag', payload);
const updateProperty = (id, payload) => {
  return axiosInstance.put(`/properties/${id}`, { ...payload })
};
const addTenantToBed = (id, payload) => axiosInstance.put(`/properties/add/tenant/bed/${id}`, payload);
const removeTenantFromBed = (id, payload) => axiosInstance.delete(`/properties/remove/tenant/bed/${id}`, { data: payload });

// Property ratings api methods
const getAllPropertyRatings = (property_id) => axiosInstance.get(`/properties/rating/${property_id}`);
const createUpdatePropertyRatings = (property_id, payload) => axiosInstance.put(`/properties/rating/${property_id}`, payload);

// Get owner and tenants of requested properperties
const getMembersOfProperties = (payload) => axiosInstance.post('/properties/members', payload);
const deleteProperty = (id) =>
  axiosInstance.delete(`/properties/${id}`);

export {
  getAllProperties,
  updatePropertyStatus,
  createProperty,
  getPropertyDetails,
  createNearTo,
  updateProperty,
  addTenantToBed,
  getAllPropertyRatings,
  createUpdatePropertyRatings,
  getMembersOfProperties,
  removeTenantFromBed,
  deleteProperty,
  getAllLivePropertiesBasedOnOrder,
  updateLivePropertyCustomOrder
};
