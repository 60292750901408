import React, { useMemo } from 'react';

const AddressComponent = ({ address }) => {
    const addressInASingleLine = useMemo(() => {
        let addressString = '';

        if (address?.address_line_1) {
            addressString += address.address_line_1 + ', ';
        }
        if (address?.address_line_2) {
            addressString += address.address_line_2 + ', ';
        }
        if (address?.city) {
            addressString += address.city + ', ';
        }
        if (address?.state) {
            addressString += address?.state + ', ';
        }
        if (address?.district) {
            addressString += address.district + ', ';
        }
        if (address?.country) {
            addressString += address.country + ' ';
        }
        if (address?.zip_code) {
            addressString += address.zip_code;
        }

        return addressString.replace(/,\s*$/, "");
    }, [address]);

    return <div>{addressInASingleLine}</div>;
};

export default AddressComponent;
