import React from "react";
import BedMatrixBhk from "./bedMatrixBhk";
import BedMatrixHome from "./bedMatrixHome";

function BedMatrixDetails({ propertyType, propertyDetails }) {
    
    return (
        <div className="bedMatrixPg">

            {/* Render Bed matrix Bhk  */}
            {(propertyType === 'bhk' ? 
                <BedMatrixBhk propertyDetails={propertyDetails} /> :
                <BedMatrixHome propertyDetails={propertyDetails} />
            )}
        </div>
    );
}

export default BedMatrixDetails;
