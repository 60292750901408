import React, { useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
//import Button from '@mui/material/Button';
import {
    Button,
  } from 'reactstrap';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export function TenantActionModal({ isOpen, handleClose, emitHandleChange }) {
    const [selectedOption, setSelectedOption] = useState('Tenant Removal');

    const handleChange = (event) => {
        if (event.target.value) {
            setSelectedOption(event.target.value)
            //emitHandleChange(event.target.value)
        }
    }

    const handleProceed = () => {
        // Use the selectedOption for further processing
        console.log("Proceeding with:", selectedOption);
        emitHandleChange(selectedOption); // Example: Emitting a change
        handleClose(); // Close the modal after proceeding
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                disableScrollLock={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Select an action</h5>
                        <CloseOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                    </div>
                    <div className='d-flex flex-column align-items-center'>
                        <FormControl style={{ width: '100%', textAlign: "left" }}>
                            <RadioGroup
                                name="use-radio-group"
                                value={selectedOption}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleChange(e)
                                }}
                            >
                                <FormControlLabel value="Tenant Removal" label="Edit the bed matrix by removing tenant" control={<Radio />} />
                                <FormControlLabel value="Vacant Request Creation" label="PG vacant request creation" control={<Radio />} />
                            </RadioGroup>
                        </FormControl>
                        <Button color='primary' style={{ textTransform: 'capitalize' }} variant="contained" onClick={handleProceed}>Proceed</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}