import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import uuid from 'react-native-uuid';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { getMessageDetails } from '../../api/message';
import { toast } from 'react-toastify';
import FileList from '../Common/FileList';
import { formatBytes } from '../../helpers/string_helper';
import ReactQuill from 'react-quill';

function SingleMessageDetailDrawer({ messageId, isOpen, setIsOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [messageDetails, setMessageDetails] = useState([]);

  // Fetch specific message details
  const fetchMessageData = async () => {
    try {
      const response = await getMessageDetails(+messageId);
      if (response?.status === 200) {
        setMessageDetails(response?.data?.data);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };

  useEffect(() => {
    if (messageId) {
      fetchMessageData();
    }
  }, [messageId]);

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => setIsOpen(!isOpen)}>
      <OffcanvasHeader toggle={() => setIsOpen(!isOpen)} />
      <OffcanvasBody>
        <Form>
          <div className=''>
            <Row className='mb-3'>
              {/* Message subject */}
              <div className='mb-3'>
                <div className='msg_title'>Subject: </div>
                <div className='full_msg'>{messageDetails?.subject}</div>
              </div>
              
              {messageDetails?.receiver_properties?.length > 0 && (
                <div className='d-flex'>
                  {/* Receivers List */}
                  <>
                    <div
                      id='fade-button'
                      aria-controls={open ? 'fade-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      className='cursor_pointer'>
                      <h6>
                        To <ArrowDropDownOutlinedIcon />
                      </h6>
                    </div>
                    <Menu
                      id='fade-menu'
                      MenuListProps={{
                        'aria-labelledby': 'fade-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                      className='message_recipient'
                      PaperProps={{
                        style: {
                          backgroundColor: '#ffffff',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 3px 6px 0px #B3B3B31A, 0px 12px 12px 0px #B3B3B317, 0px 26px 16px 0px #B3B3B30D, 0px 47px 19px 0px #B3B3B303, 0px 73px 20px 0px #B3B3B300',
                        },
                      }}>
                      {messageDetails?.receiver_properties?.map(
                        (property, index) =>
                          property.receivers.map((reciever) => (
                            <MenuItem onClick={handleClose} key={index}>
                              {reciever?.user?.name} | {property.property.name}
                            </MenuItem>
                          )),
                      )}
                    </Menu>
                  </>
                </div>
              )}

              {/* Message Body */}
              <div className='mb-3'>
                <div className='msg_title'>Message: </div>
                <div className='full_msg'>
                  <ReactQuill
                    value={messageDetails?.body}
                    readOnly={true}
                    theme={'bubble'}
                  />
                </div>
              </div>

              {/* Documents */}
              {messageDetails?.documents?.length > 0 && (
                <Row className='mb-3'>
                  <div className='col-sm-12 custom_border_bottom pb-3'>
                    <div className='msg_title'>Attachments:</div>
                    <div className='msgAttachment_listing d-flex flex-column pe-2'>
                      <FileList
                        files={messageDetails?.documents?.map((doc) => ({
                          ...doc,
                          uniqueId: uuid.v4(),
                          formattedSize: formatBytes(doc.size),
                        }))}
                      />
                    </div>
                  </div>
                </Row>
              )}
            </Row>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

SingleMessageDetailDrawer.propTypes = {
  messageId: PropTypes.number,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};
export default SingleMessageDetailDrawer;
