import { KNOWN_ROLES } from '../common/constant';
import { getParam } from '../helpers/getParam';
import axiosInstance from '../utils/axios/auth';

const getAllTenants = async (params) => await axiosInstance.get('/tenant', getParam(params));

const getTenantDetails = async (id) => await axiosInstance.get(`/tenant/${id}`);

const getDashboardData = async () =>
  await axiosInstance.get(`/dashboard/fetch?user_type=${KNOWN_ROLES.TENANT}`);

const createTenant = (payload) =>
  axiosInstance.post('/tenant', payload);

const updateTenant = (id, payload) =>
  axiosInstance.put(`/tenant/${id}`, payload);

const deleteTenant = (id) =>
  axiosInstance.delete(`/tenant/${id}`);

export { getAllTenants, getTenantDetails, getDashboardData, createTenant, updateTenant, deleteTenant };
