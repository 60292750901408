import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useProfile } from "../../components/Hooks/UserHooks";
import { logoutUser } from "../../store/actions";
import axiosInstance from "../../utils/axios/auth";
import { useDispatch } from "react-redux";
import { removeUserDetails, storeUserDetails } from "../../store/auth";
import { ROUTE_VALIDITY } from "../../common/constant";
import { AutoLogout } from "./AutoLogout";

function Authmiddleware(props) {
  const { user, token } = useProfile();
  const dispatch = useDispatch();
  const [validity, setValidity] = useState(null);

  const checkIsPermitted = () => {
    const permissionArray = user?.role?.role_permissions;
  
    const validity = permissionArray?.some(
      (per) =>
        props?.permissions?.action?.includes(per?.permission?.action) &&
        per?.permission?.module === props?.permissions?.name
    );
    if (validity === true) {
      setValidity(ROUTE_VALIDITY.PERMITTED);
    } else if (validity === false) {
      setValidity(ROUTE_VALIDITY.NOT_PERMITTED);
    } else {
      setValidity(null);
    }
  };

  useEffect(() => {
    props?.permissions && checkIsPermitted();
  }, [user, props?.permissions?.name, props?.permissions?.action]);

  useEffect(() => {
    if (!user && token) {
      axiosInstance
        .get("/user/me")
        .then((response) => {
          dispatch(storeUserDetails({ user: response.data.data }));
        })
        .catch((err) => {
          console.error(err);
          dispatch(logoutUser());
        });
    } else if (!user && !token) {
      dispatch(logoutUser());
      dispatch(removeUserDetails());
    }
  }, [token, user]);

  // call auto logout function
  if (user || token) {
    AutoLogout();
  }

  if (!token) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  if (validity === ROUTE_VALIDITY.NOT_PERMITTED) {
    return (
      <Navigate
        to={{ pathname: "/pages-404", state: { from: props.location } }}
        replace
      />
    );
  }

  return <>{user && props.children}</>;
}

export default Authmiddleware;
