import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";

const getAllSchedulePropertyVisitations = (params) => axiosInstance.get("/schedule-property-visit", getParam(params));
const createSchedulePropertyVisitRequestByAdmin = (payload) => axiosInstance.post("/schedule-property-visit/create", {...payload});
const updateSchedulePropertyVisitRequestByAdmin = (id, payload) => {
   return axiosInstance.put(`/schedule-property-visit/${id}`, {...payload})
};
const getSchedulePropertyVisitRequestDashboardInformation = () => {
    return axiosInstance.get(`dashboard/schedule-property-visit`)
 };
 const deleteSchedulePropertyVisitRequest = (id) =>
    axiosInstance.delete(`/schedule-property-visit/${id}`);  

export {
    getAllSchedulePropertyVisitations,
    createSchedulePropertyVisitRequestByAdmin,
    updateSchedulePropertyVisitRequestByAdmin,
    getSchedulePropertyVisitRequestDashboardInformation,
    deleteSchedulePropertyVisitRequest,
};
