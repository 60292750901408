import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { BILL_PAID_STATUS, PAYMENT_TYPES, PER_PAGE } from '../../common/constant';
import { getAllPayoutForOwner } from '../../api/payout-bill';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Common/Loader";
import NodataFound from '../Common/NoDataFound';
import { Td, Tr } from 'react-super-responsive-table';
import NoDataIcon from '../../assets/images/no-data.png';

function LatestTransaction({ tableHeaderBg, tableColor }) {
  const [payoutList, setPayoutList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAllPayoutSlip()
  }, [])

  const fetchAllPayoutSlip = async () => {
    try {
      setIsLoading(true);
      let params = {
        page: page,
        limit: PER_PAGE[0],
        filter_fields: ['payment_type', 'status'], filter_inputs: [PAYMENT_TYPES.PAYOUT, BILL_PAID_STATUS.PAID]
      };
      const response = (await getAllPayoutForOwner(params)).data.data;
      setHasMore(response.meta.current_page !== response.meta.last_page)
      setPayoutList((prevState) => {
        return [...prevState, ...response.data];
      });
      setPage((prevState) => prevState + 1);
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }


  return (
    <Row>
      <Col lg={12}>
        <Card style={{ borderRadius: '8px', overflow: 'hidden' }}>
          <CardBody className='p-0'>
            {/* <SimpleBar style={{ maxHeight: '438px' }}> */}
            {!payoutList.length && !isLoading ? (
              <NodataFound
                icon={NoDataIcon}
                style={{
                  height: "210px",
                  overflow: payoutList.length ? "auto" : "hidden",
                }}
                text={`No payout slip to show`} />
            ) : (
              <div
                style={{
                  height: "280px",
                  overflow: payoutList.length ? "auto" : "hidden",
                }}
              >
                <InfiniteScroll
                  dataLength={payoutList.length}
                  next={fetchAllPayoutSlip}
                  hasMore={hasMore}
                  height={payoutList?.length ? "280px" : "auto"}
                  loader={isLoading && <Loader />}
                >
                  <div className="dashboard_table">
                    <Table className="table-centered table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>SI No</th>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Month & Year</th>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Property Name</th>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Owner Name</th>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Total Payout</th>
                            <th className='font-size-13' style={{ backgroundColor: tableHeaderBg, color: tableColor }}>Payout status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payoutList?.length > 0 && payoutList.map((item, index) => (
                            <Tr className='font-size-13' key={index}>
                              <Td>{++index}</Td>
                              <Td>{item.month} {item.year}</Td>
                              <Td>{item.property?.name}</Td>
                              <Td>{item.user?.name}</Td>
                              <Td>{item.total_payout_amount}</Td>
                              <Td>
                                <span style={{ color: '#369708' }}> {item.status}</span>
                              </Td>
                            </Tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                </InfiniteScroll>
              </div>
            )}
            {/* </SimpleBar> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default LatestTransaction;
