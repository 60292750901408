import React, { useEffect, useState } from 'react';
import { Button, InputGroup } from 'reactstrap';
import GlobalInnerHeader from './globalInnerHeader';
import { viewByIdApi, editApi } from '../../api/GlobalSettings/api';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Spinner } from 'reactstrap'

function DepositAmount({ tabDetails }) {
  const { id, payload } = tabDetails;

  const [depositPayload, setDepositPayload] = useState(payload)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isDataModified, setIsDataModified] = useState(false);
  const [savedValue, setSavedValue] = useState(payload.value);

  async function validateInput(value) {
    try {
      await Yup.number().required('Deposit Amount is required').typeError('Deposit  Amount must be a number').positive('Deposit  Amount must be positive value').validate(value);
      setError('');
      setIsDataModified(true);
    } catch (err) {
      setError(err.message);
    }
  }

  async function counterIncrease(e) {

    if (parseInt(depositPayload?.value) >= 0) {
      setDepositPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) + 1
      }

      ));

      const value = parseInt(depositPayload?.value) + 1
      await validateInput(value)

    }

  }

  async function counterDecrease(e) {

    if (parseInt(depositPayload?.value) > 0) {

      const value = parseInt(depositPayload?.value) - 1
      setDepositPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) - 1
      }

      ));
      await validateInput(value)

    }

  }

  async function onDataAttributeChange(e) {
    let value = e.target.value
    if (isNaN(value)) value = ''

    setDepositPayload(prev => ({
      ...prev,
      value

    }));
    await validateInput(value);
  }

  useEffect(() => {
    getDepositAmountDetails();
  }, [])

  async function getDepositAmountDetails() {
    try {
      const response = await viewByIdApi(id);
      const { payload } = response;
      setDepositPayload(payload)
      setSavedValue(payload.value);

    } catch (error) {
      console.log(error);
    }
  }

  async function saveData() {
    try {
      setIsLoading(true)
      const payload = {
        id,
        payload: { payload: depositPayload }
      }
      await editApi(payload);
      setSavedValue(depositPayload.value);
      setIsDataModified(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Deposit Amount" />
      <div className="settingBody settings_holder mt-4">
        <span>Deposit amount</span>

        <InputGroup>
          <div
            className="input-group-append"
          >
            <Button type="button" color="primary" disabled={isLoading || !tabDetails.isEditable} onClick={(e) => { counterDecrease(e) }}
            >
              <i className="mdi mdi-minus" />
            </Button>
          </div>
          <input
            type="text"
            className="form-control"
            value={depositPayload?.value}
            placeholder="Enter Amount"
            onChange={(e) => onDataAttributeChange(e)}
            disabled={isLoading}

          />
          <div
            className="input-group-append"
            onClick={(e) => { counterIncrease(e) }}
          >
            <Button type="button" color="primary" disabled={isLoading || !tabDetails.isEditable} onClick={(e) => { counterIncrease(e) }}
            >
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </InputGroup>
        <span>INR. </span>
        {error && <div className='text-danger mt-2'>{error}</div>}
        <div className="text-end mt-4">
          {tabDetails.isEditable &&
            <Button
              disabled={!isDataModified || isLoading || depositPayload.value == savedValue || error}
              color="primary"
              onClick={saveData}>{isLoading && <Spinner size="sm" color="light" />} Save </Button>
          }
        </div>
      </div>
    </div>
  );
}

DepositAmount.propTypes = {
  tabDetails: PropTypes.any
}

export default DepositAmount;
