import React, { memo, useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { amber } from '@mui/material/colors';
import { createUpdatePropertyRatings, getAllPropertyRatings } from '../../api/property';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { KNOWN_ROLES } from '../../common/constant';
import { Stack } from '@mui/material';
import PreviewRaters from './PreviewRaters';

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

function PropertyRatings({ user, property_id, showLabel = false, showTotal = false, showRatings = false }) {
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [avarage, setAvarage] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const [view, setView] = useState({ showLabel, showRatings, showTotal })
  const [raters, setRaters] = useState([])

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    try {
      const res = await createUpdatePropertyRatings(property_id, { rating: newValue * 2 });
      if (res.status === 200) {
        toast.success(res.data.message);
      }
      else {
        toast.success(res.data.message);
      }
    }
    catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }

  const updatePropertyRating = async () => {
    const ratings = (await getAllPropertyRatings(property_id)).data.data;
    if (ratings?.myRating) setValue(ratings.myRating / 2);
    if (ratings?.totalRating) setTotal(ratings.totalRating);
    if (ratings?.avgRating) setAvarage(Math.ceil(ratings.avgRating));
    if (ratings?.tenants) setRaters(ratings.tenants);
  }

  useEffect(() => {
    updatePropertyRating();
    if (user.role.role === KNOWN_ROLES.SUPER_ADMIN) setView((rest) => ({ ...rest, showTotal: true }));
    if (user.role.role === KNOWN_ROLES.TENANT) setView((rest) => ({ ...rest, showRatings: true, showLabel: true }));
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {value !== null && (
        <Box sx={{ ml: 2, mb: 1 }}>{view.showLabel && labels[hover !== -1 ? hover : value]}</Box>
      )}
      {view.showRatings &&
        (<Rating
          name="hover-feedback"
          sx={{ ml: 5 }}
          value={value}
          precision={0.5}
          getLabelText={getLabelText}
          onChange={handleChange}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />)}


      {view.showTotal ?
        (total > 0 ? (<PreviewRaters raters={raters}>
          <Stack direction='row' alignItems="center" spacing={0.5} sx={{ ml: 2 }} className='d-flex'>
            <div> {`${avarage}`} </div>
            <StarIcon sx={{ color: amber[500] }} />
            <div>{`Based on ${total} ratings`}</div>
          </Stack>
        </PreviewRaters>)
          : (<Stack direction='row' alignItems="center" spacing={0.5} sx={{ ml: 2 }} className='d-flex'>
            No rating found
          </Stack>))
        : null}

    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(memo(PropertyRatings));