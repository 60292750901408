import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from "@mui/material";
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import ActivityTimeline from "../ActiveProperties/ActivePropertiesDetail/activityTimeline";
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT } from '../../common/constant';
import { ALL_MODULES } from "../../common/constant";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const { detailValue } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>
            {React.cloneElement(children, { detailValue })}
          </Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function VisitRequestsDetailDrawer({ isOpen, visitRequest, setIsOpen, reqId }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Offcanvas
        isOpen={isOpen}
        direction='end'
        toggle={() => setIsOpen(!isOpen)}>
        <OffcanvasHeader toggle={() => setIsOpen(!isOpen)}></OffcanvasHeader>
        <OffcanvasBody>
        <div className="active-property-main-detail">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="aPTabs"
              >
                <Tab label="Basic Details" {...a11yProps(0)} />
                <Tab label="Activity Timeline" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
          <Form>
            <div className='tenantDetailsDrawer'>
              <Row className='mb-3'>
                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>Visitor&apos;s Name: </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.fullname ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>
                    Visitor&apos;s Contact No.:{' '}
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.phone_no ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>
                    Visitor&apos;s Property:{' '}
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.property?.name ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>Visiting Date: </div>
                  <div className='tenantDetailDesc'>
                    {moment(visitRequest?.visit_datetime).format(
                      DEFAULT_DATETIME_FORMAT,
                    ) ?? 'N/A'}{' '}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>
                    Contact Person&apos;s Name:{' '}
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.property?.care_taker_name ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>
                    Contact Person&apos;s Number:{' '}
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.property?.care_taker_contact_no ?? 'N/A'}
                  </div>
                </div>
                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>
                    Status:{' '}
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.status ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3 mb-3'>
                  <div className='tenantDetailTitle'>Created On: </div>
                  <div className='tenantDetailDesc'>
                    {moment(visitRequest?.created_at).format(
                      DEFAULT_DATE_FORMAT,
                    ) ?? 'N/A'}{' '}
                  </div>
                </div>
                    
                {visitRequest?.note  &&
                  <div className='col-sm-12 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle tenantDetFullTitle'>
                    Note:
                  </div>
                  <div className='tenantDetailDesc'>
                    {visitRequest?.note?.description ?? 'N/A'}
                  </div>
                </div>}
              </Row>
            </div>
          </Form>
          </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <>
                <OffcanvasHeader
                  toggle={() => setIsOpen(!isOpen)}
                  style={{
                    marginTop: "-3vh",
                    marginLeft: "-1vw",
                  }}
                />
                <ActivityTimeline
                  module={ALL_MODULES.VISIT_REQUESTS}
                  module_id={reqId}
                  activityCompHeight={70}
                />
              </>
            </CustomTabPanel>
          </Box>
        </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

VisitRequestsDetailDrawer.propTypes = {
  children: PropTypes.any,
  visitRequest: PropTypes.object,
  refresh: PropTypes.func,
};
export default VisitRequestsDetailDrawer;
