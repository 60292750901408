import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ACTIONS, ALL_MODULES } from '../../common/constant';
import { updateSchedulePropertyVisitRequestByAdmin } from '../../api/schedule-property-visit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { AsterikLabel } from './AsterikLabel';

function AddOrEditOrViewNoteModal(props) {
  const [isLoading, setIsLoading] = useState(false)

  function toggleModal() {
    removeBodyCss();
    props?.handleNoteModalToggle(null, null)
  }

  const initialNoteDetails = {
    note: ''
  }

  const handleNoteSubmit = async () => {
    try {
      setIsLoading(true)
      const { item } = props.noteModalActionAndPayload
      await updateSchedulePropertyVisitRequestByAdmin(item.id, { note: noteFormik.values.note })

      toast.success('Note attached successfully.')
      await props?.refreshPropertyScheduleVisit()
      toggleModal()
    } catch (e) {
      toast.error('Note attachment failed.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props?.noteModalActionAndPayload?.action === ACTIONS.EDIT) {
      noteFormik.setValues({
        note: props?.noteModalActionAndPayload?.item?.note?.description
      })
    }
  }, [props?.noteModalActionAndPayload?.action])

  const noteFormik = useFormik({
    initialValues: { ...initialNoteDetails },
    validationSchema: Yup.object({
      note: Yup.string().strict(true).trim('Leading and trailing spaces is not allowed.').required('Please enter a note.'),
    }),
    validateOnMount: true,
  })

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  return (
    <div>

      {/* ========= Modal To create/edit note ===========  */}
      <Modal
        isOpen={[ACTIONS.CREATE, ACTIONS.EDIT].includes(props?.noteModalActionAndPayload?.action)}
        toggle={() => {
          toggleModal();
        }}
        backdrop='static'
        centered
        fullscreen='md'
        size='lg'
        scrollable
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props?.noteModalActionAndPayload?.action === ACTIONS.CREATE ? 'Add' : 'Edit'} Notes
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <TextField
            label={<div>Note <AsterikLabel /></div>}
            fullWidth
            name='note'
            disabled={isLoading}
            value={noteFormik.values.note}
            multiline
            rows={6}
            variant="outlined"
            onChange={(e) => {
              noteFormik.setFieldTouched('note', true, false)
              noteFormik.handleChange(e)
            }}
            onBlur={noteFormik.handleBlur}
          />
          {
            noteFormik.touched?.note && noteFormik.errors?.note && <div className='text-danger mt-2'>{noteFormik.errors.note}</div>
          }
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props?.noteModalActionAndPayload?.module === ALL_MODULES.VISIT_REQUESTS ? handleNoteSubmit() : props?.handleSubmit({ note: noteFormik.values.note })
            }}
            className={`yellow_gradient_btn btn ${isLoading || !noteFormik.isValid ? 'btn-secondary' : 'btn-primary'} waves-effect waves-light`}
            data-dismiss="modal"
            style={{ cursor: isLoading || !noteFormik.isValid ? 'not-allowed' : 'pointer' }}
            disabled={isLoading || !noteFormik.isValid}
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => {
              toggleModal();
            }}
            className="btn btn-primary waves-effect waves-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>

      {/* ========= Modal to view note ===========  */}
      <Modal
        isOpen={[ACTIONS.VIEW].includes(props?.noteModalActionAndPayload?.action)}
        toggle={() => {
          toggleModal();
        }}
        backdrop='static'
        centered
        fullscreen='md'
        size='lg'
        scrollable
      >
        <div className="modal-header">
          <div className='d-flex justify-content-center'>
            <h5 className="modal-title mt-0 me-2" id="myModalLabel">
              Notes
            </h5>
            {!props?.noteModalActionAndPayload?.isDisabled && (
              <EditNoteIcon titleAccess='Edit Note' style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer' }} fontSize={'inherit'} color='info' onClick={() => { props?.handleNoteModalToggle(ACTIONS.EDIT, props?.noteModalActionAndPayload?.item, true) }} />
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              toggleModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            {
              props?.noteModalActionAndPayload?.item?.note?.description
            }
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggleModal();
            }}
            className="btn btn-primary waves-effect waves-light"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AddOrEditOrViewNoteModal;
