import React from "react";
import { Card } from "reactstrap";
export const DetailsHeaderBlock = ({ fields = [] }) => {
  const fieldsValue = fields?.filter(e=>e)
  return (
    <div className="payment_detail_block">
      <div className="all_payment_blocks">
        <Card>
          <div className="d-grid five_grid_cols p-2">
            {fieldsValue?.map(({ filedName, value }) => (
              <div className="single_payment_item">
                <span>{filedName}</span>
                { ['string','number'].includes(typeof(value))?<h5 className="truncate_month" style={{
                    fontWeight:'bolder'
                }}>{value}</h5>:<span>{value}</span>}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};
