import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import FileList from '../Common/FileList';
import { formatBytes, getS3BaseUrl } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import { getOwnerDetails } from '../../api/owner';
import { toast } from 'react-toastify';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const removeDuplicateObjects = (arr) => {
  if (Array.isArray(arr))
    return arr.filter(
      // Removing duplicate entries
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t?.id === value?.id,
        ) && value,
    );
};
function PropertyOwnerDetailDrawer({ ownerId, isOpen, setIsOpen }) {
  const [ownerDetails, setOwnerDetails] = useState([]);
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        ...(!ownerDetails?.user?.profile_photo
          ? { bgcolor: stringToColor(name) }
          : ''),
        width: 'auto',
        height: 'inherit',
        'object-fit': 'cover',
        'font-size': '1.8rem',
      },
      children: `${name
        .split(' ')
        .map((f) => f[0])
        .join('')}`,
    };
  }
  const fetchOwnerData = async () => {
    try {
      const response = await getOwnerDetails(+ownerId);
      if (response?.status === 200) {
        setOwnerDetails(response?.data?.data);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };
  const s3BaseUrl = getS3BaseUrl();
  useEffect(() => {
    if (+ownerId) {
      fetchOwnerData();
    }
  }, [ownerId]);

  return (
    <>
      <Offcanvas
        isOpen={isOpen}
        direction='end'
        toggle={() => setIsOpen(!isOpen)}>
        <OffcanvasHeader toggle={() => setIsOpen(!isOpen)} />
        <OffcanvasBody className='pt-0'>
          <Form>
            <div className='tenantDetailsDrawer'>
              <Row className='mb-5'>
                <div className='col-sm-12'>
                  <div className='custom-image-upload'>
                    <div
                      className='image-placeholder'
                      // style={{ paddingBottom: '30px' }}
                    >
                      <Avatar
                        variant='square'
                        src={`${s3BaseUrl}${ownerDetails?.user?.profile_photo}`}
                        {...(ownerDetails?.user?.name &&
                          stringAvatar(ownerDetails?.user?.name))}
                      />
                    </div>
                    <div className='upload_picture mx-3'>
                      <div className='pro_person_name'>
                        {ownerDetails?.user?.name}
                      </div>
                      <div className='pro_person_mob'>
                        <i className='fas fa-phone-alt me-1' />
                        <span className='country_code'>+91</span>
                        <span className='pr_person_contact'>
                          {ownerDetails?.user?.contact_number ?? 'N/A'}
                        </span>
                      </div>
                      <div className='pro_person_mob tenantDetailEmail'>
                        <span className='pr_person_contact'>
                          <i className='fas fa-envelope me-1' />
                          {ownerDetails?.user?.email ?? 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
                <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Status:</div>
                  <div className='tenantDetailDesc'>
                    {ownerDetails?.user?.status ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Occupaion:</div>
                  <div className='tenantDetailDesc'>
                    {ownerDetails?.occupation ?? 'N/A'}
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
                <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'> Address:</div>
                  <div className='tenantDetailDesc'>
                    {ownerDetails?.user?.address ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Property Status:</div>
                  <div className='tenantDetailDesc'>
                    {ownerDetails?.property_status ?? 'N/A'}
                  </div>
                </div>
              </Row>

              {/* Bank Acc Details */}
              <div className='col-12 mb-3'>
                <div className='bankAccountDet'>
                  <h5 className='mb-3'>Bank Account Details</h5>
                </div>
                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Bank Name:</div>
                    <div className='tenantDetailDesc'>
                      {ownerDetails?.bank_detail?.bank_name ?? 'N/A'}
                    </div>
                  </div>

                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>IFSC:</div>
                    <div className='tenantDetailDesc'>
                      {ownerDetails?.bank_detail?.ifsc ?? 'N/A'}
                    </div>
                  </div>
                </Row>
                <Row className='mb-3'>
                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Account Name:</div>
                    <div className='tenantDetailDesc'>
                      {ownerDetails?.bank_detail?.account_holder_name ?? 'N/A'}
                    </div>
                  </div>

                  <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
                    <div className='tenantDetailTitle'>Account Number:</div>
                    <div className='tenantDetailDesc'>
                      {ownerDetails?.bank_detail?.account_number ?? 'N/A'}
                    </div>
                  </div>
                </Row>
              </div>

              {/* Properties */}
              <Row>
              {(ownerDetails?.user?.properties?.length > 0 ||
                ownerDetails?.user?.flats?.length > 0 ||
                ownerDetails?.user?.floors?.length > 0) && (
                <>
                  <div className='col-12 mb-3'>
                    <div className='bankAccountDet'>
                      <h5 className='mb-3'>Associated Properties</h5>
                    </div>
                  </div>
                  <TableContainer
                  component={Paper}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Property</TableCell>
                          <TableCell >Status</TableCell>
                          <TableCell >Near To</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {removeDuplicateObjects(
                          [
                            ownerDetails?.user?.properties,
                            ownerDetails?.user?.floors?.map(
                              (floor) => floor.property,
                            ),
                            ownerDetails?.user?.flats?.map(
                              (flat) => flat.floor.property,
                            ),
                          ].flatMap((property) => property),
                        ).map((property) => (
                          <TableRow
                            key={property?.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}>
                            <TableCell component='th' scope='row'>
                              {property?.name}
                            </TableCell>
                            <TableCell>{property?.status}</TableCell>
                            <TableCell>{property?.near_to ?? 'N/A'}</TableCell>
                            {/* <TableCell align='right'>{property?.carbs}</TableCell>
                            <TableCell align='right'>{property.protein}</TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              </Row>

              {/* Attachments */}
              <Row className='mb-3'>
                <div className='col-sm-12 custom_border_bottom pb-3'>
                  {ownerDetails?.other_documents?.length > 0 && (
                    <>
                      <div className='tenantDetailTitle tenantDetFullTitle'>
                        Attachments:
                      </div>
                      <FileList
                        files={ownerDetails?.other_documents?.map((doc) => ({
                          ...doc,
                          uniqueId: uuid.v4(),
                          formattedSize: formatBytes(doc.size),
                        }))}
                      />
                    </>
                  )}
                </div>
              </Row>

            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

PropertyOwnerDetailDrawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  ownerId: PropTypes.number,
};
export default PropertyOwnerDetailDrawer;
