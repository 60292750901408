import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import photo from "../../assets/images/photo-icon.svg";
import { IconButton } from "@mui/material";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import Tooltip from "@mui/material/Tooltip";

import AddKanBanView from "../../components/KanBan/AddKanBanView";
import { useSelector } from "react-redux";
import DeleteModal from "../../components/Common/DeleteModal";
import EditKanbanDetails from "../../components/KanBan/EditKanban";
import {
  STATUS_META_OBJECT,
  STATUS_TO_COLUMN,
  TASK_PROGRESS_STATUS,
  getInitials,
  statusObject,
} from "../../constants/kanban_helper";
import FilterKanBan from "../../components/KanBan/FilterKanBan";
import {
  ALL_MODULES,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
  TASK_PRIORITY,
} from "../../common/constant";
import highPriority from "../../assets/images/icons/highPriority.svg";
import lowPriority from "../../assets/images/icons/lowPriority.svg";
import mediumPriority from "../../assets/images/icons/mediumPriority.svg";

import {
  createTaskData,
  deleteFunctionTask,
  editParticularTask,
  fetchAssigneeData,
  getAllTask,
  getParticularTask,
  statusWiseTask,
} from "../../api/kanban";
import { usePermissionGiven } from "../../components/Hooks/UserPermission";
import { toast } from "react-toastify";
import KanbanDetailDrawer from "../../components/KanBan/detail-drawer";
import { getS3BaseUrl } from "../../helpers/string_helper";
const initialBoardData = {
  TO_DO: {
    name: "To Do",
  },
  IN_PROGRESS: {
    name: " In Progress",
  },
  DONE: {
    name: "Done",
  },
  BLOCKER: {
    name: "Blocker",
  },
};
function Board() {
  const navigate = useNavigate();
  const s3BaseUrl = getS3BaseUrl();
  const [taskData, setTaskData] = useState({});
  const [filterObject, setFilterObject] = useState({
    status: "",
    assignee_id: "",
  });
  const [page] = useState(1);
  const filterInputs = [];
  const filterFields = [];
  const [pages, setPages] = useState({
    to_do: 1,
    in_progress: 1,
    done: 1,
    blocker: 1,
  });
  const [dedicatedTabValue, setDedicatedTabValue] = useState(0);
  const { to_do, in_progress, done, blocker } = pages;
  const [filterInput, setFilterInputs] = useState([]);
  const [filterField, setFilterFields] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const currentUserId = useSelector(
    (state) => state?.AuthLogin?.auth?.user?.id
  );
  if (!currentUserId) {
    return navigate("/login");
  }
  //this useEffect is used for calling api for fetch all task//
  const [assigneePage, setAssigneePage] = useState(1);
  const [updatedMeta, setUpdatedMeta] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [assigneeMeta, setAssigneeMeta] = useState({});
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    
    const { source, destination, draggableId } = result;
    const data = await getParticularTask(draggableId);
    if (source?.droppableId !== destination?.droppableId) {
      const newObj = {
        status: destination?.droppableId,
        flag: 1,
        name: data?.name,
        description: data?.description,
        priority: data?.priority,
        assignee_id: data?.assignees?.map((e) => e?.assignee_id),
        note: {
          note: data?.note?.description || "",
        },
        ...(data?.due_date && {due_date: data?.due_date}),
        attachments: data?.attachments?.map((e) => e?.id) ?? [],
      };
      scrollToTop();
      editParticularTask(
        newObj,
        draggableId,
        () =>
          fetchTaskList("", {
            to_do: 1,
            in_progress: 1,
            done: 1,
            blocker: 1,
            limit: DEFAULT_PER_PAGE_VALUE,
          }),
        onToggleCloseDelete,
        setIsLoading
      );
      setPages({
        to_do: 1,
        in_progress: 1,
        done: 1,
        blocker: 1,
      });
    } else {
      // console.log();
    }
  };

  const [boardData, setboardData] = useState({
    ...initialBoardData,
  });
  const [taskStatus, setTaskStatus] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const scrollRef = useRef();
  //------------------------calling tasklist for the render page first time--------------
  const fetchTaskList = async (
    search = "",
    paginatedData = {
      to_do,
      in_progress,
      done,
      blocker,
      limit: DEFAULT_PER_PAGE_VALUE,
    }
  ) => {
    const params = {
      ...paginatedData,
      ...(search && {
        ...search,
      }),
      ...(filterField &&
        filterInput && {
          filter_fields: filterField,
          filter_inputs: filterInput,
        }),
    };
    const resultData = await taskListApi(params);
    setTaskData(resultData);
    fetchAllTask(resultData);
  };
  //--------------------------------------------------------------------------//

  //----------This function is being used to shape the data of task api----------//
  const fetchAllTask = (taskData) => {
    setboardData(() => {
      const newData = {
        "To Do": {
          name: "To Do",
        },
        "In Progress": {
          name: " In Progress",
        },
        Done: {
          name: "Done",
        },
        Blocker: {
          name: "Blocker",
        },
      };

      taskData?.data?.forEach((ele) => {
        const data = {
          id: ele?.id,
          name: ele?.name,
          comments: ele?.comments,
          priority: ele?.priority,
          description: ele?.description,
          isAttachments: ele?.attachments?.length,
          assignee: {
            profile_pic: ele?.assignees?.map(
              (ele) => ele?.assignee?.profile_photo
            ),
            name: ele?.assignees?.map((ele) => ele?.assignee?.name),
            ShortHands: ele?.assignees?.map((ele) =>
              getInitials(ele?.assignee?.name)
            ),
          },
        };

        const status = ele?.status;

        newData[status].id = newData[status]?.id ?? Date.now();
        newData[status].no_ofissue = newData[status].no_ofissue + 1 || 1;
        if (!newData[status]?.history) {
          const history = [];
          history.push({
            ...data,
          });
          newData[status].history = history;
        } else {
          newData[status].history.push({
            ...data,
          });
        }
      });
      return newData;
    });
  };

  //--------------------------------------------------------------------------------//
  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
  });

  // Assign To dropdown starts

  const [showAddTaskMenu, setShowAddTaskMenu] = useState(false);

  const [limit] = useState(10);
  const [isOpenDetails, setIsOpenDetails] = useState();
  const [navigateState] = useState(useLocation()?.state?.id)

  // Add new tenant drawer
  const toggleAddOpenDetail = () => {
    setIsOpenDetails(!isOpenDetails);
  };

  const getListStyle = () => ({
    background: "rgba(130, 130, 130, 0.07)",
    height: "100%",
    marginRight: "5px",
    borderRadius: "6px",
  });

  const toggleTaskMenu = () => {
    setShowAddTaskMenu(!showAddTaskMenu);
  };

  const onToggleCloseDelete = () => {
    setDeleteOpen(false);
  };

  //create task action is done here//

  const createTaskFunction = (payload) => {
    if (payload.note === "") {
      delete payload.note;
    } else {
      const newNote = {
        note: payload.note,
      };
      payload.note = newNote;
    }
    if (currentUserId) {
      payload.reporter_id = currentUserId;
    }
    if (!payload?.assignee_id) {
      delete payload?.assignee_id;
    }
    setPages({
      to_do: 1,
      in_progress: 1,
      done: 1,
      blocker: 1,
    });
    scrollToTop();
    createTaskData(
      payload,
      () => fetchAllTaskList(),
      toggleTaskMenu,
      setIsLoading
    );
  };

  const deleteFunction = () => {
    deleteFunctionTask(
      selectedId,
      () => actionAfterEdit(),
      onToggleCloseDelete,
      setIsLoading
    );
  };

  // TaskList Function with assignee_id.....................//
  const filter = async (filterFields, filterInputs) => {
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    setPages({
      to_do: 1,
      in_progress: 1,
      done: 1,
      blocker: 1,
    });
    const to_do = 1;
    const in_progress = 1;
    const done = 1;
    const blocker = 1;
    const resultData = await taskListApi({
      to_do,
      in_progress,
      done,
      blocker,
      page,
      limit,
      filter_fields: filterFields,
      filter_inputs: filterInputs,
    });
    const modifiedData = resultData?.data || [];
    const mergedData = {
      data: [...modifiedData],
      meta: resultData?.meta,
    };
    setTaskData(mergedData);
    fetchAllTask(mergedData);
  };
  const handleFilter = (searchFile) => {
    if (searchFile?.assignee_id) {
      filterFields.push("assignee_id");
      filterInputs.push(searchFile.assignee_id);
    }
    // setFilterObject({});
    searchFile = {};
    filter(filterFields, filterInputs, taskStatus);
  };
  const taskListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllTask(params)).data.data;
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false);
    }
  };
  const taskListIndividualApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await statusWiseTask(params)).data.data;
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false);
    }
  };
  //--------------------------------------------------------//
  function scrollToTop() {
    const keyArray = Object.keys(initialBoardData)?.length;
    const allIndex = Array.from({ length: keyArray }, (_, i) => i);
    if (scrollRef.current) {
      const doc = document.getElementsByClassName(scrollRef.current.className);
      for (let i = 0; i < allIndex.length; i++) {
        doc[i].scrollTop = 0;
      }
    }
  }
  const toggleEditMenu = () => {
    setEditOpen(!editOpen);
  };

  //------ assigneelist fetch--------------------------------------------------//

  const assigneeList = async (params) => {
    try {
      setIsLoading(true);
      const response = (await fetchAssigneeData(params)).data.data;
      return response;
    } catch (error) {
      return toast.error(error?.response?.data?.message)
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAssignees = async (paginatedData, state = []) => {
    const params = {
      ...paginatedData,
    };
    const resultData = await assigneeList(params);
    const modifiedData = resultData?.data;
    const meta = resultData?.meta;
    setAssigneeMeta(meta);
    setAssignee(() => [...state, ...modifiedData]);
  };
  //  -----------------------------------------------------------------------//
  useEffect(() => {
    fetchAllTaskList();
    if (navigateState) {
      toggleAddOpenDetail();
      setSelectedId(navigateState);
    }
  }, []);

  const actionAfterEdit = async () => {
    scrollToTop();
    setPages({
      to_do: 1,
      in_progress: 1,
      done: 1,
      blocker: 1,
    });
    fetchAllTaskList();
  };

  const handleScroll = (event, column) => {
    let key = "";
    if (column?.name?.toLowerCase()?.trim() === "to do") {
      key = "to_do";
    }
    if (column?.name?.toLowerCase()?.trim() === "done") {
      key = "done";
    }
    if (column?.name?.toLowerCase()?.trim() === "blocker") {
      key = "blocker";
    }
    if (column?.name?.toLowerCase()?.trim() === "in progress") {
      key = "in_progress";
    }
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (Math.floor(scrollHeight - scrollTop) === Math.floor(clientHeight)) {
      const current_page =
        taskData?.meta[STATUS_META_OBJECT[key]]?.current_page;
      const lastPage = taskData?.meta[STATUS_META_OBJECT[key]]?.last_page;

      if (current_page < lastPage) {
        setPages((old) => {
          return { ...old, [key]: pages[key] + 1 };
        });
        setTaskStatus(TASK_PROGRESS_STATUS[key.toUpperCase()]);
        setUpdatedMeta(STATUS_META_OBJECT[key.toLowerCase()]);
      }
    }
  };

  const fetchAllTaskList = async () => {
    const resultDataInProgress = await taskListIndividualApi({
      updatedMeta: "inProgressMeta",
      page: 1,
      taskStatus: "In Progress",
      limit,
    });
    const resultDataToDo = await taskListIndividualApi({
      updatedMeta: "todoMeta",
      page: 1,
      taskStatus: "To Do",
      limit,
    });
    const resultDataDone = await taskListIndividualApi({
      updatedMeta: "doneMeta",
      page: 1,
      taskStatus: "Done",
      limit,
    });
    const resultDataBlocker = await taskListIndividualApi({
      updatedMeta: "blockerMeta",
      page: 1,
      taskStatus: "Blocker",
      limit,
    });
    const filterData = [
      ...(resultDataInProgress?.data || []),
      ...(resultDataToDo?.data || []),
      ...(resultDataDone?.data || []),
      ...(resultDataBlocker?.data || []),
    ]?.filter((e) => e?.id);

    const mergedData = {
      data: [...filterData],
      meta: {
        ...resultDataInProgress?.meta,
        ...resultDataToDo?.meta,
        ...resultDataDone?.meta,
        ...resultDataBlocker?.meta,
      },
    };
    setTaskData(mergedData);
    fetchAllTask(mergedData);
  };
  
  const handleFilterScroll = async () => {
    let stat = "";
    switch (taskStatus) {
      case TASK_PROGRESS_STATUS.TO_DO:
        stat = "to_do";
        break;
      case TASK_PROGRESS_STATUS.IN_PROGRESS:
        stat = "in_progress";
        break;
      case TASK_PROGRESS_STATUS.DONE:
        stat = "done";
        break;
      case TASK_PROGRESS_STATUS.BLOCKER:
        stat = "blocker";
        break;
      default:
        stat = "";
    }

    const page = pages[stat];

    const resultData = await taskListIndividualApi({
      updatedMeta,
      page,
      taskStatus,
      limit,
      filter_fields: filterFields,
      filter_inputs: filterInputs,
    });

    const mergedData = {
      data: taskData?.data?.length
        ? [...(taskData?.data || []), ...(resultData?.data || [])]
        : [...(resultData?.data || [])],
      meta: {
        ...taskData?.meta,
        ...resultData?.meta,
      },
    };
    setTaskData(mergedData);
    fetchAllTask(mergedData);
  };

  useEffect(() => {
    if (to_do > 1 || in_progress > 1 || done > 1 || blocker > 1) {
      handleFilterScroll();
    }
  }, [to_do, in_progress, done, blocker]);

  const priorityChecker = (task) => {
    if (task.priority === TASK_PRIORITY.HIGH) {
      return (
        <Tooltip title="High Priority">
          <img
            style={{
              marginTop: "0.5em",
            }}
            src={highPriority}
            alt="high"
            height="15px"
            width="15px"
          />
        </Tooltip>
      );
    } else if (task.priority === TASK_PRIORITY.MEDIUM) {
      return (
        <Tooltip title="Medium Priority">
          <img
            style={{
              marginTop: "0.5em",
            }}
            src={mediumPriority}
            alt="high"
            height="15px"
            width="15px"
          />
        </Tooltip>
      );
    } else if (task.priority === TASK_PRIORITY.LOW) {
      return (
        <Tooltip title="Low Priority">
          <img
            style={{
              marginTop: "0.5em",
            }}
            src={lowPriority}
            alt="high"
            height="15px"
            width="15px"
          />
        </Tooltip>
      );
    } else "";
  };

  return (
    <>
      <div className="page-content position-relative kanbanPg">
        <Container fluid>
          <FilterKanBan
            toggleTaskMenu={toggleTaskMenu}
            assignee={assignee}
            filterObject={filterObject}
            setFilterObject={setFilterObject}
            handleFilter={handleFilter}
            fetchAssignees={fetchAssignees}
            setAssigneePage={setAssigneePage}
            assigneePage={assigneePage}
            assigneeMeta={assigneeMeta}
          />
          <div className="kanban_board mt-4 d-flex justify-content-start align-items-start w-100">
            <DragDropContext onDragEnd={onDragEnd}>
              {Object.entries(boardData).map(([columnId, column], index) => (
                <Droppable key={columnId} droppableId={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        <div
                          ref={scrollRef}
                          className="kanban_board_status"
                          style={{ height: "auto" }}
                          onScroll={(e) => handleScroll(e, column)}
                        >
                          <div
                            className="kanban_board_title p-2"
                            key={index}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <div className="header d-flex justify-content-between align-items-center p-2">
                              <span className="heading_title">
                                {column.name}{" "}
                              </span>
                              <span className="count">
                                {taskData?.meta?.[
                                  STATUS_TO_COLUMN[
                                    column?.name?.toLowerCase()?.trim()
                                  ]
                                ]?.total || 0}
                              </span>
                            </div>
                            {column?.history?.map((it, ind) => (
                              <Draggable
                                key={it.id}
                                draggableId={it.id.toString()}
                                index={ind}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="tickets"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      ),
                                    }}
                                  >
                                    <div className="ticket_card p-3">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h6
                                          className="ticket_name mb-0"
                                          style={{ cursor: "pointer", paddingRight: '10px', width: '250px', lineHeight: '20px' }}
                                          onClick={() => {
                                            toggleAddOpenDetail();
                                            setSelectedId(it.id);
                                          }}
                                        >
                                          {it.name}
                                        </h6>
                                        <div>
                                          <div className="d-flex justify-content-end">
                                            {priorityChecker(it)}
                                            {(usePermissionGiven(
                                              ALL_MODULES.TASKS,
                                              OPERATION_TYPE.UPDATE
                                            ) ||
                                              usePermissionGiven(
                                                ALL_MODULES.TASKS,
                                                OPERATION_TYPE.UPDATE
                                              )) && (
                                              <ul className="ps-0 mb-0">
                                                <li className="list-inline-item dropdown">
                                                  <UncontrolledDropdown>
                                                    <DropdownToggle
                                                      tag="a"
                                                      className="dot_color"
                                                    >
                                                      <i
                                                        role="button"
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        <MoreVertIcon />
                                                      </i>
                                                    </DropdownToggle>

                                                    <DropdownMenu className="dropdown-menu-end">
                                                      {usePermissionGiven(
                                                        ALL_MODULES.TASKS,
                                                        OPERATION_TYPE.UPDATE
                                                      ) ? (
                                                        <DropdownItem
                                                          onClick={() => {
                                                            setSelectedId(
                                                              it.id
                                                            );
                                                            setEditOpen(true);
                                                            setTaskStatus(
                                                              statusObject[
                                                                column?.name?.trim()
                                                              ]
                                                            );
                                                            setUpdatedMeta(
                                                              STATUS_TO_COLUMN[
                                                                column?.name
                                                                  ?.trim()
                                                                  ?.toLowerCase()
                                                              ]
                                                            );
                                                          }}
                                                        >
                                                          <span>Edit</span>
                                                        </DropdownItem>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {usePermissionGiven(
                                                        ALL_MODULES.TASKS,
                                                        OPERATION_TYPE.DELETE
                                                      ) ? (
                                                        <DropdownItem
                                                          onClick={() => {
                                                            setSelectedId(
                                                              it.id
                                                            );
                                                            setDeleteOpen(true);
                                                            setTaskStatus(
                                                              statusObject[
                                                                column?.name?.trim()
                                                              ]
                                                            );
                                                            setUpdatedMeta(
                                                              STATUS_TO_COLUMN[
                                                                column?.name
                                                                  ?.trim()
                                                                  ?.toLowerCase()
                                                              ]
                                                            );
                                                          }}
                                                        >
                                                          <span>Delete</span>
                                                        </DropdownItem>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                </li>
                                              </ul>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ticket_number">
                                        <div className="icon d-flex align-items-end">
                                          <p className="ticket_number_block mb-0">
                                            <ConfirmationNumberOutlinedIcon />{" "}
                                            {it.description.length > 100 ? (
                                              <text
                                                onClick={() => {
                                                  toggleAddOpenDetail();
                                                  setSelectedId(it.id);
                                                }}
                                              >
                                                {it.description.slice(0, 100)}{" "}
                                                ...more
                                              </text>
                                            ) : (
                                              it.description || "N/A"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="ticket_footer mt-2 ">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="MuiAvatarGroup-root css-1mceequ d-flex">
                                            <div className="d-flex">
                                              <div className="d-flex">
                                                {(it?.assignee?.name.length > 3
                                                  ? it?.assignee?.name?.slice(
                                                      0,
                                                      3
                                                    )
                                                  : it?.assignee?.name
                                                )
                                                  ?.slice(0, 3)
                                                  .map((_, indx) => (
                                                    <Tooltip
                                                      key={indx}
                                                      title={
                                                        <span>
                                                          {it?.assignee?.name[
                                                            indx
                                                          ] || "N/A"}
                                                        </span>
                                                      }
                                                      arrow
                                                    >
                                                      {it?.assignee
                                                        ?.profile_pic[indx] ? (
                                                        <img
                                                          src={
                                                            `${s3BaseUrl}${it?.assignee
                                                              ?.profile_pic[
                                                              indx
                                                            ]}`
                                                          }
                                                          className="smallProfile"
                                                          alt="img"
                                                        />
                                                                                                              ) : (
                                                        <div className="assigneeName">
                                                          {it?.assignee
                                                            ?.ShortHands[
                                                            indx
                                                          ] || "nil"}
                                                        </div>
                                                      )}
                                                    </Tooltip>
                                                  ))}
                                              </div>

                                              {it?.assignee?.name.length >
                                                3 && (
                                                <div>
                                                  <Tooltip
                                                    title={it?.assignee?.name?.map(
                                                      (name, indx) =>
                                                        indx > 2 && (
                                                          <li key={indx}>
                                                            {name}
                                                          </li>
                                                        )
                                                    )}
                                                    arrow
                                                  >
                                                    <span
                                                      style={{
                                                        display: "flex",
                                                        justifySelf: "center",
                                                        marginBlock: "6px",
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp;
                                                      <b>
                                                        {it?.assignee?.name
                                                          ?.length - 3}{" "}
                                                        more
                                                      </b>
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                              )}
                                            </div>
                                          </div>

                                          <div>
                                            <Tooltip
                                              title={
                                                <span>
                                                  {"document available"}
                                                </span>
                                              }
                                              arrow
                                            >
                                              {it?.isAttachments ? (
                                                <img src={photo} alt="docs" />
                                              ) : (
                                                ""
                                              )}{" "}
                                              {/* <img src={it.priority} /> */}
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                (it?.comments?.length || 0) +
                                                " comments"
                                              }
                                              onClick={() => {
                                                toggleAddOpenDetail();
                                                setSelectedId(it.id);
                                                setDedicatedTabValue(1);
                                              }}
                                            >
                                              <IconButton
                                                aria-label="add to favorites"
                                                size="extra-small"
                                              >
                                                <CommentIcon />
                                              </IconButton>
                                              <small>
                                                {it?.comments?.length || 0}
                                              </small>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}

                            {provided.placeholder}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
        </Container>
      </div>
      {isOpenDetails && (
        <KanbanDetailDrawer
          isOpen={isOpenDetails}
          toggleDrawer={toggleAddOpenDetail}
          selectedId={selectedId}
          dedicatedTabValue={dedicatedTabValue}
          setDedicatedTabValue={setDedicatedTabValue}
          actionAfterEdit={() => {
            scrollToTop();
            fetchAllTaskList();
          }}
        />
      )}
      <AddKanBanView
        isLoading={isLoading}
        isOpen={showAddTaskMenu}
        toggleDrawer={toggleTaskMenu}
        createTaskFunction={createTaskFunction}
        setIsLoading={setIsLoading}
      />
      {deleteOpen && (
        <DeleteModal
          isLoading={isLoading}
          show={deleteOpen}
          onCloseClick={onToggleCloseDelete}
          onDeleteClick={deleteFunction}
        />
      )}

      {editOpen && (
        <EditKanbanDetails
          assignee={assignee}
          isOpen={editOpen}
          id={selectedId}
          toggleDrawer={toggleEditMenu}
          onToggleCloseDelete={toggleEditMenu}
          cb={actionAfterEdit}
          assigneeMeta={assigneeMeta}
          fetchAssignees={fetchAssignees}
        />
      )}
    </>
  );
}

export default Board;
