module.exports = {
  google: {
    API_KEY: 'AIzaSyBA-0YjRxEsLba8454pO2CbSYyvgfTvtNE',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
};
