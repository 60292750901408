import React, { useState } from 'react';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import GlobalInnerHeader from '../globalInnerHeader';

function Details() {
  return (
    <>
      <form>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Company Contacts
          </h4>
          <div>
            <Button type="button" color="primary">
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </div>
        <ul className="activity-feed mb-0 ps-2">
          <li className="feed-item">
            <label>Contact 1</label>
            <input
              className="form-control"
              type="text"
              placeholder="Contact Number"
            />
          </li>
          <li className="feed-item">
            <label>Contact 2</label>
            <input
              className="form-control"
              type="text"
              placeholder="Contact Number"
            />
          </li>
        </ul>

        <hr className="cstm_hr" />

        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Company Emails
          </h4>
          <div>
            <Button type="button" color="primary">
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </div>
        <ul className="activity-feed mb-0 ps-2">
          <li className="feed-item">
            <label>Email 1</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email ID"
            />
          </li>
          <li className="feed-item">
            <label>Email 2</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email ID"
            />
          </li>
        </ul>

        <hr className="cstm_hr" />

        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <h4 style={{ fontWeight: '600', fontSize: '16px' }}>
            Social Medias
          </h4>
          <div>
            <Button type="button" color="primary">
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </div>
        <ul className="activity-feed mb-0 ps-2">
          <li className="feed-item">
            <label>Media 1</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email ID"
            />
          </li>
          <li className="feed-item">
            <label>Media 2</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email ID"
            />
          </li>
        </ul>
      </form>
      <div className="text-end mt-4">
        <Button className="yellow_gradient_btn">Save</Button>
      </div>
    </>
  );
}

export default Details;
