/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material";
import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
export function SearchDropDownListPaginationComponent({
  listCall,
  label,
  required,
  defaultValue,
  disabled,
  apiParams,
  searchEnabled,
  emitItem,
  disableClearable,
  onBlur,
  readOnly
}) {
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [position, setPosition] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [actualOptions, setActualOptions] = useState([]);
  const [listboxNode, setListboxNode] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    setSelectedValue(defaultValue ? {
      id: defaultValue?.id,
      key: `${defaultValue.code}|${defaultValue?.name ? defaultValue.name : '--'}`,
      label: (
        <div className='selectUserCombo d-flex justify-content-between align-items-center'>
          <span>{defaultValue?.name}</span> <span className="autocompleteId mx-4">{defaultValue?.code}</span>
        </div>
      ),
    } : null)
  }, [defaultValue]);
  const getOptionsDelayed = useCallback(
    debounce((query, apiParams, cb) => {
      setOptions([]);
      fetchAPI(query, apiParams).then((response) => {
        cb(response);
      });
    }, 1200),
    []
  );
  useEffect(() => {
    if (mounted && isOpen) {
      setLoading(true);
      getOptionsDelayed(searchQuery, apiParams, (response) => {
        const optionsList = response.data.data.data.map((opts) => {
          return {
            id: opts.id,
            // code: opts.code,
            label: (
              <div className='selectUserCombo d-flex justify-content-between align-items-center'>
                <span>{opts.name}</span> <span className="autocompleteId mx-4"> {opts.code}</span>
              </div>
            ),
            key: `${opts.code}|${opts?.name ? opts.name : '--'}`,
          };
        });
        setOptions(optionsList);
        setActualOptions(response.data.data.data)
        setPaginationObject(response.data.data.meta);
        setPage(page + 1);
        setLoading(false);
      });
    }
  }, [searchQuery, getOptionsDelayed, isOpen]);
  const fetchAPI = async (query, apiParams = {}) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };

      if (query && searchEnabled) {
        queryParams.search = query;
      }
      const response = await listCall(queryParams);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOptions = async (query) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }
      setLoading(true);
      const response = await listCall(queryParams);
      console.log("listcall", response)
      setPage(page + 1);
      const optionsList = response.data.data.data.map((opts) => {
        return {
          id: opts.id,
          label: (
            <div className='selectUserCombo d-flex justify-content-between align-items-center'>
              <span>{opts.name}</span> <span className="autocompleteId mx-4"> {opts.code}</span>
            </div>
          ),
          key: `${opts.code}|${opts?.name ? opts.name : '--'}`,
        };
      });
      setPaginationObject(response.data.data.meta);
      setActualOptions((prev) => [...prev, ...response.data.data.data])
      setOptions((prevState) => {
        return [...prevState, ...optionsList];
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Encountered problem with fetching records.");
    }
  };
  useEffect(() => {
    if (listboxNode !== "" && listboxNode !== undefined) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);
  const handleScroll = async (event) => {
    setListboxNode(event.currentTarget);
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      if (options.length !== paginationObject.total && !loading) {
        await fetchOptions();
      }
    }
  };

  return (
    <div className="customSearchDropdown">
      <MuiAutocomplete
        options={options}
        open={isOpen}
        autoHighlight
        loading={loading}
        disableClearable={disableClearable}
        disabled={disabled ?? false}
        value={selectedValue}
        onOpen={(e) => {
          if (e._reactName !== 'onChange') {
            setIsOpen(true);
          }
        }}
        onBlur={onBlur ? onBlur : undefined}
        getOptionLabel={(option) => (option ? option.key : "")}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            key={option.id}
            {...props}
          >
            {option.label}
          </Box>
        )}
        onInputChange={(event, newValue) => {
          setSearchQuery(newValue);
        }}
        onClose={() => {
          setIsOpen(false);
          setOptions([]);
          setPaginationObject([]);
          setLoading(false);
          setPage(0);
        }}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
          const itemToSend =
            newValue !== null
              ? options.find((_or) => _or.id === newValue.id)
              : undefined;
          const actualItem = newValue !== null
            ? actualOptions.find((_or) => _or.id === newValue.id)
            : undefined;
          emitItem(itemToSend && actualItem ? { ...itemToSend, ...actualItem } : undefined);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            className="selectUserCombo d-flex justify-content-between align-items-center"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                // <InputAdornment position="start" style={{ height: 'unset'}}>
                (selectedValue && selectedValue.label && selectedValue.label.props) ? (
                  <><span className="mx-2">{selectedValue.key?.split("|")[1]}</span>
                    <span className="autocompleteId me-2"> {selectedValue.key?.split("|")[0]}</span></>
                ) : null
                // </InputAdornment>
              ),
              // endAdornment: (
              // <InputAdornment position="end">
              // {selectedValue && selectedValue.label && selectedValue.label.props ? (
              // <IconButton
              // aria-label="toggle password visibility"
              // onClick={() => setSelectedValue("")}
              // >
              // <CancelRoundedIcon />
              // </IconButton>
              // ) : <ArrowDropDownIcon />}
              // </InputAdornment>
              // ),
              // endAdornment: (
              // <InputAdornment position="end">
              // {selectedValue && selectedValue.label && selectedValue.label.props ? (
              // <IconButton
              // aria-label="toggle password visibility"
              // onClick={clearButtonHandler}
              // ><CancelRoundedIcon /></IconButton>
              // ) : null}
              // </InputAdornment>
              // ),
              // endAdornment: (
              // <InputAdornment position="end">
              // {selectedValue && selectedValue.label && selectedValue.label.props ? (
              // <IconButton
              // aria-label="toggle password visibility"
              // onClick={() => setSelectedValue("")}
              // >
              // <CancelRoundedIcon />
              // </IconButton>
              // ) : <ArrowDropDownIcon />}
              // </InputAdornment>
              // ),
            }}
            inputProps={{
              ...params.inputProps,
              value: (selectedValue && selectedValue.label && selectedValue.label.props) ? '' : searchQuery
            }}
            size="small"
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </div>
  );
}