import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';
import GlobalInnerHeader from './globalInnerHeader';
import PropTypes from 'prop-types';
import { viewByIdApi, editApi } from '../../api/GlobalSettings/api';
import { Spinner } from 'reactstrap'

function PropertyListSorting({ tabDetails }) {
  const { id, payload } = tabDetails;

  const options = payload.order_available;

  const [sortPreference, setSortPreference] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDataModified, setIsDataModified] = useState(false);
  const [savedValue, setSavedValue] = useState(payload.value);

  function handleChange(sortPreference) {
    setSortPreference(sortPreference);
    setIsDataModified(true);
  }

  async function saveData() {
    try {
      setIsLoading(true)
      const input = {
        "payload": {
          order_available: payload?.order_available,
          order_by: sortPreference?.value
        }
      }
      const payloadDetails = {
        id,
        payload: { ...input }

      }
      await editApi(payloadDetails)
      setSavedValue(sortPreference.value)
      setIsDataModified(false);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }

  async function getPropertyListPreferenceDetails() {
    try {
      const response = await viewByIdApi(id)
      const { payload } = response;

      const find = options.find((item) => item.value === payload.order_by)
      setSortPreference(find)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPropertyListPreferenceDetails();
  }, [])

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Sorting Preference" />
      <div className="settingBody settings_holder mt-4">
        <span>Property List Sorting Preference</span>
        <div className="d-inline-block mx-2">
          <Select
            className="d-inline-flex"
            value={sortPreference}
            isDisabled={isLoading || !tabDetails.isEditable}
            onChange={(e) => {
              handleChange(e);
            }}

            options={options}
            classNamePrefix="select2-selection"
          />
        </div>
        <div className="text-end mt-4">
          {tabDetails.isEditable &&
            <Button
              disabled={!isDataModified || isLoading || sortPreference.value == savedValue}
              color="primary"
              onClick={saveData}>{isLoading && <Spinner size="sm" color="light" />}
              Save
            </Button>}
        </div>
      </div>
    </div>
  )

}
PropertyListSorting.propTypes = {
  tabDetails: PropTypes.any
}

export default PropertyListSorting;
