import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material";
import { toast } from "react-toastify";

export function SelectDropDownListPaginationComponent({
  listCall,
  label,
  defaultValue,
  disabled,
  apiParams,
  searchEnabled,
  emitItem,
  disableClearable,
  onBlur,
  readOnly,
  error,
  helperText,
  sx
}) {
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [position, setPosition] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [actualOptions, setActualOptions] = useState([]);
  const [listboxNode, setListboxNode] = useState("");

  useEffect(() => {
    setMounted(true);
  }, []);
  const getOptionsDelayed = useCallback(
    debounce((query, apiParams, cb) => {
      setOptions([]);
      fetchAPI(query, apiParams).then((response) => {
        cb(response);
      });
    }, 1200),
    []
  );
  useEffect(() => {
    if (mounted && isOpen) {
      setLoading(true);
      getOptionsDelayed(searchQuery, apiParams, (response) => {
        const optionsList = response.data.data.data.map((opts) => {
          const name = `${opts?.firstName} ${opts?.lastName}`
          return {
            id: opts.id,
            label: opts?.name ? opts.name : name,
            key: `${opts.id}|${opts?.name ? opts.name : name}`,
          };
        });
        setOptions(optionsList);
        setActualOptions(response.data.data.data)
        setPaginationObject(response.data.data.meta);
        setPage(page + 1);
        setLoading(false);
      });
    }
  }, [searchQuery, getOptionsDelayed, isOpen]);
  const fetchAPI = async (query, apiParams = {}) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }
      const response = await listCall(queryParams);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOptions = async (query) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }
      setLoading(true);
      const response = await listCall(queryParams);
      setPage(page + 1);
      const optionsList = response.data.data.data.map((opts) => {
        const name = `${opts?.firstName} ${opts?.lastName}`
        return {
          id: opts.id,
          label: opts.name ? opts.name : name,
          key: `${opts.id}|${opts.name ? opts.name : name}`,
        };
      });
      setPaginationObject(response.data.data.meta);
      setActualOptions((prev) => [...prev, ...response.data.data.data])
      setOptions((prevState) => {
        return [...prevState, ...optionsList];
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Encountered problem with fetching records.");
    }
  };
  useEffect(() => {
    if (listboxNode !== "" && listboxNode !== undefined) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);
  const handleScroll = async (event) => {
    setListboxNode(event.currentTarget);
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    // only when checking this condition we change the position
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      if (options.length !== paginationObject.total && !loading) {
        await fetchOptions();
      }
    }
  };

  return (
    <>
      <MuiAutocomplete
        disableClearable={disableClearable}
        options={options}
        open={isOpen}
        autoHighlight
        loading={loading}
        readOnly={readOnly}
        disabled={disabled}
        value={
          selectedValue
            ? selectedValue
            : defaultValue !== undefined
              ? defaultValue
              : null
        }
        onOpen={(e) => {
          if (e._reactName !== 'onChange') {
            setIsOpen(true);
          }
        }}
        onBlur={onBlur ? onBlur : undefined}
        getOptionLabel={(option) => (option ? option.key : "")}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            key={option.id}
            {...props}
          >
            {option.label}
          </Box>
        )}
        onInputChange={(event, newValue) => {
          let dropItem = newValue
          if (newValue.split('|').length > 1) {
            dropItem = newValue.split('|')[1]
          }
          setSearchQuery(dropItem);
        }}
        sx={sx ? sx : { minWidth: 200, marginRight: '10px' }}
        onClose={() => {
          setIsOpen(false);
          setOptions([]);
          setPaginationObject([]);
          setLoading(false);
          setPage(0);
        }}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
          const itemToSend =
            newValue !== null
              ? options.find((_or) => _or.id === newValue.id)
              : undefined;
          const actualItem = newValue !== null
            ? actualOptions.find((_or) => _or.id === newValue.id)
            : undefined;
          emitItem(itemToSend && actualItem ? { ...itemToSend, ...actualItem } : undefined);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              value: params.inputProps.value
                ? params.inputProps.value.split("|")[1]
                : "",
            }}
            className="bgWhite"
            size="small"
            error={error}
            helperText={helperText}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </>
  );
}
