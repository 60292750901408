/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import React from 'react'
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import { toast } from 'react-toastify';

export default function Search({listCall, params, disabled}) {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (data) => {
    try {
      let queryParams = {};
      if (Array.isArray(data.params)) {
        queryParams = {
          search: data.query,
          others: data.params
        }
      } else {
        queryParams = {
          ...data.params
        }
        if (Object.keys(queryParams).includes("search") && data.query) {
          queryParams.search = data.query
        } else if (!Object.keys(queryParams).includes("search") && data.query) {
          queryParams.search = data.query
        }else if (data.params.search && !data.query) {
          queryParams.search = data.query
        }
      }
      const res = await listCall(queryParams);
      setLoading(false)
      return res;
    } catch (err) {
      setLoading(false)
      toast.error('Encountered problem with fetching records.')
    }
  };
  const debouncedFetchData = useCallback(
    debounce((data, cb) => {
      fetchData(data, query).then((response) => {
        cb(response)
      })
    },
  1200), []);

  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
   if (mounted) {
    setLoading(true);
    debouncedFetchData({params, query}, res => {
      setResults(res);
    });
   }
  }, [query, debouncedFetchData]);
return (
    <div className='search_holder position-relative'>
      <TextField
          placeholder="Search..."
          disabled={ disabled ?? false }
          size="small"
          onChange={ (event) => { setQuery(event.target.value) }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="uil-search" />
              </InputAdornment>
            ),
            endAdornment: (
            <div className="flexbox_holder loadingIcon_pos_abs">
                {loading ? <CircularProgress color="inherit" size={20} /> : ""}
            </div>
            ),
          }}
    />

    </div>
)
}