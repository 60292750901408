import React, { useState, useEffect } from 'react';
import SelectBed from './selectBed';
import TextField from '@mui/material/TextField';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Link from '@mui/material/Link';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AddAmenitiesModal from "./addAmenitiesModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ACTIONS, PROPERTY_TYPES } from '../../common/constant';
import { SelectDropDownListPaginationComponent } from '../Common/SelectDropDownPaginationList';
import { roomTypeListing } from '../../api/common';
import uuid from 'react-native-uuid';
import { formatBytes, getS3BaseUrl } from '../../helpers/string_helper';
import ConfirmationModal from '../Common/ConfirmationModal';
import { selectAmenitiesIcon } from '../../common/amenities_icon';
import { documentRemove } from '../../api/documents';
import { storePropertyBedDetails, storePropertyRoomDetails } from '../../store/property';
import { useDispatch } from 'react-redux'
import { getPropertyDetails } from '../Hooks/PropertyHooks';
import { AsterikLabel } from '../Common/AsterikLabel';
import UploadDocumentModal from './uploadDocumentModal';
import { toast } from 'react-toastify';

export default function SelectRoom({ basicPropertyData, flatData, emitPropertyData, propertyData, uniqueId, previousData }) {
    const { bedData } = getPropertyDetails()
    const handleOpen = () => setOpen(true);
    const [open, setOpen] = React.useState(false);
    const [update, setUpdate] = useState(0);
    const [foodMenuUpdate, setFoodMenuUpdate] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [roomIndex, setRoomIndex] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState();
    const [onMounted, setOnMounted] = useState(false);
    const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
        status: false,
        data: null,
    });
    const roomsObject = {
        rooms: [{
            name: '',
            amenities: '',
            room_type_id: '',
            documents: '',
            roomUniqueId: '',
        }]
    }

    useEffect(() => {
        if (uniqueId && !onMounted && !propertyData?.id) {
            const result = formik.values?.rooms?.map((item) => {
                if (!item.roomUniqueId) {
                    return { ...item, roomUniqueId: item?.uniqueId ? item.uniqueId : uuid.v4() }
                } else {
                    return item
                }
            })
            formik.setValues({ rooms: result })
            setOnMounted(true)
            setUpdate(update + 1)
        }
    }, [uniqueId])

    useEffect(() => {
        if (propertyData?.rooms?.length > 0) {
            const s3BaseUrl = getS3BaseUrl()
            let options = []
            const roomData = propertyData?.rooms?.map((rm) => {
                options.push(rm.name)
                return {
                    ...rm,
                    name: rm.name,
                    room_type_id: { label: rm?.room_type.name, id: rm?.room_type.id, key: `${rm?.room_type.id}|${rm?.room_type.name}` },
                    documents: rm.documents?.map((item) => {
                        return { ...item, location: `${s3BaseUrl}${item?.location}` }
                    }),
                    amenities: rm?.amenities?.map((am) => {
                        return { name: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }
                    }),
                    roomUniqueId: uuid.v4()
                }
            })
            formik.setValues({
                rooms: roomData
            })
        }
    }, [propertyData])

    useEffect(() => {
        if (flatData?.flatData?.name && ((!propertyData?.id && !basicPropertyData?.ownerId) ? flatData?.flatData?.user_id : true)) {
            setIsDisabled(false);
            if (!formik.values.rooms[0].name && (!basicPropertyData?.ownerId ? flatData.flatData?.user_id : true) && !propertyData?.id) {
                if (previousData?.length > 0) {
                    const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                    if (result?.length > 0) {
                        const updatedRoom = result[0]?.rooms?.map((room, index) => {
                            if (!room?.name) {
                                return {
                                    ...room,
                                    name: `Room ${index + 1}`,
                                    roomUniqueId: room?.roomUniqueId ? room?.roomUniqueId : uuid.v4()
                                }
                            }
                            else {
                                return room
                            }
                        })
                        formik.setValues({ rooms: updatedRoom })
                    } else if (formik.values.rooms?.length === 1 && !formik.values.rooms[0].roomUniqueId) {
                        formik.setValues({ rooms: [{ ...formik.values.rooms[0], name: 'Room 1' }] })
                    }
                } else {
                    const result = formik.values.rooms?.map((room, index) => {
                        if (!room?.name) {
                            return {
                                ...room,
                                name: `Room ${index + 1}`,
                                roomUniqueId: room?.roomUniqueId ? room?.roomUniqueId : uuid.v4()
                            }
                        }
                        else {
                            return {
                                ...room, roomUniqueId: room?.roomUniqueId ? room?.roomUniqueId : uuid.v4()
                            }
                        }
                    })
                    formik.setValues({
                        rooms: result
                    })
                }
            }
        } else if ((flatData?.flatData?.name === null || !flatData?.flatData?.name) || (flatData?.flatData?.name)) {
            setIsDisabled(true)
            if (previousData?.length > 0) {
                const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                if (result?.length > 0) {
                    const updatedRoom = result[0]?.rooms?.map((room) => {
                        if (room?.name) {
                            return {
                                name: '',
                                amenities: '',
                                room_type_id: '',
                                documents: '',
                                roomUniqueId: room?.roomUniqueId ? room?.roomUniqueId : uuid.v4()
                            }
                        }
                        else {
                            return { ...room, roomUniqueId: room?.roomUniqueId ? room?.roomUniqueId : uuid.v4() }
                        }
                    })
                    formik.setValues({
                        rooms: updatedRoom
                    })
                    setUpdate(update + 1)
                }
            }
        }
        else {
            setIsDisabled(true)
        }
    }, [flatData?.flatData?.name, flatData.flatData?.user_id])

    const roomValidation = Yup.object().shape({
        rooms: Yup.array().of(Yup.object().shape({
            name: Yup.string().required("Please select room name"),
            room_type_id: Yup.number()
                .required("Please select room type")
        })).required().min(1),
    });

    const formik = useFormik({
        initialValues: {
            ...roomsObject,
        },
        validateOnMount: true,
        validationSchema: roomValidation
    });

    const handleAddRoom = () => {
        const roomData = []
        formik.values.rooms?.forEach((rm, index) => {
            roomData.push(rm)
            if (formik.values.rooms?.length === index + 1) {
                roomData.push(isDisabled ? { ...rm, roomUniqueId: uuid.v4() } : {
                    name: `Room ${index + 2}`,
                    roomUniqueId: uuid.v4()
                })
            }
        })
        formik.setValues({
            ...formik.values,
            rooms: roomData
        })
    };

    const handleRoomChange = (index, field, value) => {
        const result = formik.values.rooms.map((rm, rmIndex) => {
            if (rmIndex === index && field === 'room_type_id') {
                return {
                    ...rm,
                    room_type_id: value
                }
            } else {
                return rm
            }
        })
        formik.setValues({
            ...formik.values,
            rooms: result
        })
    };

    const removeRoom = (index) => {
        if (formik.values?.rooms?.length === 1) {
            // Prevent removing the last room
            return;
        }

        const updatedRooms = [...formik.values.rooms];
        const removeBedData = bedData?.filter((rm) => rm.uniqueId !== updatedRooms[index]?.uniqueId)
        dispatch(storePropertyBedDetails({
            bedData: removeBedData
        }))
        updatedRooms.splice(index, 1);
        const roomData = []
        updatedRooms?.forEach((rm, rmIndex) => {
            if (index > rmIndex) {
                roomData.push(rm)
            } else {
                roomData.push(isDisabled ? rm : {
                    ...rm,
                    name: `Room ${rmIndex + 1}`
                })
            }
        })
        formik.setValues({
            rooms: roomData
        })
        setUpdate(update + 1)
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAddAmenities = (data) => {
        const updatedRooms = [...formik.values.rooms];
        updatedRooms[roomIndex]['amenities'] = data;
        formik.setValues({
            ...formik.values,
            rooms: [...updatedRooms]
        })
    }

    function handleAcceptedFiles(files) {
        setIsLoading(true)
        files.map((file) =>
            Object.assign(file, {
                uniqueId: uuid.v4(),
                location: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            }),
        );
        const updatedRooms = [...formik.values.rooms];
        updatedRooms[roomIndex]['documents'] = selectedFiles?.length > 0 ? [...selectedFiles, ...files] : files;
        formik.setValues({
            ...formik.values,
            rooms: [...updatedRooms]
        })
        setSelectedFiles((prev) => {
            return prev ? [...prev, ...files] : files
        })
        setFoodMenuUpdate(foodMenuUpdate + 1)
        setIsLoading(false)
    }

    async function handleFileRemove(file, fileIndex) {
        setIsLoading(true)
        const newDocuments = formik.values.rooms[roomIndex].documents?.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))
        const updatedRooms = [...formik.values.rooms];
        updatedRooms[roomIndex]['documents'] = newDocuments;
        if (file?.id) {
            const response = await documentRemove(file.id, { moduleId: file.room_id, module: 'room' });
            toast.success(response.data.message);
        }

        formik.setValues({
            ...formik.values,
            rooms: [...updatedRooms]
        })
        setSelectedFiles(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
        setFoodMenuUpdate(foodMenuUpdate + 1)
        setIsLoading(false);
        return true
    }

    useEffect(() => {
        if (formik.values.rooms?.length > 0 && flatData?.flatData) {
            let rooms = []
            formik.values.rooms.forEach((room) => {
                if (room.room_type_id && room.name) {
                    rooms.push({
                        name: room.name,
                        room_type_id: room.room_type_id?.id ? room.room_type_id?.id : room.room_type_id,
                        ...(room.amenities && {
                            amenities: room.amenities
                        }),
                        ...(room.documents && {
                            documents: room.documents
                        })
                    })
                }
            })

            let payload
            if (basicPropertyData?.type === PROPERTY_TYPES.APARTMENT) {
                payload = {
                    name: flatData?.floorData.name,
                    flats: {
                        ...flatData.flatData,
                        ...(flatData.flatData?.user_id && {
                            user_id: flatData.flatData?.user_id?.id ? flatData.flatData?.user_id?.id : flatData.flatData?.user_id
                        }),
                        rooms: rooms
                    }
                }
            } else {
                payload = {
                    name: flatData?.flatData?.name,
                    ...(flatData?.flatData?.user_id && {
                        user_id: flatData?.flatData?.user_id?.id ? flatData?.flatData?.user_id?.id : flatData?.flatData?.user_id
                    }),
                    rooms: rooms
                }
            }

            if (uniqueId) {
                let y = JSON.parse(JSON.stringify(formik.values.rooms));
                const updatedData = previousData?.filter((item) => item.uniqueId !== uniqueId)
                dispatch(storePropertyRoomDetails(previousData ? {
                    roomData: [...updatedData, {
                        uniqueId,
                        rooms: y,
                    }]
                } : {
                    roomData: [{
                        uniqueId,
                        rooms: y,
                    }]
                }))
            }

            if (rooms?.length > 0) {
                emitPropertyData({
                    floorData: payload
                }, 'room')
            }
        }
    }, [formik.values.rooms])

    return (
        <>
            <div className="roomWrapper" key={update}>
                {formik.values.rooms?.map((room, index) => (
                    <div key={index} className="roomWrap d-flex">
                        <div
                            className="room_section d-flex flex-column"
                            style={{ padding: "5px 10px 5px 0" }}
                        >
                            <div className="singleRoomWrap d-flex align-items-center">
                                <TextField
                                    disablePortal
                                    id="combo-box-demo"
                                    readOnly
                                    label="Selected room"
                                    disabled={isDisabled}
                                    value={room?.name}
                                    sx={{ minWidth: 150, marginRight: '10px' }}
                                    size="small"
                                />
                                <SelectDropDownListPaginationComponent
                                    label={<div>Select Room Type <AsterikLabel /></div>}
                                    searchEnabled={false}
                                    disabled={isDisabled}
                                    disableClearable={propertyData?.id}
                                    key={update}
                                    listCall={roomTypeListing}
                                    defaultValue={
                                        room?.room_type_id?.id ? room?.room_type_id : null
                                    }
                                    emitItem={(newValue) => {
                                        handleRoomChange(
                                            index,
                                            "room_type_id",
                                            newValue ? newValue : ""
                                        );
                                    }}
                                />
                                <CancelOutlinedIcon
                                    color="error"
                                    onClick={() => removeRoom(index)}
                                    className={`cancelIcon ${index === 0 ? "fadedCross" : ""}`}
                                />
                            </div>
                            <Link
                                href="#"
                                underline="none"
                                style={{
                                    marginTop: "10px",
                                    color: isDisabled ? "grey" : "blue",
                                    pointerEvents: isDisabled ? "none" : "",
                                }}
                                onClick={() => {
                                    setRoomIndex(index);
                                    setSelectedFiles(room.documents);
                                    handleOpen();
                                }}
                            >
                                <AddPhotoAlternateOutlinedIcon /> &nbsp; Add Picture
                            </Link>
                            <Link
                                href="#"
                                underline="none"
                                onClick={() => {
                                    setRoomIndex(index);
                                    handleOpenModal();
                                }}
                                style={{
                                    color: isDisabled ? "grey" : "blue",
                                    pointerEvents: isDisabled ? "none" : "",
                                }}
                            >
                                <OtherHousesOutlinedIcon /> &nbsp; Add Amenities
                            </Link>
                        </div>

                        <div className="bed_section d-flex flex-column  padding_left_common">
                            <SelectBed
                                previousData={bedData?.length > 0 ? bedData : undefined}
                                uniqueId={room.roomUniqueId}
                                roomData={{
                                    name: flatData?.floorData
                                        ? flatData?.floorData
                                        : flatData?.flatData?.name,
                                    ...(flatData?.flatData?.user_id && {
                                        user_id: flatData?.flatData?.user_id,
                                    }),
                                    ...(flatData.floorData && { flats: flatData.flatData }),
                                    rooms: formik.values.rooms[index],
                                }}
                                emitPropertyData={emitPropertyData}
                                propertyData={propertyData ? formik.values.rooms[index] : undefined}
                            />
                        </div>
                    </div>
                ))}
                <Link href="javascript:void(0)" underline="none" onClick={handleAddRoom}>
                    <AddOutlinedIcon /> &nbsp; Add Room
                </Link>

                {/* Render added beds */}
                {open && (
                    <UploadDocumentModal
                        key={foodMenuUpdate}
                        open={open}
                        handleClose={() => setOpen(false)}
                        documents={selectedFiles}
                        handleSubmit={(files) => handleAcceptedFiles(files)}
                        handleDelete={handleFileRemove}
                    />
                )}

                {/* Rendered the Amenities Modal */}
                {isModalOpen && (
                    <AddAmenitiesModal
                        open={isModalOpen}
                        handleClose={handleCloseModal}
                        handleAddAmenities={handleAddAmenities}
                        selectedAmenities={formik.values.rooms[roomIndex].amenities}
                    />
                )}

                <ConfirmationModal
                    action={ACTIONS.DELETE}
                    show={isDocumentDeleteConfirm?.status}
                    onCloseClick={() => {
                        setIsDocumentDeleteConfirm({ status: false, data: null })
                        setOpen(true)
                    }}
                    onAcceptClick={async () => {
                        const { file, fileIndex } = isDocumentDeleteConfirm.data;
                        const isDeleted = handleFileRemove(file, fileIndex);
                        if (isDeleted) {
                            setIsDocumentDeleteConfirm({ status: false, data: null });
                            setOpen(true);
                        }
                    }}
                    isDisabled={isLoading}
                />
            </div>
        </>
    );
}
