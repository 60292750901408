import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import ComplainTicketTabs from '../../components/ComplainTicket/complainTicketTabs';
import { tenantsTicketsCount } from '../../api/ComplainTicket/api'
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

function ComplainTicket() {

  const [navigateState] = useState(useLocation()?.state);
  const [pendingTicket, setPendingTicket] = useState({});
  const [inProgressTicket, setInProgressTicket] = useState({});
  const [resolvedTicket, setResolvedTicket] = useState({});

  const [params, setParams] = useState({});

  const [selectedTab, setSelectedTab] = useState('Tenant');
  const fetchCounterData = async () => {
    try {

      let updatedParams = { ...params };

      if (selectedTab === 'Tenant') {
        updatedParams.filter_fields = !updatedParams.filter_fields ? ['role'] : [...updatedParams.filter_fields];
        updatedParams.filter_inputs = !updatedParams.filter_inputs ? ['TENANT'] : [...updatedParams.filter_inputs];
      } else {
        updatedParams.filter_fields = !updatedParams.filter_fields ? ['role'] : [...updatedParams.filter_fields];
        updatedParams.filter_inputs = !updatedParams.filter_inputs ? ['OWNER'] : [...updatedParams.filter_inputs];
      }

      const response = await tenantsTicketsCount(updatedParams);
      setPendingTicket(response.data);
      setInProgressTicket(response.data);
      setResolvedTicket(response.data);
    } catch (error) {
      if(error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCounterData();
  }, [selectedTab]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Tenant Details" breadcrumbItem="Existing Tenants" /> */}
        <Row className='d-grid' style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
          <div className="stat_outer_holder">
            <div className="stat_card_holder">
              <div className="flexbox_holder justify-content-start">
                <div className="icon_holder">
                  <i className="mdi mdi-currency-inr" />
                </div>
                <h4 className="heading">Pending Ticket</h4>
              </div>
              <h2 className="num_heading">
                {pendingTicket.pending}
              </h2>
            </div>
          </div>
          <div className="stat_outer_holder">
            <div className="stat_card_holder">
              <div className="flexbox_holder justify-content-start">
                <div className="icon_holder">
                  <i className="mdi mdi-currency-inr" />
                </div>
                <h4 className="heading">In-Progress Ticket</h4>
              </div>
              <h2 className="num_heading">{inProgressTicket.inprogress}</h2>
            </div>
          </div>
          <div className="stat_outer_holder">
            <div className="stat_card_holder">
              <div className="flexbox_holder justify-content-start">
                <div className="icon_holder">
                  <i className="mdi mdi-currency-inr" />
                </div>
                <h4 className="heading">Resolved Tickets</h4>
              </div>
              <h2 className="num_heading">{resolvedTicket.resolved}</h2>
            </div>
          </div>
          <div className="stat_outer_holder">
            <div className="stat_card_holder">
              <div className="flexbox_holder justify-content-start">
                <div className="icon_holder">
                  <i className="mdi mdi-currency-inr" />
                </div>
                <h4 className="heading">Declined Tickets</h4>
              </div>
              <h2 className="num_heading">{resolvedTicket.declined}</h2>
            </div>
          </div>
        </Row>
        {/* Tabs here */}
        <ComplainTicketTabs
        setSelectedTab={setSelectedTab}
        fetchCounterData={fetchCounterData}
        navigateState={navigateState}
        />
      </div>
    </div>
  );
}

export default ComplainTicket;
