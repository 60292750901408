import { FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { getAllPropertyOwner } from '../../api/owner';
import { MultipleSelectDropDownListPaginationComponent } from '../../components/Common/MultipleSelectDropDownPaginationList';
import { ucFirst } from '../../helpers/string_helper';

function PropertyFilter({ isOpen, toggleDrawer, emitFilterItem }) {
  const [filterObject, setFilterObject] = useState({
    name: '',
    owner_name: '',
    status: '',
  })
  const [selectedUsers, setSelectedUsers] = useState([])
  const filterInputs = []
  const filterFields = []

  const handleApplyFilter = () => {
    if (filterObject.name) {
      filterFields.push('name')
      filterInputs.push(filterObject.name)
    }

    if (filterObject.status) {
      filterFields.push('status')
      filterInputs.push(filterObject.status)
    }

    if (filterObject.user_id) {
      filterFields.push('user_id')
      filterInputs.push(filterObject.user_id)
    }

    emitFilterItem({ filter_fields: filterFields, filter_inputs: filterInputs })
    toggleDrawer()
  }

  const handleResetFilter = () => {
    setFilterObject({
      name: '',
      owner_name: '',
      status: '',
    })
    setSelectedUsers([])
    emitFilterItem({ filter_fields: [], filter_inputs: [] })
    toggleDrawer()
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        toggleDrawer(isOpen)
        setFilterObject({})
      }}
    >
      <OffcanvasHeader toggle={() => {
        toggleDrawer(isOpen)
        setFilterObject({})
      }}>
        Property Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <input
                  className="form-control"
                  type="text"
                  name='name'
                  value={filterObject.name}
                  placeholder="Property Name"
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value
                    })
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <FormControl sx={{ minWidth: 343 }} size="small">
                  <Select
                    displayEmpty
                    value={filterObject.status}
                    onChange={(e) => {
                      setFilterObject({
                        ...filterObject,
                        status: e.target.value
                      })
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#6D6D6D', fontSize: '0.9rem' }}>Select Status</span>
                      }
                      return <span style={{ color: '#6D6D6D', fontSize: '0.9rem' }}>{ucFirst(selected)}</span>
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem disabled value="">
                      <em>Status</em>
                    </MenuItem>
                    <MenuItem value='Close'>Close</MenuItem>
                    <MenuItem value='Suspend'>Suspend</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='col-md-6 col-12 mb-3'>
                <MultipleSelectDropDownListPaginationComponent
                  fullWidth
                  label="Select Owner"
                  searchEnabled={true}
                  defaultValue={selectedUsers}
                  listCall={getAllPropertyOwner}
                  emitItem={(users) => {
                    setFilterObject({
                      ...filterObject,
                      user_id: users.map(_u => _u.id).toString()
                    })
                    setSelectedUsers(users)
                  }}
                />

              </div>
            </Row>
            <div className="formActions btn_right">
              <Button className="yellow_gradient_btn mx-2" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button className="red_gradient_btn" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default PropertyFilter;
