import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import FileList from '../Common/FileList';
import { formatBytes } from '../../helpers/string_helper';
import uuid from 'react-native-uuid';
import { getCompanyAssociatesDetails } from '../../api/company-associates';
import { toast } from 'react-toastify';


function CompanyAssociatesDetailDrawer({ associateId, isOpen, setIsOpen }) {
  const [associateDetails, setAssociateDetails] = useState([])
  const fetchAssociateData = async () => {
    try {
      const response = await getCompanyAssociatesDetails(+associateId);
      if (response?.status === 200) {
        setAssociateDetails(response?.data?.data);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };
  useEffect(() => {
    if (+associateId) {
      fetchAssociateData();
    }
  }, [associateId]);
  return (
    <>
      <Offcanvas
        isOpen={isOpen}
        direction='end'
        toggle={() => setIsOpen(!isOpen)}>
        <OffcanvasHeader
        toggle={() => setIsOpen(!isOpen)}/>
        <OffcanvasBody>
          <Form>
            <div className='tenantDetailsDrawer'>
              <Row className='mb-5'>
                <div className='col-sm-12'>
                  <div className='custom-image-upload'>
                    <div className='upload_picture'>
                      <div className='pro_person_name'>
                        {associateDetails?.name}
                      </div>
                      <div className='pro_person_mob'>
                        <i className='fas fa-phone-alt me-1' />
                        <span className='country_code'>+91</span>
                        <span className='pr_person_contact'>
                          {associateDetails?.contact_number ?? 'N/A'}
                        </span>
                      </div>
                      <div className='pro_person_mob tenantDetailEmail'>
                        <span className='pr_person_contact'>
                          <i className='fas fa-envelope me-1' />
                          {associateDetails?.email ?? 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className='mb-3'>
                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Associates Type:</div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.type ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Associates Status:</div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.status ?? 'N/A'}
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>
                  Representative Name:
                  </div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.contact_person_name ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>
                  Representative Contact No.                  </div>
                  <div className='tenantDetailDesc'>
                    +91 {associateDetails?.contact_person_contact_number ?? 'N/A'}
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
              <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle'>Representative Designation:</div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.designation ?? 'N/A'}
                  </div>
                </div>

                <div className='col-sm-6 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle tenantDetHalfFullTitle'>
                    Address:
                  </div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.address ?? 'N/A'}
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
                <div className='col-sm-12 d-flex align-items-center custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle tenantDetHalfFullTitle'>
                    Note:
                  </div>
                  <div className='tenantDetailDesc'>
                    {associateDetails?.notes ?? 'N/A'}
                  </div>
                </div>
              </Row>

              <Row className='mb-3'>
                <div className='col-sm-12 custom_border_bottom pb-3'>
                    {
                      associateDetails?.documents?.length > 0 && (
                        <>
                          <div className='tenantDetailTitle tenantDetFullTitle'>
                            Attachments:
                          </div>
                          <FileList files={associateDetails?.documents.map((doc) => ({...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size)}))} />
                        </>
                      )
                    }
                </div>
              </Row>
            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

CompanyAssociatesDetailDrawer.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  associateId: PropTypes.number,
}
export default CompanyAssociatesDetailDrawer;
