import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { getS3BaseUrl } from "../../../helpers/string_helper";

export default function ProductSlider({ propertyDetails }) {
    const [mainImages, setMainImages] = useState();
    const [variantImages, setVariantImages] = useState();
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        if (propertyDetails?.pictures) {
            const result = [{
                name: propertyDetails?.default_image?.name,
                location: `${s3BaseUrl}${propertyDetails?.default_image?.location}`
            }]
            propertyDetails.pictures.forEach((item) => {
                if (item.name !== propertyDetails?.default_image?.name) {
                    result.push({
                        name: item.name,
                        location: `${s3BaseUrl}${item?.location}`  
                    })
                }
            })
            setMainImages(result)
            setVariantImages(result)
        }
    }, [propertyDetails])

    const mainSliderRef = useRef(null);
    const variantSliderRef = useRef(null);

    const mainSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplay: true,
        infinite: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const variantSliderSettings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ],
        margin: 10, // Adjust margin between variant images
        beforeChange: (current, next) => {
            mainSliderRef.current.slickGoTo(next); // Sync main slider with variant slider
        }
    };

    return (
        <div className="propertySliderSection">
            <Slider {...mainSliderSettings} className="mainSlider" ref={mainSliderRef}>
                {mainImages && mainImages?.map((image, index) => (
                    <div key={index}>
                        <img src={image.location} alt={image.name} width="610" height="354" style={{ borderRadius: '5px', width: '100%' }} />
                    </div>
                ))}
            </Slider>
            {variantImages?.length > 1 && (
                <Slider {...variantSliderSettings} className="variantSlider" ref={variantSliderRef}>
                    {variantImages?.length > 0 && variantImages?.map((image, index) => (
                        <div key={index}>
                            <img src={image?.location} alt={`Variant ${index}`} />
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
}
