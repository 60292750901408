import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Button } from 'reactstrap';
import TextField from '@mui/material/TextField';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export default function AddHouseRulesModal({ open, handleClose, handleAddHouseRules, selectedRules }) {
    const [enteredHouseRulesList, setEnteredHouseRulesList] = useState([]);

    const handleRemoveHouseRule = (itemToRemove) => {
        const isAlreadyAdded = enteredHouseRulesList.some(item => item === itemToRemove);
        if (isAlreadyAdded) {
            const selectedItem = enteredHouseRulesList.filter(item => item !== itemToRemove);
            if (selectedItem) {
                setEnteredHouseRulesList(selectedItem);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const newValue = event.target.value.trim();
            if (newValue !== '') {
                const isAlreadyAdded = enteredHouseRulesList.some(item => item === newValue);
                if (!isAlreadyAdded) {
                    setEnteredHouseRulesList(prevList => [...prevList, newValue]);
                    event.target.value = '';
                }
                else {
                    toast.warning('Rule already added');
                }
            }
        }
    };

    const handleSaveHouseRules = () => {
        handleClose();
        handleAddHouseRules(enteredHouseRulesList);
    }

    useEffect(() => {
        if (selectedRules && selectedRules?.length > 0) {
            setEnteredHouseRulesList(selectedRules)
        }
    }, [selectedRules])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="amenityCustomBox">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Add House Rules</h5>
                    <CancelOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                </div>
                <div className="search-field-wrapper d-flex align-items-center mb-2">
                    <TextField 
                        id="outlined-basic" 
                        label="Add House Rules"
                        placeholder="Type the rules and press enter to add to the list"
                        variant="outlined" 
                        className="w-100" 
                        onKeyPress={handleKeyPress}
                    />
                </div>

                <ul className="amenties_list ps-0 mt-4" style={{ listStyle: 'none' }}>
                    {enteredHouseRulesList.map((item, index) => (
                        <li className="position-relative d-flex justify-content-between" key={index + 1}>
                            <div className="d-flex"> <div className="amenity_item">{item}</div></div>
                            <CancelOutlinedIcon className="delete-icon" onClick={() => handleRemoveHouseRule(item)} />
                        </li>
                    ))}
                </ul>

                <Divider style={{ backgroundColor: 'rgb(74 74 74)', margin: '15px 0' }} />
                <div className="modal_footer text-center">
                    <Button className="yellow_gradient_btn" onClick={handleSaveHouseRules}>
                        Save
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}
