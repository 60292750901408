import React, { useEffect, useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { Autocomplete, TextField } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function VacantRequestFilter({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const roomType = ['AC', 'Non AC'];
  const acknowledgeStatus = ['Acknowledged', 'Cancelled', 'Refunded', 'Pending'];

  const [filterObject, setFilterObject] = useState({
    name: '',
    property_name: '',
    room_type: '',
    acknowledge_status: '',
    leaving_date: '',
  });

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  const [pickerOpen, setPickerOpen] = useState(false); // Manage picker visibility

  const handleApplyFilter = () => {
    const filterFields = [];
    const filterInputs = [];

    if (filterObject.name) {
      filterFields.push('tenant_name');
      filterInputs.push(filterObject.name);
    }

    if (filterObject.property_name) {
      filterFields.push('property_name');
      filterInputs.push(filterObject.property_name);
    }

    if (filterObject.room_type) {
      filterFields.push('room_type');
      filterInputs.push(filterObject.room_type);
    }

    if (filterObject.acknowledge_status) {
      filterFields.push('acknowledge_status');
      filterInputs.push(filterObject.acknowledge_status);
    }

    if (selectionRange.startDate && selectionRange.endDate) {
      const formattedStartDate = format(selectionRange.startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(selectionRange.endDate, 'yyyy-MM-dd');
      filterFields.push('leaving_date');
      filterInputs.push(`${formattedStartDate} - ${formattedEndDate}`);
    }

    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const handleResetFilter = () => {
    setFilterObject({
      name: '',
      property_name: '',
      room_type: '',
      acknowledge_status: '',
      leaving_date: '',
    });
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    });
    setPickerOpen(false); // Ensure picker is closed
    emitFilterItem([], []);
    toggleDrawer();
  };

  useEffect(() => {
    selectedFields.forEach((field, index) => {
      setFilterObject((prev) => ({ ...prev, [field]: selectedInputs[index] }));
    });
  }, [selectedFields, selectedInputs]);

  const handleDateRangeChange = (item) => {
    setSelectionRange(item.selection);
    const formattedStartDate = format(item.selection.startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(item.selection.endDate, 'yyyy-MM-dd');
    setFilterObject({
      ...filterObject,
      leaving_date: `${formattedStartDate} - ${formattedEndDate}`
    });
    setPickerOpen(false); // Close picker after selecting date range
  };

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
        setSelectionRange({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        });
        setPickerOpen(false); // Ensure picker is closed
      }}
    >
      <OffcanvasHeader toggle={() => {
        toggleDrawer(isOpen);
        setFilterObject({});
        setSelectionRange({
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        });
        setPickerOpen(false); // Ensure picker is closed
      }}>
        Vacant Request Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='name'
                  value={filterObject.name}
                  label="Tenant Name"
                  onChange={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='property_name'
                  value={filterObject.property_name}
                  label="Property Name"
                  onChange={(e) => {
                    setFilterObject({
                      ...filterObject,
                      property_name: e.target.value
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo-room-type"
                  options={roomType}
                  value={filterObject.room_type}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      room_type: newValue
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Room Type" />}
                  size="small"
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo-acknowledge-status"
                  options={acknowledgeStatus}
                  value={filterObject.acknowledge_status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      acknowledge_status: newValue
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Admin Acknowledgment Status" />}
                  size="small"
                />
              </div>
              <div className="col-md-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='leaving_date'
                  value={filterObject.leaving_date || ''}
                  label="Leaving Date"
                  InputProps={{ readOnly: true }}
                  onClick={() => setPickerOpen(!pickerOpen)}
                />
                {pickerOpen && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleDateRangeChange}
                  />
                )}
              </div>
            </Row>
            <div className="formActions btn_right">
              <Button className="yellow_gradient_btn mx-2" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button className="red_gradient_btn" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default VacantRequestFilter;
