import { useEffect } from "react"
import pusher from ".."

function useChannel(channelName, eventName, callback){
  useEffect(() =>{
    if (channelName) {
      const channel = pusher.subscribe(channelName)
      channel.bind(eventName, (data) => {
        callback(data)
      })
      return() => channel.unbind(eventName)
    } else {
      pusher?.user?.bind(eventName, (data) => {
        callback(data)
      })
    }
  }, [])
}

export default useChannel