import React, { useState, useEffect } from 'react';
import {
  Button, Offcanvas, OffcanvasHeader, OffcanvasBody, Input, Label,
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { fetchRoleWithPermissions, getAllPermissions, roleUpdate } from '../../api/role';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from "react-toastify"
import { ALL_MODULES, OPERATION_TYPE } from '../../common/constant';

function EditRoles({ isOpen, toggleDrawer, selectedRoleId }) {
  const [selectedMulti, setselectedMulti] = useState(false);
  const [modulePermissionIds, setModulePermissionIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([])
  const [update, setUpdate] = useState(0)
  const [modulePermissions, setModulePermissions] = useState([])

  const permissionActions = {
    read: { label: 'Read', order: 1 },
    create: { label: 'Create', order: 2 },
    edit: { label: 'Edit', order: 3 },
    delete: { label: 'Delete', order: 4 },
    add_tenant: { label: 'Add Tenant', order: 5 },
    remove_tenant: { label: 'Remove Tenant', order: 6 },
    payout_payment: { label: 'Payout Payment', order: 5 },
    deposit_payment: { label: 'Deposit Payment', order: 6},
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  const fetchPermissions = async () => {
    try {
      const permissionsResponse = await getAllPermissions()
      const rolePermissionsResponse = await fetchRoleWithPermissions(selectedRoleId)
      let newModulePermissions = permissionsResponse.data.data
      const newRolePermissions = rolePermissionsResponse.data.data
      const permissionsActionsFinal = Object.values(permissionActions).sort((a, b) => a.order - b.order);
      let selectedIds = []
      let modulePermissionId = []
      newModulePermissions = newModulePermissions.map((module) => {
        const foundModule = newRolePermissions.module_permissions.find((_mp) => _mp.module === module.module)
        const permissionsFinal = module.permissions.map((permission) => {
          modulePermissionId.push(permission.id)
          const foundPermissionAction = permissionsActionsFinal.find((_pa) => _pa.label === permission.action)
          const checkedPermission = foundModule?.permissions.find((_fm) => _fm.action === permission.action)
          if (checkedPermission) {
            selectedIds.push(checkedPermission.id)
          }

          return {
            ...permission,
            order: foundPermissionAction?.order,
          }
        }).sort((a, b) => a.order - b.order);
        return {
          module: module.module,
          permissions: permissionsFinal
        }
      })
      setModulePermissions(prev => [...prev, ...newModulePermissions])
      setSelectedIds(selectedIds)
      setModulePermissionIds(modulePermissionId)
      validation.setFieldValue('module_permissions', selectedIds)
    } catch (e) {
      console.log(e)
      toast.error('Error: Could not load role permissions');
    }
  }

  useEffect(() => {
    if (selectedMulti) {
      setSelectedIds([...modulePermissionIds])
      validation.setFieldValue('module_permissions', modulePermissionIds)
    } else if (selectedIds.length === modulePermissionIds?.length && !selectedMulti) {
      setSelectedIds([])
      validation.setFieldValue('module_permissions', [])
    }
  }, [selectedMulti])

  useEffect(() => {
    if (selectedIds?.length === modulePermissionIds?.length) {
      setselectedMulti(true)
    } else {
      setselectedMulti(false)
    }

  }, [selectedIds])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      module_permissions: ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      module_permissions: Yup.array().of(Yup.number()).required().min(1, 'Please select at least one module permission')
    }),
  });

  const handleUpdateRolePermission = async () => {
    try {
      const response = await roleUpdate(selectedRoleId, {
        module_permissions: selectedIds
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        toggleDrawer(false)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const chunkPermissions = (permissions) => {
    const chunkSize = 4;
    return permissions.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  };

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isOpen)}
      className="table_offcanvas_holder"
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        Edit Role
      </OffcanvasHeader>
      <OffcanvasBody>
        <div className="table-rep-plugin add_role_scroller">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table
              id="tech-companies-1"
              className="table"
            >
              <Thead>
                <Tr>
                  <Th>Administrator Access</Th>
                  <Th colSpan={4}>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="formrow-customCheck"
                        checked={selectedMulti}
                        onClick={() => setselectedMulti(!selectedMulti)}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="formrow-customCheck"
                      >
                        Select All
                      </Label>
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {modulePermissions.map((eachData, id) => (
                  chunkPermissions(eachData.permissions).map((chunk, index) => (
                    <Tr key={id}>
                      {index === 0 && (
                        <Td rowSpan={chunkPermissions(eachData.permissions).length}>
                          {eachData.module}
                        </Td>
                      )}
                      {chunk.map((item) => (
                        <Td key={item.id}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id={`checkbox-${item.id}`}
                              key={update}
                              onChange={() => {
                                if (selectedIds.includes(item.id)) {
                                  const index = selectedIds.indexOf(item.id);
                                  if (index > -1) {
                                    if (item.action === OPERATION_TYPE.READ) {
                                      selectedIds.splice(index, 1);
                                      if (selectedIds.includes(item.id + 1)) {
                                        const createIndex = selectedIds.indexOf(item.id + 1)
                                        selectedIds.splice(createIndex, 1);
                                      }
                                      if (selectedIds.includes(item.id + 2)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 2)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (selectedIds.includes(item.id + 3)) {
                                        const deleteIndex = selectedIds.indexOf(item.id + 3)
                                        selectedIds.splice(deleteIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.PROPERTIES && selectedIds.includes(item.id + 4)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 4)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.PROPERTIES && selectedIds.includes(item.id + 5)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 5)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.FINANCE && selectedIds.includes(item.id + 4)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 4)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.FINANCE && selectedIds.includes(item.id + 5)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 5)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                    } else {
                                      selectedIds.splice(index, 1);
                                    }
                                  }
                                  if (selectedIds?.length === 0 && !validation.touched?.module_permissions) {
                                    validation.setFieldTouched('module_permissions', true, true)
                                  }
                                  validation.setFieldValue("module_permissions", selectedIds)
                                  setselectedMulti(false)
                                } else {
                                  let defaultActionId
                                  if (OPERATION_TYPE.CREATE === item.action) {
                                    defaultActionId = item.id - 1
                                  } else if (OPERATION_TYPE.UPDATE === item.action) {
                                    defaultActionId = item.id - 2
                                  } else if (OPERATION_TYPE.DELETE === item.action) {
                                    defaultActionId = item.id - 3
                                  } else if (OPERATION_TYPE.ADD_TENANT === item.action) {
                                    defaultActionId = item.id - 4
                                  } else if (OPERATION_TYPE.REMOVE_TENANT === item.action) {
                                    defaultActionId = item.id - 5
                                  } else if (OPERATION_TYPE.PAYOUT_PAYMENT === item.action) {
                                    defaultActionId = item.id - 4
                                  } else if (OPERATION_TYPE.DEPOSIT_PAYMENT === item.action) {
                                    defaultActionId = item.id - 5
                                  }

                                  if (defaultActionId && !selectedIds.includes(defaultActionId)) {
                                    setSelectedIds((prev) => {
                                      if (prev) {
                                        return [...prev, defaultActionId, item.id]
                                      } else {
                                        return [defaultActionId, item.id]
                                      }
                                    })
                                  } else {
                                    setSelectedIds((prev) => {
                                      if (prev) {
                                        return [...prev, item.id]
                                      } else {
                                        return [item.id]
                                      }
                                    })
                                  }
                                  validation.setFieldValue("module_permissions", selectedIds.length === 0 ? [item.id] : selectedIds)
                                }
                                setUpdate(update + 1)
                              }}
                              checked={selectedIds.includes(item.id)}
                              name="module_permissions"
                              onBlur={validation.handleBlur}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={`checkbox-${item.id}`}
                            >
                              {item.action}
                            </Label>
                          </div>
                        </Td>
                      ))}
                      {chunk.length < 5 && (
                        Array.from({ length: 5 - chunk.length }).map((_, idx) => (
                          <Td key={`empty-${idx}`}></Td>
                        ))
                      )}
                    </Tr>
                  ))
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
        <div className='mt-2'>
          {validation.touched?.module_permissions && validation.errors?.module_permissions && (
            <span className="text-danger">{validation.errors?.module_permissions}</span>
          )}
        </div>
        <div className="text-center py-4">
          <Button color="danger" outline className="me-2">Cancel</Button>
          <Button
            className="yellow_gradient_btn"
            onClick={handleUpdateRolePermission}
            type="submit"
            disabled={!validation.isValid}> Save </Button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default EditRoles;
