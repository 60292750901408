import React, { useState } from 'react';
import {
  Button, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import GlobalInnerHeader from '../globalInnerHeader';
import BasicDetails from './basicdetails';
import FAQs from './faqs';
import About from './about';
import Terms from './terms';
import Privacy from './privacy';

function CMS() {
  const [activeTab1, setactiveTab1] = useState('5');

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="CMS" />
      <div className="settingBody settings_holder cms_content_holder mt-4">
        <Nav pills>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '5',
              })}
              onClick={() => {
                toggle1('5');
              }}
            >
              <span>Basic Details</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '6',
              })}
              onClick={() => {
                toggle1('6');
              }}
            >
              <span>FAQs</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '7',
              })}
              onClick={() => {
                toggle1('7');
              }}
            >
              <span>About Us</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '8',
              })}
              onClick={() => {
                toggle1('8');
              }}
            >
              <span>Terms & Conditions</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={classnames({
                active: activeTab1 === '9',
              })}
              onClick={() => {
                toggle1('9');
              }}
            >
              <span>Privacy Policy</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab1} className="p-3 text-muted">
          <TabPane tabId="5">
            <BasicDetails />
          </TabPane>
          <TabPane tabId="6">
            <FAQs />
          </TabPane>
          <TabPane tabId="7">
            <About />
          </TabPane>
          <TabPane tabId="8">
            <Terms />
          </TabPane>
          <TabPane tabId="9">
            <Privacy />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

export default CMS;
