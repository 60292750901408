import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileList from "../../Common/FileList";

export default function AlertDialog({
  visible,
  visibleOff,
  selectedImage,
  setSelectedImage,
  setSelectedFile,
}) {
  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = selectedImage?.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)
    );

    if (newDocuments.length === 0) {
      setSelectedImage([]);
      setSelectedFile([]);
      visibleOff();
    }
    setSelectedImage((prev) =>
      prev.filter(
        (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)
      )
    );
    setSelectedFile((prev) =>
      prev.filter(
        (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)
      )
    );
    return true;
  }
  async function confirmFileRemoval(e, file, fileIndex) {
    handleFileRemove(e, file, fileIndex);
  }
  return (
    <React.Fragment>
      <Dialog
        open={visible}
        onClose={visibleOff}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper":
            {
              width: "50em",
            },
          maxHeight: "940px",
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Uploaded Files"}</DialogTitle>
        <DialogContent
          sx={{
            height: "400px",
          }}
        >
          {selectedImage.length > 0 && (
            <FileList
              files={selectedImage}
              confirmFileRemoval={confirmFileRemoval}
              shouldEyeVisible={true}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={visibleOff}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
