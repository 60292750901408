
import FormControl from "@mui/material/FormControl";
import { Button } from "reactstrap";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { ALL_MODULES, OPERATION_TYPE } from "../../common/constant";
import { usePermissionGiven } from "../Hooks/UserPermission";
function FilterKanBan({
  toggleTaskMenu,
  assignee,
  setFilterObject,
  handleFilter,
  fetchAssignees,
  setAssigneePage,
  assigneePage,
  assigneeMeta,
}) {
  const [objectName,setObjectName]=useState('');
  const [isFilterOpen, setFilterOpen]=useState(false);

  const changeFilter = (e,v) => {
    setObjectName(v?.name);
    if (!v?.id) {
      handleFilter({});
      setObjectName('');
      return;
    }
    
    setFilterObject((old) => {
      return {
        "assignee_id": e.target.value,
      };
    });
    const searchFile = {
      "assignee_id": v?.id,
    };
    handleFilter(searchFile);
  };
  const scrollHandle = (e) => {
    const listboxNode = e.currentTarget;
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - x <= 1 &&
      assigneeMeta?.current_page < assigneeMeta?.last_page
    ) {
      fetchAssignees({
        page: assigneePage+1,
        limit: 10,
      },assignee)
      setAssigneePage(old=>old+1)
    }
  };
  useLayoutEffect(()=>{
    isFilterOpen && fetchAssignees({
      page: assigneePage,
      limit: 10,
    },[]);
  },[isFilterOpen])
  return (
    <div className="mainPageHeader d-flex justify-content-end">
      <div className="kanbanFilters d-flex">
        <div className="filterTitle d-flex align-items-center">
          <i className="ri-filter-2-fill" />
          <p className="mb-0 mx-1"> Filter by </p>
        </div>
        <FormControl
          sx={{ m: 1, minWidth: 250, backgroundColor: "#fff" }}
          size="small"
        >
          <Autocomplete
            value={objectName}
            onChange={(event,newValue)=> changeFilter(event,newValue)}
            renderOption={(props, option) => (
              <Box component="li" key={option.id} {...props}>
                {option.name}
              </Box>
            )}
            onFocus={()=>{
              setFilterOpen(true)
            }}
            options={assignee}
            renderInput={(params) => (
              <TextField {...params} label="Select Task Assignee*" size="small" />
            )}
            ListboxProps={{
              onScroll: scrollHandle
            }}
          />
        </FormControl>
        { usePermissionGiven(ALL_MODULES.TASKS,OPERATION_TYPE.CREATE) ? <Button
          color="primary"
          size="sm"
          style={{
            height: "39px",
            alignSelf: "center",
          }}
          onClick={toggleTaskMenu}
        >
          <i className="mdi mdi-plus" /> Add Task
        </Button>:''}

        {/* All Proects dropdown ends */}
      </div>
    </div>
  );
}

export default FilterKanBan;
