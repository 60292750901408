import React from 'react';
import {
  Row, Form, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
// import { PropertyRequestSlider } from './propertyRequestSlider';
import FileList from '../Common/FileList';
import uuid from 'react-native-uuid';
import { formatBytes, getS3BaseUrl } from '../../helpers/string_helper';
import { selectAmenitiesIcon } from '../../common/amenities_icon';


export function ViewPropertyRequestDrawer({ isOpen, toggleDrawer, selectedRow }) {
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isOpen)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        Property Request Details
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row className="mb-3">
              <div className="col-sm-6">
                <div className="custom-image-upload">
                  {selectedRow.owner_profile_photo && (
                    <div className="image-placeholder pro_pic">
                      <img src={selectedRow.owner_profile_photo?.includes('http') ? selectedRow.owner_profile_photo : `${getS3BaseUrl()}${selectedRow?.owner_profile_photo}`} alt={'Image not found'} />
                    </div>
                  )}
                  <div className="upload_picture mx-3">
                    <div className="pro_person_name">{selectedRow.owner_name ?? 'N/A'}</div>
                    <div className="pro_person_mob">
                      <i className="fas fa-phone-alt" />
                      <span className="country_code"> +91</span>
                      <span className="pr_person_contact"> {selectedRow.owner_contact_number ?? 'N/A'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Property Name:</div>
                <div className="tenantDetailDesc">{selectedRow.name ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Property Type:</div>
                <div className="tenantDetailDesc">{selectedRow.type ?? 'N/A'}</div>
              </div>
            </Row>

            <Row className="mb-3">
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Property Status:</div>
                <div className="tenantDetailDesc">{selectedRow.status ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Room Count:</div>
                <div className="tenantDetailDesc">{selectedRow.room_count ?? 'N/A'}</div>
              </div>
            </Row>

            {selectedRow?.amenities?.length > 0 &&
              <Row className="mb-3">
              <div className="col-sm-12 d-flex align-items-center custom_border_bottom pb-3">
                <div style={{
                  color: '#636771',
                  font: '14px',
                  width: '15%',
                }}>Amenities:</div>
                <ul
                  className="custom_amenties_options d-flex flex-wrap mt-3"
                >
                  {selectedRow?.amenities?.length > 0 ? selectedRow?.amenities?.map((item, index) => (
                    <li className="position-relative d-flex mx-1" key={index + 1}>
                      {selectAmenitiesIcon(item?.toLowerCase())}  <div className="amenity_item">{item}</div>
                    </li>
                  )) : 'N/A'}
                </ul>
              </div>
            </Row>}

            <Row>
              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Address Line 1:</div>
                <div className="tenantDetailDesc">{selectedRow.address_line_1 ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Address Line 2:</div>
                <div className="tenantDetailDesc">{selectedRow.address_line_2 ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">City:</div>
                <div className="tenantDetailDesc">{selectedRow.city ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Country:</div>
                <div className="tenantDetailDesc">{selectedRow.country ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">State:</div>
                <div className="tenantDetailDesc">{selectedRow.state ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle"> District:</div>
                <div className="tenantDetailDesc">{selectedRow.district ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Zip Code:</div>
                <div className="tenantDetailDesc">{selectedRow.zip_code ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Near To:</div>
                <div className="tenantDetailDesc">{selectedRow.near_to ?? 'N/A'}</div>
              </div>

              <div className="col-sm-6 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle">Landmark:</div>
                <div className="tenantDetailDesc">{selectedRow?.landmark ?? 'N/A'}</div>
              </div>

            </Row>

            {selectedRow?.note?.note &&
              <Row className="mb-3">
              <div className="col-sm-12 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailTitle tenantDetFullTitle">Note:</div>
                <div className="tenantDetailDesc">{selectedRow?.note?.note}</div>
              </div>
            </Row>}

            {/* Documents and pictures */}
            {selectedRow?.documents?.length > 0 ?
              (<Row className='mb-3'>
                <div className='col-sm-12 custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle tenantDetFullTitle'>
                    Attachments:
                  </div>
                  {
                    selectedRow?.documents?.length > 0 && (
                      <FileList files={selectedRow?.documents.map((doc) => ({ ...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size) }))} />
                    )
                  }
                </div>
              </Row>) : ''
            }
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}
