import React from "react";
import { Card } from "reactstrap";
export const DetailsHeaderBlockForPayout = ({ fields = [] }) => {
  const fieldsValue = fields?.filter(e => e)
  return (
    <div className="payment_detail_block">
      <div className="all_payment_blocks">
        <Card>
          <div className="d-grid five_grid_cols p-2">
            {fieldsValue?.map(({ filedName, value }, index) => (
              <div className="single_payment_item" key={index}>
                <span>{filedName}</span>
                {filedName === 'Status' ? (
                  <h6 className="truncate_month" style={{
                    fontWeight: 'bolder',
                  }}>{value}</h6>
                ) : (

                  ['string', 'number'].includes(typeof (value)) ? <h5 className="truncate_month" style={{
                    fontWeight: 'bolder'
                  }}>{value}</h5> : <span>{value}</span>
                )}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};
