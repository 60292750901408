import qs from "qs";

export const getParam = (params) => {
  return {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false, indexes: null });
    },
  };
};
