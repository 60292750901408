import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col sm={6}>
            {new Date().getFullYear()}
            {' '}
            © Minible.
          </Col>
          <Col sm={6}>
            <div className="text-sm-end d-none d-sm-block">
              Design & Develop
              {' '}
              <i className="mdi mdi-heart text-danger" />
              {' '}
              by Themesbrand
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
