import React from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const CKEditorComp = ({onChange,data,onBlur,isDisabled,onReady,maxLength=3}) => {
  
  return (
    <>
        <CKEditor
            disabled={isDisabled}
            editor={ClassicEditor}
            config={
              {
                removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Link']
              }
            }
            data={data}
            onReady={(editor) => {
              editor?.focus();
              onReady(editor);
            }}
            onChange={(event,editor) => {
              const data=editor.getData();
              onChange(data);
            }}
            // onBlur={(event, editor) => {
            //   onBlur(event,editor)
            // }}
            // onFocus={(event, editor) => {
            // }}
          />
    </>
  )
}
