/* eslint-disable react/react-in-jsx-scope */
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import NestCamWiredStandOutlinedIcon from '@mui/icons-material/NestCamWiredStandOutlined';
import CellWifiOutlinedIcon from '@mui/icons-material/CellWifiOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import { TENANT_TYPES } from './constant';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';

export const selectAmenitiesIcon = (item) => {
    switch (item) {
        case "cctv":
            return <NestCamWiredStandOutlinedIcon />
        case "wifi":
            return <CellWifiOutlinedIcon />;
        case "daily room cleaning ":
            return <CleaningServicesOutlinedIcon />
        case "daily dustbin cleaning ":
            return <SanitizerOutlinedIcon />;
        case "weekly once washroom cleaning":
            return <ChairAltOutlinedIcon />
        case "bed":
            return <BedOutlinedIcon />;
        case "almirah":
            return <DoorFrontOutlinedIcon />;
        case "fridge":
            return <KitchenOutlinedIcon />;
        case "washing machine":
            return <LocalLaundryServiceOutlinedIcon />;
        case 'water purifier':
            return <CoffeeMakerOutlinedIcon />;
        case 'lift':
            return <ElevatorOutlinedIcon />;
        case "center table":
            return <TableBarOutlinedIcon />;
        default:
            return <CommitOutlinedIcon />;
    }
};

export const selectProfessionIcon = (item) => {
    switch (item) {
        case TENANT_TYPES.STUDENT:
            return <SchoolOutlinedIcon className="me-2" />
        case TENANT_TYPES.WORKING_PROFESSIONAL:
            return <BusinessCenterOutlinedIcon className="me-2" />;
        default:
            return <PersonOutlineOutlinedIcon className="me-2" />;
    }
};