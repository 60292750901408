import { getParam } from '../helpers/getParam';
import axiosInstance from '../utils/axios/auth';
import { toast } from "react-toastify";
import dayjs from "dayjs";

const createTask = async (payload) => await axiosInstance.post('/task/create', payload);
const getAllTask = async (params) => await axiosInstance.get('/task/fetch-all',getParam(params));
const getTaskById = async (id) => await axiosInstance.get(`/task/fetch/${id}`);
const removeTaskById = async (id) => await axiosInstance.delete(`/task/delete/${id}`);
const updateTask = async (id,payload) =>await axiosInstance.put(`/task/update/${id}`,payload);
const fetchAssigneeData = async(params)=> await axiosInstance.get('task/fetch/assignee_id',getParam(params));
const statusWiseTask =async(params)=>await axiosInstance.get(`task/fetch/task-status`,getParam(params))
const fetchCommentData = async(params,task_id)=>await axiosInstance.get(`task/fetch/comments/${task_id}`,getParam(params));
const addTaskComment = async(payload)=> await axiosInstance.post(`/task/create/comment`,payload);
const updateTaskComment=async(payload,id)=> await axiosInstance.post(`/task/edit/comment/${id}`,payload);
const deleteTaskComment=async(id)=> await axiosInstance.put(`/task/delete/comment/${id}`);

const createTaskData = async (
  payload,
  callBack = "",
  toggleTaskMenu,
  setIsLoading
) => {
  try {
    const response = await createTask(payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      callBack && callBack();
      toggleTaskMenu();
      setIsLoading(false);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    setIsLoading(false);
  }
};

const getParticularTask = async (id, setFunction = "", setDate = "") => {
  if (!id) {
    toast.error("No Data Selected");
    return;
  }
  try {
    const response = await getTaskById(id);
    if (response.status === 200) {
      if(setDate){
        const date = dayjs(response?.data?.data?.due_date);
        if (date["$D"]) {
          setDate(date);
        } else setDate(null);
      }
      setFunction && setFunction(response.data.data);
      return response.data.data
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

const editParticularTask = async (
  payload,
  id,
  cb,
  onToggleCloseDelete,
  setIsLoading
) => {
  setIsLoading(true);
  if (!id) {
    toast.error("No Data Selected");
    return;
  }
  if (!Object.keys(payload).length) {
    toast.warn("No changed data is being sent");
    return;
  }
  try {
    if (payload?.flag) {
      delete payload.flag;
    }
    const response = await updateTask(id, payload);
    if (response.status === 200) {
      toast.success("Successfully Updated");
      cb && cb();
      onToggleCloseDelete && onToggleCloseDelete();
      setIsLoading(false);
    } else {
      toast.error(response.data.message);
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    setIsLoading(false);
  }
};
const deleteFunctionTask = async (
  id,
  cb,
  onToggleCloseDelete,
  setIsLoading
) => {
  setIsLoading(true);
  if (!id) {
    toast.error("No Item Selected");
    return;
  }
  try {
    const response = await removeTaskById(id);
    cb && cb();
    if (response.status === 200) {
      toast.success(response.data.message);
      onToggleCloseDelete();
      setIsLoading(false);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  } finally {
    setIsLoading(false);
  }
};

export {
  createTask,
  getAllTask,
  getTaskById,
  removeTaskById,
  updateTask,
  fetchAssigneeData,
  statusWiseTask,
  fetchCommentData,
  addTaskComment,
  updateTaskComment,
  deleteTaskComment,
  createTaskData,
  getParticularTask,
  deleteFunctionTask,
  editParticularTask,
};
