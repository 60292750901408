import React, { useState } from 'react';
import Select from 'react-select';
import { updatPriorityById } from '../../api/ComplainTicket/api'; // Import your API function here
import { COMPLAIN_TICKET_PRIORITY } from '../../common/constant';
import { createTheme } from '@material-ui/core';

function PrioritySelectBox({
  id,
  currentPriority,
  refresh,
  disableUpdate = false,
}) {
  const colors = createTheme()?.palette;
  const options = [
    { value: COMPLAIN_TICKET_PRIORITY.HIGH, label: COMPLAIN_TICKET_PRIORITY.HIGH },
    { value: COMPLAIN_TICKET_PRIORITY.MEDIUM, label: COMPLAIN_TICKET_PRIORITY.MEDIUM },
    { value: COMPLAIN_TICKET_PRIORITY.LOW, label: COMPLAIN_TICKET_PRIORITY.LOW },
  ];

  const customStyles = {
    control: (provided, state) => {
      let backgroundColor = 'transparent';

      if (state.selectProps.value) {
        const selectedOption = state.selectProps.options.find(
          (option) => option.value === state.selectProps.value.value,
        );

        backgroundColor =
          selectedOption.value === COMPLAIN_TICKET_PRIORITY.HIGH
            ? colors.error.main
            : selectedOption.value === COMPLAIN_TICKET_PRIORITY.MEDIUM
              ? colors.warning.main
              : selectedOption.value === COMPLAIN_TICKET_PRIORITY.LOW
                ? colors.primary.main
                : 'transparent';
      }

      return {
        ...provided,
        backgroundColor,
      };
    },
    singleValue: (provided, state) => ({
      ...provided,
      color:
        state.data.value === COMPLAIN_TICKET_PRIORITY.HIGH
          ? colors.error.contrastText
          : state.data.value === COMPLAIN_TICKET_PRIORITY.MEDIUM
            ? colors.warning.contrastText
            : state.data.value === COMPLAIN_TICKET_PRIORITY.LOW
              ? colors.primary.contrastText
              : '#000000',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '20px',
      overflow: 'hidden',
      backgroundColor: '#fff',
    }),
  };

  const [priority, setPriority] = useState(currentPriority);

  const handleChange = async (val) => {
    setPriority(val.value);
    await updatPriorityById(id, val.value); // Pass the selected value to your API function
    refresh();
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      defaultValue={options.find((option) => option.value === currentPriority)}
      value={options.find((option) => option.value === priority)}
      onChange={(val) => handleChange(val)}
      className='customSelectfilter'
      menuPortalTarget={document.body}
      isDisabled={disableUpdate}
    />
  );
}

export default PrioritySelectBox;
