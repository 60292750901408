import { useSelector } from "react-redux";

const getPropertyDetails = () => {
  let { roomData, flatData, bedData, floorData } = useSelector((state) => {
    return {
      roomData: state?.PropertyData?.property?.roomData,
      flatData: state?.PropertyData?.property?.flatData,
      bedData: state?.PropertyData?.property?.bedData,
      floorData: state?.PropertyData?.property?.floorData
    };
  });

  return { roomData, flatData, bedData, floorData };
};

export { getPropertyDetails };
