import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import CompanyAssociatesDetailDrawer from '../../components/CompanyAssociates/CompanyAssociatesDetailsDrawer';
import AddCompanyAssociatesDrawer from '../../components/CompanyAssociates/AddCompanyAssociatesDrawer';
import UploadCompanyAssociatesModal from '../../components/CompanyAssociates/UploadCompanyAssociatesModal';
import {
  ACTIONS,
  ALL_MODULES,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
} from '../../common/constant';
import { getAllCompanyAssociates } from '../../api/company-associates';
import PaginationComponent from '../../components/Common/PaginationComponent';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import {
  deleteCompanyAssociatesHandler,
  updateCompanyAssociatesHandler,
  updateStats,
} from '../../api/CompanyAssociates/api';
import CircularProgress from '@mui/material/CircularProgress';
import CompanyAssociatesFilter from '../../components/CompanyAssociates/CompanyAssociatesFilterDrawer';
import NodataFound from '../../components/Common/NoDataFound';
import Search from '../../components/Common/GlobalSearch';
import { uniqueId } from 'lodash';
import TableHeaders from '../../components/Common/TableHeaders';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { grey } from '@mui/material/colors';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import NoDataIcon from "../../assets/images/no-data.png";
import { usePermissionGiven } from '../../components/Hooks/UserPermission';

function CompanyAssociates() {
  document.title = 'Company Associates | Dstayz';

  const [paginationMeta, setPaginationMeta] = useState({});
  const [searchItem, setSearchItem] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [filterFields, setFilterFields] = useState([]);

  const [isAddCompanyAssociates, setAddCompanyAssociates] = useState(false);
  const [isCompanyAssociatesFilter, setCompanyAssociatesFilter] =
    useState(false);
  useState(false);
  const [isUploadCompanyAssociatesModal, setUploadCompanyAssociatesModal] =
    useState(false);
  const [companyAssociatesList, setCompanyAssociatesList] = useState([]);
  const [stats, setStats] = useState({
    totalAssociates: 0,
    totalVendorAssociates: 0,
    totalPartnerAssociates: 0,
  });
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: '',
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: '',
  });
  const [onMounted, setOnMounted] = useState(false);
  const [sort, setSort] = useState({});
  const [detailShowId, setDetailShowId] = useState(false);
  const [associateId, setAssociateId] = useState(null);
  const hasCreatePermission = usePermissionGiven(ALL_MODULES.COMPANY_ASSOCIATES, OPERATION_TYPE.CREATE);
  const hasEditPermission = usePermissionGiven(ALL_MODULES.COMPANY_ASSOCIATES, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.COMPANY_ASSOCIATES, OPERATION_TYPE.DELETE);


  // upload modal
  const toggleUploadCompanyAssociatesModal = () => {
    setUploadCompanyAssociatesModal(!isUploadCompanyAssociatesModal);
  };

  const companyAssociatesListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllCompanyAssociates(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if(error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllCompanyAssociatesList = async (
    searchItem = '',
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
          filter_fields: filterFields,
          filter_inputs: filterInputs,
        }),
      ...(sort?.order &&
        sort?.direction && {
          ...sort,
        }),
    };
    const resultData = await companyAssociatesListApi(params);
    setCompanyAssociatesList(resultData);
    if (!onMounted) {
      updateStats(setStats);
      setOnMounted(true);
    }
  };

  const globalSearch = async (params) => {
    setCompanyAssociatesList([]);
    setSearchItem(params);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    const resultData = await companyAssociatesListApi({
      ...params,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order &&
        sort?.direction && {
          ...sort,
        }),
    });
    setCompanyAssociatesList(resultData);
  };

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem,
      }),
    };
    if (data?.page) {
      setPage(data.page);
      params.page = data.page;
      fetchAllCompanyAssociatesList(params, {
        page: data.page,
        limit: rowsPerPage,
      });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      params.limit = data.limit;
      fetchAllCompanyAssociatesList(params, {
        page: data.page,
        limit: data?.limit,
      });
    }
  };

  const filter = async (filterFields, filterInputs) => {
    setCompanyAssociatesList([]);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    const resultData = await companyAssociatesListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
          filter_fields: filterFields,
          filter_inputs: filterInputs,
        }),
      ...(sort?.order &&
        sort?.direction && {
          ...sort,
        }),
    });
    setCompanyAssociatesList(resultData);
  };

  const refresh = async () => {
    setCompanyAssociatesList([]);
    setAssociateId(null);
    setPage(1);
    const resultData = await companyAssociatesListApi({
      limit: rowsPerPage,
      page: 1,
      ...(sort?.order &&
        sort?.direction && {
          ...sort,
        }),
    });
    setCompanyAssociatesList(resultData);
    updateStats(setStats);
  };

  const handleSort = async (sort) => {
    setSort(sort);
    const resultData = await companyAssociatesListApi({
      // Remaining sustainable params
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
          filter_fields: filterFields,
          filter_inputs: filterInputs,
        }),
      // Sort params
      ...(sort?.order &&
        sort?.direction && {
          ...sort,
        }),
    });
    setCompanyAssociatesList(resultData);
  };

  // const toggleMenu = (index) => {
  //   const newMenuStates = [...menu];
  //   newMenuStates[index] = !newMenuStates[index];
  //   setMenu(newMenuStates);
  // };

  // Add new associates drawer
  const toggleAddCompanyAssociates = () => {
    if (AddCompanyAssociatesDrawer) {
      setAssociateId(null);
    }
    setAddCompanyAssociates(!isAddCompanyAssociates);
  };

  // Update existing associate drawer handler
  const editCompanyAssociatesHandler = async (id) => {
    setAssociateId(id);
    setAddCompanyAssociates(true);
  };

  // Delete confirmation modal
  const deleteHandler = async (id) => {
    setDeleteConfirm({ status: true, id });
  };

  // associates filter drawer
  const toggleCompanyAssociatesFilter = () => {
    setCompanyAssociatesFilter(!isCompanyAssociatesFilter);
  };

  const columns = [
    // Name
    {
      column_name: 'Name',
      key_name: 'name',
      sort_enabled: true,
      style: { minWidth: '180px' },
    },
    // Associate Type
    {
      column_name: 'Associates Type',
      key_name: 'type',
      sort_enabled: true,
      style: { minWidth: '180px' },
    },
    // Associate Type
    {
      column_name: 'Status',
      key_name: 'status',
      sort_enabled: true,
      style: { minWidth: '180px' },
    },
    // Phone No.
    {
      column_name: 'Phone No',
      key_name: 'contact_number',
      sort_enabled: true,
      style: { minWidth: '150px' },
    },
    // Email ID
    {
      column_name: 'Email ID',
      key_name: 'email',
      sort_enabled: true,
      style: { minWidth: '200px' },
    },
    // Address
    {
      column_name: 'Address',
      key_name: 'address',
      sort_enabled: false,
      style: { minWidth: '200px' },
    },
    // Contact Person
    {
      column_name: 'Contact Person',
      key_name: 'contact_person_name',
      sort_enabled: true,
      style: { minWidth: '200px' },
    },
    // Contact Phone No.
    {
      column_name: 'Contact Phone No.',
      key_name: 'contact_person_contact_number',
      sort_enabled: true,
      style: { minWidth: '200px' },
    },
    // Purpose of Association
    {
      column_name: 'Purpose of Association',
      key_name: 'purpose',
      sort_enabled: false,
      style: { minWidth: '200px' },
    },
    // Notes
    {
      column_name: 'Notes',
      key_name: 'notes',
      sort_enabled: false,
      style: { minWidth: '200px' },
    },
  ];
  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${
        theme.palette.mode === 'dark' ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark'
          ? 'rgba(0,0,0, 0.50)'
          : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(`padding: '10px'`);

  const StyledMenuItem = styled(MenuItem)`
    && {
      padding: 10px;
      /* Add more styles as needed */
    }
  `;
  useEffect(() => {
    fetchAllCompanyAssociatesList();
  }, []);
  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          {/* <Breadcrumbs title="CompanyAssociates Details" breadcrumbItem="Existing CompanyAssociatess" /> */}
          <Row className='d-grid' style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Total Associates</h4>
                </div>
                <h2 className='num_heading'>{stats.totalAssociates}</h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Total Vendors</h4>
                </div>
                <h2 className='num_heading'>{stats.totalVendorAssociates}</h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Total Partners</h4>
                </div>
                <h2 className='num_heading'>{stats.totalPartnerAssociates}</h2>
              </div>
            </div>
          </Row>
          <Row>
            <Col className='col-12'>
              <div className='flexbox_holder'>
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      ...(sort?.order &&
                        sort?.direction && 
                        {
                          order: sort?.order,
                          direction: sort?.direction,
                        }),
                      ...(filterFields &&
                          filterInputs &&
                          {
                            filter_fields: filterFields,
                            filter_inputs: filterInputs,
                          })
                    }}
                  />
                </div>

                <div>
                  <Button
                    className='yellow_gradient_btn me-2'
                    onClick={toggleCompanyAssociatesFilter}>
                    <i className='mdi mdi-filter-variant' />
                    Filter
                  </Button>
                  {hasCreatePermission ? (
                    <Button
                      color='primary'
                      onClick={toggleAddCompanyAssociates}>
                      <i className='mdi mdi-plus' />
                      Associates
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <Card className='table_card_holder'>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div
                      className='table-responsive mb-0'
                      data-pattern='priority-columns'>
                      {companyAssociatesList?.length === 0 && isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}

                      {companyAssociatesList?.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center' }}>
                          <NodataFound text='No company associates to show' icon={NoDataIcon} />
                        </div>
                      )}

                      {companyAssociatesList?.length > 0 && (
                        <Table
                          id='tech-companies-1'
                          className='table table-bordered'>
                          <TableHeaders
                            headers={columns}
                            sortBy={sort}
                            emitSortedColumn={handleSort}
                            module={ALL_MODULES.COMPANY_ASSOCIATES}
                          />
                          <Tbody>
                            {companyAssociatesList?.length > 0  &&
                              companyAssociatesList?.map((associates) => (
                                <Tr key={uniqueId()}>
                                  <Td>{associates?.indexNo}</Td>
                                  <Td
                                    className='cursor_pointer'
                                    onClick={() =>
                                      setDetailShowId(associates?.id)
                                    }>
                                    {associates?.name}
                                  </Td>
                                  <Td>{associates?.type}</Td>
                                  <Td>{associates?.status}</Td>
                                  <Td>{associates?.contact_number}</Td>
                                  <Td>{associates?.email}</Td>
                                  <Td>{associates?.address}</Td>
                                  <Td>{associates?.contact_person_name}</Td>
                                  <Td>
                                    {associates?.contact_person_contact_number}
                                  </Td>
                                  <Td>{associates?.purpose}</Td>
                                  <Td>{associates?.notes}</Td>
                                  {(hasEditPermission ||
                                  hasDeletePermission) ? (
                                    <Td className='threeDotsSpace'>
                                      <Dropdown>
                                        <MenuButton>
                                          <i className='mdi mdi-dots-vertical font-size-15'></i>
                                        </MenuButton>
                                        <Menu slots={{ listbox: Listbox }}>
                                          {hasEditPermission ? (
                                            <StyledMenuItem
                                              className='cursor-pointer'
                                              slots={{
                                                ListboxItem: ListboxItem,
                                              }}
                                              onClick={() =>
                                                editCompanyAssociatesHandler(
                                                  associates?.id,
                                                )
                                              }>
                                              <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                                              Edit
                                            </StyledMenuItem>
                                          ) : (
                                            ''
                                          )}
                                          {hasDeletePermission ? (
                                            <StyledMenuItem
                                              className='cursor-pointer'
                                              slots={{
                                                ListboxItem: ListboxItem,
                                              }}
                                              onClick={() =>
                                                deleteHandler(associates?.id)
                                              }>
                                              <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                              Delete
                                            </StyledMenuItem>
                                          ) : (
                                            ''
                                          )}
                                        </Menu>
                                      </Dropdown>
                                    </Td>
                                  ) : null}
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {companyAssociatesList?.length > 0 && (
                    <div className='numbers_pagination_holder'>
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {detailShowId && (
        <CompanyAssociatesDetailDrawer
          associateId={detailShowId}
          refresh={refresh}
          isOpen={!!detailShowId}
          setIsOpen={setDetailShowId}></CompanyAssociatesDetailDrawer>
      )}

      {isAddCompanyAssociates && (
        <AddCompanyAssociatesDrawer
          isOpen={isAddCompanyAssociates}
          toggleDrawer={toggleAddCompanyAssociates}
          refresh={refresh}
          associateId={associateId}
          action={associateId ? ACTIONS.EDIT : ACTIONS.CREATE}
        />
      )}

      {/* Delete confirmation modal */}
      { isDeleteConfirm.status &&
      <ConfirmationModal
        action={ACTIONS.DELETE}
        show={isDeleteConfirm.status}
        onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isDeleted = await deleteCompanyAssociatesHandler(
            isDeleteConfirm.id,
          );
          if (isDeleted) {
            await refresh();
          }
          setDeleteConfirm({ status: false, id: null });
        }}
        isDisabled={isLoading}
      />}

      {/* Edit confirmation modal */}
      {isEditConfirm.status &&
        <ConfirmationModal
        action={ACTIONS.EDIT}
        show={isEditConfirm.status}
        onCloseClick={() => setEditConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isUpdated = await updateCompanyAssociatesHandler(
            isEditConfirm.data,
            isEditConfirm.id,
          );
          if (isUpdated) {
            await refresh();
          }
          setDeleteConfirm({ status: false, id: null, data: null });
        }}
        isDisabled={isLoading}
      />}

      {isCompanyAssociatesFilter &&
        <CompanyAssociatesFilter
        isOpen={isCompanyAssociatesFilter}
        toggleDrawer={toggleCompanyAssociatesFilter}
        emitFilterItem={filter}
        selectedFields={filterFields}
        selectedInputs={filterInputs}
      />}

      {isUploadCompanyAssociatesModal &&
        <UploadCompanyAssociatesModal
        isOpen={isUploadCompanyAssociatesModal}
        toggleDrawer={toggleUploadCompanyAssociatesModal}
      />}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.AuthLogin.auth.user,
});

export default connect(mapStateToProps, null)(CompanyAssociates);
