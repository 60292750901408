import {
  getAllCompanyAssociates,
  createCompanyAssociates,
  updateCompanyAssociates,
  deleteCompanyAssociates,
  getDashboardData,
} from '../company-associates';
import { toast } from 'react-toastify';
import { documentUpload, documentUploadModuleWise } from '../documents';

export const getCompanyAssociatesHandler = async (setData) => {
  try {
    const response = await getAllCompanyAssociates();
    setData(response?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createCompanyAssociatesHandler = async (companyAssociatesData) => {
  try {
    const response = await createCompanyAssociates(companyAssociatesData);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
  }
  return false;
};

export const uploadDocumentsForModuleWise = async (formData) => {
  try {
    const response = await documentUploadModuleWise(formData);
    if (response?.status === 200) {
      return { response, success: true };
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
    return { error, success: false };
  }
};

export const uploadDocuments = async (formData) => {
  try {
    const response = await documentUpload(formData);
    if (response.status === 200) {
      return { response, success: true };
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
    return { error, success: false };
  }
};

export const updateCompanyAssociatesHandler = async (
  id,
  companyAssociatesData,
) => {
  try {
    const response = await updateCompanyAssociates(id, companyAssociatesData);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
  }
  return false;
};

export const deleteCompanyAssociatesHandler = async (id) => {
  try {
    const response = await deleteCompanyAssociates(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
  }
  return false;
};

export const updateStats = async (setStats) => {
  try {
    const res = await getDashboardData();
    if (res.status === 200) {
      const { total_associates, total_vendors, total_partners } = res.data.data;
      setStats({
        totalAssociates: total_associates,
        totalVendorAssociates: total_vendors,
        totalPartnerAssociates: total_partners,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error?.message);
    console.log(error);
  }
};
