import React, { useEffect, useMemo, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Row,
} from "reactstrap";
import uuid from 'react-native-uuid';
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BasicTabs from "../SimpleTab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { CKEditorComp } from "../CKEditor";
import {
  addTaskComment,
  deleteTaskComment,
  fetchCommentData,
  getParticularTask,
  updateTaskComment,
} from "../../api/kanban";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import { useProfile } from "../Hooks/UserHooks";
import { CircularProgress, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import DeleteModal from "../Common/DeleteModal";
import NodataFound from "../Common/NoDataFound";
import FileList from "../Common/FileList";
import { formatBytes } from "../../helpers/string_helper";
import { defaultStatus, getInitials } from "../../constants/kanban_helper";
const LIMIT = 10;

const useStyles = makeStyles((theme) => ({
  scrollableList: {
    maxHeight: 500,
    overflowY: "auto",
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    position: "relative",
    overflow: "auto",
    marginTop: "10px",
    "& ul": { padding: 0 },
  },
}));
function KanbanDetailDrawer({
  isOpen,
  toggleDrawer,
  selectedId,
  setDedicatedTabValue,
  dedicatedTabValue,
  actionAfterEdit,
}) {
  const classes = useStyles();
  const [isAddExistingTenant] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const myId = useProfile()?.user?.id;
  const [status, setStatus] = useState(defaultStatus);

  function handleStatusChange(e) {
    setStatus(e.target.value);
  }
  useEffect(() => {
    getParticularTask(selectedId, setTaskDetails);
  }, []);
  
  const DetailsTaskDetails = () => {
    return (
      <div className="view-details">
        <div className="task-header d-flex justify-content-between align-items-center">
          <div className="ticketNo cursor-pointer">{taskDetails?.name}</div>
          <div className="assignPro d-flex align-items-center">
            <div className="assignTitle m-0">Reported By:</div>
            <div className="assigneeName mx-2">
              <Tooltip title={taskDetails?.reporter?.name || "N/A"} arrow>
                {getInitials(
                  taskDetails?.reporter?.name
                    ? taskDetails?.reporter?.name
                    : "N/A"
                )}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="task-desc">{taskDetails.description}</div>

        <div className="task-info d-flex justify-content-between">
          <div className="d-flex">
            <div className="assignPro d-flex align-items-center">
              <div className="assignTitle m-0">Assigned To:</div>
              {!taskDetails.assignees?.length
                ? " N/A"
                : taskDetails?.assignees?.length > 1
                ? taskDetails?.assignees?.slice(0, 1)?.map((ele) => (
                    <>
                      <div
                        className="assigneeName mx-2"
                        style={{
                          padding: "-5px",
                        }}
                      >
                        {ele?.assignee?.profile_photo ? (
                          <img
                            src={ele?.assignee?.profile_photo}
                            alt="img"
                            className="smallProfile"
                          />
                        ) : (
                          <Tooltip title={ele?.assignee?.name} arrow>
                            {getInitials(ele?.assignee?.name)}
                          </Tooltip>
                        )}
                      </div>

                      <Tooltip
                        title={taskDetails?.assignees?.map(
                          (e, indx) =>
                            indx > 0 && <li key={indx}>{e?.assignee?.name}</li>
                        )}
                        arrow
                      >
                        <span className="d-flex">
                          <small
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            &nbsp;
                            <i>+{taskDetails?.assignees?.length - 1} more</i>
                          </small>
                        </span>
                      </Tooltip>

                      {/* <span>{taskDetails?.assignee_id?.length-1} more</span> */}
                    </>
                  ))
                : taskDetails?.assignees?.map((assign, idx) => (
                    <div key={idx} className="assigneeName mx-2">
                      {assign?.assignee?.profile_photo ? (
                        <img
                          src={assign?.assignee?.profile_photo}
                          alt="img"
                          className="smallProfile"
                        />
                      ) : (
                        <Tooltip title={assign?.assignee?.name} arrow>
                          {getInitials(assign?.assignee?.name)}
                        </Tooltip>
                      )}
                    </div>
                  ))}
            </div>
            <div className="assignPro d-flex align-items-center mx-5">
              <div className="assignTitle mx-2">Priority:</div>
              <div>
                <Badge
                  badgeContent={taskDetails.priority}
                  color="primary"
                  className="mx-4"
                />
              </div>
            </div>
          </div>
          <div className="assignPro d-flex align-items-center">
            {/* <div className="assignTitle m-0">Status:</div>
                        <div className="mx-2">To do</div> */}
            <div className="assignTitle m-0">Status:</div>
            <div className="statusDropdown mx-2">
              <Select value={status} disabled onChange={handleStatusChange}>
                <MenuItem value="To do">
                  {(taskDetails?.status)}
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="attachmentBox">
          <div className="col-12 mb-3">
            <label>
              <b>Notes: </b>
            </label>
            <textarea
              id="txtAddress"
              name="notes"
              rows="2"
              disabled
              className="form-control"
              placeholder="Note"
              value={taskDetails?.note?.description}
            />
          </div>
          <div>
            <b>Due Date : </b>
            {taskDetails.due_date ? taskDetails.due_date?.split("T")[0] : "N/A"}
          </div>
          <Row className="mb-3">
            <div className="col-sm-12 custom_border_bottom pb-3">
              <div className="tenantDetailTitle tenantDetFullTitle">
                Attachments :
              </div>
              {taskDetails?.attachments?.length > 0 && (
                <FileList
                  files={taskDetails?.attachments?.map((doc) => ({
                    ...doc,
                    uniqueId: uuid.v4(),
                    formattedSize: formatBytes(doc.size),
                  }))}
                />
              )}
            </div>
          </Row>

          {/* <div className="attachmentBody ">
            {taskDetails?.attachments?.length > 0 &&
              taskDetails?.attachments?.map((attach, id) => (
                <ul
                  key={id}
                  className="attachments list-inline d-flex flex-wrap justify-content-between mt-2"
                >
                  <li className="active d-flex align-items-center">
                    <NoteOutlinedIcon />
                    <span className="mx-2"> {attach?.name}</span>
                  </li>
                </ul>
              ))}
            
          </div> */}
        </div>
        {/* {taskDetails?.attachments?.length > 0 && (
          <div className="previewBox">
            <div className="previewHeader">
              <div>Preview</div>
            </div>
            <div className="previewBody">
              <div className="imageBox">
                <div className="msg d-flex flex-column align-item-center"><img src={GallaryIcon} alt={GallaryIcon} />No Image to show</div>
                <img src={TestScreen} alt={TestScreen} />
              </div>
            </div>
          </div>
        )} */}
      </div>
    );
  };

  const CommentsDetails = () => {
    const [commentMeta, setCommentMeta] = useState({});
    const [commentsData, setCommentsData] = useState([]);
    const [comment, setComment] = useState("");
    const [page, setPage] = useState(1);
    const [Id, setId] = useState("");
    const [mode, setMode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const addValues = (e) => {
      setComment(e);
    };
    const fetchComments = async (page, limit, oldData) => {
      const params = {
        page: page,
        limit: limit,
      };
      try {
        const result = await fetchCommentData(params, selectedId);
        setCommentsData([...oldData, ...(result?.data?.data?.data||[])]);
        setCommentMeta(result?.data?.data?.meta);
      } catch (e) {
        toast.error(e.message);
      }
    };
    const createComment = async (data) => {
      try {
        setIsLoading(true);
        const payload = {
          description: comment,
          commented_by: +myId,
          task_id: selectedId,
        };
        const result =
          mode === "Edit"
            ? await updateTaskComment(
                {
                  description: comment,
                },
                Id
              )
            : await addTaskComment(payload);
        if (result?.status === 200) {
          toast.success(result?.data?.message);
          refresh();
          setComment("");
          mode !== "Edit" && actionAfterEdit();
        } else {
          toast.error(result.data.message);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
      } finally {
        setIsLoading(false);
      }
    };
    const deleteComment = async () => {
      try {
        setIsLoading(true);
        const result = await deleteTaskComment(Id);
        if (result?.status === 200) {
          toast.success(result?.data?.message);
          refresh();
          setId("");
          actionAfterEdit();
          setIsDeleteOpen(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchParticularComment = async (data) => {
      setMode("Edit");
      setId(data?.id);
      setComment(data?.description);
      const elements = document.getElementsByClassName("offcanvas-body");
      elements[0].scrollTop = 0;
    };
    const scrollHandle = (e) => {
      const listboxNode = e.currentTarget;

      const x = listboxNode.scrollTop + listboxNode.clientHeight;
      if (
        listboxNode.scrollHeight - x <= 1 &&
        commentMeta?.current_page < commentMeta?.last_page
      ) {
        fetchComments(page + 1, LIMIT, commentsData);
        setPage((old) => old + 1);
      }
    };
    const refresh = () => {
      fetchComments(1, LIMIT, []);
      setPage(1);
      setMode("");
    };
    const onReady = (editor) => {
      editor.focus();
    };

    useEffect(() => {
      fetchComments(page, LIMIT, []);
      return () => {
        setId("");
        setDedicatedTabValue(0);
      };
    }, []);

    return (
      <div>
        <div
          style={{
            width: "100%",
            border: "1px solid grey",
          }}
        >
          <CKEditorComp
            onChange={addValues}
            onReady={onReady}
            data={comment}
            isDisabled={isLoading}
          />
        </div>
        <Button
          variant="contained"
          color="success"
          disabled={!comment || isLoading}
          style={{
            marginTop: "10px",
          }}
          onClick={createComment}
        >
          <CircularProgress
            size={12}
            thickness={7}
            color="inherit"
            style={{
              color: "#00000",
              textAlign: "center",
              display: !isLoading ? "none" : "inline-block",
            }}
          />
          &nbsp; {mode === "Edit" ? "Edit Comment" : "Post Comment"}
        </Button>
        {commentsData?.length > 0 ? (
          <Paper className={`${classes.scrollableList} detail_comment_bullets`} onScroll={scrollHandle}>
            <List sx={{}}>
              {commentsData?.map((com) => (
                <>
                  <ListItem alignItems="flex-start" key={com?.id}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Avatar"
                        src={com?.commenter?.profile_photo}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<>{ReactHtmlParser(com?.description)}</>}
                      secondary={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            {com?.commenter?.name}, {""}
                            {new Date(com?.updated_at)?.toLocaleString() || ""}
                          </div>

                          {myId === com?.commenter?.id && (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                onClick={() => {
                                  setId(com?.id);
                                  setIsDeleteOpen(true);
                                }}
                              >
                                <DeleteOutlineOutlinedIcon
                                  fontSize="12"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </div>

                              <div onClick={() => fetchParticularComment(com)}>
                                <EditRoundedIcon
                                  fontSize="12"
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </>
              ))}
            </List>
          </Paper>
        ) : (
          <NodataFound text={"No Comments Found"} />
        )}

        {isDeleteOpen && (
          <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setIsDeleteOpen(false)}
            onDeleteClick={deleteComment}
            isLoading={isLoading}
          />
        )}
      </div>
    );
  };

  const tabValues = useMemo(() => {
    return {
      Details: <DetailsTaskDetails />,
      Comments: <CommentsDetails />,
    };
  }, [taskDetails]);

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isAddExistingTenant)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>Task Details</OffcanvasHeader>
      <OffcanvasBody>
        <BasicTabs
          tabValues={tabValues}
          dedicatedTabValueIndex={dedicatedTabValue}
        />
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default KanbanDetailDrawer;
