import { toast } from 'react-toastify';
import { getAllProperties, getMembersOfProperties } from '../property';
import { deleteMessage, sendMessage } from '../message';

export const getPropertyHandler = async (setData) => {
  try {
    const response = await getAllProperties();
    if (response.status === 200 && response.data.data.data.length > 0) {
      const propertiesList = response.data.data.data.map((property) => ({
        id: property.id,
        name: property.name,
      }));
      setData(propertiesList);
    }
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};

export const getMembersHandler = async (propertyId) => {
  try {
    const response = await getMembersOfProperties(propertyId);
    if (response.status === 200) {
      return response?.data?.data;
    }
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};

export const sendMessageHandler = async (payload) => {
  try {
    const response = await sendMessage(payload);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
  return false;
};

export const deleteMessageHandler = async (id) => {
  try {
    const response = await deleteMessage(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if (error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};
