import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import EditIcon from '@mui/icons-material/Edit';
import { useProfile } from "../../Hooks/UserHooks"
import { getS3BaseUrl } from "../../../helpers/string_helper";
import { KNOWN_ROLES } from "../../../common/constant";
import dayjs from "dayjs";
import FileList from "../../Common/FileList";

export default function ViewProfileDrawer({
    isOpen,
    toggleDrawer,
    setIsEditMode,
}) {
    const { user } = useProfile()
    const s3BaseUrl = getS3BaseUrl()

    const handleClose = () => {
        setIsEditMode && setIsEditMode(false);
        toggleDrawer(false);
    };

    return (
        <Offcanvas isOpen={isOpen} direction="end" className="tenantDetailsDrawer viewProDetails">
            <OffcanvasHeader toggle={handleClose}>
                View Profile Details
            </OffcanvasHeader>
            <OffcanvasBody>
                <div className="mb-3 row">
                    <div className="d-flex">
                        <div className="custom-image-upload">
                            {/* <div className="image-placeholder position-relative">
                                <img
                                    src={user?.role?.role !== KNOWN_ROLES.SUPER_ADMIN ? `${s3BaseUrl}${user?.profile_photo}` : user?.profile_photo}
                                    alt={user?.profile_photo}
                                />
                                <input
                                    className="form-control position-absolute"
                                    type="file"
                                    id="profile_photo"
                                    style={{ display: "none" }}
                                />
                                <label htmlFor="profile_photo" className="editIconBox position-absolute">
                                    <EditIcon />
                                </label>

                            </div> */}
                            {user?.profile_photo && (
                                <div
                                    className="image-placeholder"
                                >
                                    <img
                                        src={`${s3BaseUrl}${user?.profile_photo}`}
                                        alt={user.profile_photo}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='upload_picture mx-3'>
                            <div className='pro_person_name mb-0'>
                                {user?.name}
                            </div>
                            <div className="designation mb-2">{user?.role?.role}</div>
                            <div className="doubleSocio d-flex ">
                                <div className='pro_person_mob me-3'>
                                    <i className='fas fa-phone-alt me-1' />
                                    <span className='country_code'>+91</span>
                                    <span className='pr_person_contact'>
                                        {user?.contact_number}
                                    </span>
                                </div>
                                {user?.email && (
                                <div className='pro_person_mob tenantDetailEmail'>
                                    <span className='pr_person_contact'>
                                        <i className='fas fa-envelope me-1' />
                                            {user?.email}
                                    </span>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {user?.role?.role !== KNOWN_ROLES.SUPER_ADMIN && (
                    <><div className="mb-3 row">
                        <div className="col-sm-6 d-flex align-items-start custom_border_bottom">
                            <div className="tenantDetailTitle">Yearly CTC:</div>
                            <div className="tenantDetailDesc">{user?.employee?.yearly_ctc ?? 'N/A'}</div>
                        </div>
                        <div className="col-sm-6 d-flex align-items-start custom_border_bottom">
                            <div className="tenantDetailTitle">Joining Date:</div>
                            <div className="tenantDetailDesc">{user?.employee?.joining_date ? dayjs(user?.employee?.joining_date).format('DD-MM-YYYY') : 'N/A'}</div>
                        </div>
                    </div><div className="mb-3 row">
                            {/* <div className="personalInfo"> */}
                            <div className="col-sm-12 d-flex align-items-start custom_border_bottom pb-3">
                                <div className="tenantDetailTitle" style={{ width: '25%' }}>Address:</div>
                                <div className="tenantDetailDesc" style={{ width: '65%' }}>{user?.address ?? 'N/A'}</div>
                            </div>
                            <div className="col-sm-12 d-flex align-items-start custom_border_bottom pb-3">
                                <div className="tenantDetailTitle" style={{ width: '25%' }}>Additional Information:</div>
                                <div className="tenantDetailDesc" style={{ width: '65%' }}>{user?.employee?.additional_information ? user?.employee?.additional_information : 'N/A'}</div>
                            </div>
                            <div className="col-sm-12 d-flex align-items-start custom_border_bottom pb-3">
                                <div className="tenantDetailTitle" style={{ width: '25%' }}>Attached Document:</div>
                                <div className="tenantDetailDesc" style={{ width: '65%' }}>
                                    {user?.attachments?.length > 0 ? (
                                        <FileList
                                            files={user?.attachments}
                                        />
                                    ) : <div >No attachments are available for this user</div>}
                                </div>
                            </div>
                        </div></>
                )}
            </OffcanvasBody>
        </Offcanvas>
    );
}