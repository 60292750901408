import React, { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import SelectRoom from "./selectRoom"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAllUser } from "../../api/user";
import { KNOWN_ROLES, USER_STATUS } from "../../common/constant";
import { SearchDropDownListPaginationComponent } from "../Common/SearchDropDownPaginationList";
import { useDispatch } from 'react-redux'
import { storePropertyFlatDetails, storePropertyRoomDetails } from "../../store/property";
import { getPropertyDetails } from "../Hooks/PropertyHooks";
import uuid from 'react-native-uuid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function SelectFlat({ basicPropertyData, propertyData, floorData, emitPropertyData, uniqueId, previousData }) {
    const { roomData } = getPropertyDetails()
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const [onMounted, setOnMounted] = useState(false);
    const [selectDropdownUpdate, setSelectDropdownUpdate] = useState(0);
    const flatObject = {
        flats: [{
            name: '',
            user_id: '',
            amenities: '',
            flatUniqueId: '',
        }]
    }

    useEffect(() => {
        if (!propertyData?.id) {
        if (floorData?.name) {
            setIsDisabled(false);
            if (previousData?.length > 0) {
                const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                if (result?.length > 0) {
                    const updatedFlat = result[0]?.flats?.map((flat, index) => {
                        if (!flat?.name) {
                            return {
                                ...flat,
                                name: `Flat ${index + 1}`,
                                flatUniqueId: flat?.flatUniqueId ? flat?.flatUniqueId : uuid.v4()
                            }
                        }
                        else {
                            return flat
                        }
                    })
                    formik.setValues({
                        flats: updatedFlat
                    })
                }
            } else {
                const result = formik.values.flats?.map((flat, index) => {
                    if (!flat?.name) {
                        return {
                            ...flat,
                            name: `Flat ${index + 1}`,
                            flatUniqueId: flat?.flatUniqueId ? flat?.flatUniqueId : uuid.v4()
                        }
                    }
                    else {
                        return flat
                    }
                })
                formik.setValues({
                    flats: result
                })
            }
        } else if (floorData.name === null || !floorData.name) {
            setIsDisabled(true)
            if (previousData?.length > 0) {
                const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                if (result?.length > 0) {
                    const updatedFlat = result[0]?.flats?.map((flat) => {
                        if (flat?.name) {
                            return {
                                name: '',
                                user_id: '',
                                amenities: ''
                            }
                        }
                        else {
                            return flat
                        }
                    })
                    formik.setValues({
                        flats: updatedFlat
                    })
                }
            } else {
                const result = formik.values.flats?.map((flat) => {
                    if (flat?.name) {
                        return {
                            name: '',
                            user_id: '',
                            amenities: '',
                            flatUniqueId: flat?.flatUniqueId ? flat?.flatUniqueId : uuid.v4()
                        }
                    }
                    else {
                        return flat
                    }
                })
                formik.setValues({ flats: result })
            }
        }
        else {
            setIsDisabled(true)
        }
        }
    }, [floorData, floorData.name])



    const flatPropertyValidation = Yup.object().shape({
        flats: Yup.array().of(Yup.object().shape({
            name: Yup.string()
                .required("Please select flat name"),
            user_id: Yup.number().optional()
        })).required().min(1),
    });

    const formik = useFormik({
        initialValues: {
            ...flatObject,
        },
        validateOnMount: true,
        validationSchema: flatPropertyValidation
    });

    useEffect(() => {
        if (propertyData?.flats?.length > 0) {
            let options = []
            const result = propertyData.flats?.map((fl) => {
                options.push(fl.name)
                return {
                    ...fl, name: fl.name,
                    ...(fl?.user && {
                        user_id: { name: fl.user?.name, id: fl.user?.id, code: fl.user?.code }
                    }),
                    flatUniqueId: uuid.v4(),
                }
            })

            formik.setValues({
                flats: result
            })
            setSelectDropdownUpdate(selectDropdownUpdate + 1)
        }
    }, [propertyData])

    const handleAddFlat = () => {
        const flatData = []
        formik.values.flats?.forEach((fl, index) => {
            flatData.push(fl)
            if (formik.values.flats?.length === index + 1) {
                flatData.push(isDisabled ? { ...fl, flatUniqueId: uuid.v4() } : {
                    name: `Flat ${index + 2}`,
                    flatUniqueId: uuid.v4(),
                })
            }
        })
        formik.setValues({
            ...formik.values,
            flats: flatData
        })
    };

    const handleRoomChange = (index, field, value) => {
        const result = formik.values.flats.map((fl, flIndex) => {
            if (flIndex === index && field === 'user_id') {
                return {
                    ...fl,
                    user_id: value
                }
            } else {
                return fl
            }
        })
        formik.setValues({
            ...formik.values,
            flats: result
        })
    };

    const removeFlat = (index) => {
        if (formik.values?.flats?.length === 1) {
            // Prevent removing the last room
            return;
        }

        const updatedFlats = [...formik.values.flats];
        const removeRoomData = roomData?.filter((rm) => rm.uniqueId !== updatedFlats[index]?.uniqueId)
        dispatch(storePropertyRoomDetails({
            roomData: removeRoomData
        }))
        updatedFlats.splice(index, 1);
        const flatData = []
        updatedFlats?.forEach((fl, flIndex) => {
            if (index > flIndex) {
                flatData.push(fl)
            } else {
                flatData.push(isDisabled ? fl : {
                    ...fl,
                    name: `Flat ${flIndex + 1}`
                })
            }
        })
        formik.setValues({
            flats: flatData
        })
        setSelectDropdownUpdate(selectDropdownUpdate + 1)
    };

    useEffect(() => {
        if (formik.values.flats?.length > 0 && floorData) {
            let flats = []
            if (uniqueId) {
                let z = JSON.parse(JSON.stringify(formik.values.flats));
                const updatedData = previousData?.filter((item) => item.uniqueId !== uniqueId)
                dispatch(storePropertyFlatDetails(previousData ? {
                    flatData: [...updatedData, {
                        uniqueId,
                        flats: z,
                    }]
                } : {
                    flatData: [{
                        uniqueId,
                        flats: z,
                    }]
                }))
            }

            formik.values.flats.forEach((flat) => {
                if (flat.name) {
                    flats.push({
                        name: flat.name,
                        ...(flat.user_id && {
                            user_id: flat.user_id?.id ? flat.user_id?.id : flat.user_id
                        }),
                        ...(flat.amenities && {
                            amenities: flat.amenities
                        })
                    })
                }
            })
            if (flats.length > 0) {
                emitPropertyData({
                    floorName: floorData?.name, flats: flats
                }, 'flat')
            }
        }
    }, [formik.values.flats])

    useEffect(() => {
        if (!onMounted && uniqueId && !propertyData?.id) {
            const result = formik.values?.flats?.map((item) => {
                if (!item.flatUniqueId) {
                    return { ...item, flatUniqueId: item?.flatUniqueId ? item?.flatUniqueId : uuid.v4() }
                } else {
                    return item
                }
            })
            formik.setValues({
                flats: result
            })
            setSelectDropdownUpdate(selectDropdownUpdate + 1)
            setOnMounted(true)
        }
    }, [])
    const theme = createTheme({
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {
                color: 'red', // Change the color here
              },
            },
          },
        },
      });

    return (
        <React.Fragment>
            <div className="roomWrapper" key={selectDropdownUpdate}>
                {formik.values.flats?.map((flat, index) => (
                    <div key={index} className="roomWrap d-flex">
                        <div className="flatOwner padding_left_common">
                            <div className="flatOwnerWrapper d-flex align-items-center">
                                <TextField
                                    disablePortal
                                    id="combo-box-demo"
                                    readOnly
                                    label="Selected flat"
                                    disabled={isDisabled}
                                    value={flat?.name ?? ''}
                                    sx={{ minWidth: 150, marginRight: '10px' }}
                                    size="small"
                                />

                                {!basicPropertyData?.ownerId && (
                                    <div style={{ minWidth: '250px', marginRight: '15px', maxWidth: '300px' }}>
                                        <ThemeProvider theme={theme}>
                                            <SearchDropDownListPaginationComponent
                                                label="Select Flat Owner"
                                                searchEnabled={false}
                                                key={selectDropdownUpdate}
                                                disableClearable={propertyData?.id}
                                                disabled={isDisabled}
                                                listCall={getAllUser}
                                                defaultValue={flat?.user_id?.name ? flat?.user_id : null}
                                                emitItem={(newValue) => {
                                                    handleRoomChange(index, 'user_id', newValue ? { id: newValue.id, name: newValue.name, code: newValue.code } : "")
                                                }}
                                                apiParams={{
                                                    filter_fields: ['role', 'status'],
                                                    filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],                                                }}
                                                sx={{ minWidth: 150, marginRight: '15px' }}
                                                size="small"
                                                required={true}
                                            />
                                        </ThemeProvider>
                                    </div>
                                )}
                                <CancelOutlinedIcon color="error" onClick={() => removeFlat(index)}
                                    className={`cancelIcon ${index === 0 ? 'fadedCross' : ''}`} />
                            </div>
                        </div>
                        <div className='d-flex flex-column  padding_left_common'>
                            <SelectRoom
                                previousData={roomData?.length > 0 ? roomData : undefined}
                                uniqueId={flat.flatUniqueId}
                                basicPropertyData={basicPropertyData}
                                flatData={{ flatData: formik.values.flats[index], floorData: floorData }}
                                emitPropertyData={emitPropertyData}
                                propertyData={propertyData ? formik.values.flats[index] : undefined}
                            />
                        </div>
                    </div>
                ))}
                <Link href="javascript:void(0)" underline="none" onClick={handleAddFlat} style={{ marginLeft: '10px', padding: '0 0 10px 0' }}>
                    <AddOutlinedIcon /> &nbsp; Add Flat
                </Link>
            </div>
        </React.Fragment>
    )
}