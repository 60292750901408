import React, { useState } from 'react';
import Select from 'react-select';
import { updateStatus } from '../../api/Tenants/api';
import ConfirmationModal from '../Common/ConfirmationModal';
import { ACTIONS, VERIFICATION_STATUS } from '../../common/constant';
import { PropTypes } from 'prop-types';
import { createTheme } from '@mui/material';

function StatusSelectBox({ id, name, currentStatus, refresh, disableUpdate = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateConfirm, setUpdateConfirm] = useState({
    status: false,
    id: null,
  });
  const colors = createTheme()?.palette;
  const customStyles = {
    control: (provided, state) => {
      let backgroundColor = 'transparent';

      if (state.selectProps.value) {
        const selectedOption = state.selectProps.options.find(
          (option) => option.value === state.selectProps.value.value,
        );

        backgroundColor =
          selectedOption.value === VERIFICATION_STATUS.INCOMPLETE
            ? colors.error.main
            : selectedOption.value === VERIFICATION_STATUS.COMPLETE
              ? colors.success.main
              : 'transparent';

      }

      return {
        ...provided,
        backgroundColor,
      };
    },
    menu: () => ({ borderRadius: '20px', overflow: 'hidden', backgroundColor: '#fff' }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.value === VERIFICATION_STATUS.INCOMPLETE ? colors.error.contrastText : colors.success.contrastText,
    }),
  };
  const options = [
    { value: VERIFICATION_STATUS.INCOMPLETE, label: VERIFICATION_STATUS.INCOMPLETE },
    { value: VERIFICATION_STATUS.COMPLETE, label: VERIFICATION_STATUS.COMPLETE },
  ];
  const [status, setStatus] = useState(
    currentStatus === VERIFICATION_STATUS.COMPLETE ? options[1] : options[0],
  );
  let updatePayload = {};
  const handleChange = async (val) => {
    setIsLoading(true);
    setStatus(val);
    switch (name) {
      case 'agreement':
      case 'police_verification':
        updatePayload = {
          [name]: {
            status: val.value,
          },
        };
        break;
      default:
        return;
    }
    await updateStatus(id, updatePayload);
    setIsLoading(false);
    refresh();
  };
  return (
    <>
      <Select
        options={options}
        styles={customStyles}
        defaultValue={currentStatus}
        value={status}
        onChange={(val) => setUpdateConfirm({ status: true, id: val })}
        className='customSelectfilter'
        menuPortalTarget={document.body}
        isDisabled={disableUpdate}
      />
      <ConfirmationModal
        action={ACTIONS.EDIT}
        show={isUpdateConfirm.status}
        onCloseClick={() => setUpdateConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isUpdated = await handleChange(isUpdateConfirm.id);
          if (isUpdated) {
            setUpdateConfirm({ status: false, id: null });
            refresh();
          }
        }}
        isDisabled={isLoading}
      />
    </>
  );
}

StatusSelectBox.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  currentStatus: PropTypes.string,
  refresh: PropTypes.func,
}
export default StatusSelectBox;
