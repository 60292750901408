import React, { useState, useEffect, useCallback } from "react";
import { debounce, Chip, TextField, Autocomplete, Box } from "@mui/material";
import { toast } from "react-toastify";

export function MultipleSelectDropDownListPaginationComponent({
  listCall,
  label,
  defaultValue,
  disabled,
  apiParams,
  searchEnabled,
  emitItem,
  staticOption,
}) {
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationObject, setPaginationObject] = useState({})
  const [searchQuery, setSearchQuery] = useState("");
  const [position, setPosition] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([])
  const [listboxNode, setListboxNode] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  const getOptionsDelayed = useCallback(
    debounce((query, apiParams, cb) => {
      setOptions([]);
      fetchAPI(query, apiParams).then((response) => {
        cb(response);
      });
    }, 1200),
    []
  );

  useEffect(() => {
    if (mounted && isOpen) {
      setLoading(true);
      getOptionsDelayed(searchQuery, apiParams, (response) => {
        const optionsList = response.data.data.data.map((opts) => {
          // return {
          //   id: opts.id,
          //   label: (
          //     <div className='selectUserCombo d-flex justify-content-between align-items-center'>
          //       <span>{opts.name ?? opts.user.name}</span> <span className="autocompleteId mx-4">ID# {opts.code}</span>
          //     </div>
          //   ),
          //   key: `${opts.id}|${opts?.name}`,
          // };
          return {
            id: opts.id,
            // code: opts.code,
            label: (
              <div className='selectUserCombo d-flex justify-content-between align-items-center'>
                <span>{opts.name}</span> <span className="autocompleteId mx-4"> {opts.code}</span>
              </div>
            ),
            key: `${opts.code}|${opts?.name ? opts.name : '--'}`,
          };
        });
        setOptions(optionsList);
        setPaginationObject(response.data.data.meta)
        setPage(page + 1)
        setLoading(false);
      });
    }
  }, [searchQuery, getOptionsDelayed, isOpen]);

  const fetchAPI = async (query, apiParams = {}) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }

      const response = await listCall(queryParams);
      return response
    } catch (err) {
      console.log(err)
    }
  }

  const fetchOptions = async (query) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }

      setLoading(true);
      const response = await listCall(queryParams);
      setPage(page + 1);

      const optionsList = response.data.data.data.map((opts) => {
        return {
          id: opts.id,
          // code: opts.code,
          label: (
            <div className='selectUserCombo d-flex justify-content-between align-items-center'>
              <span>{opts.name}</span> <span className="autocompleteId mx-4"> {opts.code}</span>
            </div>
          ),
          key: `${opts.code}|${opts?.name ? opts.name : '--'}`,
        };
      });

      setPaginationObject(response.data.data.meta);
      setOptions((prevState) => {
        return [...prevState, ...optionsList];
      });
      setLoading(false);

    } catch (e) {
      setLoading(false);
      toast.error("Encountered problem with fetching records.");
    }
  };

  useEffect(() => {
    if (listboxNode !== '' && listboxNode !== undefined) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode])

  const handleScroll = async (event) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    // only when checking this condition we change the position
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      if (options.length !== paginationObject.total && !loading) {
        await fetchOptions();
      }
    }
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={options}
        autoHighlight
        loading={loading}
        disabled={disabled ?? false}
        value={selectedValues.length > 0 ? selectedValues : defaultValue}
        onOpen={() => {
          setIsOpen(true);
        }}
        getOptionLabel={(option) => option.key}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            key={option.id}
            {...props}
          >
            {option.label}
          </Box>
        )}
        onInputChange={(event, newValue) => {
          setSearchQuery(newValue);
        }}
        onClose={() => {
          setIsOpen(false)
          setOptions([]);
          setPaginationObject([])
          setLoading(false)
          setPage(0)
        }}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          emitItem(newValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={option.key} variant="outlined" label={option.label} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps
            }}
            size="small"
          />
        )}
        ListboxProps={{
          onScroll: handleScroll
        }}
      />
    </>
  )
}
