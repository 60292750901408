import axiosInstance from "../utils/axios/auth";
import { getParam } from "../helpers/getParam";

const getComplainData = (params) => axiosInstance.get(`/complain/all-complains`, getParam(params));
const updateComplainStatus = async (id, payload) => await axiosInstance.put(`/complain/update-complain-status/${id}`, payload);
const updateComplainNote = async (id, payload) => await axiosInstance.put(`/complain/update-complain-note/${id}`, payload);

const tenantTicketCountData =  (params) =>  axiosInstance.get('/complain/complain-data-counter', getParam(params));
const ownerTicketCountData = async () => await axiosInstance.get('/complain/owner-complain-data-counter');


const createComplain =  (data) =>  axiosInstance.post(`/complain/create-complain`, data);
const getPropertyOwner = (params) => axiosInstance.get(`/complain/all-complains`, getParam(params));
const searchTenants =async (params) => await axiosInstance.get(`/user/fetch`, getParam(params));
const searchProperties =async (params) => await axiosInstance.get(`/properties`, getParam(params));
const priorityUpdate = async (id, payload) => await axiosInstance.put(`/complain/update-complain-priority/${id}`, payload);
const complainHandlerUpdate = async (id, payload) => await axiosInstance.put(`/complain/update-complain-handler/${id}`, payload);
const removeComplain = async (id) => await axiosInstance.delete(`/complain/delete-complain/${id}`);
const updateComplain = async (payload) => await axiosInstance.put(`/complain/edit-lodged-complain`, payload);



export {
  getComplainData,
  updateComplainStatus,
  createComplain,
  getPropertyOwner,
  searchTenants,
  searchProperties,
  tenantTicketCountData,
  ownerTicketCountData,
  priorityUpdate,
  updateComplainNote,
  complainHandlerUpdate,
  removeComplain,
  updateComplain,
}