import React, { useState, useEffect } from "react";
import {
    Button,
} from "reactstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ActivePropertiesBasic from "../../components/ActiveProperties/activePropertyBasic";
import ActivePropertiesBedMetrix from "../../components/ActiveProperties/activePropertyBedmetrix";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS, PROPERTY_TYPES } from "../../common/constant";
import { toast } from 'react-toastify';
import { createProperty, getPropertyDetails, updateProperty } from "../../api/property";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { uploadDocuments, uploadDocumentsForModuleWise } from "../../api/CompanyAssociates/api";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { removePropertyDetails } from "../../store/property";
import { getS3BaseUrl } from "../../helpers/string_helper";

export default function AddActiveProperty(props) {
    const [activeStep, setActiveStep] = useState(0); // State to track active step
    const [basicPropertyData, setBasicPropertyData] = useState();
    const [isDisabled, setIsDisbaled] = useState(true);
    const [update, setUpdate] = useState(0);
    const [isBack, setIsBack] = useState(false);
    const [floorData, setFloorData] = useState();
    const [flatData, setFlatData] = useState();
    const [roomData, setRoomData] = useState();
    const [bedData, setBedData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [pictureUploadIds, setPictureUploadIds] = useState([]);
    const [documentUploadIds, setDocumentUploadIds] = useState([]);
    const [defaultImageId, setDefaultImageId] = useState()
    const { propertyId } = useParams();
    const [propertyData, setPropertyData] = useState();
    const [isSingleOwner, setIsSingleOwner] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.action === ACTIONS.EDIT) {
            fetchPropertyDetails()
        }
    }, [props?.action])

    const fetchPropertyDetails = async () => {
        try {
            const res = (await getPropertyDetails(parseInt(propertyId))).data.data
            const s3BaseUrl = getS3BaseUrl()
            let floors = []
            let flats = []
            let rooms = []
            res?.floors?.forEach((item) => {
                floors.push({
                    name: item.name, id: item.id, ...(item.user && {
                        user_id: item.user?.id
                    })
                })
                let flatFoRoom = []
                if (item?.flats?.length > 0) {
                    const flatMap = item?.flats.map((fl) => {
                        return {
                            id: fl?.id ?? null, name: fl.name, ...(item.user && {
                                user_id: item?.user?.id
                            })
                        }
                    })
                    flats.push({
                        floorName: item.name,
                        flats: flatMap
                    })
                    item?.flats?.forEach((fl, index) => {
                        flatFoRoom.push({
                            ...flatMap[index],
                            rooms: fl.rooms.map((rm) => {
                                return {
                                    id: rm?.id ?? null,
                                    documents: rm?.documents ?? [],
                                    name: rm?.name,
                                    room_type_id: rm.room_type.id,
                                    amenities: rm.amenities,
                                    beds: rm.beds.map((bd) => {
                                        return { id: bd?.id ?? null, name: bd?.name, price: bd?.price, user_id: bd?.user?.id }
                                    })
                                }
                            })
                        })
                    })

                    rooms.push({
                        floorData: {
                            name: item.name,
                            flats: flatFoRoom
                        }
                    })
                } else if (item?.rooms?.length > 0) {
                    rooms.push({
                        floorData: {
                            name: item.name,
                            rooms: item.rooms.map((rm) => {
                                return {
                                    id: rm?.id ?? null,
                                    documents: rm?.documents ?? [],
                                    name: rm?.name,
                                    room_type_id: rm.room_type.id,
                                    amenities: rm.amenities,
                                    beds: rm.beds.map((bd) => {
                                        return { id: bd?.id ?? null, name: bd?.name, price: bd?.price, user_id: bd?.user?.id }
                                    })
                                }
                            })
                        }
                    })
                }


            })
            const pictures = res.pictures?.map((item) => {
                return { ...item, location: `${s3BaseUrl}${item?.location}` }
            })
            const documents = res.documents?.map((item) => {
                return { ...item, location: `${s3BaseUrl}${item?.location}` }
            })
            setFloorData(floors);
            setFlatData(flats);
            setRoomData(rooms);
            setBedData(rooms);
            setPropertyData({ ...res, pictures: pictures, documents: documents })
        } catch (err) {
            console.log(err?.response?.data?.message)
            toast.error(err?.response?.data?.message)
        }
    }

    const handleSelectedOption = (data) => {
        setBasicPropertyData({
            ...basicPropertyData,
            type: data === 'bhk' ? PROPERTY_TYPES.APARTMENT : PROPERTY_TYPES.PERSONAL_HOME
        })
        setFlatData()
        setFloorData()
        setRoomData()
        dispatch(removePropertyDetails())
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setIsBack(true)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        dispatch(removePropertyDetails())
    };

    const navigate = useNavigate();

    const handleBackPage = () => {
        navigate("/active-properties/active-properties-live")
    };

    const steps = ["Basic Details", "Bed Matrix"];

    useEffect(() => {
        if (basicPropertyData && basicPropertyData.name && basicPropertyData.addressLine1 && basicPropertyData.city && basicPropertyData.state && basicPropertyData.country && basicPropertyData.type && basicPropertyData.pinCode && basicPropertyData?.lat && basicPropertyData?.long && basicPropertyData.defaultImage?.name && (isSingleOwner ? basicPropertyData?.ownerId : true)) {
            setIsDisbaled(false)
        }
    }, [update])

    const fetchAllPropertyData = async (data, key) => {
        if (key === 'floor') {
            let floors = []
            let options = []
            let count = 0
            data?.forEach((item, index) => {
                if (item?.name) {
                    floors.push(item)
                    options.push(item.name)
                }
                if (propertyData && floorData[index]?.name === item.name && !floorData[index]?.user_id && item.user_id) {
                    count = count + 1
                }
            })
            if (floors.length < flatData?.length) {
                const diff = flatData.filter((it) => {
                    if (options.includes(it.floorName)) {
                        return it
                    }
                })
                if (floors.length < roomData?.length) {
                    const roomDiff = roomData.filter((it) => {
                        if (it && options.includes(it?.floorData?.name)) {
                            return it
                        }
                    })
                    if (floors.length < bedData?.length) {
                        const bedDiff = bedData?.filter((it) => {
                            if (it && options.includes(it?.floorData?.name)) {
                                return it
                            }
                        })
                        setBedData(bedDiff)
                    }
                    setRoomData(roomDiff)
                }
                setFlatData(diff)
            }
            if ((floors?.length < roomData?.length && (flatData?.length === 0 || !flatData)) && !count) {
                const diff = roomData.filter((it) => {
                    if (it && options.includes(it?.floorData?.name)) {
                        return it
                    }
                })
                if (floors.length < bedData?.length) {
                    const bedDiff = bedData?.filter((it) => {
                        if (it && options.includes(it?.floorData?.name)) {
                            return it
                        }
                    })
                    setBedData(bedDiff)
                }
                setRoomData(diff)
            }
            setFloorData(floors)
        }
        if (key === 'flat') {
            let newData = []
            let match = false
            await floorData?.forEach((it) => {
                if (it?.name === data?.floorName) {
                    match = true
                    newData.push({ floorName: it.name, flats: data.flats })
                } else if (flatData && flatData?.length > 0) {
                    const test = flatData?.find((item) => {
                        if (item?.floorName === it?.name) {
                            return item
                        }
                    })
                    newData.push({ ...test })
                }
            })
            if (!match) {
                newData.push({ floorName: data.floorName, flats: data.flats })
            }
            if (newData && newData.length === roomData?.length) {
                let removedRoomDataArray = []
                newData?.forEach((it) => {
                    const roomArray = roomData.filter((rm) => rm.floorData.name === it.floorName)
                    if (it?.flats?.length < roomArray[0]?.floorData?.flats?.length) {
                        let flatName = []
                        it.flats.forEach((fl) => {
                            flatName.push(fl.name)
                        })
                        removedRoomDataArray.push({
                            floorData: {
                                name: it.floorName,
                                flats: roomArray[0]?.floorData?.flats.filter((fl) => flatName.includes(fl.name))
                            }
                        })
                    } else {
                        const result = roomArray[0]?.floorData?.flats?.map((fl, flIndex) => {
                            return { ...it?.flats[flIndex], id: fl.id, rooms: fl.rooms }
                        })
                        removedRoomDataArray.push({ floorData: { name: roomArray[0]?.floorData.name, flats: result } })
                    }
                })
                setRoomData(removedRoomDataArray)
            }

            setFlatData(newData)
        }

        if (key === 'room' && data?.floorData && data?.floorData?.flats) {
            let flats = []
            let rooms = []
            data.floorData.flats?.rooms?.forEach((it) => {
                if (it?.name && it?.room_type_id) {
                    rooms.push(it)
                }
            })
            if (rooms?.length > 0) {
                let floors = []
                await flatData?.forEach((floor) => {
                    if (floor.floorName === data.floorData?.name) {
                        floor.flats?.forEach((flat) => {
                            if (flat?.name === data.floorData?.flats.name) {
                                flats.push(data.floorData.flats)
                            } else if (roomData && roomData.length > 0) {
                                roomData?.forEach((item) => {
                                    if (item?.floorData.name === floor.floorName) {
                                        item.floorData.flats.forEach((ft) => {
                                            if (flat.name === ft.name) {
                                                flats.push(ft)
                                            }
                                        })
                                    }
                                })
                            }
                        })
                        floors.push({
                            floorData: {
                                name: floor.floorName,
                                flats: flats
                            }
                        })
                    } else if (roomData && roomData.length > 0) {
                        roomData?.forEach((item) => {
                            if (item?.floorData?.name === floor?.floorName) {
                                floors.push(item)
                            }
                        })
                    }
                })

                setRoomData(floors)
            }
        } else if (key === 'room' && data?.floorData?.name && data?.floorData?.rooms?.length > 0) {
            const newData = floorData?.map((it) => {
                if (it?.name === data?.floorData?.name) {
                    return {
                        floorData: {
                            name: data?.floorData?.name,
                            ...(data?.floorData?.user_id && {
                                user_id: data?.floorData?.user_id
                            }),
                            rooms: data?.floorData?.rooms
                        }
                    }
                } else if (roomData && roomData?.length > 0) {
                    const test = roomData?.find((item) => item?.floorData?.name === it?.name)
                    return { ...test }
                }
            })
            setRoomData(newData)
        }

        if (key === 'bed' && data?.floorData && data?.floorData?.flats?.rooms?.beds) {
            let beds = []
            let bedArray = []
            data.floorData.flats?.rooms?.beds?.forEach((it) => {
                if (it?.name && it?.price) {
                    beds.push(it)
                }
            })
            if (beds?.length > 0) {
                roomData?.forEach((floor) => {
                    if (floor.floorData.name === data.floorData?.name) {
                        let flats = []
                        floor.floorData.flats.forEach((flat) => {
                            if (flat?.name === data.floorData?.flats?.name) {
                                let rooms = []
                                flat?.rooms?.forEach((room) => {
                                    if (room.name === data?.floorData?.flats?.rooms?.name) {
                                        rooms.push(data.floorData.flats.rooms)
                                    } else if (bedData && bedData.length > 0) {
                                        const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                                        const bedMatchFlatData = bedMatchFloorData[0]?.floorData?.flats?.filter((fl) => fl.name === flat.name)
                                        const bedMatchRoomData = bedMatchFlatData[0]?.rooms?.filter((rm) => rm?.name === room?.name)
                                        if (bedMatchRoomData?.length) {
                                            rooms.push(bedMatchRoomData[0])
                                        }
                                    }
                                })
                                if (rooms.length > 0) {
                                    flats.push({
                                        ...data.floorData?.flats,
                                        rooms: rooms
                                    })
                                }
                            } else if (bedData && bedData?.length > 0) {
                                const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                                const bedMatchFlatData = bedMatchFloorData[0]?.floorData?.flats?.filter((fl) => fl.name === flat.name)
                                if (bedMatchFlatData?.length) {
                                    flats.push(bedMatchFlatData[0])
                                }
                            }
                        })

                        if (flats.length > 0) {
                            bedArray.push({
                                floorData: {
                                    name: floor.floorData.name,
                                    flats: flats
                                }
                            })
                        }
                    } else if (bedData && bedData.length > 0) {
                        const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                        if (bedMatchFloorData[0]) {
                            bedArray.push(bedMatchFloorData[0])
                        }
                    }
                })
                setBedData(bedArray)
            }
        } else if (key === 'bed' && data?.floorData && data?.floorData?.rooms?.beds) {
            let beds = []
            let bedArray = []
            data.floorData?.rooms?.beds?.forEach((it) => {
                if (it?.name && it?.price) {
                    beds.push(it)
                }
            })
            if (beds?.length > 0) {
                roomData?.forEach((floor) => {
                    if (floor?.floorData?.name === data?.floorData?.name) {
                        let rooms = []
                        floor.floorData.rooms.forEach((room) => {
                            if (room.name === data.floorData.rooms.name) {
                                rooms.push(data.floorData.rooms)
                            } else if (bedData && bedData.length > 0) {
                                const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                                const bedMatchRoomData = bedMatchFloorData[0]?.floorData?.rooms?.filter((rm) => rm.name === room.name)
                                if (bedMatchRoomData?.length) {
                                    rooms.push(bedMatchRoomData[0])
                                }
                            }
                        })

                        if (rooms.length > 0) {
                            bedArray.push({
                                floorData: {
                                    name: floor.floorData.name,
                                    ...(floor.floorData?.user_id && {
                                        user_id: floor.floorData?.user_id
                                    }),
                                    rooms: rooms
                                }
                            })
                        }

                    } else if (bedData && bedData.length > 0) {
                        const bedMatchFloorData = bedData.filter((bd) => bd?.floorData?.name === floor?.floorData?.name)
                        if (bedMatchFloorData[0]) {
                            bedArray.push(bedMatchFloorData[0])
                        }
                    }
                })

                if (bedArray?.length > 0) {
                    setBedData(bedArray)
                }
            }
        }
    }

    const handleCreate = async () => {
        try {
            setIsDisbaled(true)
            setIsLoading(true);
            let isAttchament = true;
            let payload = {
                name: basicPropertyData?.name,
                type: basicPropertyData?.type,
                ...(basicPropertyData.amenities?.length > 0 && {
                    amenities: basicPropertyData.amenities.map((am) => am.name)
                }),
                ...(basicPropertyData.rules?.length > 0 && {
                    rules: basicPropertyData.rules
                }),
                property_manager_id: basicPropertyData?.careTaker?.assignee?.id,
                ...(basicPropertyData.ownerId && {
                    user_id: basicPropertyData.ownerId?.id
                }),
                ...(basicPropertyData?.careTaker?.name && {
                    care_taker_name: basicPropertyData?.careTaker?.name
                }),
                ...(basicPropertyData?.careTaker?.contactNo && {
                    care_taker_contact_no: basicPropertyData?.careTaker?.contactNo
                }),
                ...(basicPropertyData?.nearTo?.length > 0 && {
                    near_to: basicPropertyData?.nearTo.toString()
                }),
                latitude: basicPropertyData?.lat?.toString(),
                longitude: basicPropertyData?.long?.toString(),
                address: {
                    address_line_1: basicPropertyData.addressLine1,
                    city: basicPropertyData.city,
                    state: basicPropertyData.state,
                    ...(basicPropertyData?.district && {
                        district: basicPropertyData?.district,
                    }),
                    country: basicPropertyData.country,
                    zip_code: basicPropertyData.pinCode,
                    ...(basicPropertyData.addressLine2 && {
                        address_line_2: basicPropertyData.addressLine2,
                    })
                }
            }

            let floors = []
            if (roomData?.length > 0 && bedData?.length > 0) {
                roomData.forEach((floor, floorIndex) => {
                    let flats = []
                    let roomArrays = []
                    if (floor.floorData?.flats?.length > 0) {
                        floor.floorData?.flats?.forEach((flat, flatIndex) => {
                            if (flatData[floorIndex]?.flats[flatIndex] && flatData[floorIndex]?.flats[flatIndex]?.name === flat.name) {
                                const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                                const bedMatchFlatData = bedMatchFloorData[0]?.floorData?.flats?.filter((fl) => fl.name === flat.name)
                                if (bedMatchFlatData?.length > 0) {
                                    let rooms = []
                                    flat?.rooms.forEach((room) => {
                                        const bedMatchRoomData = bedMatchFlatData[0]?.rooms?.filter((rm) => rm.name === room.name)
                                        if (bedMatchRoomData?.length > 0) {
                                            rooms.push({
                                                name: room?.name,
                                                room_type_id: room?.room_type_id,
                                                ...(room?.amenities?.length > 0 && {
                                                    amenities: room?.amenities.map((am) => am.name)
                                                }),
                                                ...(room.documents?.length > 0 && {
                                                    documents: room.documents
                                                }),
                                                beds: bedMatchRoomData[0].beds
                                            })
                                        }
                                    })

                                    if (rooms?.length > 0) {
                                        flats.push({
                                            name: flat?.name,
                                            ...(flat?.user_id && {
                                                user_id: flat.user_id
                                            }),
                                            ...(flat?.amenities?.length > 0 && {
                                                amenities: flat.amenities.map((am) => am.name)
                                            }),
                                            rooms: rooms
                                        })
                                    }
                                }
                            }
                        })
                    } else if (floor.floorData?.rooms?.length > 0) {
                        floor.floorData?.rooms?.forEach((room) => {
                            const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                            if (bedMatchFloorData?.length > 0) {
                                const bedMatchRoomData = bedMatchFloorData[0]?.floorData?.rooms?.filter((rm) => rm.name === room.name)
                                if (bedMatchRoomData?.length > 0) {
                                    roomArrays.push({
                                        name: room?.name,
                                        room_type_id: room?.room_type_id,
                                        ...(room?.amenities?.length > 0 && {
                                            amenities: room.amenities.map((am) => am.name)
                                        }),
                                        ...(room.documents?.length > 0 && {
                                            documents: room.documents
                                        }),
                                        beds: bedMatchRoomData[0].beds
                                    })
                                }
                            }
                        })
                    }
                    floors.push({
                        name: floor?.floorData?.name,
                        ...(floor?.floorData?.user_id && {
                            user_id: floor?.floorData?.user_id
                        }),
                        ...(flats?.length ? {
                            flats: flats
                        } : { rooms: roomArrays })
                    })
                })
            }

            if (floors?.length > 0) {
                let documentUpload
                let pictureUpload
                let default_image_id
                if (basicPropertyData.pictures?.length > 0 && pictureUploadIds.length !== basicPropertyData.pictures?.length) {
                    const formData = new FormData();
                    basicPropertyData.pictures.forEach((f) => {
                        formData.append('files', f)
                    })
                    formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                    formData.append('max_items', 10)
                    const result = await uploadDocuments(formData)
                    if (result.success) {
                        const { response } = result
                        pictureUpload = [...response.data.data.documents.map((doc) => {
                            if (doc.name === basicPropertyData.defaultImage?.name) {
                                default_image_id = doc.id
                                setDefaultImageId(doc.id)
                            }

                            return doc.id
                        })]
                        setPictureUploadIds(pictureUpload);
                    } else {
                        setIsLoading(false);
                        return false;
                    }
                }
                if (basicPropertyData.documents?.length > 0 && documentUploadIds.length !== basicPropertyData.documents?.length) {
                    const formData = new FormData();
                    basicPropertyData.documents.forEach((f) => {
                        formData.append('files', f)
                    })
                    formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                    formData.append('max_items', 10)
                    const result = await uploadDocuments(formData)
                    if (result.success) {
                        const { response } = result
                        documentUpload = [...response.data.data.documents.map((doc) => doc.id)]
                        setDocumentUploadIds(documentUpload)
                    } else {
                        setIsLoading(false);
                        return false;
                    }
                }

                payload.floors = floors
                const response = await createProperty({
                    ...payload,
                    default_image_id: default_image_id ? default_image_id : defaultImageId,
                    ...((documentUploadIds.length > 0 || documentUpload) && {
                        documents: documentUploadIds.length > 0 ? documentUploadIds : documentUpload
                    }),
                    ...((pictureUploadIds.length > 0 || pictureUpload) && {
                        pictures: pictureUploadIds.length > 0 ? pictureUploadIds : pictureUpload
                    })
                });

                if (response?.data?.data) {
                    let isChecked = false
                    const res = (await getPropertyDetails(parseInt(response?.data?.data?.id))).data.data
                    await res?.floors?.forEach((floor, index) => {
                        if (floor.name === floors[index].name) {
                            if (floor?.flats?.length > 0) {
                                floor?.flats.forEach((flat, flatIndex) => {
                                    if (flat?.name === floors[index]?.flats[flatIndex]?.name) {
                                        flat.rooms?.forEach(async (room, roomIndex) => {
                                            isChecked = true
                                            if (room.name === floors[index]?.flats[flatIndex]?.rooms[roomIndex].name && floors[index]?.flats[flatIndex]?.rooms[roomIndex]?.documents?.length > 0) {
                                                try {
                                                    const formData = new FormData();
                                                    floors[index]?.flats[flatIndex]?.rooms[roomIndex]?.documents?.forEach((f) => {
                                                        formData.append('files', f)
                                                    })
                                                    formData.append('max_items', 10)
                                                    formData.append('moduleId', room.id)
                                                    formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                    await uploadDocumentsForModuleWise(formData)
                                                    isAttchament = false
                                                } catch (error) {
                                                    console.error(error)
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (floor?.rooms?.length > 0) {
                                floor.rooms?.forEach(async (room, roomIndex) => {
                                    isChecked = true
                                    if (room.name === floors[index]?.rooms[roomIndex].name && floors[index]?.rooms[roomIndex].documents?.length > 0) {
                                        try {
                                            const formData = new FormData();
                                            floors[index]?.rooms[roomIndex].documents.forEach((f) => {
                                                formData.append('files', f)
                                            })
                                            formData.append('max_items', 10)
                                            formData.append('moduleId', room.id)
                                            formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                            await uploadDocumentsForModuleWise(formData)
                                            isAttchament = false
                                        } catch (error) {
                                            console.error(error)
                                        }
                                    }
                                })
                            }
                        }

                        if (index === (floors.length - 1) && !isAttchament && isChecked) {
                            toast.success(response.data.message);
                            navigate("/active-properties/active-properties-live")
                            setIsLoading(false)
                            setIsConfirm(false)
                            dispatch(removePropertyDetails())
                        }
                    })

                    if (isAttchament && isChecked) {
                        toast.success(response.data.message);
                        setIsLoading(false)
                        setIsConfirm(false)
                        dispatch(removePropertyDetails())
                        navigate("/active-properties/active-properties-live")
                    }
                }
            } else {
                toast.warning('Please enter all required field')
                setIsConfirm(false)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
            setIsConfirm(false);
            setIsDisbaled(false);
            dispatch(removePropertyDetails())
        }
    };

    const handleUpdate = async () => {
        try {
            setIsDisbaled(true)
            setIsLoading(true);
            let isAttchament = true;
            let payload = {
                name: basicPropertyData?.name,
                type: basicPropertyData?.type,
                status: propertyData?.status,
                ...(basicPropertyData.amenities?.length >= 0 && {
                    amenities: basicPropertyData.amenities.map((am) => am.name)
                }),
                ...(basicPropertyData.rules?.length >= 0 && {
                    rules: basicPropertyData.rules
                }),
                property_manager_id: basicPropertyData?.careTaker?.assignee?.id ?? null,
                ...((propertyData?.user?.id || basicPropertyData.ownerId) && {
                    user_id: isSingleOwner ? basicPropertyData.ownerId?.id : null
                }),
                ...(basicPropertyData?.careTaker?.name && {
                    care_taker_name: basicPropertyData?.careTaker?.name
                }),
                ...(basicPropertyData?.careTaker?.contactNo && {
                    care_taker_contact_no: basicPropertyData?.careTaker?.contactNo
                }),
                ...((basicPropertyData?.nearTo?.length > 0 || propertyData?.near_to?.length > 0) && {
                    near_to: basicPropertyData?.nearTo?.length > 0 ? basicPropertyData?.nearTo.toString() : null
                }),
                latitude: basicPropertyData?.lat?.toString(),
                longitude: basicPropertyData?.long?.toString(),
                address: {
                    address_line_1: basicPropertyData.addressLine1,
                    city: basicPropertyData.city,
                    state: basicPropertyData.state,
                    ...(basicPropertyData?.district && {
                        district: basicPropertyData.district,
                    }),
                    country: basicPropertyData.country,
                    zip_code: basicPropertyData.pinCode,
                    ...(basicPropertyData.addressLine2 && {
                        address_line_2: basicPropertyData.addressLine2,
                    })
                }
            }

            let floors = []
            if (roomData?.length > 0 && bedData?.length > 0) {
                await roomData.forEach(async (floor, floorIndex) => {
                    let flats = []
                    let roomArrays = []
                    if (floor.floorData?.flats?.length > 0) {
                        await floor.floorData?.flats?.forEach((flat, flatIndex) => {
                            if (flatData[floorIndex]?.flats[flatIndex] && flatData[floorIndex]?.flats[flatIndex]?.name === flat.name) {
                                const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                                const bedMatchFlatData = bedMatchFloorData[0]?.floorData?.flats?.filter((fl) => fl.name === flat.name)
                                if (bedMatchFlatData?.length > 0) {
                                    let rooms = []
                                    flat?.rooms.forEach((room) => {
                                        const bedMatchRoomData = bedMatchFlatData[0]?.rooms?.filter((rm) => rm.name === room.name)
                                        if (bedMatchRoomData?.length > 0) {
                                            rooms.push({
                                                id: bedMatchRoomData[0]?.id ?? null,
                                                name: room?.name,
                                                room_type_id: room?.room_type_id?.id ? room?.room_type_id?.id : room?.room_type_id,
                                                ...(room?.amenities?.length > 0 && {
                                                    amenities: room?.amenities?.map((am) => am?.icon ? am?.name : am)
                                                }),
                                                ...(room.documents?.length > 0 && {
                                                    documents: room.documents
                                                }),
                                                beds: bedMatchRoomData[0].beds.map((bed) => {
                                                    return { ...bed, id: bed?.id ?? null }
                                                })
                                            })
                                        }
                                    })

                                    if (rooms?.length > 0) {
                                        flats.push({
                                            id: propertyData.floors[floorIndex]?.flats[flatIndex]?.id ?? null,
                                            name: flat?.name,
                                            ...((flat?.user_id || basicPropertyData?.ownerId) && {
                                                user_id: basicPropertyData?.ownerId ? null : (flat.user_id?.id ? flat.user_id?.id : flat.user_id)
                                            }),
                                            rooms: rooms
                                        })
                                    }
                                }
                            }
                        })
                    } else if (floor.floorData?.rooms?.length > 0) {
                        await floor.floorData?.rooms?.forEach((room, roomIndex) => {
                            const bedMatchFloorData = bedData.filter((bd) => bd?.floorData.name === floor.floorData.name)
                            if (bedMatchFloorData?.length > 0) {
                                const bedMatchRoomData = bedMatchFloorData[0]?.floorData?.rooms?.filter((rm) => rm.name === room.name)
                                if (bedMatchRoomData?.length > 0) {
                                    roomArrays.push({
                                        id: propertyData.floors[floorIndex]?.rooms[roomIndex]?.id ?? null,
                                        name: room?.name,
                                        room_type_id: room?.room_type_id?.id ? room?.room_type_id?.id : room?.room_type_id,
                                        ...(room?.amenities?.length > 0 && {
                                            amenities: room?.amenities?.map((am) => am?.icon ? am?.name : am)
                                        }),
                                        ...(room.documents?.length > 0 && {
                                            documents: room.documents
                                        }),
                                        beds: bedMatchRoomData[0]?.beds?.map((bed) => {
                                            return { ...bed, id: bed?.id ?? null }
                                        })
                                    })
                                }
                            }
                        })
                    }

                    floors.push({
                        id: propertyData?.floors[floorIndex]?.id ?? null,
                        name: floor?.floorData?.name,
                        ...((floorData[floorIndex]?.user_id && propertyData?.type === PROPERTY_TYPES.PERSONAL_HOME) && {
                            user_id: basicPropertyData?.ownerId ? null : (floorData[floorIndex].user_id?.id ? floorData[floorIndex].user_id?.id : floorData[floorIndex].user_id)
                        }),
                        ...(flats?.length ? {
                            flats: flats
                        } : { rooms: roomArrays })
                    })
                })
            }

            if (floors?.length > 0) {
                let documentUpload
                let pictureUpload
                let documentExistIds = []
                let pictureExistIds = []
                let default_image_id
                if (basicPropertyData.pictures?.length > 0 && pictureUploadIds?.length !== basicPropertyData.pictures?.length) {
                    const formData = new FormData();
                    let isFile = false
                    await basicPropertyData.pictures.forEach((f) => {
                        if (f?.id) {
                            pictureExistIds.push(f.id)
                            if (f.name === basicPropertyData.defaultImage?.name) {
                                default_image_id = f.id
                                setDefaultImageId(f.id)
                            }
                        } else {
                            isFile = true
                            formData.append('files', f)
                        }
                    })
                    if (isFile) {
                        formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                        formData.append('max_items', 10)
                        const result = await uploadDocuments(formData)
                        if (result.success) {
                            const { response } = result
                            const results = [...response.data.data.documents.map((doc) => {
                                if (doc.name === basicPropertyData.defaultImage?.name) {
                                    default_image_id = doc.id
                                    setDefaultImageId(doc.id)
                                }

                                return doc.id
                            })]
                            setPictureUploadIds(pictureExistIds?.length > 0 ? [...pictureExistIds, ...results] : results);
                            pictureUpload = pictureExistIds?.length > 0 ? [...pictureExistIds, ...results] : results
                        } else {
                            setIsLoading(false);
                            return false;
                        }
                    } else if (pictureExistIds?.length > 0) {
                        pictureUpload = pictureExistIds
                    }
                }
                if (basicPropertyData.documents?.length > 0 && documentUploadIds.length !== basicPropertyData.documents?.length) {
                    const formData = new FormData();
                    let isFile = false
                    await basicPropertyData.documents.forEach((f) => {
                        if (!f?.id) {
                            formData.append('files', f)
                            isFile = true
                        } else {
                            documentExistIds.push(f.id)
                        }
                    })

                    if (isFile) {
                        formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY)
                        formData.append('max_items', 10)
                        const result = await uploadDocuments(formData)
                        if (result.success) {
                            const { response } = result
                            const results = [...response.data.data.documents.map((doc) => doc.id)]
                            setDocumentUploadIds(documentExistIds?.length > 0 ? [...documentExistIds, ...results] : results)
                            documentUpload = documentExistIds?.length > 0 ? [...documentExistIds, ...results] : results
                        } else {
                            setIsLoading(false);
                            return false;
                        }
                    } else if (documentExistIds?.length > 0) {
                        documentUpload = documentExistIds
                    }
                }

                payload.floors = floors
                const response = await updateProperty(propertyId, {
                    ...payload,
                    default_image_id: default_image_id ? default_image_id : defaultImageId,
                    ...((documentUploadIds.length > 0 || documentUpload) && {
                        documents: documentUploadIds.length > 0 ? documentUploadIds : documentUpload
                    }),
                    ...((pictureUploadIds.length > 0 || pictureUpload) && {
                        pictures: pictureUploadIds.length > 0 ? pictureUploadIds : pictureUpload
                    })
                });

                if (response?.data?.data) {
                    const res = (await getPropertyDetails(parseInt(response?.data?.data?.id))).data.data
                    let isChecked = false
                    await res?.floors?.forEach((floor, index) => {
                        if (floor.name === floors[index].name) {
                            if (floor?.flats?.length > 0) {
                                floor?.flats.forEach((flat, flatIndex) => {
                                    if (flat?.name === floors[index]?.flats[flatIndex]?.name) {
                                        flat.rooms?.forEach(async (room, roomIndex) => {
                                            if (room.name === floors[index]?.flats[flatIndex]?.rooms[roomIndex]?.name) {
                                                try {
                                                    isChecked = true
                                                    const formData = new FormData();
                                                    let isFile = false
                                                    floors[index]?.flats[flatIndex]?.rooms[roomIndex]?.documents?.forEach((f, ind) => {
                                                        if (room.documents[ind]?.name !== f?.name) {
                                                            formData.append('files', f)
                                                            isFile = true
                                                        }
                                                    })
                                                    if (isFile) {
                                                        formData.append('max_items', 10)
                                                        formData.append('moduleId', room.id)
                                                        formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                        await uploadDocumentsForModuleWise(formData)
                                                        isAttchament = false
                                                    }
                                                } catch (error) {
                                                    console.error(error)
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (floor?.rooms?.length > 0) {
                                floor.rooms?.forEach(async (room, roomIndex) => {
                                    isChecked = true
                                    if (room.name === floors[index]?.rooms[roomIndex].name && floors[index]?.rooms[roomIndex].documents?.length > 0) {
                                        try {
                                            const formData = new FormData();
                                            let isFile = false;
                                            floors[index]?.rooms[roomIndex].documents?.forEach((f, ind) => {
                                                if (room.documents[ind]?.name !== f?.name) {
                                                    formData.append('files', f)
                                                    isFile = true
                                                }
                                            })

                                            if (isFile) {
                                                formData.append('max_items', 10)
                                                formData.append('moduleId', room.id)
                                                formData.append('module', DOCUMENT_HASH_BASE_KEYS.ROOM)
                                                await uploadDocumentsForModuleWise(formData)
                                                isAttchament = false
                                            }
                                        } catch (error) {
                                            console.error(error)
                                        }
                                    }
                                })
                            }
                        }

                        if (index === (floors.length - 1) && !isAttchament && isChecked) {
                            toast.success(response.data.message);
                            navigate("/active-properties/active-properties-live")
                            setIsLoading(false)
                            setIsConfirm(false)
                            dispatch(removePropertyDetails())
                        }
                    })

                    if (isAttchament && isChecked) {
                        toast.success(response.data.message);
                        navigate("/active-properties/active-properties-live")
                        setIsLoading(false)
                        setIsConfirm(false)
                        dispatch(removePropertyDetails())
                    }
                }
            } else {
                toast.warning('Please enter all required field')
                setIsConfirm(false)
            }

        } catch (error) {
            if (error.response?.data?.message) toast.error(error.response?.data?.message);
            else toast.error(error.message);
        } finally {
            setIsLoading(false);
            setIsConfirm(false);
            setIsDisbaled(false);
        }
    };


    useEffect(() => {
        if ((roomData?.length > 0 && bedData?.length > 0 && roomData?.length === bedData.length) || roomData?.length !== floorData?.length) {
            setIsDisbaled(true)
        }
        else if (!(roomData?.length === floorData?.length && roomData?.length === bedData?.length)) {
            setIsDisbaled(false)
        }
    }, [roomData, bedData, floorData, flatData])

    return (
        <div className="page-content addActiveProperty">
            <div className="container-fluid">
                <div className="HrAfterRow d-flex align-items-center">
                    <div className="w-50">
                        <div className="page-title-box d-flex align-items-center pb-0">
                            <ArrowBackOutlinedIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleBackPage} />
                            <h4 className="mb-0"> {props.action === ACTIONS.EDIT ? 'Edit Active Property' : 'Add Active Property'} </h4>
                        </div>
                    </div>
                    <div className="w-50" style={{ textAlign: "right" }}>
                        {activeStep === 1 ? (
                            <div>
                                <Button className="me-2 addPropertyNextBtn" onClick={handleBack} >
                                    Back
                                </Button>
                                <Button className="yellow_gradient_btn addPropertyNextBtn" onClick={() => setIsConfirm(true)} disabled={isLoading || !isDisabled}>
                                    Save
                                </Button>
                            </div>
                        ) : (
                                <Button className="yellow_gradient_btn me-2 addPropertyNextBtn" disabled={isDisabled} key={update} onClick={handleNext}>
                                Next
                            </Button>
                        )}
                    </div>
                </div>
                <Box className="ActivePropertyStepper" sx={{ width: "500px", margin: '20px auto 0px' }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {/* <StepContent> */}
                <div className="step-content">
                    {activeStep === 0 && (
                        <ActivePropertiesBasic emitBasicActiveData={(data, valid, selectedOption) => {
                            setIsSingleOwner(selectedOption === 'single')
                            setBasicPropertyData(data)
                            setIsDisbaled(!valid)
                            setIsBack(false)
                            setUpdate(update + 1)
                        }} backData={isBack ? basicPropertyData : null} propertyData={propertyData} />
                    )}
                    {activeStep === 1 && (
                        <ActivePropertiesBedMetrix
                            key={update}
                            basicPropertyData={basicPropertyData}
                            emitPropertyData={fetchAllPropertyData}
                            changeBasicData={handleSelectedOption}
                            propertyData={propertyData} />
                    )}
                </div>
                {/* </StepContent> */}
            </div>

            {isConfirm && (
                <ConfirmationModal
                    show={isConfirm}
                    action={props.action === ACTIONS.EDIT ? props.action : ACTIONS.CREATE}
                    onAcceptClick={() => {
                        props.action === ACTIONS.EDIT ? handleUpdate() : handleCreate()
                        return false;
                    }}
                    onCloseClick={() => setIsConfirm(false)}
                    disabled={isLoading}
                />
            )}
        </div>
    );
}
