import React, { useEffect, useState } from 'react';
import { Thead, Tr, Th } from 'react-super-responsive-table';
import { Input } from 'reactstrap';
import { usePermissionGiven } from '../Hooks/UserPermission';
import { ALL_MODULES, OPERATION_TYPE } from '../../common/constant';

export default function TableHeaders({
  headers,
  sortBy,
  emitSortedColumn,
  module = '',
  isAllRowsSelected = false,
  shouldACtionDisplay = true,
  emitAllRowSelect = () => {},
}) {
  const [columns, setColumns] = useState([]);
  const [allSelect, setAllSelect] = useState(false);

  useEffect(() => {
    setAllSelect(isAllRowsSelected);
  }, [isAllRowsSelected]);

  useEffect(() => {
    const updatedColumns = headers.map((column) => {
      return column.key_name === sortBy?.order
        ? {
            ...column,
            sort_direction: sortBy.direction,
          }
        : column;
    });
    setColumns(updatedColumns);
  }, [headers, sortBy]);

  const handleSorting = (column) => {
    const updatedColumns = columns.map((c) => {
      if (c.column_name === column.column_name) {
        let sortDirection =
          c?.sort_direction === 'asc'
            ? 'desc'
            : c?.sort_direction === 'desc'
            ? null
            : 'asc';
        emitSortedColumn({ order: column.key_name, direction: sortDirection });
        return { ...c, sort_direction: sortDirection };
      }
      return c;
    });
    setColumns(updatedColumns);
  };
  return (
    <Thead>
      <Tr>
        {columns
          .filter((column) => !column.column_name && !column.key_name)
          .map((item, index) => (
            <Th style={item.style} key={index}>
              <Input
                type='checkbox'
                className='form-check-input'
                id='formrow-customCheck'
                checked={allSelect}
                onClick={() => {
                  setAllSelect((select) => !select);
                  emitAllRowSelect(!allSelect);
                }}
              />
            </Th>
          ))}
        <Th style={{ minWidth: '70px' }}>
          <span className='table_heading_holder'>Sl No.</span>
        </Th>
        {columns
          ?.filter((column) => column.column_name && column.key_name)
          .map((item, index) => (
            <Th key={index} style={item.style}>
              <span className='table_heading_holder'>
                {item.column_name}
                {item.sort_enabled && (
                  <div
                    onClick={() => handleSorting(item)}
                    style={{ cursor: 'pointer' }}>
                    {item.sort_direction === 'asc' && (
                      <i className='fas fas fa-caret-up' />
                    )}
                    {item.sort_direction === 'desc' && (
                      <i className='fas fas fa-caret-down' />
                    )}
                    {!item.sort_direction && (
                      <>
                        <i className='fas fas fa-caret-up' />
                        <i className='fas fas fa-caret-down' />
                      </>
                    )}
                  </div>
                )}
              </span>
            </Th>
          ))}
        {shouldACtionDisplay &&
          ([
            ALL_MODULES.ADD_PROPERTY_REQUESTS,
            ALL_MODULES.NOTIFICATION,
          ].includes(module)
            ? usePermissionGiven(module, OPERATION_TYPE.READ) ||
              usePermissionGiven(module, OPERATION_TYPE.UPDATE) ||
              usePermissionGiven(module, OPERATION_TYPE.DELETE)
            : usePermissionGiven(module, OPERATION_TYPE.UPDATE) ||
            usePermissionGiven(module, OPERATION_TYPE.DELETE) ||
            usePermissionGiven(module, OPERATION_TYPE.PAYOUT_PAYMENT) ||
            usePermissionGiven(module, OPERATION_TYPE.DEPOSIT_PAYMENT)
          ) && (
            <Th style={{ minWidth: '80px' }}>
              <span className='table_heading_holder'>Action</span>
            </Th>
          )}
      </Tr>
    </Thead>
  );
}
