import React from 'react';
import {
  Modal,
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

function ViewUsers({ isOpen, toggleDrawer, users }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleDrawer}
      centered
      className="table_offcanvas_holder view_table_holder"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">View Users</h5>
        <button
          type="button"
          onClick={toggleDrawer}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="table-rep-plugin">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table
              id="tech-companies-1"
              className="table"
            >
              <Thead>
                <Tr>
                  <Th style={{ width: '100px' }}>Sl. No.</Th>
                  <Th>Users</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  users.map((_user, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>
                        <img src={_user.profile_photo} className="rounded-circle avatar-sm" alt="" />
                        <span className="ps-2">{_user.name}</span>
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewUsers;
