import React, { useState, useEffect } from "react";
import FloorBedMatrix from "./floorBedMatrix";

function BedMatrixHome({ propertyDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);

    useEffect(() => {
        if (propertyDetails) {
            const floorData = propertyDetails?.floors?.map((floor) => {
                let sortedRoom = []
                if (floor?.rooms?.length > 0) {
                    sortedRoom = floor?.rooms.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
                }
                if (sortedRoom?.length > 0) {
                    return {
                        name: floor.name,
                        rooms: sortedRoom,
                        ...(floor?.user !== null ? {
                            user: floor.user
                        } : {
                            user: propertyDetails.user
                        })
                    }
                }
            })

            let sortedFloor = []
            if (floorData?.length > 0) {
                sortedFloor = floorData.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }
            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div className="bedMatrixPg">
            <div className="activeProperties activePropertyBedmatrix activePropertyBedmatrixHome">
                <div className="bm_wrapper">
                    <div className="bm_main_headcols d-flex">
                        <div className="bm_col_sn bm_col_sn_room">Floor</div>
                        <div className="bm_col bm_col_room"></div>
                    </div>
                    <div className="bm_body_cols_outer">
                        <div className="bm_body_cols d-flex flex-column">
                            {floorRowData.map((item, index) => (
                                <div className="bm_single_row d-flex" key={index}>
                                    <div className="bm__sn">{item?.name}</div>
                                    <div className="bm__flat"><FloorBedMatrix rooms={item} propertyStatus={propertyDetails.status} /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BedMatrixHome;
