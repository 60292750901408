export const taskStatusArray = [
    { name: "To do", value: "TO_DO" },
    { name: "In Progress", value: "IN_PROGRESS" },
    { name: "Done", value: "DONE" },
    { name: "Blocker", value: "BLOCKER" },
  ];
  export const defaultStatus = "To do";
  
  export const statusObject = {
    "To do":"TO_DO",
    "In Progress":"IN_PROGRESS",
    "Done":"DONE",
    "Blocker":"BLOCKER",
    "To Do": "TO_DO"
  };
  export const TASK_PROGRESS_STATUS={
    "To do":"To do",
    "In Progress":"In Progress",
    "Done":"Done",
    "Blocker":"Blocker",
    "IN_PROGRESS": "In Progress",
    "TO_DO":"To Do",
     DONE: "Done",
     BLOCKER: "Blocker",
  }
  export const STATUS_META_OBJECT = {
    to_do: "todoMeta",
    done: "doneMeta",
    blocker: "blockerMeta",
    in_progress: "inProgressMeta",
  };
  export const STATUS_TO_COLUMN ={
    "to do": "todoMeta",
    done: "doneMeta",
    blocker: "blockerMeta",
    "in progress": "inProgressMeta",
  }
  export const taskStatus = ["To Do", "In Progress", "Done", "Blocker"];
  export const priorityObject = {
    Low: "Low",
    Medium: "Medium",
    High: "High",
  };
  export function getInitials(name) {
    return name
      .toUpperCase()
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("");
  }
  
  export const flatenArray = (assignee) => {
    return assignee
      ?.map((obj) => {
        return obj?.users?.map((individual) => ({
          name: individual?.name,
          email: individual?.email,
          id: individual?.id,
        }));
      })
      .flat(1);
  };
  
  export const removeDuplicates = (arr) => {
    let countMap = {};
    arr.forEach((item) => {
      countMap[item] = (countMap[item] || 0) + 1;
    });
  
    let uniqueArray = arr.filter((item) => countMap[item] === 1);
  
    return uniqueArray;
  };
  