import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import ReactQuill from 'react-quill';
import {
  getMembersHandler,
  getPropertyHandler,
  sendMessageHandler,
} from '../../api/Message/api';
import uuid from 'react-native-uuid';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  createTheme,
  styled,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { AsterikLabel } from '../Common/AsterikLabel';
import { formatBytes } from '../../helpers/string_helper';
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS } from '../../common/constant';
import { uploadDocuments } from '../../api/Tenants/api';
import ConfirmationModal from '../Common/ConfirmationModal';
import FileList from '../Common/FileList';
import MultipleSelectSearchCheckbox from './MultipleSelectSearchCheckbox';

export default function CreateNewMessageDrawer({
  isOpen,
  toggleDrawer,
  refresh,
}) {
  const [editorHtml, setEditorHtml] = useState('');
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMessage] = useState(false);

  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });

  const handleChange = (html) => {
    setEditorHtml(html);
    formik.setFieldValue('body', html);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  const messageSchema = {
    properties: [],
    subject: '',
    body: '',
    documents: [],
    total_documents_size: '',
  };

  const messageValidationSchema = Yup.object().shape({
    properties: Yup.array(),
    subject: Yup.string().required("Please enter message's subject"),
    body: Yup.string().required('Please enter message'),
    documents: Yup.array()
      .max(4, 'You are only allowed to have 4 documents here.')
      .optional(),
    total_documents_size: Yup.number()
      .positive()
      .when('documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const removeDuplicateObjects = (arr) => {
    if (Array.isArray(arr))
      return arr.filter(
        // Removing duplicate entries
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name,
          ) && value,
      );
  };

  let isSuccess = null;
  const submitHandler = async (data) => {
    setIsLoading(true);
    let documents;
    const filesUnchanged =
      formik.values?.documents?.filter((file) => file?.id) ?? [];
    if (filesUnchanged.length > 0) {
      documents = [...filesUnchanged.map((sd) => sd.id)];
    }
    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
    if (filesToBeUploaded.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f);
      });
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.MESSAGE);
      formData.append('max_items', 4);

      const result = await uploadDocuments(formData);
      if (result.success) {
        const { response } = result;
        if (documents !== undefined) {
          documents = [
            ...documents,
            ...response.data.data.documents.map((doc) => doc.id),
          ];
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)];
        }
      } else {
        return false;
      }
    }
    const propertyPayload = selectedProperties.map((property) => ({
      id: property?.id,
      tenants: property?.tenants
        .filter((t) => selectedTenants.includes(t))
        .map((t) => t.id),
      owners: removeDuplicateObjects(
        property?.owners?.filter((o) =>
          selectedOwners.map((so) => so.id).includes(o.id),
        ),
      ).map((o) => o.id),
    }));
    isSuccess = await sendMessageHandler({
      subject: data.subject,
      body: data.body,
      ...(documents === undefined ? {} : { documents: documents }),
      properties: propertyPayload,
    });

    setIsLoading(false);
    return isSuccess;
  };
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: messageSchema,
    validationSchema: messageValidationSchema,
    validateOnMount: true,
    onSubmit: async (data) => {
      setCreateConfirm({ status: true, data });
    },
  });

  useEffect(() => {
    getPropertyHandler(setProperties);
  }, []);

  const handleClose = () => {
    toggleDrawer(!isOpen);
    formik.resetForm();
    setSelectedProperties([]);
    setSelectedOwners([]);
    setSelectedTenants([]);
  };

  function handleAcceptedFiles(event) {
    const files = Array.from(event.target.files);

    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik
      .setValues(
        {
          ...formik.values,
          documents: [...selectedFiles, ...files],
          total_documents_size: [...selectedFiles, ...files].reduce(
            (p, c) => p + c.size,
            0,
          ),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files];
        });
      });
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId),
    );
    const tempValues = {
      ...formik.values,
    };

    if (newDocuments.length === 0) {
      delete tempValues.documents;
      delete tempValues.total_documents_size;
    }
    formik
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            documents: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) =>
                  !(i === fileIndex && doc.uniqueId === file.uniqueId),
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true,
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId),
          ),
        );
      });

    return true;
  }

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }

  const retriveData = (userType) => {
    const uniqueUsers = removeDuplicateObjects(
      selectedProperties.flatMap((property) => property[userType]),
    ); // Fetcing all users respective to userType
    return uniqueUsers;
  };

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => {
        formik.resetForm();
        toggleDrawer(!isAddMessage);
      }}>
      <OffcanvasHeader toggle={toggleDrawer}>Create Message</OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          <div className='write_msg'>
            {/* Select Properties */}
            <div className='col-sm-12 mb-2'>
              <MultipleSelectSearchCheckbox
                label={
                  <div>
                    Select Properties <AsterikLabel />{' '}
                  </div>
                }
                options={properties}
                isDisable={isLoading}
                emitItem={async (propertyList) => {
                  if (propertyList?.length > 0)
                    setSelectedProperties(
                      await getMembersHandler({
                        properties: propertyList,
                      }),
                    );
                }}
              />
            </div>

            {/* Select Owners */}
            <div className='col-sm-12 mb-2'>
              <MultipleSelectSearchCheckbox
                label={'Select Owners'}
                options={retriveData('owners')}
                emitItem={(owners) => {
                  if (owners?.length > 0) setSelectedOwners(owners);
                  formik.setFieldValue('owners', owners);
                }}
                isDisable={isLoading || !selectedProperties.length > 0}
              // selectedIds={[10,7]}
              />
            </div>

            {/* Select Tenants */}
            <div className='col-sm-12 mb-2'>
              <MultipleSelectSearchCheckbox
                label={'Select Tenants'}
                options={retriveData('tenants')}
                isDisable={isLoading || !selectedProperties.length > 0}
                emitItem={(tenants) => {
                  if (tenants?.length > 0) setSelectedTenants(tenants);
                  formik.setFieldValue('tenants', tenants);
                }}
              />
            </div>

            {/* Subject */}
            <div className='col-sm-12 mb-2'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  disabled={isLoading}
                  label='Subject'
                  sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    'font-family': 'Roboto', // "Helvetica", "Arial", sans-serif,
                    'font-weight': 400,
                    'font-size': '1rem',
                    'line-height': '1.4375em',
                    'letter-spacing': '0.00938em',
                  }}
                  name='subject'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.subject || ''}
                  error={
                    !!(formik?.touched?.subject && formik?.errors?.subject)
                  }
                  helperText={
                    formik?.touched?.subject && formik?.errors?.subject
                      ? formik?.errors?.subject
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Body */}
            <div className='col-sm-12 mb-3'>
              <label>
                Message <AsterikLabel />
              </label>
              <ReactQuill
                value={editorHtml}
                disabled={isLoading}
                onChange={handleChange}
                placeholder='Enter your message'
                className='mb-2'
                required
              />
            </div>

            {/* Documents */}
            <div className='uploadDocumentHeader uploadPictureHeader mb-2'>
              <div className='me-2'>Attach File</div>
              <div className='col-12 mb-3'>
                {(formik.errors.documents ||
                  formik.errors.total_documents_size) && (
                    <ul>
                      {formik.errors.documents && (
                        <li className='text-danger'>{formik.errors.documents}</li>
                      )}
                      {formik.errors.total_documents_size && (
                        <li className='text-danger'>
                          {formik.errors.total_documents_size}
                        </li>
                      )}
                    </ul>
                  )}
              </div>

              <div className='browse_attachment_btn position-relative'>
                <Button
                  component='label'
                  role={undefined}
                  variant='contained'
                  size='small'
                  tabIndex={-1}
                  style={{
                    background: '#C2C9D2',
                    color: '#5F6872',
                    border: 'unset',
                  }}
                // startIcon={<CloudUploadIcon />}
                >
                  Browse File
                  <VisuallyHiddenInput
                    type='file'
                    multiple
                    onChange={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  />
                </Button>
                {selectedFiles.length > 0 && (
                  <FileList
                    files={selectedFiles}
                    confirmFileRemoval={confirmFileRemoval}
                  />
                )}
              </div>
            </div>

            <div
              style={{
                textAlign: 'right',
                borderTop: '1px solid #ddd',
                paddingTop: '15px',
              }}>
              <button
                className='yellow_gradient_btn btn btn-secondary'
                type='submit'
                disabled={!formik.isValid || isLoading}
                style={{ width: '97px', height: '44px' }}>
                Send
              </button>
            </div>
          </div>
        </Form>

        {/* Create confirmation modal */}
        {isCreateConfirm?.status && (
          <ConfirmationModal
            action={ACTIONS.CREATE}
            show={isCreateConfirm?.status}
            onCloseClick={() => setCreateConfirm({ status: false, data: null })}
            onAcceptClick={async () => {
              const isCreated = await submitHandler(isCreateConfirm?.data);
              if (isCreated && isSuccess) {
                await refresh();
                handleClose();
              }
              setCreateConfirm({ status: false, id: null });
            }}
            isDisabled={isLoading}
          />
        )}

        {/* Document Delete confirmation modal */}
        {isDocumentDeleteConfirm?.status && (
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
}
