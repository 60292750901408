import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import uuid from 'react-native-uuid';
import { MultipleSelectDropDownListPaginationComponent } from "../Common/MultipleSelectDropDownPaginationList";
import { fetchAssigneeData } from "../../api/kanban";
import { Autocomplete, Box, TextField } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ConfirmationModal from "../Common/ConfirmationModal";
import Dropzone from "react-dropzone";
import FileList from "../Common/FileList";
import { DatePicker } from "@mui/x-date-pickers";
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS } from "../../common/constant";
import { formatBytes } from "../../helpers/string_helper";
import { uploadDocuments } from "../../api/compay-employee";
import { priorityObject } from "../../constants/kanban_helper";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputLabel } from "@mui/material";

function AddKanBanView({
  isOpen,
  toggleDrawer,
  createTaskFunction,
  isLoading,
  setIsLoading
}) {
  const [isAddNonActiveProperty] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [date, setDate] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  useEffect(() => {
    return () => validation.resetForm();
  }, [isOpen]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      status: "To Do",
      priority: "",
      assignee_id: [],
      note: "",
      attachments: [],
      due_date: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Task Name"),
      description: Yup.string().required("Please Enter Task Description"),
      priority: Yup.string().required("Please Enter Task Priority"),
      assignee_id: Yup.array().required("Please Enter Task Assignee"),
      attachments: Yup.array().max(10, 'You are only allowed to have more than 10 documents here.').optional(),
      total_documents_size: Yup.number().positive().when('attachments', {
        is: (docs) => docs?.length > 0,
        then: (schema) => schema.min(2, 'The documents size must be greater than 1 Byte.').max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.').required(),
        otherwise: (schema) => schema.optional()
      })
    }),
    onSubmit: async (payload) => {

      const { due_date, total_documents_size, ...rest } = payload;
      setIsLoading(true);
      let documents = [];
      const filesUnchanged = validation.values?.attachments?.filter((file) => file?.id) ?? []
      if (filesUnchanged.length > 0) {
        documents = [...filesUnchanged.map(sd => sd.id)]
      }
      const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
      if (filesToBeUploaded.length > 0) {
        const formData = new FormData();
        filesToBeUploaded.forEach((f) => {
          formData.append('files', f)
        })
        formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.TASK)

        formData.append('max_items', 10)

        const result = await uploadDocuments(formData)
        if (result.success) {
          const { response } = result
          if (documents !== undefined) {
            documents = [...documents, ...response.data.data.documents.map((doc) => doc.id)]
          } else {
            documents = [...response.data.data.documents.map((doc) => doc.id)]
          }
        } else {
          setIsLoading(false);
          return false;
        }
      }


      const payloadData = {
        ...rest,
        ...(due_date && { due_date: validation.values.due_date }),
        attachments: [...documents],
      };
      await createTaskFunction(payloadData);
      setConfirmation(false);
      setDate(null);
    },
  });

  function changeDate(e) {
    validation.setFieldValue("due_date", e);
    setDate(e);
  }
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    validation.setValues({
      ...validation.values,
      attachments: [...selectedFiles, ...files],
      total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
    }, true).then(() => {
      setselectedFiles((prev) => {
        return [...prev, ...files]
      })
    });
  }

  const addAssignee = (e) => {
    let newAttachmentData = [...e];
    validation.setFieldValue("assignee_id", newAttachmentData);
  };
  const isValid = () => {
    return (!validation?.values?.name || !validation?.values?.description || !validation?.values?.priority)
  }
  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex }
    })
  }

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isAddNonActiveProperty)}
    >
      <OffcanvasHeader
        toggle={() => {
          toggleDrawer();
          validation.resetForm();
        }}
      >
        Add Task
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setConfirmation(true);
            return false;
          }}
        >
          <Row>
            <div className="col-sm-6 mb-3">
              <ThemeProvider theme={theme}>
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  placeholder="Type Name"
                  label="Name"
                  name="name"
                  disabled={isLoading}
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  error={!!(validation.touched?.name && validation.errors?.name)}
                  helperText={
                    validation.touched?.name && validation.errors?.name
                      ? validation.errors?.name
                      : ""
                  }
                  required
                />
              </ThemeProvider>
            </div>
            <div className="col-sm-6 mb-3">
              <Autocomplete
                value={validation.values.priority}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  validation.setFieldValue(
                    "priority",
                    priorityObject[newValue]
                  );
                }}
                onBlur={() => {
                  if (!validation.touched?.priority) {
                    validation.setFieldTouched("priority", true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(priorityObject)}
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                    <TextField
                      {...params}
                      error={
                        validation.touched.priority && validation.errors.priority
                      }
                      label="Select Task Priority"
                      size="small"
                      required
                    />
                  </ThemeProvider>
                )}
              />
              {validation.touched.priority && validation.errors.priority ? (
                <span className="text-danger-formik">
                  {validation.errors.priority}
                </span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3">
              <MultipleSelectDropDownListPaginationComponent
                fullWidth
                disabled={isLoading}
                label="Select Assignees"
                searchEnabled={true}
                listCall={fetchAssigneeData}
                apiParams={{ limit: 10 }}
                emitItem={(users) => {
                  const data = users?.map((e) => e?.id);
                  addAssignee(data);
                }}
              />
            </div>
            <div
              className="col-sm-6 mb-3"
              style={{
                marginTop: "-8px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    id="example-datetime-local-input"
                    slotProps={{ textField: { size: "small" } }}
                    referenceDate={dayjs(new Date())}
                    label="Select Due Date"
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    value={date}
                    onChange={changeDate}
                    sx={{width: '100%'}}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="col-12 mb-3">
            <InputLabel htmlFor="txtAddress">Description <span style={{ color: 'red' }}>*</span></InputLabel>
              <BaseTextareaAutosize
                id="txtAddress"
                name="description"
                type="textarea"
                disabled={isLoading}
                className={
                  "form-control" +
                  (validation.touched.description &&
                    validation.errors.description
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter your Description"
                value={validation.values.description}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                minRows={2}
              />
              <span className="text-danger-formik">
                {validation.touched?.description &&
                  validation.errors?.description
                  ? validation.errors?.description
                  : ""}
              </span>
            </div>
            <div className="col-12 mb-3">
              <TextField
                fullWidth
                id="outlined-multiline-static txtAddress"
                label="Note"
                name="note"
                multiline
                rows="2"
                disabled={isLoading}
                className="form-control"
                onChange={validation.handleChange}
                invalid={!!(validation.touched.note && validation.errors.note)}
              />
            </div>
            <div className='col-12 mb-3'>
              {
                (validation.errors.attachments || validation.errors.total_documents_size) &&
                <ul>
                  {
                    validation.errors.attachments && <li className='text-danger'>{validation.errors.attachments}</li>
                  }
                  {
                    validation.errors.total_documents_size && <li className='text-danger'>{validation.errors.total_documents_size}</li>
                  }
                </ul>

              }
            </div>
            <div className='col-12 mb-3'>
              <label>Upload Documents</label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>

              {
                selectedFiles.length > 0 && (
                  <FileList files={selectedFiles} confirmFileRemoval={confirmFileRemoval} />
                )
              }

            </div>
          </Row>
          <div className="formActions btn_right mt-3">
            <Button
              disabled={isValid() || isLoading}
              className={
                !isLoading ? "yellow_gradient_btn" : "fade_yellow_gradient_btn"
              }
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
        {confirmation && (
          <ConfirmationModal
            action={ACTIONS.CREATE}
            show={confirmation}
            onAcceptClick={() => {
              validation.handleSubmit();
              return false;
            }}
            onCloseClick={() => setConfirmation(false)}
            isDisabled={isLoading}
          />
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default AddKanBanView;
