import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "reactstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TextField from "@mui/material/TextField";
import { ACTIONS } from "../../common/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

export default function AddNote({
  isAddNoteOpen,
  isAddNotetoggle,
  ids,
  setNotes,
  value,
  add_title,
  isDisable,
  addNote,
  action,
}) {
  const submitNote = () => {
    addNote(true);
    isAddNotetoggle();
  };
  return (
    <div>
      <Modal
        open={isAddNoteOpen}
        onClose={() => {
          isAddNotetoggle();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="amenityCustomBox">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>{add_title}</h5>
            <CancelOutlinedIcon
              onClick={() => {
                isAddNotetoggle();
              }}
              className="cursor-pointer"
            />
          </div>

          <div>
            <TextField
              id="outlined-multiline-static"
              label="Note"
              multiline
              fullWidth
              value={value ? value[ids] : ids}
              rows={4}
              className="mb-3"
              onChange={(e) => {
                if (action === ACTIONS.VIEW) {
                  e.preventDefault();
                } else {
                  setNotes((old) => ({
                    ...old,
                    [ids]: e?.target?.value,
                  }));
                }
              }}
            />
          </div>
          <div className="modal_footer text-center">
            {action === ACTIONS.CREATE ? (
              <Button
                onClick={isAddNotetoggle}
                disabled={isDisable}
                className="yellow_gradient_btn"
              >
                Save
              </Button>
            ) : action === ACTIONS.EDIT ? (
              <Button
                onClick={submitNote}
                disabled={isDisable}
                className="yellow_gradient_btn"
              >
                Submit
              </Button>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
