import { getParam } from '../helpers/getParam';
import axiosInstance from '../utils/axios/auth';

const getAllCompanyAssociates = async (params) => {
  return await axiosInstance.get('/company-associates', getParam(params));
};

const getCompanyAssociatesDetails = async (id) => {
  return await axiosInstance.get(`/company-associates/${id}`);
};

const getDashboardData = async () =>
  await axiosInstance.get('/dashboard/company-associates');

const createCompanyAssociates = (payload) =>
  axiosInstance.post('/company-associates', payload);

const updateCompanyAssociates = (id, payload) =>
  axiosInstance.put(`/company-associates/${id}`, payload);

const deleteCompanyAssociates = (id) =>
  axiosInstance.delete(`/company-associates/${id}`);

export {
  getAllCompanyAssociates,
  getDashboardData,
  getCompanyAssociatesDetails,
  createCompanyAssociates,
  updateCompanyAssociates,
  deleteCompanyAssociates,
};
