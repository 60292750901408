import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import uuid from "react-native-uuid";
import { getSpecificUser } from "../../api/user";
import { toast } from "react-toastify";
import FileUpload from "../../assets/images/icons/image-add-line.svg";
import FileList from "../Common/FileList";
import { formatBytes, getS3BaseUrl } from "../../helpers/string_helper";

function CompanyEmployeesDetailDrawer({ isOpen, toggleDrawer, userId }) {
  const [isCompanyEmployeesDetail] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [objectName, setObjectName] = useState("");
  const fetchEmployeeData = async () => {
    try {
      const response = await getSpecificUser(+userId);
      if (response?.status === 200) {
        setEmployeeData(response?.data?.data);
        setObjectName(response?.data?.data?.role?.role);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e.message);
    }
  };
  const s3BaseUrl = getS3BaseUrl()
  useEffect(() => {
    if (userId) {
      fetchEmployeeData();
    }
  }, [userId]);
  
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isCompanyEmployeesDetail)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        Company Employee Details
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="">
            <Row className="mb-3">
              <div
                className="image-placeholder"
                style={{
                  paddingBottom: "30px",
                }}
              >
                <img
                  height="80px"
                  width="80px"
                  src={employeeData?.profile_photo?`${s3BaseUrl}${employeeData?.profile_photo}`: FileUpload}
                  alt={"employees_photo"}
                />
              </div>
              <div className="col-sm-6 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Name: </div>
                <div className="tenantDetailDesc">{employeeData?.name}</div>
              </div>

              <div className="col-sm-6 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Designation/ Role: </div>
                <div className="tenantDetailDesc">{objectName}</div>
              </div>

              <div className="col-sm-6 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Date of joining: </div>
                <div className="tenantDetailDesc">
                  {employeeData?.employee?.joining_date
                    ? new Date(
                        employeeData?.employee?.joining_date
                      ).toLocaleDateString()
                    : "N/A"}{" "}
                </div>
              </div>

              <div className="col-sm-6 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Contact: </div>
                <div className="tenantDetailDesc">
                  {employeeData?.contact_number}
                </div>
              </div>
              <div className="col-sm-6 d-grid  custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Email: </div>
                <div style={{wordBreak: 'break-all'}}>{employeeData?.email||"N/A"}</div>
              </div>
              <div className="col-sm-6 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Salary: </div>
                <div className="tenantDetailDesc">
                  {employeeData?.employee?.yearly_ctc
                    ? employeeData?.employee?.yearly_ctc + "LPA"
                    : "N/A"}
                </div>
              </div>
              <div className="col-sm-12 d-grid custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">Residential Address: </div>
                <div className="tenantDetailDesc">{employeeData?.address||"N/A"}</div>
              </div>
              <div className="col-sm-12 d-grid  custom_border_bottom pb-3 mb-3 custom_grid_two_cols">
                <div className="tenantDetailTitle">
                  Additional Information:{" "}
                </div>
                <div className="tenantDetailDesc">
                  {employeeData?.employee?.additional_information||"N/A"}
                </div>
              </div>
              <Row className='mb-3'>
                <div className='col-sm-12 custom_border_bottom pb-3'>
                  <div className='tenantDetailTitle tenantDetFullTitle'>
                    Attachments :
                  </div>
                    {
                      employeeData?.attachments?.length > 0 && (
                        <FileList files={ employeeData?.attachments.map((doc) => ({...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size)}))} />
                      )
                    }
                </div>
              </Row>
            </Row>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default CompanyEmployeesDetailDrawer;
