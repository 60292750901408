import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";
const login = async (payload) => await axiosInstance.post('/auth/login', payload);
const getUser = async () => await axiosInstance.get('/user/me');
const recoverPassword = async (payload) => await axiosInstance.post('/auth/resend-token', payload);
const verifyOtp = async (payload) => await axiosInstance.post('/auth/verify-user', payload);
const forgetPasswordConfirm = async (payload) => await axiosInstance.post('/auth/forgot-password-confirm', payload)
const getAllUser = (params) => axiosInstance.get('user/fetch', getParam(params));
const getSpecificUser =(id)=> axiosInstance.get(`user/fetch/user-admin/${id}`);
const updateUser=(payload,id)=>axiosInstance.put(`/user/update/${id}`,payload);
const deleteUser = (id) => axiosInstance.put(`/user/delete/${id}`);
export {
    login,
    getUser,
    recoverPassword,
    verifyOtp,
    forgetPasswordConfirm,
    getAllUser,
    getSpecificUser,
    updateUser,
    deleteUser
}