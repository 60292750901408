import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Button } from 'reactstrap';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from "react-toastify";
import { createNearTo } from "../../api/property";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export default function NearToModal({ isNearToOpen, isNearTotoggle }) {
    const [nearToList, setNearToList] = useState([]);
    const [nearToValue, setNearToValue] = useState("")
    const [update, setUpdate] = useState(0)

    const handleAddNearTo = (event) => {
        if (event.key === "Enter") {
            if (!event.target.value?.trim()) {
                return toast.error("Please enter near to value")
            }

            const isNearToExist = nearToList?.filter((item) => item.toLowerCase() === event.target.value.toLowerCase())
            if (nearToList?.length > 0 && isNearToExist?.length > 0) {
                return toast.error("Please enter unique near to")
            } else {
                setNearToList((prev) => [...prev, event.target.value?.trim()])
                setNearToValue()
                setUpdate(update + 1)
            }
        }
    }

    const removeNearTo = (value) => {
        const updatedNearTo = nearToList.filter((item) => item !== value)
        setNearToList(updatedNearTo)
    }

    const createNearToData = async () => {
        try {
            const response = await createNearTo({ name: nearToList })
            if (response.status === 200) {
                toast.success(response.data.message);
                isNearTotoggle()
                setNearToList([])
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }


    return (
        <div>
            <Modal
                open={isNearToOpen}
                onClose={() => {
                    setNearToList([])
                    isNearTotoggle()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className="amenityCustomBox">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Near To</h5>
                        <CancelOutlinedIcon onClick={() => {
                            setNearToList([])
                            isNearTotoggle()
                        }} className="cursor-pointer" />
                    </div>
                    <div className="search-field-wrapper d-flex align-items-center mb-2">
                        <TextField
                            id="outlined-basic"
                            label="Create new tag by typing and clicking enter button"
                            variant="outlined"
                            className="w-100"
                            key={update}
                            value={nearToValue}
                            // InputProps={{
                            //     startAdornment: (
                            //         <InputAdornment position="start">
                            //             <SearchOutlinedIcon />
                            //         </InputAdornment>
                            //     ),
                            // }}
                            inputProps={{
                                onKeyPress: (event) => handleAddNearTo(event)
                            }}
                            onChange={(e) => {
                                setNearToValue(e.target.value)
                            }}
                        />
                    </div>


                    <ul className="amenties_list ps-0 mt-4" style={{ listStyle: 'none' }}>
                        {nearToList?.length > 0 && nearToList.map((item, index) => (
                            <li className="position-relative d-flex justify-content-between" key={index + 1} >
                                <div className="amenity_item">{item}</div>
                                <CancelOutlinedIcon className="cursor-pointer" onClick={() => removeNearTo(item)} />
                            </li>
                        ))}
                    </ul>

                    <div className="modal_footer text-center">
                        <Button className="yellow_gradient_btn" onClick={createNearToData} disabled={nearToList?.length === 0}>
                            Save
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
