import React, { useEffect, useState } from 'react';
import {
  Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { permissionListing } from '../../api/permission';
import { CircularProgress } from '@mui/material';
import { fetchRoleWithPermissions, toggleRolePermission } from '../../api/role';

function ViewRoles({ isOpen, toggleDrawer, role_id }) {
  const permissionActions = {
    read: { label: 'Read', order: 1},
    create: { label: 'Create', order: 2},
    edit: { label: 'Edit', order: 3},
    delete: { label: 'Delete', order: 4 },
    add_tenant: { label: 'Add Tenant', order: 5 },
    remove_tenant: { label: 'Remove Tenant', order: 6 },
    payout_payment: { label: 'Payout Payment', order: 7 },
    deposit_payment: { label: 'Deposit Payment', order: 8},
  }
  const [modulePermissionMeta, setModulePermissionMeta] = useState({
    modulePermissions: [],
    loading: false,
  })

  const fetchPermissions = async () => {
    try {
      setModulePermissionMeta((prev) => ({
        ...prev,
        loading: true
      }))
      const permissionsResponse = await permissionListing()
      const rolePermissionsResponse = await fetchRoleWithPermissions(role_id)
      let newModulePermissions = permissionsResponse.data.data
      const newRolePermissions = rolePermissionsResponse.data.data
      const permissionsActionsFinal = Object.values(permissionActions).sort((a,b) => a.order - b.order);
      newModulePermissions = newModulePermissions.map((module) => {
        const foundModule = newRolePermissions.module_permissions.find((_mp) => _mp.module === module.module)
        const permissionsFinal = module.permissions.map((permission) => {
          const foundPermissionAction = permissionsActionsFinal.find((_pa) =>_pa.label === permission.action)
          return {
            ...permission,
            order: foundPermissionAction?.order,
            check: foundModule ? !!foundModule.permissions.find((_fm) =>_fm.action === permission.action) : false
          }
        }).sort((a,b) => a.order - b.order);
        return {
          module: module.module,
          permissions: permissionsFinal
        }
      })
      setModulePermissionMeta(prev => ({
        ...prev,
        modulePermissions: newModulePermissions
      }))

    } catch (e) {
      console.log(e)
      toast.error(e?.response?.data?.message ?? 'Error: Could not load role permissions');
    } finally {
      setModulePermissionMeta((prev) => ({
        ...prev,
        loading: false
      }))
    }
  }

  const toggleSwitch = async(module, permission_id) =>  {
    try {
      setModulePermissionMeta((prev) => ({
        ...prev,
        loading: true
      }))
      const response = await  toggleRolePermission(role_id, permission_id)

      setModulePermissionMeta((prev) => {
        const foundModuleIndex = prev.modulePermissions.findIndex((_mp) => _mp.module === module)
        if (foundModuleIndex !== -1) {
          prev.modulePermissions[foundModuleIndex].permissions = prev.modulePermissions[foundModuleIndex].permissions.map((_p) => {
            if (_p.id === permission_id) {
              return {
                ..._p,
                check: !_p.check
              }
            }
            return {
              ..._p
            }
          })
        }

        return {
          ...prev,
        }
      })
      toast.success(response.data.message);
    } catch (e) {
      console.log(e)
      toast.error(e?.response?.data?.message ?? 'Error: Could not update role permission.');
    } finally {
      setModulePermissionMeta((prev) => ({
        ...prev,
        loading: false
      }))
    }
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  function Offsymbol() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        No
      </div>
    );
  }

  function OnSymbol() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 12,
          color: '#fff',
          paddingRight: 2,
        }}
      >
        {' '}
        Yes
      </div>
    );
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isOpen)}
      className="table_offcanvas_holder view_table_holder"
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        View Role {modulePermissionMeta.loading && <CircularProgress size={20} thickness={5} color='inherit' style={{ color: '#00000', textAlign: 'center', marginLeft: '5px' }}/>} 
      </OffcanvasHeader>
      <OffcanvasBody>
        <div className="table-rep-plugin">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table
              id="tech-companies-1"
              className="table"
            >
              <Thead>
                <Tr>
                  <Th>Administrator Access</Th>
                  {
                    Object.values(permissionActions).sort((a,b) => a.order - b.order).map(action => (
                      <Th key={action.order}>{action.label}</Th>
                    )) 
                  }
                </Tr>
              </Thead>
              <Tbody>
                {modulePermissionMeta.modulePermissions.map((modulePermission, index) => (
                  <Tr key={index}>
                    <Td key={`module-${index}`}>
                      {modulePermission.module}
                    </Td>
                    {
                      modulePermission.permissions.map((permission) => (
                        <Td key={`module-permission-${permission.id}`}>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() => {
                              toggleSwitch(modulePermission.module, permission.id)
                            }}
                            checked={permission.check}
                            disabled={modulePermissionMeta.loading}
                          />
                        </Td>
                      ))
                    }
                    {/* <Td>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={() => {
                          setswitch1(!switch1);
                        }}
                        checked={switch1}
                        disabled
                      />
                    </Td>
                    <Td>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={() => {
                          setswitch1(!switch1);
                        }}
                        checked={switch1}
                        disabled
                      />
                    </Td>
                    <Td>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={() => {
                          setswitch1(!switch1);
                        }}
                        checked={switch1}
                        disabled
                      />
                    </Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default ViewRoles;
