import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Button } from 'reactstrap';
import TextField from '@mui/material/TextField';

// Icons

import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import NestCamWiredStandOutlinedIcon from '@mui/icons-material/NestCamWiredStandOutlined';
import CellWifiOutlinedIcon from '@mui/icons-material/CellWifiOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { selectAmenitiesIcon } from "../../common/amenities_icon";
import { ucFirst } from "../../helpers/string_helper";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};

export default function AddAmenitiesModal({ open, handleClose, handleAddAmenities, selectedAmenities }) {
    const [AddedAmenitiesList, setAddedAmenitiesList] = useState([]);

    const [AmenitiesList, setAmenitiesList] = useState([
        {
            name: 'Fridge',
            icon: <KitchenOutlinedIcon />
        },
        {
            name: 'Washing Machine',
            icon: <LocalLaundryServiceOutlinedIcon />
        },
        {
            name: 'Water Purifier',
            icon: <CoffeeMakerOutlinedIcon />
        },
        {
            name: 'Lift',
            icon: <ElevatorOutlinedIcon />
        },
        {
            name: 'CCTV',
            icon: <NestCamWiredStandOutlinedIcon />
        },
        {
            name: 'Wifi',
            icon: <CellWifiOutlinedIcon />
        },
        {
            name: 'Daily Room Cleaning ',
            icon: <CleaningServicesOutlinedIcon />
        },
        {
            name: 'Daily Dustbin Cleaning ',
            icon: <SanitizerOutlinedIcon />
        },
        {
            name: 'Weekly Once Washroom Cleaning',
            icon: <ChairAltOutlinedIcon />
        },
        {
            name: 'Bed',
            icon: <BedOutlinedIcon />
        },
        {
            name: 'Almirah',
            icon: <DoorFrontOutlinedIcon />
        },
        {
            name: 'Center Table',
            icon: <TableBarOutlinedIcon />
        },
    ]);

    useEffect(() => {
        if (selectedAmenities && selectedAmenities?.length > 0) {
            setAddedAmenitiesList(selectedAmenities)
            let amenitiesNames = []
            selectedAmenities?.forEach((am) => {
                amenitiesNames.push(am.name?.toLowerCase())
            })
            const amenitiesList = AmenitiesList.filter((amen) => !amenitiesNames.includes(amen.name?.toLowerCase()))
            setAmenitiesList(amenitiesList)
        }
    }, [selectedAmenities])

    const handleAddAmenity = (itemName) => {
        const isAlreadyAdded = AddedAmenitiesList.some(item => item.name.toLowerCase() === itemName?.toLowerCase());
        if (!isAlreadyAdded) {
            const selectedItem = AmenitiesList.find(item => item.name.toLowerCase() === itemName?.toLowerCase());
            if (selectedItem) {
                setAddedAmenitiesList(prevList => [...prevList, selectedItem]);
                setAmenitiesList(prevList => prevList.filter(item => item.name?.toLowerCase() !== itemName?.toLowerCase()));
            }
        }
    };

    const handleRemoveAmenities = (itemName) => {
        const isAlreadyAdded = AddedAmenitiesList.some(item => item.name?.toLowerCase() === itemName.name?.toLowerCase());
        if (isAlreadyAdded) {
            const selectedItem = AddedAmenitiesList.filter(item => item.name?.toLowerCase() !== itemName.name?.toLowerCase());
            if (selectedItem) {
                const removeItem = AddedAmenitiesList.find(item => item.name?.toLowerCase() === itemName.name?.toLowerCase());
                setAddedAmenitiesList(selectedItem);
                setAmenitiesList(prevList => [...prevList, removeItem]);
            }
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const newValue = event.target.value.trim();
            if (newValue !== '') {
                const isAlreadyAdded = AddedAmenitiesList.some(item => {
                    if (item.name?.toLowerCase() === newValue?.toLowerCase()) {
                        return item
                    }
                });

                let updateValue
                const amenitiesList = AmenitiesList.filter((amen) => {
                    if (amen.name?.toLowerCase() === newValue?.toLowerCase()) {
                        updateValue = amen.name
                    } else {
                        return amen
                    }
                })
                setAmenitiesList(amenitiesList)
                if (!isAlreadyAdded) {
                    const newAmenity = {
                        name: updateValue ?? ucFirst(newValue),
                        icon: selectAmenitiesIcon(newValue)
                    };
                    setAddedAmenitiesList(prevList => [...prevList, newAmenity]);
                    event.target.value = ''
                }
            }
        }
    };

    const handleAdd = () => {
        handleClose()
        handleAddAmenities(AddedAmenitiesList)
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={style} className="amenityCustomBox">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Add Amenities</h5>
                    <CancelOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                </div>
                <div className="add_amenities_box" style={{ height: '400px', overflowY: 'auto'}}>
                    <ul className="custom_amenties_options d-flex flex-wrap ps-0" style={{ listStyle: 'none' }}>
                        {AddedAmenitiesList.map((item, index) => (
                            <li className="position-relative d-flex" key={index + 1}>
                                {item.icon} <div className="amenity_item">{item.name}</div>
                                <CancelOutlinedIcon className="cross_ico" onClick={() => handleRemoveAmenities(item)} />
                            </li>
                        ))}
                    </ul>
                    {/* <Autocomplete
                        multiple
                        freeSolo
                        options={options}
                        onChange={(event, newValue) => setOptions(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select or create options"
                                onChange={handleInputChange}
                            />
                        )}
                    /> */}
                    <div className="search-field-wrapper d-flex align-items-center mb-2">
                        <TextField 
                            id="outlined-basic" 
                            label="create new amenity" 
                            variant="outlined" 
                            className="w-100" 
                            onKeyPress={(e) => {
                                handleKeyPress(e)
                            }}
                        />
                    </div>


                    <ul className="amenties_list ps-0 mt-4" style={{ listStyle: 'none' }}>
                        {AmenitiesList.map((item, index) => (
                            <li className="position-relative d-flex" key={index + 1} onClick={() => handleAddAmenity(item.name)}>
                                {item.icon} <div className="amenity_item">{item.name}</div>
                            </li>
                        ))}
                    </ul>
                </div>
                <Divider style={{ backgroundColor: 'rgb(74 74 74)', margin: '15px 0' }} />
                <div className="modal_footer text-center">
                    <Button className="yellow_gradient_btn" onClick={handleAdd}>
                        Save
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}
