import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: {
    user: null,
  },
  resetPassword: {
    success: false,
    error: null,
    data: null,
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeUserDetails: (state, action) => {
      state.auth.user = action.payload.user;
    },
    removeUserDetails: (state) => {
      state.auth.user = null;
    },
  },
});

export const { storeUserDetails, removeUserDetails } = slice.actions;
export default slice.reducer;

