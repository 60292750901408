import { useSelector } from "react-redux";

const useProfile = () => {
  let { user } = useSelector((state) => {
    return {
      user: state?.AuthLogin?.auth?.user,
    };
  });

  const token = localStorage.getItem("token");

  return { user, token };
};

export { useProfile };
