import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row, Col, Card, CardBody, Button,
} from 'reactstrap';

import {
  Table, Tbody, Tr, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import TuneIcon from '@mui/icons-material/Tune';
import { deleteProperty, getAllProperties, updatePropertyStatus } from '../../api/property';
import AddressComponent from '../../components/Common/Address';
import { toast } from 'react-toastify';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { ACTIONS, ALL_MODULES, DEFAULT_PER_PAGE_VALUE, OPERATION_TYPE, PROPERTY_STATUS, PROPERTY_TYPES } from '../../common/constant';
import Search from '../../components/Common/GlobalSearch';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import TableHeaders from '../../components/Common/TableHeaders';
import PropertyFilter from './PropertyFilter';
import SelectBox from '../../components/ActiveProperties/SelectBox';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { grey } from '@mui/material/colors';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import NoDataIcon from "../../assets/images/no-data.png";

const Listbox = styled('ul')(
  ({ theme }) => `
 font-size: 16px;
 padding: 10px;
 margin: 12px 0;
 min-width: 150px;
 border-radius: 5px;
 outline: 0px;
 background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
 border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
    };
 color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
 box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark'
      ? 'rgba(0,0,0, 0.50)'
      : 'rgba(0,0,0, 0.05)'
    };
 z-index: 99;
 position: absolute;
 top: 100%;
 right: 0;
 list-style-type: none;
 `,
);
const ListboxItem = styled('li')(`padding: '10px'`);
const StyledMenuItem = styled(MenuItem)`
 &&{ padding: 10px;
 /* Add more styles as needed */}`;
function SuspendClosedProperty() {
  document.title = ' Suspended & Closed Properties | Dstayz';
  const navigate = useNavigate();
  const [isOpenPropertyFilterModal, setIsOpenPropertyFilterModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationMeta, setPaginationMeta] = useState({})
  const [propertyList, setPropertyList] = useState([])
  const [update, setUpdate] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE)
  const paramsObject = useRef({
    page: 1,
    limit: DEFAULT_PER_PAGE_VALUE,
    filter_fields: ['status'],
    filter_inputs: [`${PROPERTY_STATUS.CLOSED},${PROPERTY_STATUS.SUSPENDED}`]
  })
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.DELETE);
  const hasEditPermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.UPDATE);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });

  // request filter drawer
  const togglePropertyFilter = () => {
    setIsOpenPropertyFilterModal(!isOpenPropertyFilterModal);
  };

  const fetchActiveAndLiveProperties = async (params) => {
    try {
      setIsLoading(true)
      params = {
        ...params,
        filter_fields: params ? ((params.filter_fields && params.filter_fields.includes('status')) ? params.filter_fields : ['status', params.filter_fields]) : ['status'],
        filter_inputs: params ? ((params.filter_fields && params.filter_fields.includes('status')) ? params.filter_inputs : [`${PROPERTY_STATUS.CLOSED},${PROPERTY_STATUS.SUSPENDED}`, params.filter_inputs]) : [`${PROPERTY_STATUS.CLOSED},${PROPERTY_STATUS.SUSPENDED}`],
      }

      const response = (await getAllProperties(params)).data.data
      setPaginationMeta(response.meta)
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          id: res.id,
          index_no: ++level,
          name: res.name,
          address: res.address ? <AddressComponent address={res.address} /> : 'N/A',
          owner_name: res.user?.name ?? 'N/A',
          owner_contact_no: res.user?.contact_number ?? 'N/A',
          total_bed: res.bed_count ?? 0,
          status: res.status,
          near_to: res?.near_to ?? 'N/A',
          type: res.type
        };
      });
      setPropertyList(resultData)
    } catch (error) {
      return toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const deletePropertyHandler = async (id) => {
    try {
      const response = await deleteProperty(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };

  useEffect(() => {
    fetchActiveAndLiveProperties({ page: 1, limit: rowsPerPage })
  }, [])

  const handleStatusChange = async (item, status) => {
    try {
      const res = await updatePropertyStatus(item.id, { status: status.value })
      await fetchActiveAndLiveProperties(paramsObject.current)
      toast.success(res.data.message)
    } catch (e) {
      toast.error('Status update failed.')
    }
  }

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      paramsObject.current = {
        ...paramsObject.current,
        page: data.page,
        limit: rowsPerPage
      };
      await fetchActiveAndLiveProperties(paramsObject.current);
    } else {
      setUpdate(prev => prev + 1);
      setRowsPerPage(data?.limit);
      paramsObject.current = {
        ...paramsObject.current,
        page: 1,
        limit: data?.limit
      };
      await fetchActiveAndLiveProperties(paramsObject.current);
    }
  };

  const globalSearch = async (params) => {
    setPaginationMeta()
    setUpdate((pre) => pre + 1)
    if (!params.search || params.search === '') {
      delete paramsObject.current['search']
      delete params['search']
    }
    paramsObject.current = {
      ...paramsObject.current,
      ...params
    }
    await fetchActiveAndLiveProperties(paramsObject.current)
  };

  const handlePropertyFilter = async (params) => {
    paramsObject.current = { ...paramsObject.current, limit: rowsPerPage, page: 1, ...params }
    await fetchActiveAndLiveProperties(paramsObject.current)
  }

  const columns = [
    { column_name: 'Property Name', key_name: 'name', sort_enabled: true, style: { minWidth: '130px' } },
    { column_name: 'Address', key_name: 'address', sort_enabled: false, style: { minWidth: '125px' } },
    { column_name: 'Property Owner', key_name: 'owner_name', sort_enabled: true, style: { minWidth: '145px' } },
    { column_name: 'Owners Phone No.', key_name: 'owner_phone_number', sort_enabled: true, style: { minWidth: '170px' } },
    { column_name: 'Total Bed', key_name: 'bed_count', sort_enabled: true, style: { minWidth: '210px' } },
    { column_name: 'Near To', key_name: 'near_to', sort_enabled: false, style: { minWidth: '145px' } },
    { column_name: 'Status', key_name: 'status', sort_enabled: true }
  ]

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Suspended & Closed Property</h4>
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <div className="flexbox_holder">
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{ ...paramsObject.current, limit: rowsPerPage, page: 1, order: paramsObject.current.order, direction: paramsObject.current.direction }}
                  />
                </div>
                <div>
                  <Button className="btn grey_outline_btn me-2" onClick={togglePropertyFilter}>
                    <TuneIcon className="me-2 font-size-18" />
                    Filter
                  </Button>
                </div>
              </div>
              <Card className="table_card_holder mt-2">
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {isLoading && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {propertyList?.length === 0 && !isLoading && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                          <NodataFound text="No property to show." icon={NoDataIcon} />
                        </div>
                      )}
                      {propertyList?.length !== 0 && !isLoading && (
                        <Table
                          id="tech-companies-1"
                          className="table table-bordered activePropertiesListing"
                        >
                          <TableHeaders
                            headers={columns}
                            sortBy={{ order: paramsObject.current.order, direction: paramsObject.current.direction }}
                            emitSortedColumn={async (sort) => {
                              if (sort.direction) {
                                paramsObject.current = {
                                  ...paramsObject.current,
                                  ...sort
                                }
                              } else {
                                delete paramsObject.current['order']
                                delete paramsObject.current['direction']
                              }
                              await fetchActiveAndLiveProperties(paramsObject.current)
                            }}
                            module={ALL_MODULES.PROPERTIES}
                          />
                          <Tbody>
                            {propertyList && propertyList?.map((item, index) => (
                              <Tr key={index}>
                                <Td>{item.index_no}</Td>
                                <Td className="cursor_pointer" onClick={() => {
                                  navigate(`/active-properties/${item.id}/${item.type === PROPERTY_TYPES.APARTMENT ? 'bhk' : 'room'}`)
                                }
                                }>{item.name}</Td>
                                <Td>{item.address}</Td>
                                <Td>{item.owner_name}</Td>
                                <Td>{item.owner_contact_no}</Td>
                                <Td>{item.total_bed}</Td>
                                <Td>{item.near_to}</Td>
                                <Td>
                                  <SelectBox hasEditPermission={hasEditPermission} item={item} handleChange={handleStatusChange} />
                                  {/* Status */}
                                </Td>
                                <Td className="threeDotsSpace">
                                  <Dropdown>
                                    <MenuButton><i className='mdi mdi-dots-vertical font-size-15'></i></MenuButton>
                                    <Menu slots={{ listbox: Listbox }}>
                                      {hasDeletePermission &&
                                        (<StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() =>
                                          setDeleteConfirm({ status: true, id: item?.id })
                                        }><i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                          Delete
                                        </StyledMenuItem>)}
                                    </Menu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </div>
                  </div>

                  {propertyList?.length > 0 && (<div className="numbers_pagination_holder">
                    <PaginationComponent
                      paginationMeta={paginationMeta}
                      handleChangePagination={paginationModelMethod}
                      update={update}
                    />
                  </div>)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <PropertyFilter
        isOpen={isOpenPropertyFilterModal}
        toggleDrawer={togglePropertyFilter}
        emitFilterItem={(e) => {
          handlePropertyFilter(e)
        }}
      />

      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deletePropertyHandler(isDeleteConfirm.id);
            if (isDeleted) {
              fetchActiveAndLiveProperties();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}
    </>
  )
}
export default SuspendClosedProperty
