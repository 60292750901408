import React, { useState } from 'react';
import {
  Button,
  Collapse,
} from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function About() {
  const [about, setAbout] = useState([
    {
      isOpenCollapse: false,
      isVisible: false,
      isEdit: false,
      question: 'What is Lorem Ipsum ?',
      answer:
          'If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual',
    },
  ]);

  const toggleCollapse = (index) => {
    console.log('collapse');
    const newAbout = [...about];
    newAbout[index].isOpenCollapse = !newAbout[index].isOpenCollapse;
    newAbout[index].isEdit = false;
    setAbout(newAbout);
  };

  const handleAboutEdit = (index, event) => {
    console.log('testing', index, about[index]);
    event.stopPropagation();
    setAbout((prevAbout) => {
      prevAbout[index].isEdit = !prevAbout[index].isEdit;
      if (!prevAbout[index].isOpenCollapse) {
        prevAbout[index].isOpenCollapse = !prevAbout[index].isOpenCollapse;
      }
      return [...prevAbout];
    });
  };

  return (
    <form>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h4 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>About Us</h4>
        <div>
          <Button type="button" color="primary">
            Add Another
            {' '}
            <i className="mdi mdi-plus" />
          </Button>
        </div>
      </div>
      <div id="faqs-accordion" className="custom-accordion mt-5 mt-xl-0">
        <ul className="allFaq list-inline">
          {about.map((about, index) => (
            <li className="singleFaq mb-2 bg-body p-3" key={index}>
              <div
                className="text-dark"
                onClick={() => { toggleCollapse(index); }}
              >
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <input
                      onClickCapture={(e) => { e.preventDefault(); e.stopPropagation(); }}
                      className={`font-size-16 ${!about.isEdit ? 'input-bg-add' : 'mb-3 form-control'}`}
                      readOnly={!about.isEdit}
                      type="text"
                      placeholder="Enter Question"
                      value={about.question}
                      onChange={(e) => {
                        const newAbout = [...about];
                        newAbout[index].question = e.target.value;
                        setAbout(newAbout);
                      }}
                    />
                  </div>
                  <div className="delivarableIcons">
                    <i
                      className="mdi mdi-pencil font-size-16 ml-2"
                      onClick={(e) => { handleAboutEdit(index, e); }}
                    />
                    <i className="mdi mdi-chevron-up accor-down-icon font-size-16" />
                  </div>
                </div>
              </div>
              <Collapse
                isOpen={about.isOpenCollapse}
                id={`abouts-gen-ques-collapse-${index}`}
              >
                <div className="bg-body border-top">
                  <div className="d-flex align-items-start mt-2">
                    <div className="flex-grow-1 overflow-hidden">
                      {!about.isEdit ? (
                        <p className="text-muted">{about.answer}</p>
                      ) : (
                        <Editor
                          toolbarHidden={!about.isEdit}
                          wrapperClassName="answerEditor"
                          editorClassName="editorBody"
                          className={about.isEdit ? 'input-bg-add' : ''}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Collapse>
              <div className="text-end mt-4" style={{ display: !about.isEdit ? 'none' : '' }}>
                <Button type="button" className="yellow_gradient_btn mx-3"><span className="mdi mdi-close" onClick={(e) => { handleAboutEdit(index, e); }} /></Button>
                <Button type="button" color="primary"><span className="mdi mdi-check" onClick={(e) => { handleAboutEdit(index, e); }} /></Button>
              </div>
            </li>
          ))}
        </ul>
        <div className="text-end mt-4">
          <Button type="button" className="yellow_gradient_btn">Save</Button>
        </div>
      </div>
    </form>
  );
}

export default About;
