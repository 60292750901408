import moment from 'moment';
import React, { useState } from 'react';
import {
  Row, Form, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { DEFAULT_DATETIME_FORMAT } from '../../common/constant';
import ReactQuill from 'react-quill';

function DetailDrawer({ isOpen, toggleDrawer, details }) {
  const [isExistingNotification] = useState(false);
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isExistingNotification)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        {details?.subject}
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="tenantDetailsDrawer">
            <Row className="mb-3">
              <div className="col-sm-12 d-flex align-items-center custom_border_bottom pb-3">
                <div className="tenantDetailDesc">
                  <ReactQuill
                    value={details?.content}
                    readOnly={true}
                    theme={'bubble'}
                  />
                  </div>
              </div>
              <div className="col-sm-12">
                <div className="pro_person_mob">
                  <span className="mdi mdi-account theme_color font-size-md" />
                  <span className="pr_person_contact mx-2">{details.sender_role}: {details.sender} ({details?.sender_contact_number})</span>
                </div>
                <div className="pro_person_mob">
                  <span className="mdi mdi-update theme_color font-size-md" />
                  <span className="pr_person_contact mx-2">{moment(details.created_at).format(DEFAULT_DATETIME_FORMAT)}</span>
                </div>
              </div>
            </Row>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default DetailDrawer;
