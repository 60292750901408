import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { PropTypes } from 'prop-types';
import { TextField, Autocomplete, Box } from '@mui/material';
import { BOOKING_STATUS, TENANT_TYPES } from '../../common/constant';
import { handleKeyDown } from '../../helpers/string_helper';

function TenantFilter({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [isTenantFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({
    name: '',
    contact_number: '',
    type: '',
    booking_status: '',
    organization_name: '',
  });
  const filterInputs = [];
  const filterFields = [];

  const handleFilter = () => {
    if (filterObject.name) {
      filterFields.push('name');
      filterInputs.push(filterObject.name);
    }

    if (filterObject.contact_number) {
      filterFields.push('contact_number');
      filterInputs.push(filterObject.contact_number);
    }

    if (filterObject.type) {
      filterFields.push('type');
      filterInputs.push(filterObject.type);
    }

    if (filterObject.booking_status) {
      filterFields.push('booking_status');
      filterInputs.push(filterObject.booking_status);
    }

    if (filterObject.organization_name) {
      filterFields.push('organization_name');
      filterInputs.push(filterObject.organization_name);
    }
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const resetFilter = () => {
    filterInputs.splice(0, filterInputs.length)
    filterFields.splice(0, filterFields.length)
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
    setFilterObject({
      name: '',
      contact_number: '',
      type: '',
      booking_status: '',
      organization_name: '',
    });
  }

  useEffect(()=> {
    selectedFields.map((field, index) => {
      setFilterObject((prev)=> ({...prev, [field]:selectedInputs[index]}))
    })
  }, [])
  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => toggleDrawer(!isTenantFilter)}>
      <OffcanvasHeader toggle={toggleDrawer}>Tenant Filter</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className=''>
            <Row>
              {/* Name */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  name='name'
                  value={filterObject.name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>
              {/* Contact Number */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Contact Number'
                  name='contact_number'
                  value={filterObject.contact_number}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      contact_number: e.target.value,
                    });
                  }}
                  size='small'
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                />
              </div>
              {/* Tenant Type* */}
              <div className='col-sm-6 mb-3'>
                <Autocomplete
                  value={filterObject.type}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      type: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' key={option} {...props}>
                      {option}
                    </Box>
                  )}
                  options={Object.values(TENANT_TYPES)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Tenant Types'
                      size='small'
                    />
                  )}
                />
              </div>

              {/* Organization Name */}
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='College/ Office Name'
                  name='organization_name'
                  value={filterObject.organization_name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      organization_name: e.target.value,
                    });
                  }}
                  size='small'
                />
              </div>

              {/* Booking Status */}
              <div className='col-sm-6 mb-3'>
                <Autocomplete
                  value={filterObject.booking_status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      booking_status: newValue,
                    });
                  }}
                  renderOption={(props, option) => (
                    <Box component='li' key={option} {...props}>
                      {option}
                    </Box>
                  )}
                  options={Object.values(BOOKING_STATUS)}
                  renderInput={(params) => (
                    <TextField {...params} label='Select Booking Status' size='small'/>
                  )}
                />
              </div>
            </Row>
            <div className='formActions btn_right'>
              <Button className='yellow_gradient_btn me-2' onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className='red_gradient_btn' onClick={resetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

TenantFilter.protoTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  emitFilterItem: PropTypes.func,
}
export default TenantFilter;
