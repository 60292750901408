import React from 'react';


const NodataFound = ({ text, icon, style }) => {
    return(
        <div className="noDataShow text-center p-4">
            <img src={icon} alt={icon} style={style} />
            <h6>{text}</h6>
        </div>
    )
}

export default NodataFound;