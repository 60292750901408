import React, { useState, useEffect } from "react";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Link from '@mui/material/Link';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TextField from '@mui/material/TextField';
import * as Yup from "yup";
import { useFormik } from "formik";
import { storePropertyBedDetails } from "../../store/property";
import { useDispatch } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function SelectBed({ roomData, emitPropertyData, propertyData, previousData, uniqueId }) {
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(0);
    const bedsObject = {
        beds: [{
            name: '',
            price: '',
        }]
    }

    const bedValidation = Yup.object().shape({
        beds: Yup.array().of(Yup.object().shape({
            name: Yup.string().required("Please select bed name"),
            price: Yup.number().optional(),
        })).required().min(1)
    });

    const formik = useFormik({
        initialValues: {
            ...bedsObject,
        },
        validateOnMount: true,
        validationSchema: bedValidation
    });

    useEffect(() => {
        if (propertyData?.beds?.length > 0) {
            let options = []
            const bedData = propertyData?.beds?.map((bed) => {
                options.push(bed.name)
                return {
                    ...bed,
                    name: bed.name,
                    ...(bed?.price && {
                        price: parseInt(bed.price)
                    })
                }
            })
            formik.setValues({
                beds: bedData
            })
        }
    }, [propertyData])

    useEffect(() => {
        if (roomData?.rooms?.name && roomData?.rooms?.room_type_id) {
            setIsDisabled(false);
            if (!propertyData?.id) {
                if (previousData?.length > 0) {
                    const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                    if (result?.length > 0) {
                        const updatedBed = result[0]?.beds?.map((bed, index) => {
                            if (!bed?.name) {
                                return {
                                    ...bed,
                                    name: `Bed ${index + 1}`,
                                }
                            }
                            else {
                                return bed
                            }
                        })
                        formik.setValues({ beds: updatedBed })
                    } else {
                        const result = formik.values.beds?.map((bed, index) => {
                            if (!bed?.name) {
                                return {
                                    ...bed,
                                    name: `Bed ${index + 1}`,
                                }
                            }
                            else {
                                return bed
                            }
                        })
                        formik.setValues({
                            beds: result
                        })
                    }
                } else {
                    const result = formik.values.beds?.map((bed, index) => {
                        if (!bed?.name) {
                            return {
                                ...bed,
                                name: `Bed ${index + 1}`,
                            }
                        }
                        else {
                            return bed
                        }
                    })
                    formik.setValues({
                        beds: result
                    })
                }
            }
        } else if (roomData?.rooms?.name === '' || roomData?.rooms?.name === null || !roomData.rooms?.room_type_id) {
            setIsDisabled(true)
            if (previousData?.length > 0) {
                const result = previousData?.filter((item) => item.uniqueId === uniqueId)
                if (result?.length > 0) {
                    const updatedBed = result[0]?.beds?.map((bed) => {
                        if (bed?.name) {
                            return {
                                name: '',
                                price: '',
                            }
                        }
                        else {
                            return bed
                        }
                    })
                    formik.setValues({
                        beds: updatedBed
                    })
                }
            } else {
                let emptyBed = []
                formik.values.beds?.forEach((bed) => {
                    if (bed?.name) {
                        emptyBed.push({
                            name: '',
                            price: '',
                        })
                    }
                    else {
                        emptyBed.push(bed)
                    }
                })
                formik.setValues({
                    beds: emptyBed
                })
            }
            setUpdate(update + 1)
        }
        else {
            setIsDisabled(true)
        }
    }, [roomData.rooms?.name, roomData.rooms?.room_type_id])

    const addBed = () => {
        const bedData = []
        formik.values.beds?.forEach((bed, index) => {
            bedData.push(bed)
            if (formik.values.beds?.length === index + 1) {
                bedData.push(isDisabled ? bed : {
                    name: `Bed ${index + 2}`
                })
            }
        })
        formik.setValues({
            ...formik.values,
            beds: bedData
        })
    };

    const removeBed = (index) => {
        if (formik.values?.beds?.length === 1) {
            return;
        }

        const updatedBeds = [...formik.values.beds];
        updatedBeds.splice(index, 1);
        const bedData = []
        updatedBeds?.forEach((bed, bedIndex) => {
            if (index > bedIndex) {
                bedData.push(bed)
            } else {
                bedData.push(isDisabled ? bed : {
                    ...bed,
                    name: `Bed ${bedIndex + 1}`
                })
            }
        })
        formik.setValues({
            beds: bedData
        })
        setUpdate(update + 1)
    };

    const handleRoomChange = (index, field, value) => {
        const result = formik.values.beds.map((bed, bedIndex) => {
            if (bedIndex === index && field === 'price') {
                return {
                    ...bed,
                    price: value
                }
            } else {
                return bed
            }
        })
        formik.setValues({
            ...formik.values,
            beds: result
        })
    };

    useEffect(() => {
        if (formik.values.beds?.length > 0 && roomData?.rooms) {
            if (uniqueId) {
                let w = JSON.parse(JSON.stringify(formik.values.beds));
                const updatedData = previousData?.filter((item) => item.uniqueId !== uniqueId)
                dispatch(storePropertyBedDetails(previousData ? {
                    bedData: [...updatedData, {
                        uniqueId,
                        beds: w,
                    }]
                } : {
                    bedData: [{
                        uniqueId,
                        beds: w,
                    }]
                }))
            }
            let beds = []
            formik.values.beds?.forEach((bed) => {
                if (bed.name && bed.price) {
                    beds.push({
                        ...(bed?.id && {
                            id: bed.id,
                            user_id: bed?.user_id ? bed?.user_id : (bed?.user?.id ? bed?.user?.id : null)
                        }),
                        name: bed.name,
                        price: bed.price
                    })
                }
            })

            if (beds.length > 0) {
                emitPropertyData({
                    floorData: {
                        name: roomData?.name?.name ? roomData?.name?.name : roomData?.name,
                        ...(roomData?.user_id && {
                            user_id: roomData?.user_id?.id
                        }),
                        ...(roomData?.flats ? {
                            flats: {
                                ...roomData?.flats,
                                rooms: {
                                    ...roomData?.rooms,
                                    beds: beds
                                }
                            }
                        } : {
                            rooms: {
                                ...roomData?.rooms,
                                beds: beds
                            }
                        })
                    }
                }, 'bed')
            }
        }
    }, [formik.values.beds])

    const theme = createTheme({
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {
                color: 'red', // Change the color here
              },
            },
          },
        },
      });

    return (
        <React.Fragment>
            {formik.values?.beds?.map((bed, index) => (
                <div key={index}>
                    <div className="d-flex align-items-center" style={{ padding: '5px 0' }}>
                        <TextField
                            disablePortal
                            id="combo-box-demo"
                            readOnly
                            label="Selected bed"
                            disabled={isDisabled}
                            value={bed?.name}
                            sx={{ minWidth: 180, marginRight: '15px' }}
                            size="small"
                        />
                        <div style={{ width: '200px', marginRight: '15px' }}>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    fullWidth
                                    label={"Price"}
                                    name="price"
                                    key={update}
                                    type="number"
                                    disabled={isDisabled}
                                    value={bed.price}
                                    onChange={(event) => {
                                        handleRoomChange(index, 'price', event.target.value)
                                    }}
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                            </ThemeProvider>
                        </div>
                        <CancelOutlinedIcon color="error" onClick={() => removeBed(index)} 
                        className={`cancelIcon ${index === 0 ? 'fadedCross' : ''}`}/>
                    </div>
                    
                </div>
            ))}
            <div>
                <Link href="javascript:void(0)" underline="none" onClick={addBed} style={{ display: 'inline', marginBottom: '10px' }}>
                    <AddOutlinedIcon /> &nbsp; Add Bed
                </Link>
            </div>
        </React.Fragment>
    )
}
