import React, { useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { Autocomplete, TextField, Box } from "@mui/material"
import { PROPERTY_REQUEST_STATUS, PROPERTY_TYPES } from '../../common/constant';

function PropertyRequestFilter({ isOpen, toggleDrawer, emitFilterItem }) {
  const [filterObject, setFilterObject] = useState({
    name: '',
    owner_name: '',
    owner_contact_number: '',
    type: '',
    status: ''
  })
  const filterInputs = []
  const filterFields = []

  const handleFilter = () => {
    if (filterObject.name) {
      filterFields.push('name')
      filterInputs.push(filterObject.name)
    }

    if (filterObject.owner_name) {
      filterFields.push('owner_name')
      filterInputs.push(filterObject.owner_name)
    }

    if (filterObject.owner_contact_number) {
      filterFields.push('owner_contact_number')
      filterInputs.push(filterObject.owner_contact_number)
    }

    if (filterObject.type) {
      filterFields.push('type')
      filterInputs.push(filterObject.type)
    }
    if (filterObject.status) {
      filterFields.push('status')
      filterInputs.push(filterObject.status)
    }

    emitFilterItem(filterFields, filterInputs)
    toggleDrawer()
  }

  const handleResetFilter = () => {
    setFilterObject({})
    emitFilterItem([], [])
    toggleDrawer()
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        toggleDrawer(isOpen)
        setFilterObject({})
      }}
    >
      <OffcanvasHeader toggle={() => {
        toggleDrawer(isOpen)
        setFilterObject({})
      }}>
        Property Request Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className="">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name='name'
                  value={filterObject.name}
                  label="Property Name"
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value
                    })
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name="owner_name"
                  value={filterObject.owner_name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      owner_name: e.target.value
                    })
                  }}
                  label="Property Owner Name"
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  name="owner_contact_number"
                  label="Property Owner Contact Number"
                  value={filterObject.owner_contact_number}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      owner_contact_number: e.target.value
                    })
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  value={filterObject.type}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      type: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(PROPERTY_TYPES)}
                  renderInput={(params) => <TextField {...params} label="Property Type" size="small" />}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  value={filterObject.status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      status: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(PROPERTY_REQUEST_STATUS)}
                  renderInput={(params) => <TextField {...params} label="Property Status" size="small" />}
                />
              </div>
            </Row>
            <div className="formActions btn_right">
              <Button className="yellow_gradient_btn mx-2" onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className="red_gradient_btn" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default PropertyRequestFilter;
