import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Input, Button } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import DetailDrawer from '../../components/Notification/detailDrawer';
import TableHeaders from '../../components/Common/TableHeaders';
import {
  DEFAULT_PER_PAGE_VALUE,
  ACTIONS,
  ALL_MODULES,
} from '../../common/constant';
import {
  getAllNotificationList,
  deleteNotificationApi,
  markAsReadNotificationApi,
} from '../../api/notification';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import moment from 'moment';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
// import NotificationFilter from '../../components/Notification/NotificationFilter';
import { Switch, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import NoDataIcon from "../../assets/images/no-data.png";

export default function Notifications() {
  document.title = 'Notifications | Dstayz';

  const [isExistingDetails, setExistingDetails] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [update, setUpdate] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isShowUnread, setShowUnread] = useState(false);

  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });

  const currentUserId = useSelector(
    (state) => state?.AuthLogin?.auth?.user?.id,
  );

  const paramsObject = useRef({
    page: 1,
    limit: DEFAULT_PER_PAGE_VALUE,
    filter_fields: [],
    filter_inputs: [],
  });

  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 180px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${
        theme.palette.mode === 'dark' ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark'
          ? 'rgba(0,0,0, 0.50)'
          : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(`padding: '10px'`);

  const StyledMenuItem = styled(MenuItem)`
    && {
      padding: 10px;
      /* Add more styles as needed */
    }
  `;

  const [details, setDetails] = useState({});
  const toggleDetailsDrawer = async (data = {}) => {
    setDetails(data);
    setExistingDetails(!isExistingDetails);
    if (data?.id && !data.read) {
      await markAsReadNotificationApi({ ids: [data.id] });
      fetchNotificationList(paramsObject.current);
    }
  };

  const columns = [
    {
      column_name: '',
      key_name: '',
      sort_enabled: false,
      style: { minWidth: '50px' },
    },
    {
      column_name: 'Date and Time',
      key_name: 'created_at',
      sort_enabled: true,
      style: { minWidth: '170px' },
    },
    {
      column_name: 'Content',
      key_name: 'content',
      sort_enabled: false,
      style: { minWidth: '150px' },
    },
    {
      column_name: 'Sender',
      key_name: 'sender',
      sort_enabled: false,
      style: { minWidth: '145px' },
    },
  ];

  const fetchNotificationList = async (params) => {
    try {
      setLoading(true);
      params.filter_fields = ['receiver_id', ...params.filter_fields];
      params.filter_inputs = [currentUserId, params.filter_inputs];
      const response = (await getAllNotificationList(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          id: res.id,
          index_no: ++level,
          subject: res?.notification?.content?.subject,
          created_at: moment(res?.created_at).format('LLL'),
          sender: res?.notification?.sender?.name ?? 'N/A',
          sender_role: res?.notification?.sender?.role?.role ?? 'N/A',
          sender_contact_number: res?.notification?.sender?.contact_number ?? 'N/A',
          content: res?.notification?.content?.body ?? 'N/A',
          meta_id: res?.notification?.content?.meta?.id,
          read: res?.is_read,
          link: res?.notification?.content?.url,
        };
      });
      setNotificationList(resultData);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      paramsObject.current = {
        ...paramsObject.current,
        page: data.page,
        limit: rowsPerPage,
      };
    } else {
      paramsObject.current = {
        ...paramsObject.current,
        page: 1,
        limit: data?.limit,
      };
      setUpdate((prev) => prev + 1);
      setRowsPerPage(data?.limit);
    }
    await fetchNotificationList(paramsObject.current);
  };

  useEffect(() => {
    fetchNotificationList(paramsObject.current);
  }, []);

  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async (ids) => {
    try {
      setIsDeleting(true);
      const res = await deleteNotificationApi({ ids });
      paramsObject.current = { ...paramsObject.current, page: 1 };
      await fetchNotificationList(paramsObject.current);
      setSelectedItems([]);
      toast.success(res.data.message);
      return true;
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong.');
      return false;
    }
  };

  // const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  // const toggleFilter = () => {
  //   setIsOpenFilterModal(!isOpenFilterModal);
  // };

  const handleFilter = async (params) => {
    paramsObject.current = { ...paramsObject.current, page: 1, ...params };
    await fetchNotificationList(paramsObject.current);
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <Breadcrumbs title='Dstayz' breadcrumbItem='Notifications' />
        <div>
          <div className='tabsBody'>
            <Row>
              <Col className='col-12'>
                <div className='flexbox_holder'>
                  <div></div>
                  <div>
                    <span>
                      <Typography component='label'>
                        Show unread only
                      </Typography>
                      <Switch
                        defaultChecked={isShowUnread}
                        aria-label='Unread'
                        onChange={(event) => {
                          if (event.target.checked)
                            handleFilter({
                              filter_fields: ['is_read'],
                              filter_inputs: ['No'],
                            });
                          else
                            handleFilter({
                              filter_fields: [],
                              filter_inputs: [],
                            });
                          setShowUnread(event.target.checked);
                        }}
                      />
                    </span>

                    <Button
                      className='btn yellow_gradient_btn me-2'
                      disabled={isDeleting || selectedItems.length <= 1}
                      onClick={() =>
                        setDeleteConfirm({ status: true, id: selectedItems })
                      }>
                      <DeleteIcon className='me-2 font-size-18' />
                      Bulk Delete
                    </Button>

                    {/* <Button
                      className='btn grey_outline_btn me-2'
                      onClick={toggleFilter}>
                      <TuneIcon className='me-2 font-size-18' />
                      Filter
                    </Button> */}
                  </div>
                </div>
                <Card className='table_card_holder mt-2'>
                  <CardBody>
                    <div className='table-rep-plugin'>
                      <div
                        className='table-responsive mb-0'
                        data-pattern='priority-columns'>
                        {loading && (
                          <div style={{ textAlign: 'center', height: '500px' }}>
                            <CircularProgress />
                          </div>
                        )}
                        {notificationList?.length === 0 && !loading && (
                          <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No notification to show.' icon={NoDataIcon} />
                          </div>
                        )}
                        {notificationList?.length !== 0 && !loading && (
                          <Table
                            id='tech-companies-1'
                            className='table table-bordered activePropertiesListing'>
                            <TableHeaders
                              headers={columns}
                              module={ALL_MODULES.NOTIFICATION}
                              sortBy={{
                                order: paramsObject.current.order,
                                direction: paramsObject.current.direction,
                              }}
                              emitSortedColumn={(sort) => {
                                if (sort.direction) {
                                  paramsObject.current = {
                                    ...paramsObject.current,
                                    ...sort,
                                  };
                                } else {
                                  delete paramsObject.current['order'];
                                  delete paramsObject.current['direction'];
                                }
                                fetchNotificationList(paramsObject.current);
                              }}
                              isAllRowsSelected={
                                selectedItems.length &&
                                notificationList.every((el) =>
                                  selectedItems.includes(el.id),
                                )
                              }
                              emitAllRowSelect={(value) => {
                                setSelectedItems((prev) => {
                                  if (value) {
                                    return [
                                      ...prev,
                                      ...notificationList.map(
                                        (notification) => notification.id,
                                      ),
                                    ];
                                  } else {
                                    return prev.filter(
                                      (_v) =>
                                        !notificationList
                                          .map(
                                            (notification) => notification.id,
                                          )
                                          .includes(_v),
                                    );
                                  }
                                });
                              }}
                            />
                            <Tbody>
                              {notificationList.length > 0 &&
                                notificationList?.map((item, index) => (
                                  <Tr
                                    className={`cursor-pointer ${
                                      item.read ? 'read' : 'unread'
                                    }`}
                                    key={index}>
                                    <Td onClick={(e) => e.stopPropagation()}>
                                      <Input
                                        type='checkbox'
                                        className='form-check-input'
                                        id='formrow-customCheck'
                                        defaultChecked={selectedItems.includes(
                                          item.id,
                                        )}
                                        onClick={() => {
                                          setSelectedItems((prev) => {
                                            if (
                                              selectedItems.includes(item.id)
                                            ) {
                                              return prev.filter(
                                                (_v) => _v !== item.id,
                                              );
                                            } else {
                                              return [...prev, item.id];
                                            }
                                          });
                                        }}
                                      />
                                    </Td>
                                    <Td>{item.index_no}</Td>
                                    <Td>{item.created_at}</Td>
                                    <Td>
                                      <Link to={item.link} state={item?.meta_id}>
                                      <ReactQuill
                                        value={item.content.replace(/(<([^>]+)>)/gi, "")}
                                        readOnly={true}
                                        theme={'bubble'}
                                      />
                                      </Link>
                                    </Td>
                                    <Td>{item.sender}</Td>
                                    <Td
                                      className='threeDotsSpace'
                                      onClick={(e) => e.stopPropagation()}>
                                      <Dropdown>
                                        <MenuButton>
                                          <i className='mdi mdi-dots-vertical font-size-15'></i>
                                        </MenuButton>
                                        <Menu slots={{ listbox: Listbox }}>
                                          <StyledMenuItem
                                            className='cursor-pointer'
                                            slots={{ ListboxItem: ListboxItem }}
                                            onClick={() =>
                                              toggleDetailsDrawer(item)
                                            }>
                                            <i className='mdi mdi-eye-outline font-size-18 align-middle me-1 text-muted' />{' '}
                                            View
                                          </StyledMenuItem>
                                          <StyledMenuItem
                                            className='cursor-pointer'
                                            slots={{ ListboxItem: ListboxItem }}
                                            onClick={() =>
                                              setDeleteConfirm({
                                                status: true,
                                                id: [item.id],
                                              })
                                            }>
                                            <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />{' '}
                                            Delete
                                          </StyledMenuItem>
                                        </Menu>
                                      </Dropdown>
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                    {notificationList?.length > 0 && (
                      <div className='numbers_pagination_holder'>
                        <PaginationComponent
                          paginationMeta={paginationMeta}
                          handleChangePagination={paginationModelMethod}
                          update={update}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      {isExistingDetails && (
        <DetailDrawer
          isOpen={isExistingDetails}
          toggleDrawer={toggleDetailsDrawer}
          details={details}
        />
      )}

      {isDeleteConfirm.status && (
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await handleDelete(isDeleteConfirm.id);
            if (isDeleted) {
              setIsDeleting(false)
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isDeleting}
        />
      )}

      {/* {isOpenFilterModal && (
        <NotificationFilter
          isOpen={isOpenFilterModal}
          toggleDrawer={toggleFilter}
          emitFilterItem={(e) => {
            handleFilter(e);
          }}
        />
      )} */}
    </div>
  );
}
