import React, { useState, useEffect, useCallback } from "react";
import { debounce, TextField, Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export function NearToDropDownPaginationList({
  listCall,
  defaultValue,
  disabled,
  apiParams,
  searchEnabled,
  emitItem,
}) {
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationObject, setPaginationObject] = useState({})
  const [searchQuery, setSearchQuery] = useState("");
  const [position, setPosition] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([])
  const [listboxNode, setListboxNode] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  const getOptionsDelayed = useCallback(
    debounce((query, apiParams, cb) => {
      setOptions([]);
      fetchAPI(query, apiParams).then((response) => {
        cb(response);
      });
    }, 1200),
    []
  );

  useEffect(() => {
    if (mounted && isOpen) {
      setLoading(true);
      getOptionsDelayed(searchQuery, apiParams, (response) => {
        const optionsList = response.data.data.data.map((opts) => opts.name)
        setOptions(optionsList);
        setPaginationObject(response.data.data.meta)
        setPage(page + 1)
        setLoading(false);
      });
    }
  }, [searchQuery, getOptionsDelayed, isOpen]);

  const fetchAPI = async (query, apiParams = {}) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }

      const response = await listCall(queryParams);
      return response
    } catch (err) {
      console.log(err)
    }
  }

  const fetchOptions = async (query) => {
    try {
      const queryParams = {
        ...apiParams,
        page: page + 1,
      };
      if (query && searchEnabled) {
        queryParams.search = query;
      }

      setLoading(true);
      const response = await listCall(queryParams);
      setPage(page + 1);

      const optionsList = response.data.data.data.map((opts) => opts.name)

      setPaginationObject(response.data.data.meta);
      setOptions((prevState) => {
        return [...prevState, ...optionsList];
      });
      setLoading(false);

    } catch (e) {
      setLoading(false);
      toast.error("Encountered problem with fetching records.");
    }
  };

  useEffect(() => {
    if (listboxNode !== '' && listboxNode !== undefined) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode])

  const handleScroll = async (event) => {
    setListboxNode(event.currentTarget);

    const x = listboxNode.scrollTop + listboxNode.clientHeight;

    // only when checking this condition we change the position
    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x);
      if (options.length !== paginationObject.total && !loading) {
        await fetchOptions();
      }
    }
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={options}
        autoHighlight
        size="small"
        loading={loading}
        disabled={disabled ?? false}
        value={selectedValues.length > 0 ? selectedValues : defaultValue}
        onOpen={() => {
          setIsOpen(true);
        }}
        getOptionLabel={(option) => option}
        onInputChange={(event, newValue) => {
          setSearchQuery(newValue);
        }}
        onClose={() => {
          setIsOpen(false)
          setOptions([]);
          setPaginationObject([])
          setLoading(false)
          setPage(0)
        }}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          emitItem(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Near To"
            className="d-flex justify-content-between align-items-center autocomplete-icon"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  <KeyboardArrowDownOutlinedIcon />
                </React.Fragment>
              )
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll
        }}
      />
    </>
  )
}
