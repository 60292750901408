import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import VisitRequestsDetailDrawer from '../../components/VisitRequests/VisitRequestsDetailsDrawer';
import Search from '../../components/Common/GlobalSearch';
import {
  ACTIONS,
  ALL_MODULES,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_PER_PAGE_VALUE,
  OPERATION_TYPE,
} from '../../common/constant';
import {
  deleteSchedulePropertyVisitRequest,
  getAllSchedulePropertyVisitations,
  getSchedulePropertyVisitRequestDashboardInformation,
  updateSchedulePropertyVisitRequestByAdmin,
} from '../../api/schedule-property-visit';
import CircularProgress from '@mui/material/CircularProgress';
import NodataFound from '../../components/Common/NoDataFound';
import { toast } from 'react-toastify';
import PaginationComponent from '../../components/Common/PaginationComponent';
import AddOrEditVisitRequestsDrawer from '../../components/VisitRequests/AddOrEditVisitRequestsDrawer';
import moment from 'moment';
import SelectBox from '../../components/VisitRequests/SelectBox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import AddOrEditOrViewNoteModal from '../../components/Common/AddOrEditOrViewNoteModal';
import { BlobProvider } from '@react-pdf/renderer';
import SchedulePropertyVisitSlipPdf from '../../components/VisitRequests/SchedulePropertyVisitSlipPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisitRequestFilterDrawer from '../../components/VisitRequests/VisitRequestsFilterDrawer';
import TableHeaders from '../../components/Common/TableHeaders';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/base';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import NoDataIcon from "../../assets/images/no-data.png";

function VisitRequests() {
  document.title = 'Visit Requests | Dstayz';
  const [schedulePropertyVisitList, setSchedulePropertyVisitList] = useState(
    [],
  );
  const [stats, setStats] = useState({
    total_requests: null,
    current_day_visits: null,
    current_week_visits: null,
    current_month_visits: null,
    last_month_visits: null,
    loading: false,
  });
  const [paginationMeta, setPaginationMeta] = useState({});
  const [searchItem, setSearchItem] = useState();
  const [drawerAction, setDrawerAction] = useState(ACTIONS.CREATE);
  const [selectedScheduleToEdit, setSelectedScheduleToEdit] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
    action: null,
    item: null,
    module: ALL_MODULES.VISIT_REQUESTS
  });
  const [noteModal, setNoteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [sort, setSort] = useState({});
  const [isAddOrEditVisitRequests, setAddOrEditVisitRequests] = useState(false);
  const [isVisitRequestsFilter, setVisitRequestsFilter] = useState(false);
  const [onMounted, setOnMounted] = useState(false);
  const [detailShowId, setDetailShowId] = useState(useLocation()?.state?.id);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const hasEditPermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.UPDATE);
  const hasDeletePermission = usePermissionGiven(ALL_MODULES.PROPERTIES, OPERATION_TYPE.DELETE);



  const handleStatusChange = async (item, status) => {
    try {
      await updateSchedulePropertyVisitRequestByAdmin(item.id, {
        status: status.value,
      });
      setSchedulePropertyVisitList((prev) => {
        const ind = prev.findIndex((v) => v.id === item.id);
        if (ind !== -1) {
          prev[ind].status = status.value;
        }
        return [...prev];
      });
      toast.success('Status updated successfully.');
    } catch (e) {
      toast.error('Status update failed.');
    }
  };

  const propertyVisitRequestListApi = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllSchedulePropertyVisitations(params)).data
        .data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      return resultData;
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStats = async () => {
    try {
      setStats({ ...stats, loading: true });
      const res = await getSchedulePropertyVisitRequestDashboardInformation();
      setStats({
        ...stats,
        ...res.data.data,
        loading: false,
      });
    } catch (error) {
      return toast.error(error?.response?.data?.message);
    }
  };

  const fetchAllPropertyVisitRequestList = async (
    searchItem = '',
    paginatedData = { page, limit: rowsPerPage },
  ) => {
    const params = {
      ...paginatedData,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    };
    const resultData = await propertyVisitRequestListApi(params);
    setSchedulePropertyVisitList(resultData);
    if (!onMounted) {
      updateStats();
      setOnMounted(true);
    }
  };
  const handleNoteModalToggle = (action, item, modalState) => {
    setNoteModalActionAndPayload((old) => ({
      ...old,
      action,
      item,
      module: ALL_MODULES.VISIT_REQUESTS,
    }));
    setNoteModal((prev) => {
      if (modalState) {
        return modalState;
      }
      return !prev;
    });
  };

  const globalSearch = async (params) => {
    setSchedulePropertyVisitList([]);
    setSearchItem(params);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    const resultData = await propertyVisitRequestListApi({
      ...params,
      ...(params.search && {
        search: params.search,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setSchedulePropertyVisitList(resultData);
  };

  const paginationModelMethod = async (data) => {
    let params = {
      ...(searchItem?.search && {
        ...searchItem,
      }),
    };
    if (data?.page) {
      setPage(data.page);
      params.page = data.page;
      fetchAllPropertyVisitRequestList({ page: data.page, limit: rowsPerPage });
    } else {
      setUpdate((prev) => prev + 1);
      setPage(1);
      setRowsPerPage(data?.limit);
      fetchAllPropertyVisitRequestList({ page: 1, limit: data?.limit });
    }
  };

  // Schedule requests filtering handler
  const filter = async (filterFields, filterInputs) => {
    setSchedulePropertyVisitList([]);
    setPage(1);
    setPaginationMeta();
    setUpdate((pre) => pre + 1);
    setFilterInputs(filterInputs);
    setFilterFields(filterFields);
    const resultData = await propertyVisitRequestListApi({
      limit: rowsPerPage,
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),
    });
    setSchedulePropertyVisitList(resultData);
  };

  // Schedule requests sorting handler
  const handleSort = async (sort) => {
    setSchedulePropertyVisitList([]);
    setSort(sort);
    const resultData = await propertyVisitRequestListApi({
      ...(sort?.order &&
        sort?.direction && {
        ...sort,
      }),

      // Remaining sustainable params
      ...(page && page),
      ...(rowsPerPage && { limit: rowsPerPage }),
      ...(searchItem && {
        ...searchItem,
      }),
      ...(filterFields &&
        filterInputs && {
        filter_fields: filterFields,
        filter_inputs: filterInputs,
      }),
    });
    setSchedulePropertyVisitList(resultData);
  };

  const refreshPropertyScheduleVisit = async () => {
    setSchedulePropertyVisitList([]);
    setPage(1);
    const resultData = await propertyVisitRequestListApi({
      limit: rowsPerPage,
      page: 1,
    });
    setSchedulePropertyVisitList(resultData);
    updateStats();
  };

  // Add new request drawer
  const toggleAddOrEditVisitRequests = (performedAction, payload) => {
    setDrawerAction(performedAction ?? ACTIONS.CREATE);
    setSelectedScheduleToEdit(payload ?? undefined);
    setAddOrEditVisitRequests(!isAddOrEditVisitRequests);
  };

  // request filter drawer
  const toggleVisitRequestsFilter = () => {
    setVisitRequestsFilter(!isVisitRequestsFilter);
  };

  const deletePropertyVisitRequestHandler = async (id) => {
    try {
      const response = await deleteSchedulePropertyVisitRequest(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  }

  const columns = [
    // Visitors name
    {
      column_name: "Visitor's Name",
      key_name: 'fullname',
      sort_enabled: true,
    },
    // Visitors Contact Number
    {
      column_name: "Visitor's Contact No",
      key_name: 'phone_no',
      sort_enabled: true,
    },
    // Visitor's Property
    {
      column_name: "Visitor's Property",
      key_name: 'property_name',
      sort_enabled: true,
    },
    // Visiting Date
    {
      column_name: 'Visiting Date',
      key_name: 'visit_datetime',
      sort_enabled: true,
    },
    // Visit request status
    {
      column_name: 'Status',
      key_name: 'status',
      sort_enabled: true,
    },
    // Contact Person's Name
    {
      column_name: "Contact Person's Name",
      key_name: 'care_taker_name',
      sort_enabled: true,
    },
    // Contact Person's Number
    {
      column_name: "Contact Person's Number",
      key_name: 'care_taker_contact_no',
      sort_enabled: true,
    },
    // Note
    {
      column_name: 'Note',
      key_name: 'note',
      sort_enabled: false,
    },
    //  Slip
    {
      column_name: 'Slip',
      key_name: 'slip',
      sort_enabled: false,
    },
  ];

  useEffect(() => {
    fetchAllPropertyVisitRequestList();
  }, []);

  const Listbox = styled('ul')(
    ({ theme }) => `
      font-size: 16px;
      padding: 10px;
      margin: 12px 0;
      min-width: 150px;
      border-radius: 5px;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${theme.palette.mode === 'dark'
        ? 'rgba(0,0,0, 0.50)'
        : 'rgba(0,0,0, 0.05)'
      };
      z-index: 99;
      position: absolute;
      top: 100%;
      right: 0;
      list-style-type: none;
    `,
  );

  const ListboxItem = styled('li')(`padding: '10px'`);

  const StyledMenuItem = styled(MenuItem)`
    && {
      padding: 10px;
      /* Add more styles as needed */
    }
  `;
  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          {/* <Breadcrumbs title='Tenant Details' breadcrumbItem='Existing Tenants' /> */}
          <Row
            className='d-grid'
            style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Today&apos;s Visit</h4>
                </div>
                <h2 className='num_heading'>
                  {stats.loading && (
                    <CircularProgress
                      size={12}
                      thickness={5}
                      color='error'
                      style={{ textAlign: 'center' }}
                    />
                  )}
                  {!stats.loading && stats.current_day_visits && (
                    <span>{stats.current_day_visits}</span>
                  )}
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>This Weak&apos;s Visit</h4>
                </div>
                <h2 className='num_heading'>
                  {stats.loading && (
                    <CircularProgress
                      size={12}
                      thickness={5}
                      color='error'
                      style={{ textAlign: 'center' }}
                    />
                  )}
                  {!stats.loading && stats.current_week_visits && (
                    <span>{stats.current_week_visits}</span>
                  )}
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>This Month&apos;s Visit</h4>
                </div>
                <h2 className='num_heading'>
                  {stats.loading && (
                    <CircularProgress
                      size={12}
                      thickness={5}
                      color='error'
                      style={{ textAlign: 'center' }}
                    />
                  )}
                  {!stats.loading && stats.current_month_visits && (
                    <span>{stats.current_month_visits}</span>
                  )}
                </h2>
              </div>
            </div>
            <div className='stat_outer_holder'>
              <div className='stat_card_holder'>
                <div className='flexbox_holder justify-content-start'>
                  <div className='icon_holder'>
                    <i className='mdi mdi-information-variant' />
                  </div>
                  <h4 className='heading'>Last Month&apos;s Visit</h4>
                </div>
                <h2 className='num_heading'>
                  {stats.loading && (
                    <CircularProgress
                      size={12}
                      thickness={5}
                      color='error'
                      style={{ textAlign: 'center' }}
                    />
                  )}
                  {!stats.loading && stats.last_month_visits && (
                    <span>{stats.last_month_visits}</span>
                  )}
                </h2>
              </div>
            </div>
          </Row>
          <Row>
            <Col className='col-12'>
              <div className='flexbox_holder mt-2'>
                <div className='app-search'>
                  <Search
                    listCall={globalSearch}
                    params={{
                      limit: rowsPerPage,
                      page: 1,
                      ...(sort?.order &&
                        sort?.direction &&
                      {
                        order: sort?.order,
                        direction: sort?.direction,
                      }),
                      ...(filterFields &&
                        filterInputs &&
                      {
                        filter_fields: filterFields,
                        filter_inputs: filterInputs,
                      })
                    }}
                  />
                </div>

                <div>
                  <Button
                    className='yellow_gradient_btn me-2'
                    onClick={toggleVisitRequestsFilter}>
                    <i className='mdi mdi-filter-variant' />
                    Filter
                  </Button>
                  {usePermissionGiven(
                    ALL_MODULES.VISIT_REQUESTS,
                    OPERATION_TYPE.CREATE,
                  ) && (
                      <Button
                        color='primary'
                        onClick={() => {
                          toggleAddOrEditVisitRequests();
                        }}>
                        <i className='mdi mdi-plus' />
                        Requests
                      </Button>
                    )}
                </div>
              </div>
              <Card className='table_card_holder'>
                <CardBody>
                  <div className='table-rep-plugin'>
                    <div
                      className='table-responsive mb-0'
                      data-pattern='priority-columns'>
                      {isLoading && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {schedulePropertyVisitList?.length === 0 &&
                        !isLoading && (
                          <div style={{ textAlign: 'center', height: '500px' }}>
                          <NodataFound text='No Property Schedule Visit Requested to show.' icon={NoDataIcon} />
                          </div>
                        )}
                      {schedulePropertyVisitList?.length !== 0 &&
                        !isLoading && (
                          <Table
                            id='tech-companies-1'
                            className='table table-bordered'>
                            <TableHeaders
                              headers={columns}
                              sortBy={sort}
                              emitSortedColumn={handleSort}
                              module={ALL_MODULES.VISIT_REQUESTS}
                            />
                            <Tbody>
                              {schedulePropertyVisitList &&
                                schedulePropertyVisitList?.map(
                                  (item, index) => (
                                    <Tr key={index}>
                                      <Td>{item.indexNo}</Td>
                                      <Td
                                        className='cursor_pointer'
                                        onClick={() =>
                                          setDetailShowId(item.id)
                                        }>
                                        {item.fullname}
                                      </Td>
                                      <Td>{item.phone_no}</Td>
                                      <Td>{item?.property?.name ?? 'N/A'}</Td>
                                      <Td>
                                        {item?.visit_datetime
                                          ? moment(item.visit_datetime).format(
                                            DEFAULT_DATETIME_FORMAT,
                                          )
                                          : 'N/A'}
                                      </Td>
                                      <Td>
                                        <SelectBox
                                          item={item}
                                          handleChange={handleStatusChange}
                                        />
                                      </Td>
                                      <Td>{item?.property?.care_taker_name}</Td>
                                      <Td>
                                        {item?.property?.care_taker_contact_no}
                                      </Td>
                                      <Td className='d-flex justify-content-center'>
                                        {item?.note ? (
                                          <DescriptionIcon
                                            titleAccess='Show Note'
                                            style={{
                                              textAlign: 'center',
                                              fontSize: '28px',
                                              cursor: 'pointer',
                                            }}
                                            fontSize={'inherit'}
                                            color='info'
                                            onClick={() => {
                                              handleNoteModalToggle(
                                                ACTIONS.VIEW,
                                                item,
                                              );
                                            }}
                                          />
                                        ) : (
                                          <NoteAddIcon
                                            titleAccess='Add Note'
                                            style={{
                                              textAlign: 'center',
                                              fontSize: '28px',
                                              cursor: 'pointer',
                                            }}
                                            fontSize={'inherit'}
                                            color='error'
                                            onClick={() => {
                                              handleNoteModalToggle(
                                                ACTIONS.CREATE,
                                                item,
                                              );
                                            }}
                                          />
                                        )}
                                      </Td>
                                      <Td>
                                        <BlobProvider
                                          document={
                                            <SchedulePropertyVisitSlipPdf
                                              item={item}
                                            />
                                          }>
                                          {({ url }) => (
                                            <a
                                              href={url}
                                              target='_blank'
                                              rel='noreferrer'>
                                              <FileDownloadIcon
                                                titleAccess='Download Slip'
                                                fontSize={'inherit'}
                                                color='error'
                                                style={{
                                                  textAlign: 'center',
                                                  fontSize: '28px',
                                                  cursor: 'pointer',
                                                }}
                                              />
                                            </a>
                                          )}
                                        </BlobProvider>
                                      </Td>
                                      <Td className='threeDotsSpace'>
                                        <Dropdown>
                                          <MenuButton>
                                            <i className='mdi mdi-dots-vertical font-size-15'></i>
                                          </MenuButton>
                                          <Menu slots={{ listbox: Listbox }}>
                                            {hasEditPermission && <StyledMenuItem
                                              className='cursor-pointer'
                                              slots={{
                                                ListboxItem: ListboxItem,
                                              }}
                                              onClick={() =>
                                                toggleAddOrEditVisitRequests(
                                                  ACTIONS.EDIT,
                                                  item,
                                                )
                                              }>
                                              <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                                              Edit
                                            </StyledMenuItem>}
                                            {hasDeletePermission && (<StyledMenuItem className="cursor-pointer" slots={{ ListboxItem: ListboxItem }} onClick={() =>
                                              setDeleteConfirm({ status: true, id: item?.id })
                                            }><i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                                              Delete
                                            </StyledMenuItem>)}
                                          </Menu>
                                        </Dropdown>
                                      </Td>
                                    </Tr>
                                  ),
                                )}
                            </Tbody>
                          </Table>
                        )}
                    </div>
                  </div>
                  {schedulePropertyVisitList?.length > 0 && (
                    <div className='numbers_pagination_holder'>
                      <PaginationComponent
                        paginationMeta={paginationMeta}
                        handleChangePagination={paginationModelMethod}
                        update={update}></PaginationComponent>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {isAddOrEditVisitRequests && (
        <AddOrEditVisitRequestsDrawer
          isOpen={isAddOrEditVisitRequests}
          toggleDrawer={toggleAddOrEditVisitRequests}
          refreshPropertyScheduleVisit={refreshPropertyScheduleVisit}
          action={drawerAction}
          scheduleDetails={selectedScheduleToEdit}
        />
      )}
      {noteModal && (
        <AddOrEditOrViewNoteModal
          refreshPropertyScheduleVisit={refreshPropertyScheduleVisit}
          handleNoteModalToggle={handleNoteModalToggle}
          noteModalActionAndPayload={noteModalActionAndPayload}
        />
      )}
      {detailShowId && (
        <VisitRequestsDetailDrawer
          isOpen={detailShowId}
          setIsOpen={setDetailShowId}
          reqId={detailShowId}
          visitRequest={schedulePropertyVisitList.filter((item) => item.id === detailShowId)[0]}
        />
      )}

      {isVisitRequestsFilter && (
        <VisitRequestFilterDrawer
          isOpen={isVisitRequestsFilter}
          toggleDrawer={toggleVisitRequestsFilter}
          emitFilterItem={filter}
          selectedFields={filterFields}
          selectedInputs={filterInputs}
        />
      )}

      {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deletePropertyVisitRequestHandler(isDeleteConfirm.id);
            if (isDeleted) {
              fetchAllPropertyVisitRequestList();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
          isDisabled={isLoading}
        />}
    </>
  );
}

export default VisitRequests;
