import React, { useEffect, useState } from 'react';
import { Button, InputGroup } from 'reactstrap';
import GlobalInnerHeader from './globalInnerHeader';
import { viewByIdApi, editApi } from '../../api/GlobalSettings/api';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Spinner } from 'reactstrap'

function Electricity({ tabDetails }) {
  const { id, payload } = tabDetails;

  const [electricityPayload, setElectricityPayload] = useState(payload)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDataModified, setIsDataModified] = useState(false);
  const [savedValue, setSavedValue] = useState(payload.value);

  async function validateInput(value) {
    try {
      await Yup.number().required('Electricity Charge Amount is required').typeError(' Electricity Charge Amount must be a number').positive('Electricity Charge Amount must be positive value').validate(value);
      setError('');
      setIsDataModified(true);
    } catch (err) {
      setError(err.message);
    }
  }

  async function counterIncrease(e) {

    if (parseInt(electricityPayload?.value) >= 0) {
      setElectricityPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) + 1
      }

      ));

      const value = parseInt(electricityPayload?.value) + 1
      await validateInput(value)

    }

  }

  async function counterDecrease(e) {

    if (parseInt(electricityPayload?.value) > 0) {

      const value = parseInt(electricityPayload?.value) - 1
      setElectricityPayload(prev => ({
        ...prev,
        value: parseInt(prev.value) - 1
      }

      ));
      await validateInput(value)

    }

  }
  async function onDataAttributeChange(e) {
    let value = e.target.value
    if (isNaN(value)) value = ''
    setElectricityPayload(prev => ({
      ...prev,
      value

    }));
    await validateInput(value);
  }

  async function getElectricityDetails() {
    try {

      const response = await viewByIdApi(id);
      const { payload } = response;
      setElectricityPayload(payload)
      setSavedValue(payload.value);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getElectricityDetails();
  }, [])

  async function saveData() {
    try {
      setIsLoading(true)
      const payload = {
        id,
        payload: { payload: electricityPayload }
      }
      await editApi(payload);
      setSavedValue(electricityPayload.value);
      setIsDataModified(false);

    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="tabInnerContent">
      <GlobalInnerHeader title="Electricity Charge" />
      <div className="settingBody settings_holder mt-4">
        <span>Charges applied for electricity per unit</span>
        <InputGroup>
          <div className="input-group-append">
            <Button type="button" color="primary" onClick={(e) => { counterDecrease(e) }} disabled={isLoading || !tabDetails.isEditable}>
              <i className="mdi mdi-minus" />
            </Button>
          </div>
          <input
            type="text"
            className="form-control"
            value={electricityPayload?.value}
            placeholder="Enter Amount"
            onChange={(e) => onDataAttributeChange(e)}
            disabled={isLoading}
          />
          <div className="input-group-append">
            <Button type="button" color="primary" onClick={(e) => { counterIncrease(e) }} disabled={isLoading || !tabDetails.isEditable}>
              <i className="mdi mdi-plus" />
            </Button>
          </div>
        </InputGroup>
        <span>INR. </span>
        {error && <div className='text-danger mt-2'>{error}</div>}
        <div className="text-end mt-4">
          {tabDetails.isEditable &&
            <Button
              disabled={!isDataModified || isLoading || electricityPayload.value == savedValue || error}
              color="primary"
              onClick={saveData}>{isLoading && <Spinner size="sm" color="light" />}
              Save
            </Button>}

        </div>
      </div>
    </div>
  );
}
Electricity.propTypes = {
  tabDetails: PropTypes.any
}
export default Electricity;
