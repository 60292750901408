import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
} from "reactstrap";
import uuid from "react-native-uuid";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ConfirmationModal from "../Common/ConfirmationModal";
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS } from "../../common/constant";
import { formatBytes } from "../../helpers/string_helper";
import FileList from "../Common/FileList";
import Dropzone from "react-dropzone";
import { uploadDocuments } from "../../api/compay-employee";
import { editParticularTask, getParticularTask } from "../../api/kanban";
import { priorityObject, taskStatus } from "../../constants/kanban_helper";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputLabel } from "@mui/material";

function EditKanbanDetails({
  assignee,
  isOpen,
  toggleDrawer,
  onToggleCloseDelete,
  id,
  cb,
  assigneeMeta,
  fetchAssignees,
}) {
  const [isLoading, setIsLoading] = useState("");
  const [date, setDate] = useState(null);
  const [assigneePage, setAssigneePage] = useState(1);
  const [confirmation, setConfirmation] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      priority: "",
      status: "",
      assignee_id: [],
      note: {},
      due_date: "",
      attachments:[],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Task Name"),
      description: Yup.string().required("Please Enter Task Description"),
      status: Yup.string().required("Please Enter Task Status"),
      priority: Yup.string().required("Please Enter Task Priority"),
      assignee_id: Yup.array().optional("Please Enter Task Assignee"),
      attachments: Yup.array().max(10, 'You are only allowed to have more than 10 documents here.').optional(),
      total_documents_size: Yup.number().positive().when('attachments', {
        is: (docs) => docs?.length > 0,
        then: (schema) => schema.min(2, 'The documents size must be greater than 1 Byte.').max(50 * 1024 * 1024, 'The documents size must not exceed 50 MiB.').required(),
        otherwise: (schema) => schema.optional()
      })
    }),
    onSubmit: async (payload) => {
      const {due_date,...other_payload}=payload;
      let payloadData = { ...other_payload,...(due_date && {due_date}) };
      setIsLoading(true);
      let documents = [];
      const filesUnchanged =
        validation.values?.attachments?.filter((file) => file?.id) ?? [];
      if (filesUnchanged.length > 0) {
        documents = [...filesUnchanged.map((sd) => sd.id)];
      }
      const filesToBeUploaded = selectedFiles.filter((file) => !file?.id);
      if (filesToBeUploaded.length > 0) {
        const formData = new FormData();
        filesToBeUploaded.forEach((f) => {
          formData.append("files", f);
        });
        formData.append("base_key", DOCUMENT_HASH_BASE_KEYS.TASK);
        const result = await uploadDocuments(formData);
        if (result.success) {
          const { response } = result;
          if (documents !== undefined) {
            documents = [
              ...documents,
              ...response.data.data.documents.map((doc) => doc.id),
            ];
          } else {
            documents = [...response.data.data.documents.map((doc) => doc.id)];
          }
        } else {
          setIsLoading(false);
          return false;
        }
      }

      if (!payloadData?.note) {
        payloadData.note = {};
      } else {
        payloadData.note = {
          note: payloadData?.note,
        };
      }
      delete payloadData.total_documents_size;
      
      editParticularTask(
        {...payloadData, attachments:[...documents]},
        id,
        cb,
        onToggleCloseDelete,
        setIsLoading
      );
      setConfirmation(false);
      setDate(null);
    },
  });

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    priority: "",
    status: "",
    assignee_id: "",
    note: {},
    due_date: "",
  });
  const [assigneeName, setAssigneeName] = useState([]);
  useEffect(() => {
    fetchAssignees(
      {
        page: assigneePage,
        limit: 10,
      },
      []
    );
    getParticularTask(id, setFormData, setDate);
  }, [id]);

  useEffect(() => {
    const newData =
      formData?.attachments?.map((doc) => ({
        ...doc,
        uniqueId: uuid.v4(),
        formattedSize: formatBytes(doc.size),
      })) ?? [];
    const sizeNewData = [...newData]?.reduce((p, c) => p + c.size, 0);
    validation.setValues({
      name: formData?.name,
      description: formData?.description,
      priority: formData?.priority,
      status: formData?.status,
      assignee_id: formData?.assignees?.map((e) => e?.assignee_id),
      note: formData?.note?.description || "",
      due_date: formData?.due_date || "",
      attachments: [...newData] ?? [],
      total_documents_size: sizeNewData==0?0.1:sizeNewData,
    });

    setselectedFiles([...(newData ?? [])]);

    const assigneeNames = formData?.assignees?.map((e) => ({
      name: e?.assignee?.name,
      id: e?.assignee_id,
    }));

    assigneeNames?.length
      ? setAssigneeName([...assigneeNames])
      : setAssigneeName([]);
  }, [formData]);

  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - x <= 1 &&
      assigneeMeta?.current_page < assigneeMeta?.last_page
    ) {
      fetchAssignees(
        {
          page: assigneePage + 1,
          limit: 10,
        },
        assignee
      );
      setAssigneePage((old) => old + 1);
    }
  };

  const addAssignee = (e, newVal) => {
    let newAttachmentData = [...newVal?.map((e) => e?.id)];
    const newAttachmentDataCopy = [...new Set(newAttachmentData)];
    validation.setFieldValue("assignee_id", newAttachmentDataCopy);
    setAssigneeName([...newVal]);
  };

  function changeDate(e) {
    validation.setFieldValue("due_date", e);
    setDate(e);
  }
  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex },
    });
  }
  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = validation.values.attachments.filter(
      (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)
    );
    const tempValues = {
      ...validation.values,
    };

    if (newDocuments.length === 0) {
      tempValues.attachments = [];
      delete tempValues.total_documents_size;
    }
    validation
      .setValues(
        {
          ...tempValues,
          ...(newDocuments.length > 0 && {
            attachments: newDocuments,
          }),
          ...(newDocuments.length > 0 && {
            total_documents_size: selectedFiles
              .filter(
                (doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)
              )
              .reduce((p, c) => p + c.size, 0),
          }),
        },
        true
      )
      .then(() => {
        setselectedFiles((prev) =>
          prev.filter(
            (_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)
          )
        );
      });

    return true;
  }
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    validation.setValues({
      ...validation.values,
      attachments: [...selectedFiles, ...files],
      total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
    }, true).then(() => {
        setselectedFiles((prev) => {
          return [...prev, ...files]
        })
    });
  }

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => {
        setAssigneePage(1);
      }}
    >
      <OffcanvasHeader
        toggle={() => {
          toggleDrawer();
        }}
      >
        Edit Task
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setConfirmation(true);
            return false;
          }}
        >
          <Row>
            <div className="col-sm-6 mb-3">
              <ThemeProvider theme={theme}>
                <TextField
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  label="Name"
                  disabled={isLoading}
                  name="name"
                  value={validation?.values?.name}
                  onChange={validation?.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={!!(validation.touched.name && validation.errors.name)}
                  required
                  size="small"
                />
              </ThemeProvider>
              {validation.touched.name && validation.errors.name ? (
                <span className="text-danger-formik">
                  {validation.errors.name}
                </span>
              ) : null}
            </div>
            <div className="col-sm-6 mb-3">
              <Autocomplete
                value={validation?.values?.priority}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  validation.setFieldValue(
                    "priority",
                    priorityObject[newValue] || ""
                  );
                }}
                onBlur={() => {
                  if (!validation.touched?.priority) {
                    validation.setFieldTouched("priority", true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={Object.values(priorityObject)}
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                  <TextField
                    {...params}
                    error={
                      validation.touched.priority && validation.errors.priority
                    }
                    label="Select Task Priority"
                    size="small"
                    required
                  />
                  </ThemeProvider>
                )}
              />
              {validation.touched.priority && validation.errors.priority ? (
                <span className="text-danger-formik">
                  {validation.errors.priority}
                </span>
              ) : null}
            </div>

            <div className="col-sm-6 mb-3">
              <Autocomplete
                value={validation.values.status}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  validation.setFieldValue("status", newValue);
                }}
                onBlur={() => {
                  if (!validation.touched?.status) {
                    validation.setFieldTouched("status", true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={taskStatus}
                renderInput={(params) => (
                  <ThemeProvider theme={theme}>
                  <TextField
                    {...params}
                    error={
                      validation.touched.status && validation.errors.status
                    }
                    label="Select Task Status"
                    size="small"
                    required
                  />
                  </ThemeProvider>
                )}
              />
              {validation.touched.status && validation.errors.status ? (
                <span className="text-danger-formik">
                  {validation.errors.status}
                </span>
              ) : null}
            </div>
            <div
              className="col-sm-6 mb-3"
              style={{
                marginTop: "-8px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    id="example-datetime-local-input"
                    slotProps={{ textField: { size: "small" } }}
                    referenceDate={dayjs(new Date())}
                    label="Select Due Date"
                    ampm={false}
                    timeSteps={{ minutes: 1 }}
                    value={date}
                    onChange={changeDate}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="col-sm-6 mb-3">
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={assignee}
                autoHighlight
                disabled={isLoading ?? false}
                value={assignee?.filter((e) =>
                  assigneeName?.find((el) => el?.id === e?.id)
                )}
                getOptionLabel={(option) => option.key}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    key={option.id}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                onChange={addAssignee}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.key}
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Select Assignee"}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    size="small"
                  />
                )}
                ListboxProps={{
                  onScroll: handleScroll,
                }}
              />
            </div>
            <div className="col-12 mb-3">
            <InputLabel htmlFor="txtAddress">Description <span style={{ color: 'red' }}>*</span></InputLabel>
              <BaseTextareaAutosize
                id="txtAddress"
                name="description"
                type="textarea"
                disabled={isLoading}
                className={
                  "form-control" +
                  (validation.touched.description &&
                  validation.errors.description
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter your Description"
                value={validation.values.description}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                minRows={2}
              />
              <span className="text-danger-formik">
                {validation.touched?.description &&
                validation.errors?.description
                  ? validation.errors?.description
                  : ""}
              </span>
            </div>
            <div className="col-12 mb-3">
              <label>
                <b>Note:</b>
              </label>
              <textarea
                id="txtAddress"
                name="note"
                disabled={isLoading}
                rows="2"
                className="form-control"
                placeholder="Note"
                onChange={validation.handleChange}
                value={validation?.values?.note}
              />
            </div>
          </Row>
          <div className="col-12 mb-3">
            {(validation.errors.attachments ||
              validation.errors.total_documents_size) && (
              <ul>
                {validation.errors.attachments && (
                  <li className="text-danger">
                    {validation.errors.attachments}
                  </li>
                )}
                {validation.errors.total_documents_size && (
                  <li className="text-danger">
                    {validation.errors.total_documents_size}
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="col-12 mb-3">
            <label>Upload Documents</label>
            <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted uil uil-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>

            {selectedFiles.length > 0 && (
              <FileList
                files={selectedFiles}
                confirmFileRemoval={confirmFileRemoval}
              />
            )}
          </div>

          <div className="formActions btn_right">
            <Button
              disabled={isLoading || !validation.isValid}
              className={
                !isLoading ? "yellow_gradient_btn" : "fade_yellow_gradient_btn"
              }
              type="submit"
            >
              <CircularProgress
                size={12}
                thickness={7}
                color="inherit"
                style={{
                  color: "#00000",
                  textAlign: "center",
                  display: !isLoading ? "none" : "inline-block",
                }}
              />
              Save
            </Button>
          </div>
        </Form>
        {confirmation && (
          <ConfirmationModal
            action={ACTIONS.EDIT}
            show={confirmation}
            onAcceptClick={() => {
              validation.handleSubmit();
              return false;
            }}
            onCloseClick={() => setConfirmation(false)}
            isDisabled={isLoading}
          />
        )}
        {isDocumentDeleteConfirm.status && (
          <ConfirmationModal
            action={ACTIONS.DELETE}
            show={isDocumentDeleteConfirm?.status}
            onCloseClick={() =>
              setIsDocumentDeleteConfirm({ status: false, data: null })
            }
            onAcceptClick={async () => {
              const { e, file, fileIndex } = isDocumentDeleteConfirm.data;
              const isDeleted = handleFileRemove(e, file, fileIndex);
              if (isDeleted) {
                setIsDocumentDeleteConfirm({ status: false, data: null });
              }
            }}
            isDisabled={isLoading}
          />
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default EditKanbanDetails;
