import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  property: {
    floorData: [],
    flatData: [],
    roomData: [],
    bedData: []
  },
  dataSaved: {
    success: false,
    error: null,
    data: null,
  },
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    storePropertyFloorDetails: (state, action) => {
      state.property.floorData = action.payload.floorData;
    },
    storePropertyFlatDetails: (state, action) => {
      state.property.flatData = action.payload.flatData;
    },
    storePropertyRoomDetails: (state, action) => {
      state.property.roomData = action.payload.roomData;
    },
    storePropertyBedDetails: (state, action) => {
      state.property.bedData = action.payload.bedData;
    },
    removePropertyDetails: (state) => {
      state.property.floorData = null;
      state.property.flatData = null;
      state.property.roomData = null;
      state.property.bedData = null;
    },
  },
});

export const { storePropertyFloorDetails, storePropertyFlatDetails, storePropertyRoomDetails, storePropertyBedDetails, removePropertyDetails } = slice.actions;
export default slice.reducer;

