import React from "react";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";

export const UploadFileButton = () => {
  return (
    <div
      className="d-flex"
      style={{
        padding: "5px",
        borderRadius: "4px 4px",
        border: "1px solid #adb5bd",
        marginTop: "-3px",
        cursor:'pointer'
      }}
    >
      <PostAddOutlinedIcon
        style={{
          display: "flex",
          margin: "auto",
          marginRight: "1em",
          cursor: "pointer",
        }}
      />
      <span
        style={{
          marginLeft: "-20px",
        }}
      >
        File Upload
      </span>
    </div>
  );
};
