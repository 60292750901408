import React, { useState, useEffect } from 'react';
import {
    Card, Button,
} from 'reactstrap';
import {
    Table, Tbody, Tr, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { deletePayoutForOwner, getAllPayoutForOwner, paymentPayoutForOwner, updatePayoutForOwner } from '../../api/payout-bill';
import { ACTIONS, ALL_MODULES, BILL_PAID_STATUS, DEFAULT_DATE_FORMAT, DEFAULT_PER_PAGE_VALUE, OPERATION_TYPE } from '../../common/constant';
import NodataFound from '../../components/Common/NoDataFound';
import CircularProgress from '@mui/material/CircularProgress';
import TableHeaders from '../../components/Common/TableHeaders';
import PaginationComponent from '../../components/Common/PaginationComponent';
import { usePermissionGiven } from '../../components/Hooks/UserPermission';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import AddOrEditOrViewNoteModal from '../../components/Common/AddOrEditOrViewNoteModal';
import { toast } from 'react-toastify';
import DeleteModal from '../../components/Common/DeleteModal';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NoDataIcon from "../../assets/images/no-data.png";
import PaymentsIcon from '@mui/icons-material/Payments';
import moment from 'moment';
import ConfirmationModal from '../../components/Common/ConfirmationModal';

function PaySlips() {
    document.title = ' Finance | Dstayz';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElPaid, setAnchorElPaid] = useState(null)
    const [id, setId] = useState(null);
    const [update, setUpdate] = useState(0);
    const [page, setPage] = useState(1);
    const [paginationMeta, setPaginationMeta] = useState({});
    const [payoutList, setPayoutList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
    const [sort, setSort] = useState();
    const [noteModal, setNoteModal] = useState(false);
    const [noteModalActionAndPayload, setNoteModalActionAndPayload] = useState({
        action: null,
        item: null
    })
    const [isLoading, setIsLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleDeleteCloseModal = () => setDeleteOpen(false);
    const hasDeletePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.DELETE)
    const hasCreatePermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.CREATE)
    const paymentPermission = usePermissionGiven(ALL_MODULES.FINANCE, OPERATION_TYPE.PAYOUT_PAYMENT)
    const navigate = useNavigate();
    const [isPayment, setIsPayment] = useState({
        status: false,
        data: null
    })

    useEffect(() => {
        fetchAllPayoutSlip({ page: page, limit: rowsPerPage })
    }, [])

    const fetchAllPayoutSlip = async (paginatedData) => {
        try {
            setIsLoading(true);
            const params = {
                ...(sort?.order && { order: sort.order }),
                ...(sort?.direction && { direction: sort.direction }),
                ...paginatedData,
            };
            const response = (await getAllPayoutForOwner(params)).data.data;
            setPaginationMeta(response.meta)
            let level = (response.meta.current_page - 1) * response.meta.per_page;
            const resultData = response.data.map((res) => {
                return {
                    indexNo: ++level,
                    ...res,
                };
            });
            setPayoutList(resultData)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSort = async (sort) => {
        setPayoutList([]);
        setSort(sort);
        await fetchAllPayoutSlip({
            ...(sort?.order && { order: sort.order }),
            ...(sort?.direction && { direction: sort.direction }),
            page: page,
            limit: rowsPerPage,
        });
    };

    const columns = [
        { column_name: `Month & Year`, key_name: 'month', sort_enabled: true, style: { minWidth: '140px' } },
        { column_name: `Date Range`, key_name: 'date_range', sort_enabled: true, style: { minWidth: '140px' } },
        { column_name: `Property Name`, key_name: 'property_name', sort_enabled: true, style: { minWidth: '140px' } },
        { column_name: `Owner Name`, key_name: 'owner_name', sort_enabled: false, style: { minWidth: '155px' } },
        { column_name: 'Total Payout Amount', key_name: 'total_payout_amount', sort_enabled: false, style: { minWidth: '140px' } },
        { column_name: `Comment`, key_name: 'note', sort_enabled: false, style: { minWidth: '100px' } },
        { column_name: `Payout Status`, key_name: 'status', sort_enabled: false, style: { minWidth: '100px' } },
    ]

    const navigateToGenerateTicket = () => {
        navigate('/generate-payout-slip');
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleClickForPaid = (event) => {
        setAnchorElPaid(event.currentTarget);
    };
    const handleCloseForPaid = () => {
        setAnchorElPaid(null);
    };

    const showInfo = Boolean(anchorElPaid)

    const paginationModelMethod = async (data) => {
        let params = {
            ...(sort && {
                ...sort
            })
        }
        if (data?.page) {
            setPage(data.page)
            params.page = data.page
            fetchAllPayoutSlip(params, { page: data.page, limit: rowsPerPage })
        } else {
            setUpdate(prev => prev + 1)
            setPage(1)
            setRowsPerPage(data?.limit)
            params.limit = data.limit
            fetchAllPayoutSlip(params, { page: data.page, limit: data?.limit })
        }
    };

    const handleNoteModalToggle = (action, item, modalState) => {
        setNoteModalActionAndPayload({
            action,
            item,
            module: ALL_MODULES.PG_VACANT_REQUESTS
        })
        setNoteModal(prev => {
            if (modalState) {
                return modalState
            }
            return !prev
        })
    }

    const refresh = (id, data) => {
        const updatedResult = payoutList.map((item) => {
            if (item.id === id) {
                return { ...item, ...data }
            } else {
                return item
            }
        })
        setPayoutList(updatedResult);
    }

    const handleSubmit = async ({ note }) => {
        try {
            const response = await updatePayoutForOwner(id, { note })
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh(id, { note: { id: response.data.data.note_id, description: note } });
            } else {
                toast.error(response.data.message);
            }

            setNoteModal(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setNoteModal(false)
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            const res = await deletePayoutForOwner(id);
            if (res?.status == 200) {
                toast.success(res?.data?.message);
                setPage(1);
                fetchAllPayoutSlip({ page: 1, limit: rowsPerPage });
            }
        } catch (e) {
            setIsLoading(false);
            toast.error(e?.response?.data?.message || e.message);
        } finally {
            setDeleteOpen(false);
            setIsLoading(false);
        }
    };

    const handlePayment = async () => {
        try {
            setIsLoading(true);
            const payload = {
                total_amount: isPayment?.data?.total_payout_amount,
                payoutId: isPayment?.data?.id
            }
            const res = await paymentPayoutForOwner(payload);
            if (res?.status == 200) {
                toast.success(res?.data?.message);
                setPayoutList([])
                setPage(1);
                fetchAllPayoutSlip({ page: 1, limit: rowsPerPage });
            }
        } catch (e) {
            setIsLoading(false);
            toast.error(e?.response?.data?.message || e.message);
        } finally {
            setIsPayment({ status: false, data: null });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="flexbox_holder d-flex align-items-center justify-content-between mb-3">
                        <div className="page-title-box pb-0">
                            <h4 className="mb-0">Payout Slip</h4>
                        </div>
                        {hasCreatePermission && (
                        <Button className="yellow_gradient_btn me-2 d-flex align-items-center" onClick={navigateToGenerateTicket}>
                            <PostAddOutlinedIcon />
                            Generate Payout Slip
                        </Button>
                        )}
                    </div>
                    <Card className="table_card_holder">
                        {/* <CardBody> */}
                        <div className="table-rep-plugin">
                            <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                            >
                                {payoutList?.length === 0 && isLoading && (
                                    <div style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                )}
                                {payoutList?.length === 0 && !isLoading && (
                                    <div style={{ textAlign: 'center', height: '400px' }}>
                                        <NodataFound text="No payout slip to show" icon={NoDataIcon} />
                                    </div>
                                )}

                                {payoutList?.length > 0 && (
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-bordered rentSlipTable"
                                    >
                                        <TableHeaders
                                            headers={columns}
                                            module={ALL_MODULES.FINANCE}
                                            sort={sort ?? ''}
                                            emitSortedColumn={handleSort}
                                        />
                                        <Tbody>
                                            {payoutList?.map((item, index) => (
                                                <Tr key={index}>
                                                    <Td>{item.indexNo}</Td>
                                                    <Td onClick={() => navigate(`/generated-payout-slip/view/${item.id}`, {
                                                        state: {
                                                            payout: JSON.stringify(item)
                                                        }
                                                    })}
                                                        style={{ cursor: "pointer" }}> {item.month} {item.year}</Td>
                                                    <Td>{item.date_range}</Td>
                                                    <Td>{item.property?.name}</Td>
                                                    <Td>{item.user?.name}</Td>
                                                    <Td>{item.total_payout_amount}</Td>
                                                    <Td className="d-flex justify-content-center">{item?.note ? (
                                                        <DescriptionIcon titleAccess='Show Note'
                                                            style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer', color: 'blue' }}
                                                            fontSize={'inherit'} color='info' onClick={() => {
                                                                setId(item.id)
                                                                handleNoteModalToggle(ACTIONS.VIEW, item)
                                                            }} />
                                                    ) :
                                                        <NoteAddIcon titleAccess='Add Note'
                                                            style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer', color: 'red' }}
                                                            fontSize={'inherit'} color='error'
                                                            onClick={() => {
                                                                setId(item.id)
                                                                handleNoteModalToggle(ACTIONS.CREATE, item)
                                                            }} />
                                                    }</Td>
                                                    <Td>
                                                        <span className={item?.status === BILL_PAID_STATUS.PAID ? 'rentStatus paidStatus' : 'rentStatus unpaidStatus'}>{item.status === BILL_PAID_STATUS.PAID ? item.status : 'UnPaid'}</span>
                                                        <InfoIcon onClick={handleClickForPaid} className='ms-2' />
                                                        <Popover
                                                            className='popup_block'
                                                            id={item.id}
                                                            open={showInfo}
                                                            anchorEl={anchorElPaid}
                                                            onClose={handleCloseForPaid}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }} className='popContent'>
                                                                <p>Payment Date: {item.payment ? moment(moment(item.payment?.payment_date).toISOString()?.split("T")[0]).format(DEFAULT_DATE_FORMAT) : 'N/A'}</p>
                                                                <p>Transaction ID: {item.payment ? item.payment?.transaction_id : 'N/A'}</p>
                                                            </Typography>
                                                        </Popover>
                                                    </Td>
                                                    {(hasDeletePermission || paymentPermission) && (
                                                        <Td className="threeDotsSpace">
                                                            <Button
                                                                id="basic-button"
                                                                aria-controls={open ? "basic-menu" : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? "true" : undefined}
                                                                onClick={(e) => {
                                                                    setId(item?.id);
                                                                    setIsPayment({
                                                                        status: false,
                                                                        data: item
                                                                    })
                                                                    handleClick(e);
                                                                }}
                                                            >
                                                                <i className="mdi mdi-dots-vertical font-size-15"></i>
                                                            </Button>
                                                            <Menu
                                                                disableScrollLock={true}
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    "aria-labelledby": "basic-button",
                                                                }}
                                                            >
                                                                {paymentPermission && (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setIsPayment({
                                                                                status: true,
                                                                                data: isPayment.data
                                                                            })
                                                                            handleClose()
                                                                        }}
                                                                    >
                                                                        <PaymentsIcon className='font-size-18 align-middle me-2 text-muted' /> Pay
                                                                    </MenuItem>
                                                                )}
                                                                {hasDeletePermission && (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setDeleteOpen(true);
                                                                            handleClose();
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted" />
                                                                        Delete
                                                                    </MenuItem>
                                                                )}
                                                            </Menu>
                                                        </Td>
                                                    )}
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}

                            </div>
                            {payoutList?.length > 0 && (<div className="numbers_pagination_holder">
                                <PaginationComponent
                                    paginationMeta={paginationMeta}
                                    handleChangePagination={paginationModelMethod}
                                    update={update}
                                >
                                </PaginationComponent>
                            </div>)}
                        </div>
                    </Card>
                </div>
            </div>

            {
                noteModal && <AddOrEditOrViewNoteModal handleNoteModalToggle={handleNoteModalToggle} handleSubmit={handleSubmit} noteModalActionAndPayload={noteModalActionAndPayload} />
            }

            {deleteOpen && (
                <DeleteModal
                    isLoading={isLoading}
                    show={deleteOpen}
                    onCloseClick={handleDeleteCloseModal}
                    onDeleteClick={() => handleDelete()}
                />
            )}

            {isPayment?.status && (
                <ConfirmationModal
                    action={""}
                    show={isPayment?.status}
                    text={"payment the payout"}
                    onCloseClick={() => setIsPayment({ status: false, data: null })}
                    onAcceptClick={() => handlePayment()}
                    isDisabled={isLoading}
                />
            )}
        </>
    );
}

export default PaySlips;
