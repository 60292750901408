import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { roleListing } from "../../api/role";
import { ACTIVE_STATUS, ROLES } from "../../constants/variables";
import { toast } from "react-toastify";

function TenantDetailDrawer({
  isOpen,
  toggleDrawer,
  handleFilter,
  setFilterObject,
  filterObject,
  isLoading,
  setPage,
  setUpdate,
}) {
  const [isExistingTenantFilter] = useState(false);
  const exclude_filter_fields = ["role"];
  const exclude_filter_inputs = [
    `${ROLES.OWNER},${ROLES.TENANT},${ROLES.SUPER_ADMIN}`,
  ];
  const [limitRole] = useState(10);
  const [rolePage, setRolePage] = useState(1);
  const [roleMeta, setRoleMeta] = useState({});
  const [roleList, setRoleList] = useState([]);
  const changeFilter = (e, v) => {
    setUpdate(0);
    setPage(1);
    setFilterObject((old) => {
      if (v?.id) {
        return {
          ...old,
          role: v?.role,
        };
      } else {
        return {
          ...(filterObject?.name && { name: filterObject?.name }),
          ...(filterObject?.status && { status: filterObject?.status }),
          ...(filterObject?.contact_number && {
            contact_number: filterObject?.contact_number,
          }),
        };
      }
    });
  };
  const filterValues = (v, n) => {
    setFilterObject((old) => {
      return {
        ...old,
        [n]: v,
      };
    });
  };
  const handleFilterData = () => {
    setPage(1);
    setUpdate(0);
    const searchFile = {
      ...(filterObject?.role && { role: filterObject?.role }),
      ...(filterObject?.name && { name: filterObject?.name }),
      ...(filterObject?.status && { status: filterObject?.status }),
      ...(filterObject?.contact_number && {
        contact_number: filterObject?.contact_number,
      }),
    };
    handleFilter(searchFile);
  };
  const fetchRoles = async (
    rolePage,
    limitRole,
    exclude_filter_fields,
    exclude_filter_inputs
  ) => {
    try {
      const params = {
        page: rolePage,
        limit: limitRole,
        exclude_filter_fields,
        exclude_filter_inputs,
      };
      const response = (await roleListing(params)).data.data;
      const modifiedData = response?.data || [];
      setRoleList((old) => [...old, ...modifiedData]);
      setRoleMeta(response?.meta);
    } catch (error) {
      return toast.error(error.message);
    }
  };
  const callEmptyFilterFields = () => {
    setFilterObject({});
    setUpdate(0);
    handleFilter({ is_system: 0 }, 1,"reset");
    setPage(1);
  };
  useEffect(() => {
    fetchRoles(
      rolePage,
      limitRole,
      exclude_filter_fields,
      exclude_filter_inputs
    );
  }, []);
  const scrollHandle = (e) => {
    const listboxNode = e.currentTarget;
    const x = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - x <= 1 &&
      roleMeta?.current_page < roleMeta?.last_page
    ) {
      fetchRoles(
        rolePage + 1,
        limitRole,
        exclude_filter_fields,
        exclude_filter_inputs
      );
      setRolePage((old) => old + 1);
    }
  };
 
  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isExistingTenantFilter)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>Employee Filter</OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div>
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="text"
                  value={filterObject?.name}
                  placeholder="Name"
                  name="name"
                  onChange={(event) =>
                    filterValues(event.target.value, event.target.name)
                  }
                />
              </div>
              <div
                className="col-md-6 col-12 mb-3"
                style={{
                  margin: "auto",
                }}
              >
                <Autocomplete
                  value={filterObject?.role || ""}
                  onChange={(event, newValue) => changeFilter(event, newValue)}
                  renderOption={(props, option) => (
                    <Box component="li" key={option.id} {...props}>
                      {option?.role}
                    </Box>
                  )}
                  options={roleList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Role" size="small" />
                  )}
                  ListboxProps={{
                    onScroll: scrollHandle,
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <TextField
                  size="small"
                  className="form-control"
                  type="number"
                  value={filterObject?.contact_number}
                  onChange={(event) =>
                    filterValues(event.target.value, event.target.name)
                  }
                  onWheel={(e) => e.target.blur()}
                  name="contact_number"
                  placeholder="Contact Number"
                />
              </div>
              <div className="col-sm-6 mb-3">
              <Autocomplete
                value={filterObject?.status || ""}
                disabled={isLoading}
                onChange={(event, newValue) => {
                  filterValues(newValue,'status')
                }}
                renderOption={(props, option) => (
                  <Box component="li" key={option} {...props}>
                    {option}
                  </Box>
                )}
                options={[ACTIVE_STATUS.ACTIVE,ACTIVE_STATUS.IN_ACTIVE]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Active Status*"
                    size="small"
                  />
                )}
                
              />
            </div>
            </Row>
            <div className="formActions btn_right ga">
              <Button
                onClick={handleFilterData}
                className="yellow_gradient_btn mx-2"
                disabled={
                  (!filterObject?.name &&
                    !filterObject?.role &&
                    !filterObject?.contact_number && 
                    !filterObject?.status) ||
                  isLoading
                }
              >
                Apply Filter
              </Button>
              <Button
                className="red_gradient_btn"
                disabled={
                  (!filterObject?.name &&
                    !filterObject?.role &&
                    !filterObject?.contact_number && !filterObject?.status) ||
                  isLoading
                }
                onClick={callEmptyFilterFields}
              >
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default TenantDetailDrawer;
