import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { fetchBedCountVsTimeData } from '../../api/dashboard';
import { ucFirst } from '../../helpers/string_helper';

const CustomBarChart = () => {
  const [bedCountData, setBedCountData] = useState([]);
  const [personName, setPersonName] = React.useState(["weekly"]);
  const [filterRange, setFilterRange] = useState('weekly')

  useEffect(() => {
    fetchRentPaymentList()
  }, [filterRange]);

  const fetchRentPaymentList = async () => {
    try {
      const res = (await fetchBedCountVsTimeData({ date_range: filterRange })).data.data;
      setBedCountData(res)
    } catch (error) {
      console.log(error);
    }
  };

  const optionsBarChart = {
    series: [{
      name: 'Inflation',
      data: bedCountData?.length > 0 ? bedCountData.map((item) => item.bedCount) : [],
    }],
    chart: {
      height: 280,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    noData: {
      text: "There's no data",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#feaa00'
          }]
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    xaxis: {
      categories: bedCountData?.length > 0 && bedCountData?.map((item) => item.timeName),
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        }
      },
    },
    title: {
      text: `${ucFirst(filterRange)} Inflation in Argentina`,
      floating: true,
      offsetY: 260,
      align: 'center',
      style: {
        color: '#444',
        fontSize: '10px'
      }
    }
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (bedCountData?.length > 0) {
      if (!chartRef.current) return;

      const chart = new ApexCharts(chartRef.current, optionsBarChart);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [bedCountData]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };

  const names = [
    'weekly',
    'monthly',
    'yearly',
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setFilterRange(value)
    setBedCountData([])
  };

  return (
    <div className='chart_box'>
      <div className='upper_card d-flex justify-content-between align-items-center'>
        <h5 style={{ color: '#565656' }}>No of bed booking Vs Time</h5>
        <div className="float-end">
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={personName}
              onChange={handleChange}
              MenuProps={MenuProps}
              style={{ width: 111, height: 31, fontSize: '12px', backgroundColor: '#fff' }}
          >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
      </div>
      <div id="chart" ref={chartRef} />
    </div>
  );
};

export default CustomBarChart;
