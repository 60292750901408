import { getAllNearTo } from '../common';
import { documentUpload } from '../documents';
import {
  getAllPropertyRequests,
  createPropertyRequest,
  updatePropertyRequest,
  deletePropertyRequest,
  // deletePropertyRequest,
} from '../property-request';
import { toast } from 'react-toastify';

export const getPropertyRequestHandler = async (setData) => {
  try {
    const response = await getAllPropertyRequests();
    setData(response.data.data);
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);  }
};

export const createPropertyRequestHandler = async (propertyRequestData) => {
  try {
    const response = await createPropertyRequest(propertyRequestData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const uploadDocuments = async (formData) => {
  try {
    const response = await documentUpload(formData);
    if (response.status === 200) {
      return { response, success: true };
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
    return { error, success: false };
  }
};

export const updatePropertyRequestHandler = async (id, propertyRequestData) => {
  try {
    const response = await updatePropertyRequest(id, propertyRequestData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const updateStatus = async (id, payload) => {
  try {
    const response = await updatePropertyRequest(id, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const fetchAllNearTo = async (setNearToOptions) => {
  try {
    const res = (await getAllNearTo()).data.data
    const result = res?.data.map(item => item.name);
    setNearToOptions(result);
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);  }
}

export const deletePropertyRequestHandler = async (id) => {
  try {
    const response = await deletePropertyRequest(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};
