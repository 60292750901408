import { tenantTicketCountData, updateComplain } from '../complainTicket'
import { toast } from 'react-toastify';
import {
  getComplainData,
  updateComplainStatus,
  createComplain,
  getPropertyOwner,
  searchTenants,
  priorityUpdate,
  updateComplainNote,
  complainHandlerUpdate,
} from '../complainTicket';

const tenantsTicketsCount = async (params) => {
  try {
    const response = await tenantTicketCountData(params);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
export {
  tenantsTicketsCount,
}

const allComplain = async (params) => {
  try {
    const response = await getComplainData(params);
    return response;
  } catch (error) {
    console.log(error);
  }
};
// complain status
const updateComplainStatusById = async (id, option) => {
  try {
    const response = await updateComplainStatus(id, { status: option });
    toast.success(response.data.message);
    return response.data.data;
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};
// update notes
const updateComplainNotesById = async (id, note) => {
  try {
    const response = await updateComplainNote(id, note);
    toast.success(response.data.message);
    return response.data.data;
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};

// priority update
const updatPriorityById = async (id, option) => {
  try {
    const response = await priorityUpdate(id, { priority: option });
    toast.success(response.data.message);
    return response.data.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

const complainCreate = async (data) => {
  try {
    return await createComplain(data);
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};

const ownerComplain = async (params) => {
  try {
    const response = await getPropertyOwner(params);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const fetchTenantsData = async (search) => {
  try {
    const response = await searchTenants(search);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

// update handler
const updateComplainHandler = async (id, payload) => {
  try {
    const response = await complainHandlerUpdate(id, payload);
    toast.success(response.data.message);
    return response.data.data;
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
  }
};

// edit handler
const editComplainHandler = async (payload) => {
  try {
    const response = await updateComplain(payload);
    toast.success(response.data.message);
    return true;
  } catch (e) {
    if (e?.response?.data?.message) toast.error(e?.response?.data?.message);
    else toast.error(e.message);
    console.log(e);
    return false
  }
};

export {
  allComplain,
  updateComplainStatusById,
  complainCreate,
  ownerComplain,
  fetchTenantsData,
  updatPriorityById,
  updateComplainNotesById,
  updateComplainHandler,
  editComplainHandler,
};
