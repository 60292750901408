import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Card, CardBody, UncontrolledTooltip, Button,
} from 'reactstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'flatpickr/dist/themes/material_blue.css';
import AddRoleDrawer from '../../../components/Roles/AddRoles';
import ViewRoleDrawer from '../../../components/Roles/ViewRoles';
import EditRoleDrawer from '../../../components/Roles/EditRoles';
import ViewUsersModal from '../../../components/Roles/ViewAllUser';

// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { removeRole, roleListing } from '../../../api/role'
import { toast } from 'react-toastify';
import { ACTIONS, ALL_MODULES, KNOWN_ROLES, OPERATION_TYPE } from '../../../common/constant';
import { CircularProgress } from '@mui/material';
import { usePermissionGiven } from '../../../components/Hooks/UserPermission';
import { getS3BaseUrl, ucFirst } from '../../../helpers/string_helper';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';

function Roles() {
  document.title = ' Roles | Dstayz';

  const [isEditRole, setEditRole] = useState(false);
  const [isViewRole, setViewRole] = useState(false);
  const [isViewUserModal, setViewUserModal] = useState(false);
  const [isAddRoleDrawer, setAddRoleDrawer] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState();
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const hasRoleEditPermission = usePermissionGiven(ALL_MODULES.ROLE_PERMISSIONS, OPERATION_TYPE.UPDATE)
  // const hasRoleViewPermission = usePermissionGiven(ALL_MODULES.ROLE_PERMISSIONS, OPERATION_TYPE.READ)
  const hasRoleDeletePermission = usePermissionGiven(ALL_MODULES.ROLE_PERMISSIONS, OPERATION_TYPE.DELETE)
  const s3Url = getS3BaseUrl()

  const [roleMeta, setRoleMeta] = useState({
    roles: [],
    loading: false,
    user_count_to_display: 3,
    view_users_role_index: null
  })

  const fetchAllRoles = async () => {
    try {
      setRoleMeta(prev => ({
        ...prev,
        loading: true
      }))
      const query = {
        display_users: true,
        display_users_count: true,
        exclude_filter_fields: ['role', 'is_system'],
        exclude_filter_inputs: [`${KNOWN_ROLES.OWNER},${KNOWN_ROLES.TENANT}`, 1],
      }

      const response = await roleListing(query)
      setRoleMeta(prev => ({
        ...prev,
        roles: response.data.data.data
      }))
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'Error: Could not load roles.');
    } finally {
      setRoleMeta(prev => ({
        ...prev,
        loading: false
      }))
    }
  }

  useEffect(() => {
    fetchAllRoles();
  }, [])

  const deleteRoleHandler = async (id) => {
    try {
      const response = await removeRole(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };

  // Add new roles drawer
  const toggleAddRoles = () => {
    setAddRoleDrawer(!isAddRoleDrawer);
  };

  // edit role drawer
  const toggleEditRole = () => {
    setEditRole(!isEditRole);
  };

  // view role drawer
  const toggleViewRole = (roleIndex) => {
    console.log(roleIndex, typeof roleIndex, typeof roleIndex === 'number')
    setRoleMeta((prev) => ({
      ...prev,
      view_users_role_index: typeof roleIndex === 'number' ? roleIndex : null
    }))
    setViewRole(!isViewRole);
  };

  // view user modal
  const toggleViewUsers = (roleIndex) => {
    console.log(roleIndex, typeof roleIndex, typeof roleIndex === 'number')
    setRoleMeta((prev) => ({
      ...prev,
      view_users_role_index: typeof roleIndex === 'number' ? roleIndex : null
    }))
    setViewUserModal(!isViewUserModal);
  };

  const getInitialLetter = (name) => {
    const nameArray = name.split(' ')
    let initial = ''
    nameArray.forEach((item) => {
      initial = initial.concat(ucFirst(item.charAt(0)))
    })

    return initial
  }

  return (
    <>
      <div className="page-content">
        <Container fluid className="no_breadcrumbs_holder">
          <Breadcrumbs breadcrumbItem="Roles" />
          <div className="flexbox_holder mb-4">
            <p className="text-muted mb-0">
              A role provided access to predefined menus and features so that depending on
              <br />
              assigned role an administrator can have access to what user needs.
            </p>
            {usePermissionGiven(ALL_MODULES.ROLE_PERMISSIONS, OPERATION_TYPE.CREATE) && (
              <Button color="primary" onClick={toggleAddRoles}>
                <i className="mdi mdi-plus" />
                <span className="ps-2">Role</span>
              </Button>
            )}
          </div>
          <Row>
            {
              roleMeta.loading &&
              <>
                <Col className="col-md-12">
                  <div className='d-flex justify-content-center align-items-center' style={{ height: '20rem' }}>
                    <CircularProgress size={50} thickness={5} color='inherit' style={{ color: '#00000', textAlign: 'center' }} />
                  </div>
                </Col>
              </>
            }
            {
              !roleMeta.loading && roleMeta.roles.length === 0 && <>no data</>
            }
            {
              !roleMeta.loading && roleMeta.roles.length > 0 && roleMeta.roles.map((role, index) => (
                <>
                  <Col className="col-md-4" key={`role-${index}`}>
                    <Card className="stat_info_card">
                      <CardBody>
                        <div className="flexbox_holder">
                          <h6 className="text-muted">Total {role._count.users} user{role._count.users > 2 ? 's' : ''}</h6>
                          <div className="grouping_image_holder">
                            {role.users && role.users.length !== 0 && role.users.slice(0, roleMeta.user_count_to_display).map((_user) => (
                              <div className="each_user_holder" key={`role-${role.id}-user-${_user.id}`}>
                                {_user.profile_photo ? (
                                  <img
                                    className="initial_letter"
                                    src={`${s3Url}${_user.profile_photo}`}
                                    alt="Header Avatar"
                                    id={`role-${role.id}-user-${_user.id}-edit-tooltip`}
                                  />
                                ) : (
                                  <span className='initial_letter' id={`role-${role.id}-user-${_user.id}-edit-tooltip`}> {getInitialLetter(_user.name)} </span>
                                )}
                                <UncontrolledTooltip placement="bottom" target={`role-${role.id}-user-${_user.id}-edit-tooltip`}>
                                  {_user.name}
                                </UncontrolledTooltip>
                              </div>
                            ))}

                            {
                              role.users.slice(roleMeta.user_count_to_display).length > 0 && (
                                <Button
                                  color="light"
                                  outline
                                  className="btn-sm text-info p-0 ms-1"
                                  onClick={() => { toggleViewUsers(index) }}
                                >
                                  + {role.users.slice(roleMeta.user_count_to_display).length} more
                                </Button>
                              )
                            }
                          </div>
                        </div>
                        <h4 className="main_heading">{role.role}</h4>
                        <div className="flexbox_holder">
                          {hasRoleEditPermission && (
                            <Button
                              color="light"
                              outline
                              className="text-primary p-0"
                              onClick={() => {
                                setSelectedRoleId(role.id)
                                toggleEditRole()
                              }}
                            >
                              Edit Role
                            </Button>
                          )}
                          <div>
                            {/* {hasRoleViewPermission && (
                              <Button color="light" outline className="text-info p-0 me-1" onClick={() => { toggleViewRole(index) }}>
                                <i className="mdi mdi-eye" />
                              </Button>
                            )} */}
                            {hasRoleDeletePermission && (
                              <Button color="light" outline className="text-info p-0" onClick={() => setDeleteConfirm({ status: true, id: role.id })}>
                                <i className="mdi mdi-trash-can-outline font-size-18 align-middle  text-muted theme_color text-center delete_action_col" />
                              </Button>
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              ))
            }


            {/* <Col className="col-md-4">
              <Card className="stat_info_card">
                <CardBody>
                  <div className="flexbox_holder">
                    <h6 className="text-muted">Total 5 users</h6>
                    <div className="grouping_image_holder">
                      <div className="each_user_holder">
                        <img src={avatar1} className="avatar-sm" alt="" id="edittooltip1" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip1">
                          User 1
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar2} className="avatar-sm" alt="" id="edittooltip2" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip2">
                          User 2
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar3} className="avatar-sm" alt="" id="edittooltip3" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip3">
                          User 3
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar4} className="avatar-sm" alt="" id="edittooltip4" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip4">
                          User 4
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar5} className="avatar-sm" alt="" id="edittooltip5" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip5">
                          User 5
                        </UncontrolledTooltip>
                      </div>
                      <Button color="light" outline className="btn-sm text-info p-0 ms-1">+ 5 more</Button>
                    </div>
                  </div>
                  <h4 className="main_heading">Property Manager</h4>
                  <div className="flexbox_holder">
                    <Button color="light" outline className="text-primary p-0">Edit Role</Button>
                    <Button color="light" outline className="text-info p-0">
                      <i className="mdi mdi-eye" />
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-md-4">
              <Card className="stat_info_card">
                <CardBody>
                  <div className="flexbox_holder">
                    <h6 className="text-muted">Total 5 users</h6>
                    <div className="grouping_image_holder">
                      <div className="each_user_holder">
                        <img src={avatar1} className="avatar-sm" alt="" id="edittooltip1" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip1">
                          User 1
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar2} className="avatar-sm" alt="" id="edittooltip2" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip2">
                          User 2
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar3} className="avatar-sm" alt="" id="edittooltip3" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip3">
                          User 3
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar4} className="avatar-sm" alt="" id="edittooltip4" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip4">
                          User 4
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar5} className="avatar-sm" alt="" id="edittooltip5" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip5">
                          User 5
                        </UncontrolledTooltip>
                      </div>
                      <Button color="light" outline className="btn-sm text-info p-0 ms-1">+ 5 more</Button>
                    </div>
                  </div>
                  <h4 className="main_heading">Canteen Manager</h4>
                  <div className="flexbox_holder">
                    <Button color="light" outline className="text-primary p-0">Edit Role</Button>
                    <Button color="light" outline className="text-info p-0">
                      <i className="mdi mdi-eye" />
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-md-4">
              <Card className="stat_info_card">
                <CardBody>
                  <div className="flexbox_holder">
                    <h6 className="text-muted">Total 5 users</h6>
                    <div className="grouping_image_holder">
                      <div className="each_user_holder">
                        <img src={avatar1} className="avatar-sm" alt="" id="edittooltip1" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip1">
                          User 1
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar2} className="avatar-sm" alt="" id="edittooltip2" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip2">
                          User 2
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar3} className="avatar-sm" alt="" id="edittooltip3" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip3">
                          User 3
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar4} className="avatar-sm" alt="" id="edittooltip4" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip4">
                          User 4
                        </UncontrolledTooltip>
                      </div>
                      <div className="each_user_holder">
                        <img src={avatar5} className="avatar-sm" alt="" id="edittooltip5" />
                        <UncontrolledTooltip placement="bottom" target="edittooltip5">
                          User 5
                        </UncontrolledTooltip>
                      </div>
                      <Button color="light" outline className="btn-sm text-info p-0 ms-1">+ 5 more</Button>
                    </div>
                  </div>
                  <h4 className="main_heading">Accountant</h4>
                  <div className="flexbox_holder">
                    <Button color="light" outline className="text-primary p-0">Edit Role</Button>
                    <Button color="light" outline className="text-info p-0">
                      <i className="mdi mdi-eye" />
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>

      {isAddRoleDrawer && <AddRoleDrawer isOpen={isAddRoleDrawer} toggleDrawer={toggleAddRoles} refresh={fetchAllRoles} />}

      {isEditRole && <EditRoleDrawer isOpen={isEditRole} toggleDrawer={toggleEditRole} selectedRoleId={selectedRoleId} />}
      {/* Delete confirmation modal */}
      
      {isDeleteConfirm.status && (
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDeleteConfirm.status}
          onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
          onAcceptClick={async () => {
            const isDeleted = await deleteRoleHandler(isDeleteConfirm.id);
            if (isDeleted) {
              fetchAllRoles();
            }
            setDeleteConfirm({ status: false, id: null });
          }}
        />
      )}

      {
        isViewRole && typeof roleMeta.view_users_role_index === 'number' &&
        <ViewRoleDrawer isOpen={isViewRole} toggleDrawer={toggleViewRole} role_id={typeof roleMeta.view_users_role_index === 'number' ? roleMeta.roles[roleMeta.view_users_role_index].id : null} />
      }


      {
        isViewUserModal && typeof roleMeta.view_users_role_index === 'number' &&
        <ViewUsersModal isOpen={isViewUserModal} toggleDrawer={toggleViewUsers} users={typeof roleMeta.view_users_role_index === 'number' ? roleMeta.roles[roleMeta.view_users_role_index].users : []} />
      }
    </>
  );
}

export default Roles;
