import React from 'react';

function GlobalInnerHeader(props) {
  return (
    <h4 style={{ fontWeight: '600', fontSize: '18px' }}>
      {props.title}
    </h4>
  );
}

export default GlobalInnerHeader;
