import axiosInstance from "../utils/axios/auth";
import { ToastContainer, toast } from "react-toastify";
import { documentUpload, fileUpload } from "./documents";

export const employeeUserCreate = async (payload) => await axiosInstance.post('/user/admin-user/create',payload);

export const uploadDocuments = async (formData) => {
    try {
      const response = await documentUpload(formData);
      if (response.status === 200) {
        return {response, success: true};
      } else {
        ToastContainer.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      return {error, success: false};
    }
  };


  export const fileUploads = async (formData) => {
    try {
      const response = await fileUpload(formData);
      if (response.status === 200) {
        return {response, success: true};
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      return {error, success: false};
    }
  };
