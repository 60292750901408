import React, { useState, useEffect } from 'react';
import {
  Button, Offcanvas, OffcanvasHeader, OffcanvasBody, Input, Label,
} from 'reactstrap';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getAllPermissions, roleCreate } from '../../api/role';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextField } from "@mui/material";
import { toast } from "react-toastify"
import { ACTIONS, ALL_MODULES, OPERATION_TYPE } from '../../common/constant';
import { AsterikLabel } from '../Common/AsterikLabel';


function AddRoles({ isOpen, toggleDrawer, action, refresh }) {
  const [permissionList, setPermissionList] = useState([]);
  const [selectedMulti, setselectedMulti] = useState(false);
  const [selectedIdsChecked, setSelectedIdsChecked] = useState([]);
  const [selectedIds, setSelectedIds] = useState([])
  const [update, setUpdate] = useState(0)

  useEffect(() => {
    fetchAllPermission()
  }, [])

  const fetchAllPermission = async () => {
    try {
      const result = await getAllPermissions()
      const permission = result.data.data.map((item) => {
        let index = 0
        const permissionData = item.permissions.map((per) => {
          return { id: per.id, checked: false }
        })
        setSelectedIdsChecked((pre) => {
          if (pre) {
            return [...pre, ...permissionData]
          } else {
            return [...permissionData]
          }
        })
        return {
          id: ++index,
          module: item.module,
          permissions: item.permissions,
        }
      })
      setPermissionList(permission)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (selectedMulti) {
      let selectedData = []
      permissionList.forEach((item) => {
        item.permissions.forEach((permission) => {
          selectedData.push(permission.id)
        })
      })
      setSelectedIds(selectedData)
      validation.setFieldValue('module_permissions', selectedData)
    } else if (selectedIds.length === selectedIdsChecked?.length && !selectedMulti) {
      setSelectedIds([])
      validation.setFieldValue('module_permissions', [])
    }
  }, [selectedMulti])

  useEffect(() => {
    if (selectedIds?.length === selectedIdsChecked?.length) {
      setselectedMulti(true)
    } else {
      setselectedMulti(false)
    }

  }, [selectedIds, selectedIdsChecked])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: '',
      module_permissions: ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      role: Yup.string().required('Please enter role'),
      module_permissions: Yup.array().of(Yup.number()).required().min(1, 'Please select at least one module permission')
    }),
  });

  const handleCreateRole = async () => {
    try {
      const response = await roleCreate({
        role: validation.values.role,
        module_permissions: selectedIds
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        toggleDrawer(false)
        validation.resetForm()
        refresh()
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isOpen)}
      className="table_offcanvas_holder"
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        {action === ACTIONS.EDIT ? 'Edit Role' : 'Add Role'}
      </OffcanvasHeader>
      <OffcanvasBody>
        {action !== ACTIONS.EDIT && (
          <div className="mb-2">
            {/* <label className="control-label">
              Add Role Name
            </label> */}
            <TextField
              size='small'
              className="form-control"
              type="text"
              label={<div>Add Role Name <AsterikLabel /></div>}
              name='role'
              value={validation.values.role}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            <div>
              {validation.touched.role && validation.errors.role && (
                <span className="text-danger">{validation.errors.role}</span>
              )}
            </div>
          </div>
        )}
        <div className="table-rep-plugin add_role_scroller">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table
              id="tech-companies-1"
              className="table"
            >
              <Thead>
                <Tr>
                  <Th>Administrator Access</Th>
                  <Th colSpan={4}>
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="formrow-customCheck"
                        checked={selectedMulti}
                        onClick={() => setselectedMulti(!selectedMulti)}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="formrow-customCheck"
                      >
                        Select All
                      </Label>
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {permissionList.map((eachData, id) => (
                  chunkPermissions(eachData.permissions).map((chunk, index) => (
                    <Tr key={id}>
                      {index === 0 && (
                        <Td rowSpan={chunkPermissions(eachData.permissions).length}>
                          {eachData.module}
                        </Td>
                      )}
                      {chunk.map((item) => (
                        <Td key={item.id}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id={`checkbox-${item.id}`}
                              key={update}
                              onChange={() => {
                                if (selectedIds.includes(item.id)) {
                                  const index = selectedIds.indexOf(item.id);
                                  if (index > -1) {
                                    if (item.action === OPERATION_TYPE.READ) {
                                      selectedIds.splice(index, 1);
                                      if (selectedIds.includes(item.id + 1)) {
                                        const createIndex = selectedIds.indexOf(item.id + 1)
                                        selectedIds.splice(createIndex, 1);
                                      }
                                      if (selectedIds.includes(item.id + 2)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 2)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (selectedIds.includes(item.id + 3)) {
                                        const deleteIndex = selectedIds.indexOf(item.id + 3)
                                        selectedIds.splice(deleteIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.PROPERTIES && selectedIds.includes(item.id + 4)) {
                                         const updateIndex = selectedIds.indexOf(item.id + 4)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.PROPERTIES && selectedIds.includes(item.id + 5)) {
                                         const updateIndex = selectedIds.indexOf(item.id + 5)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.FINANCE && selectedIds.includes(item.id + 4)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 4)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                      if (eachData.module === ALL_MODULES.FINANCE && selectedIds.includes(item.id + 5)) {
                                        const updateIndex = selectedIds.indexOf(item.id + 5)
                                        selectedIds.splice(updateIndex, 1);
                                      }
                                    } else {
                                      selectedIds.splice(index, 1);
                                    }
                                  }
                                  setselectedMulti(false)
                                  validation.setFieldValue("module_permissions", selectedIds)
                                  if (selectedIds?.length === 0 && !validation.touched?.module_permissions) {
                                    validation.setFieldTouched('module_permissions', true, true)
                                  }
                                } else {
                                  let defaultActionId
                                  if (OPERATION_TYPE.CREATE === item.action) {
                                    defaultActionId = item.id - 1
                                  } else if (OPERATION_TYPE.UPDATE === item.action) {
                                    defaultActionId = item.id - 2
                                  } else if (OPERATION_TYPE.DELETE === item.action) {
                                    defaultActionId = item.id - 3
                                  } else if (OPERATION_TYPE.ADD_TENANT === item.action) {
                                    defaultActionId = item.id - 4
                                  } else if (OPERATION_TYPE.REMOVE_TENANT === item.action) {
                                    defaultActionId = item.id - 5
                                  } else if (OPERATION_TYPE.PAYOUT_PAYMENT === item.action) {
                                    defaultActionId = item.id - 4
                                  } else if (OPERATION_TYPE.DEPOSIT_PAYMENT === item.action) {
                                    defaultActionId = item.id - 5
                                  }

                                  if (defaultActionId && !selectedIds.includes(defaultActionId)) {
                                    setSelectedIds((prev) => {
                                      if (prev) {
                                        return [...prev, defaultActionId, item.id]
                                      } else {
                                        return [defaultActionId, item.id]
                                      }
                                    })
                                  } else {
                                    setSelectedIds((prev) => {
                                      if (prev) {
                                        return [...prev, item.id]
                                      } else {
                                        return [item.id]
                                      }
                                    })
                                  }
                                  validation.setFieldValue("module_permissions", selectedIds.length === 0 ? [item.id] : selectedIds)
                                }

                                setUpdate(update + 1)
                              }}
                              checked={selectedIds?.includes(item.id)}
                              name="module_permissions"
                              onBlur={validation.handleBlur}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={`checkbox-${item.id}`}
                            >
                              {item.action}
                            </Label>
                          </div>
                        </Td>
                      ))}
                      {chunk.length < 5 && (
                        Array.from({ length: 5 - chunk.length }).map((_, idx) => (
                          <Td key={`empty-${idx}`}></Td>
                        ))
                      )}
                    </Tr>
                  ))
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
        <div className='mt-2'>
          {validation.touched?.module_permissions && validation.errors?.module_permissions && (
            <span className="text-danger">{validation.errors?.module_permissions}</span>
          )}
        </div>
        <div className="text-center py-2">
          <Button color="danger" outline className="me-2">Cancel</Button>
          <Button className="yellow_gradient_btn" onClick={handleCreateRole} type="submit" disabled={!validation.isValid}>Save</Button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default AddRoles;

// Function to chunk permissions into groups of 5 for rendering

const chunkPermissions = (permissions) => {
  const chunkSize = 4;
  return permissions.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};
