import { documentUpload } from '../documents';
import {
  getAllPropertyOwner,
  createPropertyOwner,
  updatePropertyOwner,
  deletePropertyOwner,
  getDashboardData,
} from '../owner';
import { toast } from 'react-toastify';

export const getPropertyOwnerHandler = async (setData) => {
  try {
    const response = await getAllPropertyOwner();
    setData(response.data.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPropertyOwnerHandler = async (ownerData) => {
  try {
    const response = await createPropertyOwner(ownerData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
  return false;
};

export const uploadDocuments = async (formData) => {
  try {
    const response = await documentUpload(formData);
    if (response.status === 200) {
      return { response, success: true };
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
    return { error, success: false };
  }
};

export const updatePropertyOwnerHandler = async (id, ownerData) => {
  try {
    const response = await updatePropertyOwner(id, ownerData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
  return false;
};

export const updateStatus = async (id, payload) => {
  try {
    const response = await updatePropertyOwner(id, payload);
    if (response.status === 200) {
      toast.success(response.data.message);
      return true;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
  return false;
};

export const deletePropertyOwnerHandler = async (id) => {
  try {
    const response = await deletePropertyOwner(id);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      return true;
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    if(error.response?.data?.message) toast.error(error.response?.data?.message);
    else toast.error(error.message);
    console.log(error);
  }
  return false;
};

export const updateStats = async (setStats) => {
  try {
    const res = await getDashboardData();
    if (res.status === 200) {
      const {
        total_owners,
        total_active_property_owners,
        total_live_property_owners,
        total_suspended_property_owners,
      } = res.data.data;
      setStats({
        totalOwners: total_owners,
        totalActivePropertyOwners: total_active_property_owners,
        totalLivePropertyOwners: total_live_property_owners,
        totalSuspendedPropertyOwners: total_suspended_property_owners,
      });
    } else {
      toast.error(res?.data?.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};
