import React, { useState } from 'react';
import {
  TabContent, TabPane, NavLink, NavItem, Nav,
} from 'reactstrap';
import classnames from 'classnames';
import TenantTab from './tenantTab';
import PropertyOwnerTab from './propertyOwnerTab';
import { ROLES } from '../../constants/variables';

function ComplainTicket({ setSelectedTab, fetchCounterData, navigateState }) {
  const [activeTab1, setactiveTab1] = useState('5');

  function toggle1(tab) {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  }

  // testing 
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  useState(()=>{
    if(navigateState?.role === ROLES.TENANT){
      toggle1('5');
      handleTabClick('Tenant')
    }
    else if(navigateState?.role === ROLES.OWNER){
      toggle1('6');
      handleTabClick('PropertyOwner')
    }
  }, [])
  return (
    <div className="complainTicketTabs mt-4">
      <Nav pills>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({
              active: activeTab1 === '5',
            })}
            onClick={() => {
              toggle1('5');
              handleTabClick('Tenant')
            }}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home" />
            </span>
            <span className="d-none d-sm-block">Tenant</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({
              active: activeTab1 === '6',
            })}
            onClick={() => {
              toggle1('6');
              handleTabClick('PropertyOwner')
            }}
          >
            <span className="d-block d-sm-none">
              <i className="far fa-user" />
            </span>
            <span className="d-none d-sm-block">Property Owner</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab1} className="p-3 text-muted">
        <TabPane tabId="5">
          <TenantTab fetchCounterData={fetchCounterData}/>
        </TabPane>
        <TabPane tabId="6">
          <PropertyOwnerTab fetchCounterData={fetchCounterData}/>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default ComplainTicket;
