import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Row, Form, Button, Offcanvas, OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import { COMPLAIN_TICKET_PRIORITY, COMPLAIN_TICKET_STATUS } from '../../common/constant';

function complainOwnreFilterDrawer({ isOpen, toggleDrawer, emitFilter, selectedFields, selectedInputs }) {
  const [isExistingTenantFilter, setExistingTenantFilter] = useState(false);
  const [filter, setFilter] = useState("");

  function removeEmptyValues(obj) {
    const result = {}
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        result[key] = obj[key]
      }
    }
    return result
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredValues = removeEmptyValues(filter)
    emitFilter({
      keys: Object.keys(filteredValues),
      values: Object.values(filteredValues)
    })
    toggleDrawer()
  };

  // research here
  const [filterObject, setFilterObject] = useState({
    property_name: '',
    priority: '',
    status: '',
    created_at: '',
  })

  const filterInputs = ['OWNER']
  const filterFields = ['role']

  const handleFilter = () => {

    if (filterObject.property_name) {
      filterFields.push('property_name')
      filterInputs.push(filterObject.property_name)
    }

    if (filterObject.priority) {
      filterFields.push('priority')
      filterInputs.push(filterObject.priority)
    }

    if (filterObject.status) {
      filterFields.push('status')
      filterInputs.push(filterObject.status)
    }

    if (filterObject.created_at) {
      filterFields.push('created_at')
      filterInputs.push(filterObject.created_at)
    }

    emitFilter(filterFields, filterInputs)
    toggleDrawer()
  }

  const resetFilter = () => {
    filterInputs.splice(0, filterInputs.length)
    filterFields.splice(0, filterFields.length)
    emitFilter(filterFields, filterInputs);
    toggleDrawer();
    setFilterObject({
      property_name: '',
      priority: '',
      status: '',
      created_at: '',
    });
  }

  useEffect(()=> {
    selectedFields.map((field, index) => {
      setFilterObject((prev)=> ({...prev, [field]:selectedInputs[index]}))
    })
  }, [])

  return (
    <Offcanvas
      isOpen={isOpen}
      direction="end"
      toggle={() => toggleDrawer(!isExistingTenantFilter)}
    >
      <OffcanvasHeader toggle={toggleDrawer}>
        Owner&apos;s Complain Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form onSubmit={handleSubmit}>
          <div className="">
            <Row>
              <div className="col-md-6 col-12 mb-3">
                <input
                  className="form-control"
                  // type="date"
                  placeholder="YYYY-MM-DD"
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      created_at: e.target.value
                    })
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  value={filterObject.status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      status: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(COMPLAIN_TICKET_STATUS)}
                  renderInput={(params) => <TextField {...params} label="Select Status" size="small" />}
                />
              </div>
              {/* <div className="col-md-6 col-12 mb-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Property Name*"
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      property_name: e.target.value
                    })
                  }}
                />
              </div> */}
              <div className="col-md-6 col-12 mb-3">
                <Autocomplete
                  value={filterObject.priority}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      priority: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(COMPLAIN_TICKET_PRIORITY)}
                  renderInput={(params) => <TextField {...params} label="Select Priority" size="small" />}
                />
              </div>
            </Row>
            <div className='formActions btn_right'>
              <Button className='yellow_gradient_btn me-2' onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className='red_gradient_btn' onClick={resetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default complainOwnreFilterDrawer;
