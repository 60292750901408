import axiosInstance from "../utils/axios/auth";
import { getParam } from '../helpers/getParam';

const roomTypeListing = async () => await axiosInstance.get('/commons/room-types');
const bedTypeListing = async () => await axiosInstance.get('/commons/bed-types');
const getAllNearTo = async (params) => await axiosInstance.get('/near-to-tag', getParam(params));
export const sendPasswordResetLink = async (id) => await axiosInstance.get(`/user/send-reset-link/${id}`);
export const verifyPasswordResetLink = async (token) => await axiosInstance.get(`/user/password-reset/${token}`);
export const passwordResetWithURL = async (token, payload) => await axiosInstance.put(`/user/password-reset/${token}`, payload);

export {
    roomTypeListing,
    bedTypeListing,
    getAllNearTo
}