import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";

// const globalSettingCreated= async (payload) => await axiosInstance.post('/create/global-settings', payload);
const getAllGlobalSettings = async (params) => await axiosInstance.get('global-settings/list', getParam(params));

const updateFromList = async ({id,payload}) => await axiosInstance.put(`/global-settings/update/${id}`, payload);
const deleteFromList = async (payload) => await axiosInstance.delete('/global-settings/delete', payload);

const globalSettingsViewById = async(id) => await axiosInstance.get(`/global-settings/view/${id}`)
export {
    getAllGlobalSettings,
    updateFromList,
    deleteFromList,
    globalSettingsViewById
}