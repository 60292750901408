import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { COMPANY_ASSOCIATE_TYPES, USER_STATUS } from '../../common/constant';
import { TextField, Autocomplete, Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import { handleKeyDown } from '../../helpers/string_helper';

function CompanyAssociatesFilter({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [isCompanyAssociatesFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({
    name: '',
    type: '',
    status: '',
    contact_number: '',
  });
  const filterInputs = [];
  const filterFields = [];


  const handleFilter = () => {
    if (filterObject.contact_number) {
      filterFields.push('contact_number');
      filterInputs.push(filterObject.contact_number);
    }

    if (filterObject.type) {
      filterFields.push('type');
      filterInputs.push(filterObject.type);
    }

    if (filterObject.status) {
      filterFields.push('status');
      filterInputs.push(filterObject.status);
    }

    if (filterObject.name) {
      filterFields.push('name');
      filterInputs.push(filterObject.name);
    }
    
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const resetFilter = () => {
    filterInputs.splice(0, filterInputs.length)
    filterFields.splice(0, filterFields.length)
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
    setFilterObject({
      name: '',
      type: '',
      status: '',
      designation: '',
    });
  }

  useEffect(()=> {
    selectedFields.map((field, index) => {
      setFilterObject((prev)=> ({...prev, [field]:selectedInputs[index]}))
    })
  }, [])

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => toggleDrawer(!isCompanyAssociatesFilter)}>
      <OffcanvasHeader toggle={toggleDrawer}>
        Company Associates Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className='companyAssociatesDetailsDrawer'>
            <Row>
              {/* Name */}
              <div className='col-sm-6 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  name='name'
                  value={filterObject.name}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value
                    })
                  }}
                  size='small'
                />
              </div>

              {/* Associates Type */}
              <div className='col-sm-6 mb-3'>
                <Autocomplete
                  value={filterObject.type}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      type: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(COMPANY_ASSOCIATE_TYPES)}
                  renderInput={(params) => <TextField {...params} label="Associates Type" />}
                  size='small'
                />
              </div>

              {/* Associates Status */}
              <div className='col-sm-6 mb-3'>
                <Autocomplete
                  value={filterObject.status}
                  onChange={(event, newValue) => {
                    setFilterObject({
                      ...filterObject,
                      status: newValue
                    })
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  options={Object.values(USER_STATUS)}
                  renderInput={(params) => <TextField {...params} label="Associates Status" />}
                  size='small'
                />
              </div>

              {/* Contact No */}

              <div className='col-sm-6 mb-3'>
                <TextField
                  className='form-control'
                  type='text'
                  label='Contact Number'
                  name='contact_number'
                  value={filterObject.contact_number}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      contact_number: e.target.value
                    })
                  }}
                  size='small'
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                />
              </div>
            </Row>
            <div className='formActions btn_right'>
              <Button className='yellow_gradient_btn me-2' onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className='red_gradient_btn' onClick={resetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

CompanyAssociatesFilter.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  emitFilterItem: PropTypes.func,
}
export default CompanyAssociatesFilter;
