import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";

export const sendMessage = async (payload) => await axiosInstance.post('/message', payload);

export const getAllMessages = async (params) => await axiosInstance.get('/message', getParam(params));

export const getMessageDetails = async (id) => await axiosInstance.get(`/message/${id}`);

export const deleteMessage = async (id) => await axiosInstance.delete(`/message/${id}`);
