import { KNOWN_ROLES } from "../common/constant";
import { getParam } from "../helpers/getParam";
import axiosInstance from "../utils/axios/auth";

const getAllPropertyOwner = (params) => axiosInstance.get("/property-owner", getParam(params));
const getAllProspectingOwner = (params) => axiosInstance.get("/property-owner/prospecting", getParam(params));
const getOwnerDetails = (id) => axiosInstance.get(`/property-owner/${id}`);
const getDashboardData = () => axiosInstance.get(`/dashboard/fetch?user_type=${KNOWN_ROLES.OWNER}`);
const createPropertyOwner = (payload) => axiosInstance.post("/property-owner", payload);
const updatePropertyOwner = (params, payload) => axiosInstance.put(`/property-owner/${params}`, payload);
const deletePropertyOwner = (id) => axiosInstance.delete(`/property-owner/${id}`);

export {
    getAllProspectingOwner,
    getAllPropertyOwner,
    getOwnerDetails,
    getDashboardData,
    createPropertyOwner,
    updatePropertyOwner,
    deletePropertyOwner
};
