import React, { useState, useEffect } from 'react';
import { Card } from "reactstrap";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Link from '@mui/material/Link';
import SelectRoom from './selectRoom';
import SelectFlat from './selectFlat';
import * as Yup from "yup";
import { useFormik } from "formik";
import { KNOWN_ROLES, PROPERTY_TYPES, USER_STATUS } from '../../common/constant';
import { getAllUser } from '../../api/user';
import { SearchDropDownListPaginationComponent } from '../Common/SearchDropDownPaginationList';
import uuid from 'react-native-uuid';
import { useDispatch } from 'react-redux';
import { storePropertyBedDetails, storePropertyFlatDetails, storePropertyFloorDetails, storePropertyRoomDetails } from '../../store/property';
import { getPropertyDetails } from '../Hooks/PropertyHooks';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ActiveBhkRoom({ heading, disableFlat, basicPropertyData, emitPropertyData, propertyData }) {
    const { roomData, bedData, flatData } = getPropertyDetails()
    const floor = ["0th", "1st", "2nd", "3rd", "4th", "5th"]
    const [floorOptions, setFloorOptions] = useState(floor);
    const [onMounted, setOnMounted] = useState(false);
    const [update, setUpdate] = useState(0);
    const dispatch = useDispatch();

    const handleAddFloor = () => {
        setOnMounted(true)
        formik.setValues({
            ...formik.values,
            floorName: [...formik.values.floorName, {
                name: '',
                uniqueId: uuid.v4()
            }]
        })
    };

    useEffect(() => {
        if (!propertyData?.id) {
            const result = formik.values?.floorName?.map((item) => {
                if (!item.uniqueId) {
                    return { ...item, uniqueId: item?.uniqueId ? item.uniqueId : uuid.v4() }
                } else {
                    return item
                }
            })
            formik.setValues({ floorName: result })
            setUpdate(update + 1)
        }
    }, [])

    const handleRoomChange = (index, field, value) => {
        const updatedFloors = [...formik.values.floorName];
        updatedFloors[index][field] = value;
        formik.setValues({
            ...formik.values,
            floorName: [...updatedFloors]
        })
        setOnMounted(true)
        let options = []
        formik.values.floorName.forEach((item) => {
            options.push(item.name)
        })
        const diff = floor.filter((it) => {
            if (!options.includes(it)) {
                return it
            }
        })
        setFloorOptions(diff)
    };

    const removeFloor = (index) => {
        if (formik.values.floorName?.length === 1) {
            return;
        }
        const updatedFloors = [...formik.values.floorName];
        setOnMounted(true)
        if (basicPropertyData?.type === PROPERTY_TYPES.APARTMENT) {
            const flatUniqueId = flatData?.filter((item) => item.uniqueId === updatedFloors[index]?.uniqueId)
            const removeFlatData = flatData?.filter((fl) => fl.uniqueId !== updatedFloors[index]?.uniqueId)
            dispatch(storePropertyFlatDetails({
                flatData: removeFlatData
            }))
            if (roomData?.length > 0) {
                const roomUniqueId = roomData.filter((item) => item.uniqueId === flatUniqueId)
                const removeRoomData = roomData.filter((fl) => fl.uniqueId !== flatUniqueId)
                dispatch(storePropertyRoomDetails({
                    roomData: removeRoomData
                }))
                if (bedData?.length > 0) {
                    const removeBedData = bedData.filter((fl) => fl.uniqueId !== roomUniqueId)
                    dispatch(storePropertyBedDetails({
                        bedData: removeBedData
                    }))
                }
            }
        } else {
            const roomUniqueId = roomData?.filter((item) => item.uniqueId === updatedFloors[index]?.uniqueId)
            const removeRoomData = roomData?.filter((rm) => rm.uniqueId !== updatedFloors[index]?.uniqueId)
            dispatch(storePropertyRoomDetails({
                roomData: removeRoomData
            }))
            if (bedData?.length > 0) {
                const removeBedData = bedData.filter((fl) => fl.uniqueId !== roomUniqueId)
                dispatch(storePropertyBedDetails({
                    bedData: removeBedData
                }))
            }
        }
        updatedFloors.splice(index, 1);
        formik.setValues({
            floorName: [...updatedFloors]
        })
        let options = []
        updatedFloors.forEach((item) => {
            options.push(item.name)
        })
        const diff = floor.filter((it) => {
            if (!options.includes(it)) {
                return it
            }
        })
        setFloorOptions(diff)
    };

    useEffect(() => {
        if (propertyData?.floors?.length > 0) {
            let options = []
            const result = propertyData.floors?.map((fl) => {
                options.push(fl.name)
                return {
                    ...fl, name: fl.name,
                    ...(fl.user && { user_id: { name: fl.user?.name, id: fl.user?.id, code: fl.user?.code } }),
                    uniqueId: uuid.v4()
                }
            })

            const diff = floor.filter((it) => {
                if (!options.includes(it)) {
                    return it
                }
            })
            setFloorOptions(diff)
            formik.setValues({
                floorName: result
            })
            setUpdate(update + 1);
        }
    }, [propertyData])

    const formik = useFormik({
        initialValues: {
            floorName: [{
                name: '',
                user_id: '',
                uniqueId: ''
            }]
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            floorName: Yup.array().of(Yup.object().shape({
                name: Yup.string()
                    .required("Please enter property floor name")
            })).required().min(1),
            name: Yup.string().optional()
        })
    });

    useEffect(() => {
        if (formik.values.floorName?.length > 0 && onMounted) {
            let floors = []
            let x = JSON.parse(JSON.stringify(formik.values.floorName));
            dispatch(storePropertyFloorDetails({
                floorData: x
            }))
            formik.values.floorName.forEach((floor) => {
                if (floor.name && ((!basicPropertyData?.ownerId && basicPropertyData?.type === PROPERTY_TYPES.PERSONAL_HOME) ? floor.user_id : true)) {
                    floors.push({
                        name: floor.name,
                        ...(floor.user_id && {
                            user_id: floor.user_id
                        })
                    })
                }
            })
            emitPropertyData(floors, 'floor')
        }
    }, [formik.values.floorName])

    const theme = createTheme({
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {
                color: 'red', // Change the color here
              },
            },
          },
        },
      });

    return (
        <div className="bed_matrix_section" key={update}>
            <h5>{heading}</h5>
            <Card className="p-3" style={{ overflowX: 'auto' }}>
                <div className="roomWrapper">
                    {formik.values.floorName?.length > 0 && formik.values.floorName.map((floor, index) => (
                        <div key={index} className="roomWrap d-flex ">
                            <div className="floorWrapper">
                                <div className='d-flex align-items-center'>
                                    <Autocomplete
                                        disablePortal
                                        name={`floorName[${index}].name`}
                                        value={floor?.name ?? ''}
                                        id="combo-box-demo"
                                        key={index}
                                        options={floorOptions}
                                        disableClearable
                                        onChange={(event, newValue) => {
                                            handleRoomChange(index, 'name', newValue)
                                        }}
                                        sx={{ minWidth: 150, marginRight: '10px' }}
                                        renderInput={(params) => <ThemeProvider theme={theme}><TextField {...params} label="Select Floor" size="small" required/> </ThemeProvider>}
                                    />
                                    {!basicPropertyData?.ownerId && !disableFlat && (
                                        <div style={{ minWidth: '200px', marginRight: '15px', maxWidth: '200px' }}>
                                            <ThemeProvider theme={theme}>
                                                <SearchDropDownListPaginationComponent
                                                    label="Select Floor Owner"
                                                    searchEnabled={false}
                                                    listCall={getAllUser}
                                                    disableClearable={propertyData?.id}
                                                    defaultValue={floor?.user_id?.id ? floor?.user_id : null}
                                                    emitItem={(newValue) => {
                                                        handleRoomChange(index, 'user_id', newValue ? newValue : "")
                                                    }}
                                                    apiParams={{
                                                        filter_fields: ['role', 'status'],
                                                        filter_inputs: [`${KNOWN_ROLES.PROSPECTING_OWNER},${KNOWN_ROLES.OWNER}`, USER_STATUS.ACTIVE],
                                                    }}
                                                    sx={{ minWidth: 150, marginRight: '15px' }}
                                                    size="small"
                                                    required
                                            />
                                            </ThemeProvider>
                                        </div>
                                    )}
                                    <CancelOutlinedIcon color="error" onClick={() => {
                                        removeFloor(index)
                                    }} className={`cancelIcon ${index === 0 ? 'fadedCross' : ''}`} />
                                </div>
                            </div>
                            {(disableFlat === true && basicPropertyData?.type === PROPERTY_TYPES.APARTMENT ?
                                <SelectFlat
                                    previousData={flatData?.length > 0 ? flatData : undefined}
                                    uniqueId={floor.uniqueId}
                                    basicPropertyData={basicPropertyData}
                                    floorData={formik.values.floorName[index]}
                                    propertyData={propertyData ? formik.values.floorName[index] : undefined}
                                    emitPropertyData={emitPropertyData}
                                /> : <div style={{ marginLeft: '15px' }} >
                                    <SelectRoom
                                        previousData={roomData?.length > 0 ? roomData : undefined}
                                        uniqueId={floor?.uniqueId}
                                        basicPropertyData={basicPropertyData}
                                        flatData={{ flatData: formik.values.floorName[index] }}
                                        emitPropertyData={emitPropertyData}
                                        propertyData={propertyData ? formik.values.floorName[index] : undefined}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <Link href="javascript:void(0)" underline="none" onClick={handleAddFloor} >
                        <AddOutlinedIcon /> &nbsp; Add Floor
                    </Link>
                </div>
            </Card>
        </div>
    )
}