import axiosInstance from "../utils/axios/auth";
import { getParam } from "../helpers/getParam";

const getAllPermissions = async () => await axiosInstance.get('/permission');
const roleCreate = async (payload) => await axiosInstance.post('/role', payload); 
const roleListing = async (query) => await axiosInstance.get('/role', { ...getParam(query) });
const fetchRoleWithPermissions = async (id) => await axiosInstance.get(`/role/${id}`);
const toggleRolePermission = async (role_id, permission_id) => await axiosInstance.put(`/role/${role_id}/permission/${permission_id}`);
const roleUpdate = async (id, payload) => await axiosInstance.put(`/role/${id}`, payload);
const removeRole = async (id) => await axiosInstance.delete(`/role/${id}`);

export {
    roleListing,
    fetchRoleWithPermissions,
    toggleRolePermission,
    getAllPermissions,
    roleCreate,
    roleUpdate,
    removeRole,
}