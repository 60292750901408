import React, { useState, useEffect, useRef } from "react";
import ApexCharts from 'apexcharts';
import { fetchPaymentData } from "../../api/dashboard";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PAYMENT_TYPES } from "../../common/constant";

export function ApexRentChart({ module }) {
  const [rentData, setRentData] = useState();
  const [personName, setPersonName] = React.useState(["weekly"]);
  const [filterRange, setFilterRange] = useState('weekly')

  useEffect(() => {
    setRentData()
    fetchRentPaymentList()
  }, [module, filterRange])

  const fetchRentPaymentList = async () => {
    try {
      let payload = module === 'Rent' ? { payment_type: PAYMENT_TYPES.RENT } : { payment_type: PAYMENT_TYPES.PAYOUT }
      const res = (await fetchPaymentData({ ...payload, date_range: filterRange })).data.data;
      setRentData(res)
    } catch (error) {
      console.log(error);
    }
  };

  const dataChart = {
    chart: {
      height: 280,
      type: "area",
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    series: [{
      name: "Price",
      data: rentData?.length > 0 ? rentData.map((item) => item.total_amount ?? 0) : [],

    }],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      categories: rentData?.length > 0 ? rentData.map((item) => item.monthName) : [],
      labels: {
        rotate: -90,
        rotateAlways: true,
        hideOverlappingLabels: true,
        show: true,
      },
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'Price',
        offsetX: 5,
      },
      opposite: false
    },
    colors: ['#0ab39c'],
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = new ApexCharts(chartRef.current, dataChart);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [rentData]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 160,
      },
    },
  };

  const names = [
    'weekly',
    'monthly',
    'yearly',
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setFilterRange(value)
    setRentData()
  };

  return (
    <div className='chart_box'>
      <div className='upper_card d-flex justify-content-between align-items-center'>
        <h5 style={{ color: '#565656' }}>{module} Vs Time</h5>
        <div className="float-end">
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={personName}
            onChange={handleChange}
            MenuProps={MenuProps}
            style={{ width: 111, height: 31, fontSize: '12px', backgroundColor: '#fff' }}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div ref={chartRef} />
    </div>

  );
}