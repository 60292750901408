import { sendPasswordResetLink } from "../common";
import { forgetPasswordConfirm, recoverPassword, verifyOtp } from "../user";
import { toast } from "react-toastify";

export const handleRecoverPassword = async (values) => {
    try {
        const response = await recoverPassword(values)
        if (response.status === 200) {
            return toast.success(response.data.message);
        } else {
            return toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message)
        return Promise.reject(error)
    }
}

export const handleVerifyOpt = async (values) => {
    try {
        const response = await verifyOtp(values)
        if (response.status === 200) {
            return toast.success(response.data.message);
        } else {
            return toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message)
        return Promise.reject(error)
    }
}

export const handleSetPassword = async (values) => {
    try {
        const response = await forgetPasswordConfirm(values)
        if (response.status === 200) {
            return toast.success(response.data.message);
        } else {
            return toast.error(response.data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message)
        return Promise.reject(error)
    }
}

export const handlePasswordResetLink = async (userId) => {
    try {
      const response = await sendPasswordResetLink(userId);
      if(response?.data?.data?.success === true ) return toast.success(response?.data?.message)
      if(response?.data?.data?.success === false ) return toast.warning(response?.data?.message)
      } catch (e) {
        if(e?.response?.data?.message) toast.error(e.response.data.message);
        else toast.error(e?.message);
        console.log(e);
      }
  }
