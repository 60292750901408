import { getParam } from '../helpers/getParam';
import axiosInstance from '../utils/axios/auth';


const fetchTenantOfaPropertyForMonth = async (params,property_id) => await axiosInstance.get(`/tenant-bill-slip/fetch/${property_id}`, getParam(params));
const fetchAllProperty = async (params) => await axiosInstance.get(`/tenant-bill-slip/fetch/property-list`,getParam(params));
const createElectricityBill = async(property_id,month,year,payload)=> await axiosInstance.post(`/tenant-bill-slip/create/${property_id}/${month}/${year}`, payload); 
const fetchBillDataPropertyMonthWise = async (params) => await axiosInstance.get(`/tenant-bill-slip/fetch-all/tenant-bill`,getParam(params));
const fetchRentSlipData = async(params,id) => await axiosInstance.get(`/tenant-bill-slip/fetch/property-bill/${id}`,getParam(params));
const updateRentBill = async(id,payload) => await axiosInstance.put(`/tenant-bill-slip/update/property-rent-bill/${id}`,payload)
const publishBill = async(id)=> await axiosInstance.put(`/tenant-bill-slip/publish/property-rent-bill/${id}`);
const deleteBill = async (id) => await axiosInstance.put(`/tenant-bill-slip/remove/property-rent-bill/${id}`);
const fetchTenantBillsForOwner = async (params, query) => await axiosInstance.get(`/tenant-bill-slip/owner/${params}`, getParam(query));

export {
  createElectricityBill,
  fetchAllProperty,
  fetchTenantOfaPropertyForMonth,
  fetchBillDataPropertyMonthWise,
  fetchRentSlipData,
  updateRentBill,
  publishBill,
  deleteBill,
  fetchTenantBillsForOwner
};
