import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card,
} from 'reactstrap';
import Button from '@mui/material/Button';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Import Components
import MiniWidget from '../../components/Dashboard/mini-widget';
import LatestActivites from '../../components/Dashboard/latestActivites';
import LatestTransaction from '../../components/Dashboard/latest-transaction';
import CustomBarChart from '../../components/Dashboard/custom_barchart'; // Adjust import path
import { getAllDashboardData } from '../../api/dashboard';
import { useNavigate } from 'react-router-dom';
import { TIME_INTERVAL } from '../../common/constant';
import { LatestTransactionRefund } from '../../components/Dashboard/latest-transaction -refund';
import { ApexRentChart } from '../../components/Dashboard/rent-chart';

function Dashboard() {
  document.title = ' Dashboard | Minible - Responsive Bootstrap 5 Admin Dashboard';
  const [dasboardData, setDashboardData] = useState();
  const [filterDate, setFilterDate] = useState(TIME_INTERVAL.THIS_MONTH);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllDashboardData({ date_range: filterDate })
  }, [filterDate])

  const fetchAllDashboardData = async (params) => {
    try {
      const response = (await getAllDashboardData(params)).data.data;
      setDashboardData(response)

    } catch (err) {
      console.log(err)
    }
  }

  const reportWithoutTimeSpecific = [
    {
      id: 1,
      icon: 'mdi mdi-bed-king-outline',
      title: 'Vacant Beds in all properties',
      value: dasboardData?.vacantBedCount,
      suffix: '',
      ...(dasboardData?.vacantBedCount < 10 && {
        prefix: '0',
      }),
      color: '#FAA61B',
      backgroundColor: '#FFECCE',
      className: 'upper_five',
    },
    {
      id: 2,
      icon: 'mdi mdi-bed-king-outline',
      title: `total beds in ${dasboardData?.allPropertiesCount} properties`,
      value: dasboardData?.allBedCount,
      suffix: '',
      ...(dasboardData?.allBedCount < 10 && {
        prefix: '0',
      }),
      color: '#FAA61B',
      backgroundColor: '#FFECCE',
      className: 'upper_five',
    },
  ]

  const reports = [
    {
      id: 1,
      icon: 'mdi mdi-account-minus-outline',
      title: 'PG Leaving',
      value: dasboardData?.leavingCount,
      ...(dasboardData?.leavingCount < 10 && {
        prefix: '0',
      }),
      color: '#ED3542',
      backgroundColor: '#FFE8EA',
      className: 'upper_five',
    },
    {
      id: 2,
      icon: 'mdi mdi-account-check-outline',
      title: 'New Booking',
      value: dasboardData?.newBookingCount,
      ...(dasboardData?.newBookingCount < 10 && {
        prefix: '0',
      }),
      color: '#0DAA13',
      backgroundColor: '#E6FFCE',
      className: 'upper_five',
    },
    {
      id: 3,
      icon: 'mdi mdi-comment-account-outline',
      title: 'Owner’s Open Complain',
      value: dasboardData?.ownerComplainTicketCount,
      ...(dasboardData?.ownerComplainTicketCount < 10 && {
        prefix: '0',
      }),
      color: '#6C38C2',
      backgroundColor: '#E9DBFF',
      className: 'upper_five',
    },
    {
      id: 4,
      icon: 'mdi mdi-message-text-outline',
      title: 'Tenant’s Open Complain',
      value: dasboardData?.tenantComplainTicketCount,
      ...(dasboardData?.tenantComplainTicketCount < 10 && {
        prefix: '0',
      }),
      color: '#FAA61B',
      backgroundColor: '#FFECCE',
      className: 'upper_five',
    },
    {
      id: 5,
      icon: 'mdi mdi-cash-multiple',
      title: 'Total Payment Collected',
      value: dasboardData?.totalPaymentCollected?._sum?.total_amount,
      prefix: '₹ ',
      color: '#0DAA13',
      backgroundColor: '#E6FFCE',
      className: 'lower_four',
    },
    {
      id: 6,
      icon: 'mdi mdi-cash-multiple',
      title: 'Total Amount of Payout',
      value: dasboardData?.totalPayoutAmount?._sum?.total_amount,
      prefix: '₹ ',
      color: '#6C38C2',
      backgroundColor: '#E9DBFF',
      className: 'lower_four',
    },
    {
      id: 7,
      icon: 'mdi mdi-cash-multiple',
      title: 'Rent Pending in All Properties',
      value: dasboardData?.totalRentPending?._sum?.total_amount,
      prefix: '₹ ',
      color: '#FAA61B',
      backgroundColor: '#FFECCE',
      className: 'lower_four',
    },
    {
      id: 8,
      icon: 'mdi mdi-cash-multiple',
      title: 'Payout Pending for All Properties',
      value: dasboardData?.totalPayoutPending?._sum?.total_payout_amount,
      prefix: '₹',
      color: '#FAA61B',
      backgroundColor: '#FFECCE',
      className: 'upper_five',
    },
  ];

  return (
    <div className="page-content dashboard_pg">
      <Container fluid>
        <Row>
          <Col xl={8} className="add_card_padding" style={{ paddingRight: '0' }}>
            <Breadcrumbs title="Minible" reports={reportWithoutTimeSpecific} breadcrumbItem="Stats" emitFilterItem={(data) => setFilterDate(data)} />
            <div className='dashboard_upper_cards'>
              <MiniWidget reports={reports} />
            </div>
          </Col>
          <Col xl={4}>
            <LatestActivites />
          </Col>
        </Row>
        <Row>
          <Col xl={4} className='mb-3'>
            <div className='shadow_card'>
              <Card>
                <ApexRentChart module="Rent" />
              </Card>
            </div>
          </Col>
          <Col xl={4} style={{ padding: '0'}} className='mb-3 add_card_padding'>
            <div className='shadow_card'>
              <Card>
                <ApexRentChart module="Payout" />
              </Card>
            </div>
          </Col>
          <Col xl={4}>
            <div className='shadow_card mb-3'>
              <Card>
                <CustomBarChart /> 
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className='mb-2'>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h4 className='font-size-16 fullname_color mb-0'>Payout History</h4>
              <Button variant="outlined" className="dash_table_show_all" onClick={() => navigate('/payout-slips')} >Show all</Button>
            </div>
            <div className='shadow_card'>
              <LatestTransaction tableHeaderBg="#ED3542" tableColor="#fff" />
            </div>
          </Col>
          <Col xl={6} className='mb-2'>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <h4 className='font-size-16 fullname_color mb-0'>Deposit Refund List</h4>
              <Button variant="outlined" className="dash_table_show_all" onClick={() => navigate('/deposit-refund')}>Show all</Button>
            </div>
            <div className='shadow_card'>
              <LatestTransactionRefund tableHeaderBg="#FAA61B" tableColor="#fff" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
