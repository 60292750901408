import React, { useEffect, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';
import { TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { handleKeyDown } from '../../helpers/string_helper';

function PropertyOwnerFilter({ isOpen, toggleDrawer, emitFilterItem, selectedFields, selectedInputs }) {
  const [isExistingOwnerFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({
    name: '',
    email: '',
    contact_number: '',
    occupation: '',
  });
  const filterInputs = [];
  const filterFields = [];

  const handleFilter = () => {
    if (filterObject.name) {
      filterFields.push('name');
      filterInputs.push(filterObject.name);
    }

    if (filterObject.email) {
      filterFields.push('email');
      filterInputs.push(filterObject.email);
    }

    if (filterObject.contact_number) {
      filterFields.push('contact_number');
      filterInputs.push(filterObject.contact_number);
    }

    if (filterObject.occupation) {
      filterFields.push('occupation');
      filterInputs.push(filterObject.occupation);
    }

    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
  };

  const resetFilter = () => {
    filterInputs.splice(0, filterInputs.length);
    filterFields.splice(0, filterFields.length);
    emitFilterItem(filterFields, filterInputs);
    toggleDrawer();
    setFilterObject({
      name: '',
      email: '',
      contact_number: '',
      occupation: '',
    })
  };

  useEffect(()=> {
    selectedFields.map((field, index) => {
      setFilterObject((prev)=> ({...prev, [field]:selectedInputs[index]}))
    })
  }, [])

  return (
    <Offcanvas
      isOpen={isOpen}
      direction='end'
      toggle={() => toggleDrawer(!isExistingOwnerFilter)}>
      <OffcanvasHeader
        toggle={() => {
          toggleDrawer(!isExistingOwnerFilter);
        }}>
        Property Owner Filter
      </OffcanvasHeader>
      <OffcanvasBody>
        <Form>
          <div className=''>
            <Row>
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='name'
                  value={filterObject.name}
                  label='Property Owner Name'
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='email'
                  value={filterObject.email}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      email: e.target.value,
                    });
                  }}
                  label='Property Owner Email'
                />
              </div>
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='contact_number'
                  label='Property Owner Contact Number'
                  value={filterObject.contact_number}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      contact_number: e.target.value,
                    });
                  }}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                }}
                />
              </div>
              <div className='col-md-6 col-12 mb-3'>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  name='occupation'
                  label='Property Owner Occupation'
                  value={filterObject.occupation}
                  onInput={(e) => {
                    setFilterObject({
                      ...filterObject,
                      occupation: e.target.value,
                    });
                  }}
                />
              </div>
            </Row>
            <div className='formActions btn_right'>
              <Button
                className='yellow_gradient_btn me-2'
                onClick={handleFilter}>
                Apply Filter
              </Button>
              <Button className='red_gradient_btn' onClick={resetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
}

PropertyOwnerFilter.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  emitFilterItem: PropTypes.func,
};

export default PropertyOwnerFilter;
