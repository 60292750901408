import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import NodataFound from "../Common/NoDataFound";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));

function PreviewRaters({ children, raters = [] }) {
  const [isPreviewRatersOpen, setPreviewRatersOpen] = useState(false);
  return (
    <>
      <span onClick={() => setPreviewRatersOpen(!isPreviewRatersOpen)}>
        {children}
      </span>
      <Modal
        size="sm"
        isOpen={isPreviewRatersOpen}
        centered={true}
        scrollable={true}
        fade={true}
        toggle={() => setPreviewRatersOpen(!isPreviewRatersOpen)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setPreviewRatersOpen(!isPreviewRatersOpen)}>
          Preview Raters
        </ModalHeader>
        <ModalBody style={{ width: 'inherit', height: '300px', alignContent: 'center' }}>
          <div style={{ alignContent: 'center' }}>
            {raters.length > 0 ?
              raters.map((rater) => (
                <Box key={rater.id} sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                  <Item
                    sx={{
                      my: 1,
                      mx: 'auto',
                      p: 2,
                    }}
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Avatar>{rater.name[0]}</Avatar>
                      <Stack sx={{ minWidth: 0 }}>

                        <Typography noWrap>{rater.name}</Typography>
                        <Typography noWrap>{`${rater.rating / 2} out of 5`}</Typography>
                      </Stack>
                    </Stack>
                  </Item>
                </Box>
              ))
              :
              (<div style={{ textAlign: 'center' }}>
                <NodataFound text='No raters to show' />
              </div>)}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

PreviewRaters.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  file: PropTypes.object,
};

export default PreviewRaters;
